import InputField from 'containers/MainContent/Orcatec/components/Input'
import SelectField from 'containers/MainContent/Orcatec/components/Select'
import EditButton from 'containers/MainContent/Orcatec/components/buttons/EditButton'
import SearchAddress from 'containers/MainContent/Orcatec/components/SearchAddress'
import { useAppSelector } from 'store/Orcatec/hooks'
import validator from 'validator'
import { useTranslation } from 'react-i18next'

import './styles.scss'

const AddressForm = ({
  property,
  isNew,
  onChange,
  onChooseAddress,
  canEdit,
  toogleEditMode,
  editMode,
  errors,
  manualInput,
  toggleManualInput,
  isInstantAppointment,
  onAddressError,
  required = true,
  resetFromParent,
  isAppointment,
}) => {
  const { t } = useTranslation('property')
  const { t: tCommon } = useTranslation('common')
  const company = useAppSelector(state => state.orcatec.company)

  const options = [
    {
      id: 1,
      name: t('type.commercial'),
    },
    {
      id: 2,
      name: t('type.residential'),
    },
  ]

  return (
    <div className='address-fields'>
      <div style={{ marginBottom: '1rem' }}>
        {!!manualInput ? (
          <InputField
            label={tCommon('heading.customAddress')}
            name='address'
            required
            value={property?.address || ''}
            onChange={onChange}
            disabled={!editMode && !manualInput}
          />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            {isNew && company.id === 264 && isAppointment && (
              <SearchAddress
                searchByRelation
                label={tCommon('heading.searchByProjectName')}
                onChooseAddress={onChooseAddress}
              />
            )}
            <div className='address-row'>
              <SearchAddress
                disabled={
                  !editMode && !manualInput && !isNew && !isInstantAppointment
                }
                initialValue={property.address}
                label={
                  isNew
                    ? tCommon('heading.searchAddress')
                    : tCommon('heading.propertyAddress')
                }
                onChooseAddress={onChooseAddress}
                onClickManualInput={toggleManualInput}
                // onChangeSearchValue={handleChangeSearchValue}
                resetFromParent={resetFromParent}
                required={required}
                onError={onAddressError}
              />

              {!isNew && canEdit && !manualInput && (
                <EditButton onClick={toogleEditMode} />
              )}
            </div>
          </div>
        )}
      </div>
      {(!isNew || (isNew && (manualInput || !!property.choosen))) && (
        <>
          <div className='address-row'>
            <InputField
              label={t('zip')}
              name='postcode'
              value={property?.postcode || ''}
              onChange={e => {
                if (
                  !!e.target.value.length &&
                  !validator.isAlphanumeric(e.target.value, 'en-US', {
                    ignore: ' -',
                  })
                ) {
                  return
                }

                onChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value.toUpperCase(),
                  },
                })
              }}
              className='zip'
              inputProps={{
                maxLength: 10,
              }}
              error={errors}
              disabled={!editMode && !manualInput}
            />
            <InputField
              label={tCommon('heading.city')}
              name='city'
              value={property?.city || ''}
              onChange={onChange}
              className='city'
              disabled={!manualInput}
              error={errors}
            />
            <InputField
              label={tCommon('heading.state')}
              name='state'
              value={property?.state || ''}
              onChange={onChange}
              disabled={!manualInput}
              error={errors}
            />
          </div>
          <div className='address-row'>
            <InputField
              label={tCommon('heading.unit')}
              name='unit'
              value={property?.unit || ''}
              onChange={onChange}
              className='unit'
              inputProps={{ maxLength: 30 }}
              // onBlur={onBlurUnitField}
              error={errors}
            />
            <SelectField
              label={tCommon('heading.propertyType')}
              name='type'
              value={property?.type || ''}
              onChange={onChange}
              options={options}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default AddressForm
