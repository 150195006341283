import { useEffect, useState } from 'react'

import { createProposal } from 'api/Proposal'

import AppointmentSources from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'

import { openNotificationWithIcon } from '../../../../../../../helpers/notifications/openNotificationWithIcon'
import useProjectSettings from 'hooks/useProjectSettings'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  ModuleName,
  ProjectPermissions,
  TypeSourcePermissions,
} from 'features/Settings/UsersAndGroups'
import {
  selectUserPermissionsByModule,
  selectUserPermissionsByName,
} from 'features/Settings/UsersAndGroups/permissionSlice'
// import useTechnicians from 'hooks/useTechnicians'
import { Checkbox } from 'antd'
import { IScope } from 'features/Settings/UsersAndGroups/types'
import { fetchUsersForTemplate } from 'api/User'
import './styles.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import { InfoCircleOutlined } from '@ant-design/icons'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
import { useHistory } from 'react-router-dom'
import { useTourGuide } from 'features/Support/hooks/useTourGuide'
import { useTranslation } from 'react-i18next'

const CreateProposal = ({ onCancel, entity, fromAppointment }) => {
  const history = useHistory()
  const { isActive: tourIsActive, handleUserAction } = useTourGuide(
    'projectsTable',
  )
  const { t } = useTranslation(['project', 'common'])

  const industries = useAppSelector(state => state.orcatec.user?.me?.industries)

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const projectSettings = useProjectSettings()
  // const [industries, setIndustries] = useState(industriesList)
  const me = useAppSelector(state => state.orcatec.user.me)

  const [technicians, setTechnicians] = useState([])
  const [proposalData, setProposalData] = useState({
    industry_id: entity?.industry_id || industries?.[0]?.id,
    source_id: entity?.appointment_source_id || null,
    type_id: entity?.appointment_type_id || null,
    status: 1,
    user_id: me.id,
    copy_notes: false,
  })
  const [defaultTemplate, setDefaultTemplate] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleChangeFields = e => {
    const { name, value } = e.target

    if (error) setError(null)

    setProposalData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleCreateProposal = async () => {
    setLoading(true)
    const data = !entity
      ? proposalData
      : {
          ...proposalData,
          [entity.type]: entity.id,
        }

    try {
      const resp = await createProposal(data)
      openNotificationWithIcon('success', {
        message: t('createProject.projectCreated'),
      })

      if (tourIsActive) handleUserAction()

      history.push(`/projects/${resp?.id}`)

      // IT WORK ON IOS PLATFORM
      /*  setTimeout(() => {
        window.open(`/projects/${resp?.id}`, '_blank')
      }, 0) */
      // onCancel()
    } catch (error) {
      setError(error?.response?.data)
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || error?.message,
      })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (!!projectSettings?.industry_id && !entity?.industry_id)
      setProposalData(prev => ({
        ...prev,
        industry_id: projectSettings.industry_id,
      }))
  }, [projectSettings?.industry_id])

  useEffect(() => {
    const getTech = async () => {
      const res = await fetchUsersForTemplate()

      setTechnicians(
        res?.users?.map(({ id, full_name: name }) => ({ id, name })),
      )

      if (res.template_name) setDefaultTemplate(res.template_name)
    }
    getTech()
  }, [])

  return (
    <div className='create-proposal'>
      <PlanLimits entity='projects' />

      <AccessControl
        module={ModuleName.PROJECT}
        scopes={[ProjectPermissions.PROJECT_CAN_CREATE]}
        additionalAccess={
          permissions[ProjectPermissions.PROJECT_CAN_CREATE] === IScope.ALL
        }
      >
        <Select
          options={technicians}
          label={t('createProject.assignedTo')}
          value={proposalData?.user_id}
          name='user_id'
          onChange={handleChangeFields}
        />
      </AccessControl>

      <div data-testid='projects-modal--type-and-source'>
        <div style={{ marginBottom: '1rem' }}>
          <AppointmentSources
            label={t('createProject.leadSource')}
            name='source_id'
            value={proposalData?.source_id}
            onChange={handleChangeFields}
            error={error}
            disableCRUD={!canEditTypeSource}
          />
        </div>

        <AppointmentTypes
          label={t('createProject.type')}
          name='type_id'
          value={proposalData?.type_id}
          onChange={handleChangeFields}
          error={error}
          disableCRUD={!canEditTypeSource}
          fullWidth
        />
      </div>

      {!!industries && industries?.length > 1 && (
        <Select
          data-testid='projects-modal--industry'
          label={t('createProject.industry')}
          name='industry_id'
          value={proposalData?.industry_id}
          onChange={handleChangeFields}
          options={industries}
          customValue={industries?.find(
            i => i.id === projectSettings?.industry_id,
          )}
        />
      )}
      {fromAppointment && (
        <Checkbox
          checked={proposalData.copy_notes}
          onChange={e =>
            handleChangeFields({
              target: { name: 'copy_notes', value: e.target.checked },
            })
          }
        >
          {t('createProject.copyNotes')}
        </Checkbox>
      )}

      {!!defaultTemplate && (
        <p style={{ fontStyle: 'italic', color: 'grey' }}>
          <InfoCircleOutlined style={{ color: '#4285f4' }} />{' '}
          {t('createProject.useTemplate')} -{' '}
          <span style={{ fontWeight: 500 }}>{defaultTemplate}</span>
        </p>
      )}

      <div className='buttons'>
        <MainButton
          title={t('button.cancel', { ns: 'common' })}
          type='cancel'
          onClick={onCancel}
        />
        <MainButton
          title={t('button.create', { ns: 'common' })}
          onClick={handleCreateProposal}
          isFetching={loading}
          data-testid='projects-modal--create-project'
        />
      </div>
    </div>
  )
}

export default CreateProposal
