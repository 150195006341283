import { ErrorBoundary } from '@sentry/react'
import { Badge, Collapse, Skeleton } from 'antd'
import { getPropertyForms } from 'api/Property'
import { FormsBlock } from 'features/Forms/components/FormsBlock/FormsBlock'
import { CustomForm } from 'features/Forms/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import styled from 'styled-components'

const { Panel } = Collapse

export const PropertyForms = ({ propertyId }: { propertyId: number }) => {
  const dictionary = useAppSelector(selectJobDictionary)
  const { t } = useTranslation('property')

  const [propertyForms, setPropertyForms] = useState<
    Record<
      string,
      { code: string; id: number; name?: string; forms: CustomForm[] }[]
    >
  >({})
  const [loading, setLoading] = useState(false)

  const getData = async () => {
    setLoading(true)
    const res = await getPropertyForms(propertyId)
    setPropertyForms(res)
    setLoading(false)
  }

  useEffect(() => {
    if (propertyId) getData()
  }, [])

  const onFormDeleted = async () => {
    const res = await getPropertyForms(propertyId)
    setPropertyForms(res)
  }

  if (loading)
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Skeleton.Input
          active
          size='small'
          style={{ width: 200, borderRadius: 4 }}
        />
        <Skeleton.Input
          active
          size='small'
          style={{ width: 200, borderRadius: 4 }}
        />
        <Skeleton.Input
          active
          size='small'
          style={{ width: 200, borderRadius: 4 }}
        />
      </div>
    )

  if (!Object.entries(propertyForms).length) {
    return <p>{t('tabs.forms.noForms')}</p>
  }

  return (
    <Wrapper>
      <Collapse ghost defaultActiveKey={['0', '1', '2']}>
        {Object.entries(propertyForms).map(([entityName, entities], index) => (
          <Panel
            key={index}
            header={entityName === 'jobs' ? dictionary.plural : entityName}
            style={{
              textTransform: 'capitalize',
              fontWeight: 500,
            }}
          >
            <Collapse ghost>
              {entities.map(entity => (
                <Panel
                  key={entity.id}
                  header={
                    <Heading>
                      <Link
                        to={`/${getEntitySlug(entityName)}/${entity.id}`}
                        target='_blank'
                        onClick={e => e.stopPropagation()}
                      >
                        {entity.code}
                        {entityName === 'projects' && entity.name
                          ? ` - ${entity.name}`
                          : ''}
                      </Link>
                      <Badge
                        style={{ background: '#4688f133', color: '#4688f1' }}
                        count={entity?.forms?.length}
                        title='Forms count'
                      />
                    </Heading>
                  }
                >
                  <ErrorBoundary>
                    <FormsBlock
                      id={entity?.id}
                      relatedForms={entity.forms}
                      hideTitle
                      onFormDelete={onFormDeleted}
                    />
                  </ErrorBoundary>
                </Panel>
              ))}
            </Collapse>
          </Panel>
        ))}
      </Collapse>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-collapse-ghost
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 7px 15px;
  }

  .ant-collapse-expand-icon {
    position: relative;
    top: -2px;
  }
`
const Heading = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const getEntitySlug = (entityName: 'projects' | 'jobs' | 'appointments') =>
  entityName === 'projects'
    ? 'projects'
    : entityName === 'jobs'
    ? 'jobs'
    : 'appointment'
