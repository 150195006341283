import React from 'react'
import { createMarkupWithoutSpace } from 'helpers/createMarkup'

import './styles.scss'

const prepareLogos = data => {
  let logos = []
  if (data) {
    if (Array.isArray(data)) {
      logos = data
    } else {
      for (let i = 1; i <= 7; i++) {
        const logo = data[`logo${i}`] ? data[`logo${i}`] : {}
        logos.push(logo)
      }
    }
  } else {
    logos.push({})
  }

  return logos
}
const isEmpty = obj => Object.keys(obj)?.length === 0
const ProposalSettings = ({ settings }) => {
  const logos = settings?.logos
  let isLogos
  if (logos)
    isLogos =
      Object.keys(logos)?.length === 1 && !logos.logo4?.path ? false : true

  return (
    <div className='settings-header'>
      {isLogos && (
        <div className='proposal-logos'>
          {prepareLogos(logos).map((logo, idx, arr) => {
            return !isEmpty(logo) ? (
              <div
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  if (logo.company_link)
                    window.open(logo.company_link, '_blank')
                }}
                key={idx}
                className={`print-logo${idx === 3 ? ' main' : ''}`}
                style={{
                  backgroundImage: `url(${
                    typeof logo === 'string'
                      ? logo
                      : process.env.REACT_APP_API_MEDIA + logo.preview
                  })`,
                }}
              >
                {/* <img
                  src={
                    typeof logo === 'string'
                      ? logo
                      : process.env.REACT_APP_API_MEDIA + logo.preview
                  }
                  style={{
                    cursor: logo.company_link ? 'pointer' : 'default',
                  }}
                  alt=''
                /> */}
              </div>
            ) : (
              <div className={`print-logo${idx === 3 ? ' main' : ''}`}></div>
            )
          })}
        </div>
      )}
      <div
        style={{ padding: '0 5px', overflow: 'auto' }}
        className='proposal-header'
        dangerouslySetInnerHTML={createMarkupWithoutSpace(settings?.header)}
      />
    </div>
  )
}

export default ProposalSettings
