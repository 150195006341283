import { Checkbox, Tooltip } from 'antd'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'
import { useTranslation } from 'react-i18next'

const Location = ({
  isChecked,
  entity_id,
  changeEntityHeandler,
  properties,
  setEntityIdHeandler,
  withoutText = false,
}) => {
  const { t } = useTranslation('property')

  const options = properties?.map(prop => ({
    id: prop?.value?.id || prop.value?.fullProperty?.id || prop?.id,
    name:
      prop?.value?.full_address ||
      prop.value?.fullProperty?.full_address ||
      prop?.full_address,
  }))

  return (
    <div className='location__wrapper' style={{ marginBottom: 20 }}>
      <Checkbox
        checked={isChecked}
        onChange={changeEntityHeandler}
        disabled={!properties?.length}
      >
        <Tooltip
          title={
            !properties?.length && t('tabs.appointments.noAttachedProperties')
          }
        >
          {!withoutText ? t('tabs.appointments.appointmentInOffice') : ''}
        </Tooltip>
      </Checkbox>

      {!isChecked && !!properties?.length ? (
        <div style={{ maxWidth: 224, marginTop: 20 }}>
          <Select
            label={t('tabs.appointments.chooseProperty')}
            value={entity_id}
            options={options}
            onChange={e => setEntityIdHeandler(e.target.value)}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default Location
