import { Button, InfoPlate, Select, TextArea } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import AppointmentTypes from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTypes'
import useTechnicians from 'hooks/useTechnicians'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { EventKind } from 'types/Appointment'
import DatePicker from 'react-multi-date-picker'
import CustomTimePicker from 'containers/MainContent/Orcatec/components/CustomTimePicker'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'constants/dateFormats'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
// import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { useEventAssignToOptions } from 'hooks/useEventAssignToOptions'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import { CalendarOutlined } from '@ant-design/icons'
import { IEventSettings } from 'features/Settings/Dispatch/components/EventsTab/EventsTab'
import { EventGroupAPI } from 'features/Visits/api'
import { Popconfirm, Spin } from 'antd'
import { AppointmentTimezone } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentTimezone/AppointmentTimezone'
import { EventGroup, GroupEventsOperation } from 'features/Visits/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { useTranslation } from 'react-i18next'

interface Props {
  groupId: number
  eventKind: EventKind
  eventSettings: IEventSettings
  onClose: () => void
  onSave: () => void
  onGroupDelete: () => void
}

const initialState: EventGroup = {
  id: 0,
  event_name: '',
  appointment_type_id: null,
  preferred_technician_id: null,
  date: [],
  timezone: '',
  time_start: '',
  time_end: '',
  column_template_id: [],
  matrix_time_end: null,
  matrix_time_start: null,
}

export const GroupModal = ({
  groupId,
  eventSettings,
  eventKind,
  onClose,
  onSave,
  onGroupDelete,
}: Props) => {
  const { DELETE } = usePermissionsByModule(GroupEventsOperation)

  const { technicians: workers } = useTechnicians()
  const { start_hour, end_hour } = useAppSelector(selectDispatchSettings)
  const { t } = useTranslation(['appointment', 'common'])

  const [group, setGroup] = useState(initialState)
  const [error, setError] = useState({})
  const [loading, setLoading] = useState(false)

  const isJob = eventKind === EventKind.JOB

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      try {
        const res = await EventGroupAPI.getEventGroup(groupId)

        setGroup({
          ...res,
          column_template_id: [
            ...res.queue_id.map(id => id.toString()),
            ...res.column_template_id,
          ],
          date: res?.date.map(date => moment(date).format(DATE_FORMAT)),
          time_start:
            res.time_start || res.time_start === 0
              ? moment(moment().format('YYYY-MM-DD') + ' ' + res.time_start)
              : null,
          time_end: res.time_end
            ? moment(moment().format('YYYY-MM-DD') + ' ' + res.time_end)
            : null,
          matrix_time_end: res?.current_end_time
            ? moment()
                .hours(res.current_end_time)
                .set('minute', 0)
            : null,
          matrix_time_start:
            res?.current_start_time || res.current_start_time == 0
              ? moment()
                  .hours(res.current_start_time)
                  .set('minute', 0)
              : null,
        })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (groupId) {
      getData()
    }
  }, [])

  const { data: assignToOptions } = useEventAssignToOptions(group.date?.[0])

  const onChange = e => {
    const { name, value } = e.target

    setGroup(prev => ({
      ...prev,
      [name]: value,
      time_end:
        name === 'time_start' && value
          ? moment(value).add(eventSettings.promised_time_increment, 'hours')
          : name === 'time_end'
          ? value
          : prev.time_end,
      matrix_time_end:
        name === 'matrix_time_start' && value
          ? moment(value).add(1, 'hours')
          : name === 'matrix_time_end'
          ? value
          : prev.matrix_time_end,
    }))

    if (error[name]) {
      setError(prev => ({
        ...prev,
        [name]: null,
      }))
    }
  }

  const handleChangeTime = (time: moment.Moment | null, type: string) => {
    onChange({
      target: {
        name: type,
        value: time,
      },
    })
  }

  const handleChangeAssignTo = (_, value) => {
    onChange({
      target: {
        name: 'column_template_id',
        value,
      },
    })
  }

  const handleDateChange = (_, options) => {
    onChange({
      target: {
        name: 'date',
        value: options.validatedValue,
      },
    })
  }

  const handleGroupDelete = async () => {
    if (!DELETE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    try {
      await EventGroupAPI.deleteEventGroup(groupId)

      openNotificationWithIcon('success', {
        message: 'Group of events has been delete successfully',
      })

      onGroupDelete?.()
    } catch (error) {
      openNotificationWithIcon('error', {
        message: error?.reponse?.data?.message || 'Something went wrong',
      })
    }
  }
  const handleGroupSave = async () => {
    if (!group.column_template_id.length) {
      return setError({ column_template_id: 'This field is required' })
    }

    try {
      setLoading(true)
      await EventGroupAPI.updateEventGroup(groupId, {
        ...group,
        date: group.date.map(date => moment(date).format('YYYY-MM-DD')),
        column_template_id: group.column_template_id.filter(
          id => typeof id === 'number',
        ),
        queue_id: group.column_template_id.filter(id => typeof id === 'string'),
        time_start: group.time_start
          ? moment(group.time_start).format('HH:mm')
          : moment(group.matrix_time_start).format('HH:mm'),
        time_end: group.time_end
          ? moment(group.time_end).format('HH:mm')
          : moment(group.matrix_time_end).format('HH:mm'),
        current_start_time: group.matrix_time_start
          ? moment(group.matrix_time_start).hours()
          : null,
        current_end_time: group.matrix_time_end
          ? moment(group.matrix_time_end).hours()
          : null,
        matrix_time_start: group.matrix_time_start
          ? moment(group.matrix_time_start).format('HH:mm')
          : null,
        matrix_time_end: group.matrix_time_end
          ? moment(group.matrix_time_end).format('HH:mm')
          : null,
      })

      openNotificationWithIcon('success', {
        message: 'Group has been updated successfully',
      })

      onClose?.()
      onSave?.()
    } catch (error) {
      setError(error?.response?.data)
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      open
      title={t('group.title')}
      onCancel={onClose}
      footer={
        <Footer>
          <Popconfirm
            title={t('group.deleteEvents')}
            onConfirm={handleGroupDelete}
          >
            <Button danger style={{ marginRight: 'auto' }} loading={loading}>
              {t('group.delete')}
            </Button>
          </Popconfirm>
          <Button onClick={onClose}>
            {t('button.cancel', { ns: 'common' })}
          </Button>
          <Button onClick={handleGroupSave} type='primary' loading={loading}>
            {t('button.save', { ns: 'common' })}
          </Button>
        </Footer>
      }
      width={700}
    >
      <Spin spinning={loading}>
        <Content>
          <InfoPlate type='info' size='small' width='auto'>
            {t('group.anyChanges')}
          </InfoPlate>

          {isJob && (
            <TextArea
              name='event_name'
              value={group.event_name}
              onChange={onChange}
              label={t('group.eventName')}
              maxLength={255}
              error={error?.event_name}
            />
          )}

          <Type>
            <Label>{t('group.eventType')}</Label>
            <AppointmentTypes
              // label={`${dictionary.singular} Type`}
              withoutLabel
              name='appointment_type_id'
              value={group.appointment_type_id}
              onChange={onChange}
              disableCRUD
            />
          </Type>

          <Select
            options={workers.map(worker => ({
              label: worker.name,
              value: worker.id,
            }))}
            label={t('group.leadWorker')}
            value={group.preferred_technician_id || ''}
            name='preferred_technician_id'
            onChange={e => onChange(e)}
            placeholder={t('group.selectLeadWorker')}
            error={error?.preferred_technician_id}
          />

          {eventSettings?.display_timezone && (
            <AppointmentTimezone
              onChange={onChange}
              value={group?.timezone || eventSettings?.timezone}
              defaultValue={eventSettings?.timezone}
            />
          )}

          <DateSection>
            <Date error={!!error?.date}>
              <Label>{t('group.eventDate')}</Label>
              <DatePicker
                multiple
                format={DATE_FORMAT}
                value={
                  Array.isArray(group.date)
                    ? group.date
                    : moment(group.date).format(DATE_FORMAT)
                }
                onChange={handleDateChange}
                plugins={[
                  <DatePanel key={'panel'} />,
                  <Toolbar
                    key='toolbar'
                    position='bottom'
                    names={{ today: '', deselect: '', close: 'OK' }}
                  />,
                ]}
                inputClass='styled-datepicker'
                placeholder={t('group.selectTime')}
              />

              <CalendarOutlined />

              {error?.date ? <ErrorText>{error.date}</ErrorText> : ''}
            </Date>

            {eventSettings?.show_promise_time && (
              <TimeSection>
                <CustomTimePicker
                  title={t('heading.promisedArrivalTime')}
                  name='time_start'
                  onChange={(value, _valueNum, name) =>
                    handleChangeTime(
                      value ? moment(value, 'HH:mm ') : null,
                      name,
                    )
                  }
                  value={
                    group.time_start
                      ? moment(group.time_start).format('HH:mm')
                      : null
                  }
                  endWorkTime={23}
                  startWorkTime={0}
                  error={error?.time_start}
                  placeholder={t('group.selectTime')}
                  withMinute={true}
                  required={!!group.assign_to_matrix}
                  step={+eventSettings?.promised_time_increment}
                  style={{ width: '130px', height: 32 }}
                  short
                />

                <CustomTimePicker
                  name='time_end'
                  onChange={(value, valueNum, name) =>
                    handleChangeTime(moment(value, 'HH:mm '), name)
                  }
                  value={
                    group.time_end
                      ? moment(group.time_end).format('HH:mm')
                      : null
                  }
                  endWorkTime={23}
                  startWorkTime={0}
                  error={error?.time_end}
                  placeholder={t('group.selectTime')}
                  withMinute={true}
                  required={!!group.assign_to_matrix}
                  step={+eventSettings?.promised_time_increment}
                  short
                  style={{ width: '130px', height: 30 }}
                />
              </TimeSection>
            )}
          </DateSection>

          <AssagneeSection>
            <Select
              required
              label={t('group.assignedTo')}
              name='column_template_id'
              mode='multiple'
              value={group.column_template_id}
              options={transformData(assignToOptions)}
              onChange={handleChangeAssignTo}
              listItemHeight={32}
              error={error?.column_template_id}
            />

            {group.column_template_id.some(id => typeof id === 'number') && (
              <TimeSection>
                <CustomTimePicker
                  title={t('group.dispatchTime')}
                  name='matrix_time_start'
                  onChange={(value, valueNum, name) =>
                    handleChangeTime(moment(value, 'HH:mm '), name)
                  }
                  value={moment(group.matrix_time_start).format('HH:mm')}
                  endWorkTime={end_hour - 1}
                  startWorkTime={start_hour}
                  error={error?.matrix_time_start}
                  required={!!group.assign_to_matrix}
                  placeholder={t('group.selectTime')}
                  style={{ width: '130px', height: 30 }}
                  short
                />

                <CustomTimePicker
                  name='matrix_time_end'
                  onChange={(value, valueNum, name) =>
                    handleChangeTime(moment(value, 'HH:mm '), name)
                  }
                  value={
                    moment(group.matrix_time_end).hour() === 0
                      ? '24:00'
                      : moment(group.matrix_time_end).format('HH:mm')
                  }
                  endWorkTime={end_hour}
                  startWorkTime={start_hour}
                  error={error?.matrix_time_end}
                  required={!!group.assign_to_matrix}
                  // disabledTime={[moment(group.current_start_time).hour()]}
                  placeholder={t('group.selectTime')}
                  short
                  style={{ width: '130px', height: 30 }}
                />
              </TimeSection>
            )}
          </AssagneeSection>
        </Content>
      </Spin>
    </Modal>
  )
}

const transformData = data => {
  const groupedData = data.reduce((acc, item) => {
    // If the source does not exist in the accumulator, add it
    if (!acc[item.source]) {
      acc[item.source] = []
    }
    // Push the current item into the respective source group
    acc[item.source].push({
      label: item.name,
      value: item.id,
      key: item.name + item.id,
    })
    return acc
  }, {})

  // Transform the grouped data into the desired structure
  return Object.keys(groupedData).map(key => ({
    label: key,
    options: groupedData[key],
  }))
}

export const Content = styled.div`
  & > * {
    margin-bottom: 1rem;
  }

  .rmdp-container {
    width: 100%;
  }
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Type = styled.div``
export const Date = styled.div<{ error?: boolean }>`
  position: relative;

  & > span {
    position: absolute;
    right: 5px;
    top: 28px;
    font-size: 18px;
    pointer-events: none;
    color: #0000008a;
  }

  .styled-datepicker {
    width: 100%;
    border: 1px solid;
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
    border-radius: 2px;
    height: 32px;
    padding: 4px 25px 4px 11px;
    cursor: pointer;
  }
  .rmdp-toolbar {
    & > div {
      visibility: hidden;

      &:nth-child(3) {
        visibility: visible;
      }
    }
  }
`
export const Label = styled.p`
  font-size: 12px;
  color: #808080;
  line-height: 1.5;
  margin-bottom: 3px;
`

export const AssagneeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: center;

  .time-start {
    position: relative;

    &::before {
      position: relative;
      content: 'Promised arrival time:';
      left: 0;
      top: -23px;
      font-size: 0.8rem;
      width: 200px;
    }
  }

  .matrix-time__start {
    position: relative;

    &::before {
      position: absolute;
      content: 'Schedule time:';
      left: 0;
      top: -23px;
      font-size: 0.8rem;
      width: 200px;
    }
  }

  & > * {
    width: 100%;
  }
`

export const DateSection = styled(AssagneeSection)`
  margin-bottom: 25px !important;
`

export const TimeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: end;
`

export const Row = styled.div`
  display: flex;
  gap: 10px;
`

const ErrorText = styled.p`
  font-size: 12px;
  color: #f12832;
`
