import styled from 'styled-components'

export const SMSWrapper = styled.div`
  display: flex;
`
export const InputContainer = styled.div`
  width: 90%;
  padding-right: 10px;
`
export const VariablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: flex-end;
`
