import { Spin } from 'antd'
import { getFormByPreview } from 'api/CustomForms'

import { getComponentByType } from 'features/Settings/FormBuilder/utils/getComponentByType'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { createGlobalStyle } from 'styled-components'
import { CustomForm } from './types'
import { FORM_WIDTH } from './utils/constants'
// import moment from 'moment-timezone'

const GlobalStyle = createGlobalStyle`
  @media print {
    /* Set margins for printing */
     @page {
      margin: 1cm 0.5cm;
    }
  }
`

const FormToPrint = () => {
  const [form, setForm] = useState<CustomForm | null>(null)
  const [loading, setLoading] = useState(false)

  const onChangeSignature = () => console.warn('edit')
  const handleUploadFile = () => console.warn('edit')
  // const handleDeleteFile = () => console.warn('edit')
  const onChange = () => console.warn('edit')

  const ref = useRef<HTMLDivElement | null>(null)
  const { id } = useParams()

  useEffect(() => {
    const getForm = async () => {
      setLoading(true)

      try {
        const data = await getFormByPreview(id)
        setForm(data)
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error?.response?.data?.error?.message || 'Invalid data',
        })
      } finally {
        setLoading(false)
      }
    }

    if (id) getForm()
  }, [id])

  if (!form) return '.loading'

  /* const usePreviewForImage = moment(form?.created_at).isAfter(
    moment('03/27/2024', 'MM/DD/YYYY'),
  ) */

  return (
    <Wrapper>
      <GlobalStyle />
      <FormWrapper ref={ref}>
        <h5>{form.name || form.title}</h5>
        <Spin spinning={loading}>
          {Object?.values(form.body)
            ?.sort((a, b) => a?.position - b?.position)
            ?.map(component => (
              <ComponentWrapper key={component?.id}>
                {getComponentByType(
                  component,
                  onChange,
                  form?.body?.[component?.id],
                  onChangeSignature,
                  handleUploadFile,
                  // handleDeleteFile,
                  true,
                  form?.main_field_id,
                  // usePreviewForImage,
                )}
              </ComponentWrapper>
            ))}
        </Spin>
      </FormWrapper>
    </Wrapper>
  )
}

export default FormToPrint

const Wrapper = styled.div`
  max-width: ${FORM_WIDTH}px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.25);
  margin: 10px auto;
  padding: 20px;

  @media print {
    /* @page {
      margin: 100mm 50mm 100mm 50mm;
    } */
    .image {
      break-inside: avoid;
    }
  }
`

const FormWrapper = styled.div`
  border: 1px solid #ebeaea;
  padding: 10px;
  border-radius: 8px;
`

const ComponentWrapper = styled.div`
  /* & > div > p {
    font-weight: ${props => (props.mainField ? 700 : 400)};
  } */
`
