import React from 'react'
import { connect } from 'react-redux'

import './style.scss'
import { Header } from '.'
import * as actions from '../../../../../../store/Orcatec/actions'
// import TimeOffModal from '../timeoffModal'
import TimeOffModal from '../TimeOff/TimeOffModal'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
// import Preloader from '../../../components/Preloader'
import { isMobileDevise } from '../../../../../../helpers/isMobileDevice'
import AppointmentsList from '../AppointmentsList'
// import SettingsIcon from '@material-ui/icons/Settings'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import ScrollAreas from 'containers/MainContent/Orcatec/components/ScrollAreas'
// import { Tooltip } from 'antd'
import { getValueFromLS } from 'hooks/useLocalStorage'
import styled from 'styled-components'
import { Spin } from 'antd'
import { MatrixView } from '../../types'
import {
  dispatchSettingsUpdated,
  eventsDeleted,
  getMatrixLog,
} from 'features/Dispatch/dispatchSlice'
import {
  fetchTimeOffList,
  toggleTimeOffModal,
} from 'store/Orcatec/reducers/timeoff'
import { resetCreated } from 'features/Dispatch/dispatchSlice'
// import { drawerIsOpen, setPlaceFree } from 'store/Orcatec/reducers/matrix/matrixLog'
import Calendar from 'features/Dispatch/Calendar'
import { drawerIsOpen, setPlaceFree } from 'store/Orcatec/UI/uiSlice'
// import TimeOffTable from '../TimeOffTable'
import { Appointment } from '../Appointment/Appointment'
import { initialAppointment } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/AppointmentForm'
import { withTranslation } from 'react-i18next'

const meta = document.createElement('meta')
meta.setAttribute('name', '.matrix-v2')
meta.setAttribute('content', 'width=600, initial-scale=0.5')
const MatrixWrapper = styled.div`
  & .matrix__header-settings {
    top: ${props =>
      props?.isVerticalNavigation
        ? '0px'
        : props.scrollDir === 'up'
        ? '75px'
        : '65px'};
    background-color: white;
    z-index: 999;
    width: calc(100% - 30px);
  }
  & .react-transform-wrapper__inner {
    height: ${props =>
      props?.isVerticalNavigation
        ? props.scrollDir === 'up'
          ? '87vh !important'
          : '98vh !important'
        : props.scrollDir === 'up'
        ? '98vh !important'
        : '98vh !important'};
    padding-top: '230px !important';
    @media screen and (min-width: 670px) {
      padding-top: ${props =>
        props?.isVerticalNavigation ? '100px !important' : '155px !important'};
    }
    @media screen and (min-width: 1140px) {
      padding-top: ${props =>
        props?.isVerticalNavigation ? '36px !important' : '130px !important'};
    }
  }
`

class CalendarHoc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentDate: moment(),
      today: moment(),
      timeOff: false,
      edit: false,
      isLoading: true,
      queue: false,
      jobs: false,
      lead: false,
      todo: false,
      recurring: false,
      recurring_jobs: false,
      error: false,
      invalidDate: false,
      update: null,
      isPreloader: false,
      isDragging: false,
      pageView: 1, //1 - Day view, 2 - Week view, 3 - List view
      dayMinWidth: 115,
      zoomLevel: 1,
      visibleDrawer: false,
      choosenEmployees: [],
      newLook: false,
      showAppointment: false,
      showPrintModal: false,
      event: initialAppointment,
      itemsView: 1,
      mounted: false,
    }
    this.isMobileDevice = isMobileDevise()
    this.dayRef = React.createRef()
    this.zoomTransformRef = React.createRef()
  }

  changeMatrixListener = ({ data: { user_id, matrix, message, date } }) => {
    const isSameDates = moment(date /* , 'MM/DD/YYYY' */)
      .startOf('day')
      .isSame(moment(this.state.currentDate).startOf('day'), 'd')

    if (isSameDates /*  && user_id !== this.props.me.id */) {
      this.props.getMatrixLog(
        moment(this.state.currentDate || new Date()).format('MM/DD/YYYY'),
      )
    }
    this.setState({ isPreloader: false })
  }

  deleteEventListener = data => {
    this.props.deleteEvents(data?.eventIds)
  }

  changeUserStatusListener = () => {
    this.props.getMatrixLog(moment(this.state.currentDate).format('MM/DD/YYYY'))
  }

  handleResizeWindow = () => {
    const widthWindow = window.innerWidth
    const widthSideBar = this.state.showAppointment ? 780 : 0
    if (this.state.showAppointment) {
      this.props.setFreePlaceInView(widthWindow - widthSideBar)
    }
  }

  componentDidMount() {
    let settings = getValueFromLS('matrixSettings')

    settings &&
      this.setState({
        queue: settings?.queue ?? false,
        todo: settings?.todo ?? false,
        lead: settings?.lead ?? false,
        jobs: settings?.jobs ?? false,
        recurring: settings?.recurring ?? false,
        recurring_jobs: settings?.recurring_jobs ?? false,
        pageView: settings?.pageView || 1,
        zoomLevel: settings?.zoomLevel || 1,
        itemsView: settings?.itemsView || 1,
      })
    const date = this.props.dispatch.date
      ? this.props.dispatch.date
      : this.props.dateFromOuterComponent
      ? this.props.dateFromOuterComponent
      : settings?.date || moment()
    const dateStr =
      typeof date === 'string'
        ? moment(date /* , 'YYYY-MM-DD' */).format('MM/DD/YYYY')
        : moment(date).format('MM/DD/YYYY')

    const momentDate =
      settings?.pageView === 2
        ? [
            moment()
              .isoWeekday(this.props.company?.start_day_of_week || 1)
              .add(
                moment().isoWeekday() <
                  (this.props.company?.start_day_of_week || 1)
                  ? -1
                  : 0,
                'week',
              ),
            moment()
              .isoWeekday(this.props.company?.start_day_of_week || 1)
              .add(
                moment().isoWeekday() <
                  (this.props.company?.start_day_of_week || 1)
                  ? -1
                  : 0,
                'week',
              )
              .add(6, 'd'),
          ]
        : moment(dateStr)
    // const a = this.getmatrixLogByDate(momentDate)
    if (this.props.dispatch.date || this.props.dateFromOuterComponent) {
      this.setState({ currentDate: moment(date, 'YYYY-MM-DD') })
    }

    // const b = this.props.getTimeOff()
    this.getmatrixLogByDate(momentDate).then(
      value => {
        this.setState({ isLoading: false })

        setTimeout(() => this.setState({ mounted: true }), 1500)

        // fetch dispatch data on current week
        if (this.state.pageView === MatrixView.DAY)
          this.getmatrixLogByDate(
            [
              moment()
                .isoWeekday(this.props.company?.start_day_of_week || 1)
                .add(
                  moment().isoWeekday() <
                    (this.props.company?.start_day_of_week || 1)
                    ? -1
                    : 0,
                  'week',
                ),
              moment()
                .isoWeekday(this.props.company?.start_day_of_week || 1)
                .add(
                  moment().isoWeekday() <
                    (this.props.company?.start_day_of_week || 1)
                    ? -1
                    : 0,
                  'week',
                )
                .add(6, 'd'),
            ],
            true,
          )
      },
      err => {
        this.setState({ error: true, isLoading: false })
      },
    )

    window.addEventListener('resize', this.handleResizeWindow)

    // this.props.getMatrixQueues()

    window.Echo?.private('matrix-structure.' + this.props.ownerId)
      .listen('MatrixChangesEvent', this.changeMatrixListener)
      .listen('Matrix\\EventDeletedNotifier', this.deleteEventListener)

    // Listen to Timecards status changes
    window.Echo?.private('time-card-user.' + this.props.ownerId).listen(
      'TimeCardsStatusEvent',
      this.changeUserStatusListener,
    )

    // if (this.props.me?.settings_matrix?.matrix_week) {
    //   this.handleChangeRange()
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.dispatch.currentDate !== this.props.dispatch.currentDate &&
      this.state.pageView === MatrixView.DAY
    ) {
      this.setState({ currentDate: this.props?.dispatch?.currentDate })
    }

    if (
      this.dayRef.current?.firstChild?.offsetWidth >= this.state.dayMinWidth &&
      prevState.dayMinWidth !== this.dayRef.current.firstChild.offsetWidth
    ) {
      const widestDay = Math.max(
        ...[...this.dayRef.current.childNodes].map(el => el.offsetWidth),
      ) // return width of the widest day

      this.setState({ dayMinWidth: widestDay })
    }
    if (prevProps.useMeAsTechonMatrix !== this.props.useMeAsTechonMatrix) {
      this.setState({ isPreloader: true })
      const a = this.props.getMatrixLog(
        moment(this.state.currentDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      )
      // const b = this.props.getTimeOff()
      Promise.all([a, b]).then(
        value => {
          this.setState({ isPreloader: false })
        },
        err => {
          this.setState({ error: true, isPreloader: false })
        },
      )
    }
  }

  componentWillUnmount() {
    window.Echo.leave('matrix-structure.' + this.props.ownerId)
    window.removeEventListener('resize', this.handleResizeWindow)
    this.props.sidebarIsOpen(false)
  }

  toggleAdditionalCols = type => {
    this.setState(
      prev => ({ [type]: !prev[type] }),
      () => {
        localStorage.setItem(
          'matrixSettings',
          JSON.stringify({
            pageView: this.state.pageView,
            zoomLevel: this.state.zoomLevel || 1,
          }),
        )
      },
    )
  }

  onOpenTimeOff = title => {
    if (title === 'ADD') {
      this.setState({ edit: false })
    } else if (title == 'EDIT') {
      this.setState({ edit: true })
    }
    this.setState({ timeOff: !this.state.timeOff })
  }

  getmatrixLogByDate = (momentObj, backgroundFetch) => {
    if (!backgroundFetch)
      this.setState({ isPreloader: true, currentDate: momentObj })

    this.props.resetCreated()
    const formatDate = Array.isArray(momentObj)
      ? {
          range: [
            momentObj[0].format('YYYY-MM-DD'),
            momentObj[1].format('YYYY-MM-DD'),
          ],
        }
      : momentObj.format('MM/DD/YYYY')

    return this.props
      .getMatrixLog(formatDate)
      .then(res => {
        this.setState({ invalidDate: false, isPreloader: false })
      })
      .catch(err => {
        this.setState({ invalidDate: true, isPreloader: false })
      })
  }

  getMatrixQueue = () => this.props.getMatrixQueues

  dayNext = () => {
    const newDate =
      this.state.pageView !== 2
        ? moment(this.state.currentDate).add(1, 'day')
        : [
            moment(this.state.currentDate[0]).add(7, 'd'),
            moment(this.state.currentDate[0]).add(13, 'd'),
          ]

    this.getmatrixLogByDate(newDate)
    this.props.resetCreated()
  }

  dayBefore = () => {
    const newDate =
      this.state.pageView !== 2
        ? moment(this.state.currentDate).add(-1, 'day')
        : [
            moment(this.state.currentDate[0]).add(-7, 'd'),
            moment(this.state.currentDate[0]).add(-1, 'd'),
          ]

    this.getmatrixLogByDate(newDate)
    this.props.resetCreated()
  }
  today = () => {
    const newDate = moment()
    this.getmatrixLogByDate(newDate)
    this.props.resetCreated()
  }
  showPreloader = () => {
    this.setState({ isPreloader: true })
  }
  hidePreloader = () => {
    this.setState({ isPreloader: false })
  }

  handleChangePageView = viewType => {
    this.setState({ pageView: viewType }, () => {
      localStorage.setItem(
        'matrixSettings',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('matrixSettings')),
          pageView: viewType,
        }),
      )
      this.setState(
        {
          currentDate:
            this.state.pageView === 2
              ? [
                  moment()
                    .isoWeekday(this.props.company?.start_day_of_week || 1)
                    .add(
                      moment().isoWeekday() <
                        (this.props.company?.start_day_of_week || 1)
                        ? -1
                        : 0,
                      'week',
                    ),
                  moment()
                    .isoWeekday(this.props.company?.start_day_of_week || 1)
                    .add(
                      moment().isoWeekday() <
                        (this.props.company?.start_day_of_week || 1)
                        ? -1
                        : 0,
                      'week',
                    )
                    .add(6, 'd'),
                ]
              : moment(),
        },
        () => {
          if ([1, 2].includes(viewType))
            this.getmatrixLogByDate(this.state.currentDate)
        },
      )
    })
  }

  handleCalendarChange = date => {
    this.setState({ currentDate: [moment(date), moment(date).add(7, 'day')] })
    this.props.resetCreated()
  }

  handleChooseTheDay = (e, date) => {
    if (e.target.classList.contains('tech-avatar')) return
    this.setState(
      {
        pageView: 1,
        currentDate: moment(date),
      },
      () => this.getmatrixLogByDate(this.state.currentDate),
    )
    this.props.resetCreated()
  }

  handleDragEvent = isDragging => {
    // console.log({ isDragging })
    setTimeout(
      () => {
        this.setState({ isDragging })
      },
      isDragging ? 300 : 0,
    )
  }

  onZoomIn = () => {
    this.zoomTransformRef?.current.setTransform(
      1,
      1,
      this.state.zoomLevel * 1.5,
    )
    this.setState({ zoomLevel: this.state.zoomLevel * 1.5 }, () =>
      localStorage.setItem(
        'matrixSettings',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('matrixSettings')),
          zoomLevel: this.state.zoomLevel,
        }),
      ),
    )
  }

  onZoomOut = () => {
    this.zoomTransformRef?.current.setTransform(
      1,
      1,
      this.state.zoomLevel / 1.5,
    )
    if (this.state.zoomLevel <= 0.5) return
    this.setState({ zoomLevel: this.state.zoomLevel / 1.5 }, () =>
      localStorage.setItem(
        'matrixSettings',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('matrixSettings')),
          zoomLevel: this.state.zoomLevel,
        }),
      ),
    )
  }
  onZoomReset = () => {
    this.zoomTransformRef?.current.setTransform(1, 1, 1)
    this.setState({ zoomLevel: 1 }, () =>
      localStorage.setItem(
        'matrixSettings',
        JSON.stringify({
          ...JSON.parse(localStorage.getItem('matrixSettings')),
          zoomLevel: this.state.zoomLevel,
        }),
      ),
    )
  }

  handleDispatchSizeChange = e => {
    this.props.dispatchSettingsUpdated({
      ...this.props.dispatch.settings,
      size: e.target.value,
    })

    localStorage.setItem(
      'matrixSettings',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('matrixSettings')),
        size: e.target.value,
      }),
    )
  }

  handleZoom = zoomLevel => {
    this.zoomTransformRef?.current?.setTransform(1, 1, zoomLevel)
  }

  handleChooseEmployee = choosenEmployees => {
    this.setState({
      choosenEmployees,
    })
  }

  setShowAppointment = value => {
    const widthWindow = window.innerWidth
    const widthSideBar = value ? 780 : 0
    this.props.sidebarIsOpen(value)
    this.props.setFreePlaceInView(widthWindow - widthSideBar)
    this.setState(prev => ({ ...prev, showAppointment: value }))
  }

  heandlePrintModal = visible => {
    this.setState(prev => ({ ...prev, showPrintModal: visible }))
  }

  setEvent = event => {
    this.setState(prev => ({ ...prev, event }))
  }

  changeItemsView = type => {
    this.setState(prev => ({ ...prev, itemsView: type }))
    localStorage.setItem(
      'matrixSettings',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('matrixSettings')),
        itemsView: type,
      }),
    )
  }

  render() {
    const {
      currentDate,
      today,
      isLoading,
      error,
      invalidDate,
      isPreloader,
    } = this.state
    const { dispatch, t } = this.props

    const getDaysBetweenDates = function(startDate, endDate) {
      var now = startDate.clone(),
        dates = []

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'))
        now.add(1, 'days')
      }
      return dates
    }

    let weekDays = []

    if (this.state.pageView === 2 && Array.isArray(this.state.currentDate))
      weekDays = getDaysBetweenDates(
        this.state.currentDate[0],
        this.state.currentDate[1],
      )

    return (
      <>
        {[1, 2].includes(this.state.pageView) ? (
          <MatrixWrapper
            // isVerticalNavigation={this.props.isVerticalNavigation}
            scrollDir={this.props.scrollDir}
            className='matrix-v2'
          >
            <div
              className={
                this.props.scrollDir === 'down'
                  ? 'hide-matrix__header matrix__header-settings'
                  : 'show-matrix__header matrix__header-settings'
              }
            >
              <Header
                setShowAppointment={this.setShowAppointment}
                showAppointment={this.state.showAppointment}
                dayNext={this.dayNext}
                dayBefore={this.dayBefore}
                today={this.today}
                changeType={this.props.changeType}
                onChangeDate={this.getmatrixLogByDate}
                currentDate={currentDate}
                onOpenTimeOff={this.onOpenTimeOff}
                toggleAdditionalCols={this.toggleAdditionalCols}
                toggleTimeoff={this.props.toggleTimeoff}
                queue={this.state.queue}
                jobs={this.state.jobs}
                recurring={this.state.recurring}
                loading={isLoading}
                timeoff={this.props.timeoff}
                todo={this.state.todo}
                isMobileDevice={this.isMobileDevice}
                weekView={this.state.pageView === 2}
                listView={this.state.pageView === 3}
                pageView={this.state.pageView}
                onSizeChange={this.handleDispatchSizeChange}
                onChageView={this.handleChangePageView}
                onCalendarChange={this.handleCalendarChange}
                isWorker={this.props.me.role_id === 5}
                currentUserId={this.props.me.id}
                zoomLevel={this.state.zoomLevel}
                onZoomIn={this.onZoomIn}
                onZoomOut={this.onZoomOut}
                onZoomReset={this.onZoomReset}
                startDayOfWeek={this.props?.company?.start_day_of_week || 1}
                technicians={dispatch.technicians}
                onChooseEmployee={this.handleChooseEmployee}
                heandlePrintModal={this.heandlePrintModal}
                printModalVisible={this.state.showPrintModal}
                changeItemsView={this.changeItemsView}
                itemsView={this.state.itemsView}
                dispatchSize={this.props.dispatch.settings.size}
              />
            </div>
            {!isLoading &&
            !error &&
            dispatch?.settings?.start_hour !== dispatch?.settings?.end_hour ? (
              <TransformWrapper
                ref={this.zoomTransformRef}
                initialScale={this.state.zoomLevel}
                wheel={{ wheelDisabled: true }}
                disabled={this.isMobileDevice}
                panning={{
                  disabled: true,
                  activationKeys: ['box'],
                }}
                doubleClick={{
                  disabled: true,
                }}
              >
                {this.state.pageView === 1 ? (
                  <>
                    <div className='body'>
                      {invalidDate && !isLoading ? (
                        <center>{t('dispatch.dateUnavailable')}</center>
                      ) : (
                        <>
                          <ScrollAreas
                            scrollContainerSelector='.react-transform-wrapper'
                            areaSize={!this.isMobileDevice ? 70 : 20}
                            disablePointerEvents={!this.state.isDragging}
                          >
                            <TransformComponent
                              wrapperClass='react-transform-wrapper__inner'
                              wrapperStyle={{
                                width: 'initial',
                                height: 'calc(100vh - 10px)',
                                overflow: 'scroll',
                                paddingBottom: '55px',
                              }}
                              contentStyle={{
                                width: 'initial',
                                minHeight: '70vh',
                                display: 'block',
                              }}
                            >
                              <Calendar
                                currentDate={currentDate}
                                companyLogo={this.props.companyLogo}
                                timeoff={this.props.timeoff}
                                showPreloader={this.showPreloader}
                                hidePreloader={this.hidePreloader}
                                isMobileDevise={this.isMobileDevice}
                                isWorker={this.props.me.role_id === 5}
                                getMatrixLog={this.getmatrixLogByDate}
                                toggleQueueContent={this.toggleAdditionalCols}
                                zoomLevel={this.state.zoomLevel}
                                handleZoom={this.handleZoom}
                                onDragEvent={this.handleDragEvent}
                                choosenEmployees={this.state.choosenEmployees}
                                onChooseEmployee={this.handleChooseEmployee}
                                setShowAppointment={this.setShowAppointment}
                                showAppointment={this.state.showAppointment}
                                heandlePrintModal={this.heandlePrintModal}
                                printModalVisible={this.state.showPrintModal}
                              />
                            </TransformComponent>
                          </ScrollAreas>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className='body'>
                      {invalidDate && !isLoading ? (
                        <center>{t('dispatch.dateUnavailable')}</center>
                      ) : !dispatch.loading ? (
                        <TransformComponent
                          ref={this.dayRef}
                          wrapperClass='react-transform-wrapper__inner weekView'
                          contentStyle={
                            this.state.itemsView === 2
                              ? {
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }
                              : {}
                          }
                          wrapperStyle={{
                            width: 'initial',
                            height: 'calc(100vh - 10px)',
                            overflow: 'scroll',
                          }}
                        >
                          {weekDays.map(day => (
                            <div
                              key={day}
                              className='dayOfWeek' /*  onClick={e => this.handleChooseTheDay(e, day)} */
                            >
                              <div
                                className='dayOfWeek__heading'
                                onClick={e => this.handleChooseTheDay(e, day)}
                                style={{ cursor: 'pointer' }}
                              >
                                <p>{moment(day).format('ddd')}</p>
                                <p>{moment(day).format('MM/DD/YYYY')}</p>
                              </div>

                              <Calendar
                                currentDate={day}
                                companyLogo={this.props.companyLogo}
                                weekView
                                timeoff={this.props.timeoff}
                                showPreloader={this.showPreloader}
                                isMobileDevise={this.isMobileDevice}
                                // isPreloader={this.state.isPreloader}
                                hidePreloader={this.hidePreloader}
                                isWorker={this.props.me.role_id === 5}
                                setEvent={this.setEvent}
                                setShowAppointment={this.setShowAppointment}
                                showAppointment={this.state.showAppointment}
                                handleZoom={this.handleZoom}
                              />
                            </div>
                          ))}
                        </TransformComponent>
                      ) : (
                        <Spin size='large' className='matrix__spin' />
                      )}
                    </div>
                  </>
                )}
              </TransformWrapper>
            ) : (error ||
                dispatch?.settings?.time_start ===
                  dispatch?.settings?.time_end) &&
              !isLoading &&
              !isPreloader ? (
              <center>
                <div>
                  Go to settings and chose Min and Max time of appointments
                </div>
                <Link to='/settings/matrix'>
                  <button className='btn btn-custom-info mt-4'>
                    Setup Appointments
                  </button>
                </Link>
              </center>
            ) : (
              <Spin size='large' className='matrix__spin' />
            )}
          </MatrixWrapper>
        ) : (
          <div style={{ paddingTop: '70px' }}>
            <AppointmentsList
              onChangeView={this.handleChangePageView}
              pageView={this.state.pageView}
            />
          </div>
        )}

        {this.state.showAppointment && this.state.pageView == 2 && (
          <Appointment
            event={this.state.event}
            visibleDrawer
            onCloseDrawer={() => {
              this.setShowAppointment(false)
              this.setEvent(initialAppointment)
            }}
            currentDate={this.state.currentDate}
          />
        )}
        {this.props.modalTimeoff && <TimeOffModal />}
      </>
    )
  }
}
const mapStateToProps = state => {
  return {
    dispatch: state.orcatec.dispatchSlice || {},
    timeoff: state.orcatec.timeoff.list
      ? Object.values(state.orcatec.timeoff.list)
      : [],
    modalTimeoff: state.orcatec.modalTimeoff,
    companyLogo:
      !!state.orcatec.company && !!state.orcatec.company.logo
        ? state.orcatec.company.logo
        : '',
    ownerId: state.orcatec.company.owner_id,
    me: state.orcatec.user.me,
    company: state.orcatec.company,
    useMeAsTechonMatrix: state.orcatec.user.me.useAsTech,
    scrollDir: state.orcatec.dispatchSlice.scrollDirection || 'up',
    isVerticalNavigation:
      state.orcatec.user?.me?.ui_settings?.is_vertical_sidebar,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMatrixLog: data => dispatch(actions.matrixLog.setMatrixLog(data)),
    getMatrixLog: date => dispatch(getMatrixLog({ date })),
    deleteEvents: ids => dispatch(eventsDeleted(ids)),
    getMatrixQueues: date => dispatch(actions.matrixLog.getMatrixQueues()),
    getTimeOff: () => dispatch(fetchTimeOffList()),
    changeType: bool => dispatch(actions.appointment.changeType(bool)),
    toggleTimeoff: () => dispatch(toggleTimeOffModal()),
    resetCreated: () => dispatch(resetCreated()),
    sidebarIsOpen: bool => dispatch(drawerIsOpen(bool)),
    setFreePlaceInView: width => dispatch(setPlaceFree(width)),
    dispatchSettingsUpdated: settings =>
      dispatch(dispatchSettingsUpdated(settings)),
  }
}

const connectedHoc = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CalendarHoc))

export { connectedHoc as CalendarHoc }
