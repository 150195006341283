import React, { useEffect, useState } from 'react'
import './Clients.scss'
import { TableMy } from './Clients/TableMy'
import ClientForm from '../../../../Clients/components/Form/components/Form'
import { Empty } from 'antd'
import { WithModal } from '../../../../../../../hoc/WithModal'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { useTranslation } from 'react-i18next'

const ClientstTab = ({
  show,
  fetchProperty,
  isAddButtonClicked,
  setIsAddButtonClicked,
  clients,
  onClickTab,
  propertyId,
  propertyRelation,
  onAddAttachedClient,
  onDellAttachedClient,
  isServiceContract,
  isAppointment,
  setSelectedClientsIds,
  selectedClientsIds,
  isCanDeleteClients,
  organizationId,
  isOrganization,
  isAssociation,
  parentAssociations,
  isClient,
  isContact,
  getPopupContainer,
  stepOfOpenedForm,
  fetchClientsList,
  toggleEditClient = () => {},
  isSchedule,
  onUpdateRelation,
  onDetachClientOrOrganization,
  isPropertyForm,
  toggleClientForm,
  assotiationsId,
  withModal = false,
  onSubmit,
}) => {
  const { t } = useTranslation(['contact', 'common'])

  const newClientTitle = isOrganization
    ? t('heading.organizatoin', { ns: 'common' })
    : t('heading.contact', { ns: 'common' })

  return (
    <div
      className={`clients-tab-table${
        show ? ' clientsTabShow' : ' clientsTabHide'
      }`}
    >
      {isAddButtonClicked && (
        <div className='new-client-wrapper'>
          <div className='new-client-wrapper-title'>{`${t(
            'attachNew',
          )} ${newClientTitle}`}</div>
          <WithModal
            title={`${t('attachNew')} ${newClientTitle}`}
            showModal={isAddButtonClicked}
            toggleModal={() => {
              toggleClientForm()
              toggleEditClient()
            }}
            withModal={withModal}
          >
            <ClientForm
              closeForm={setIsAddButtonClicked}
              propertyRelation={true}
              onAddAttachedClient={onAddAttachedClient}
              isAddButtonClicked={isAddButtonClicked}
              fetchProperty={fetchProperty}
              allreadyAttachedClients={clients}
              isServiceContract={isServiceContract}
              isAppointment={isAppointment}
              organizationId={organizationId}
              isOrganization={isOrganization}
              isAssociation={isAssociation}
              parentAssociations={parentAssociations}
              isClient={isClient}
              isContact={isContact}
              stepOfOpenedForm={stepOfOpenedForm}
              toggleEditClient={toggleEditClient}
              isPropertyForm={isPropertyForm}
            />
          </WithModal>
        </div>
      )}
      {clients.length ? (
        <TableMy
          clients={clients}
          onDellAttachedClient={onDellAttachedClient}
          isServiceContract={isServiceContract}
          isAppointment={isAppointment}
          propertyId={propertyId}
          setSelectedClientsIds={setSelectedClientsIds}
          selectedClientsIds={selectedClientsIds}
          fetchProperty={fetchProperty}
          isCanDeleteClients={isCanDeleteClients}
          organizationId={organizationId}
          isOrganization={isOrganization}
          isAssociation={isAssociation}
          isClient={isClient}
          isContact={isContact}
          getPopupContainer={getPopupContainer}
          stepOfOpenedForm={stepOfOpenedForm}
          toggleEditClient={toggleEditClient}
          parentAssociations={parentAssociations}
          fetchClientsList={fetchClientsList}
          isSchedule={isSchedule}
          onUpdateRelation={onUpdateRelation}
          onDetachClientOrOrganization={onDetachClientOrOrganization}
          assotiationsId={assotiationsId}
        />
      ) : !isAddButtonClicked ? (
        <>
          <Empty
            description={
              isAssociation
                ? t('noAssociations')
                : isOrganization
                ? t('noOrgnizations')
                : organizationId || isContact
                ? t('noContacts')
                : t('noAttached')
            }
          />
          <MainButton
            title={
              isAssociation
                ? t('addAssociations')
                : isOrganization
                ? t('addOrgnization')
                : organizationId || isContact
                ? t('addContact')
                : t('addClient')
            }
            className='add-client-button'
            onClick={e => {
              e.preventDefault()
              toggleClientForm?.()
              // setIsAddButtonClicked?.()
            }}
          />
        </>
      ) : (
        ''
      )}
    </div>
  )
}

export default ClientstTab
