import { Badge, Popover } from 'antd'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { Column } from 'features/Dispatch/types'
import { getMediaURL } from 'helpers/getMediaURL'
import React from 'react'
import styled from 'styled-components'
import { ITechnician } from 'types/Appointment'

type Props = {
  column: Column
  small?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  width: number
}

export const Title = ({ column, small, onClick, disabled, width }: Props) => {
  const data =
    column?.technicians?.find(tech => tech.user_id === column.main_tech_id) ||
    column

  const isTech = (data: Column | ITechnician): data is ITechnician =>
    !!(data as ITechnician).name

  const badgeStyle = {
    padding: '0px 3px',
    minWidth: small ? 10 : 14,
    height: small ? 10 : 16,
    width: small ? 10 : 16,
    fontSize: small ? 5 : 10,
    lineHeight: small ? 2 : 'initial',
    top: small ? 10 : 7,
    right: small ? 0 : 6,
  }

  return (
    <Wrapper
      small={small}
      onClick={!disabled ? onClick : undefined}
      width={width}
      data-testid='dispatch-column-header'
    >
      <Popover
        overlayStyle={{ whiteSpace: 'pre-line' }}
        title={column.title}
        content={
          sortTechniciansByMainTechIdFirst(column).length
            ? sortTechniciansByMainTechIdFirst(column).map(tech => (
                <TechRow key={tech.id}>
                  <Heading>
                    <Badge
                      dot
                      status={tech.time_status === 1 ? 'success' : 'error'}
                    />
                    <TechTitle>
                      {tech.name} - <span>{tech?.title}</span>
                    </TechTitle>
                  </Heading>
                  <TechDescription>{tech?.description}</TechDescription>
                </TechRow>
              ))
            : column.title
        }
        overlayInnerStyle={{
          width: 250,
        }}
      >
        <Badge
          count={
            disabled
              ? 0
              : column?.technicians?.length - 1 > 0
              ? `+${column?.technicians?.length - 1}`
              : 0
          }
          size='small'
          // offset={[-8, 15]}
          style={badgeStyle}
          color='grey'
          title=''
        >
          <Badge
            dot
            status={
              column?.technicians?.find(
                tech => tech?.user_id === column?.main_tech_id,
              )?.time_status === 1
                ? 'success'
                : 'error'
            }
            style={{
              left: -10,
              top: 5,
            }}
          >
            <Avatar
              pictureURL={
                isTech(data) ? getMediaURL(data?.profile_picture) : undefined
              }
              color={data?.background_color || '#626ed4'}
              className='avatar'
              size={small ? 24 : 36}
              // title={isTech(data) ? data?.name : data?.title}
            >
              {isTech(data) ? data?.name : data?.title}
            </Avatar>
          </Badge>
        </Badge>
      </Popover>

      {!small && (
        <Description>
          <Name>{isTech(data) ? data?.name : data?.title}</Name>
          <Role>{isTech(data) ? data?.title : 'Column'}</Role>
        </Description>
      )}
    </Wrapper>
  )
}

const Description = styled.div`
  & > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
const Name = styled.p`
  font-size: 8px;
  font-weight: 900;
`

const Heading = styled.div`
  font-weight: 500;
  display: flex;
  gap: 10px;
`

const TechRow = styled.div`
  margin-bottom: 5px;
`

const TechTitle = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  span {
    font-weight: 400;
    color: grey;
  }
`
const TechDescription = styled.p`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Role = styled.p`
  font-size: 7px;
`

const Wrapper = styled.div<{ small?: boolean; width: number }>`
  height: ${props => (props.small ? '30px' : '70px')};
  width: ${props => (props.small ? 30 : props.width)}px;
  text-align: center;
  box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
  /* width: 60px; */
  padding: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .avatar {
    margin: auto;
  }

  @media (max-width: 500px) {
    padding: 3px;
    width: 20px;
    height: 20px;

    .tech-text {
      .tech-title {
        font-size: 4px;
      }
      .tech-role {
        font-size: 6px;
      }
    }
  }
`

function sortTechniciansByMainTechIdFirst(column: Column): ITechnician[] {
  const mainTechId = column.main_tech_id
  const technicians = column.technicians.slice() // Create a copy of the original array to avoid modifying it directly

  if (mainTechId !== null) {
    const mainTechIndex = technicians.findIndex(
      technician => technician.user_id === mainTechId,
    )

    if (mainTechIndex !== -1) {
      const mainTech = technicians.splice(mainTechIndex, 1)[0]
      technicians.unshift(mainTech)
    }
  }

  return technicians
}
