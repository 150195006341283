import React, { useState } from 'react'
import styled from 'styled-components'
import { SubMenu } from 'containers/MainContent/Orcatec/components/SubMenu/SubMenu'
import { DefaultTaxRate } from './components/DefaultTaxRate/DefaultTaxRate'
import { CustomTaxRate } from './components/CustomTaxRate/CustomTaxRate'

enum Tabs {
  DEFAULT,
  CUSTOM,
}

const menuItems = [
  {
    id: 0,
    title: 'Default',
  },
  {
    id: 1,
    title: 'Custom',
  },
]

export const Taxes = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.DEFAULT)
  const handleChangeTabs = (tabId: Tabs) => setActiveTab(tabId)

  const renderContent = () => {
    if (activeTab === Tabs.DEFAULT) {
      return <DefaultTaxRate />
    }
    if (activeTab === Tabs.CUSTOM) {
      return <CustomTaxRate />
    }
  }
  return (
    <Wrapper>
      <SubMenu
        activeItem={activeTab}
        menuItemsList={menuItems}
        onChange={handleChangeTabs}
      />
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div``
const ContentWrapper = styled.div``
