import React, { useState, useEffect, ChangeEvent } from 'react'
import { SettingsRow } from 'components/UI/SettingsRow/SettingsRow'
import styled from 'styled-components'
import { InputField } from 'containers/MainContent/Orcatec/components/UI/InputFieldTransformable'

import { FAQ } from './components/FAQ'
import { Description } from './components/Description'
import { getProjectPatternCode, updateProjectPatternCode } from 'api/Project'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Spin } from 'antd'
import { useAppSelector } from 'store/Orcatec/hooks'
import { meSelector } from 'store/SuperAdmin/selectors'
import { parseString, validateInput } from './helpers'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { EditIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

interface IState {
  proposal_code_pattern: string
}

const inputStringExp = '{UPI}{yy}{mm}{00}'

export const ProjectNumber = () => {
  const me = useAppSelector(meSelector)
  const [state, setState] = useState<IState>({
    proposal_code_pattern: inputStringExp,
  })
  const [initState, setInitState] = useState<IState>({
    proposal_code_pattern: inputStringExp,
  })

  const [loading, setLoading] = useState(false)

  const [error, setError] = useState('')

  const [isEdit, setIsEdit] = useState(false)

  const handleTextClick = () => {
    setIsEdit(true)
  }

  const handleSave = async () => {
    const { isValid, error } = validateInput(state.proposal_code_pattern)
    if (!isValid) return setError(error)
    setLoading(true)
    try {
      const res = await updateProjectPatternCode(state)
      setState(res)
      setInitState(res)
      setIsEdit(false)
    } catch (error) {
      const message = Object.values(error?.response?.data?.errors || {})[0]
      openNotificationWithIcon('error', { message })
    } finally {
      setLoading(false)
    }
  }
  const handleCancel = () => {
    setState(initState)
    setIsEdit(false)
    setError('')
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    // eslint-disable-next-line no-useless-escape
    const clearedValue = value.replace(/[ \!@#$%^&*.+_()]/g, '')
    setError('')
    setState(prev => ({ ...prev, [name]: clearedValue }))
  }

  useEffect(() => {
    setLoading(true)
    const fetchProjectPatternCode = async () => {
      try {
        const res = await getProjectPatternCode()
        setInitState(res)
        setState(res)
      } catch (error) {
        const message = Object.values(error?.response?.data?.errors || {})[0]
        openNotificationWithIcon('error', { message })
      } finally {
        setLoading(false)
      }
    }
    fetchProjectPatternCode()
  }, [])

  return (
    <Wrapper>
      <Spin spinning={loading}>
        <h6>Custom Project ID</h6>
        <TextRow>
          Customize your project ID for better organization and efficiency.
        </TextRow>

        <SettingsRow required title='Project ID Pattern'>
          <InputWrapper>
            <Wrapper>
              {isEdit ? (
                <Content className='editable-content' data-testid='content'>
                  <InputField
                    value={state.proposal_code_pattern}
                    name='proposal_code_pattern'
                    onChange={onChange}
                    style={{ width: '250px' }}
                  />
                  {/* <InfoIcon title={error?.code} /> */}
                  <Buttons>
                    <MainButton title='Save' onClick={handleSave} />
                    <MainButton
                      type='cancel'
                      title='Cancel'
                      onClick={handleCancel}
                    />
                  </Buttons>
                </Content>
              ) : (
                <ValueWrapper>
                  <Value>{state.proposal_code_pattern || '--'}</Value>

                  <Edit onClick={handleTextClick} data-testid='edit-icon'>
                    <EditIcon size={12} />
                  </Edit>
                </ValueWrapper>
              )}
            </Wrapper>
            {/* <EditableBlock
              value={state.proposal_code_pattern}
              onSave={handleSave}
              onCancel={handleCancel}
              editMode={!!error}
            >
              <InputField
                value={state.proposal_code_pattern}
                name='proposal_code_pattern'
                onChange={onChange}
                style={{ width: '250px' }}
              />
            </EditableBlock> */}
            <ErrorWrapper>{error}</ErrorWrapper>
          </InputWrapper>
        </SettingsRow>
        <SettingsRow title='Project ID Example'>
          <ExampleBlock>
            <span>
              <b>{`${parseString(
                state?.proposal_code_pattern,
                me.info.number_prefix,
              )} `}</b>
            </span>
          </ExampleBlock>
        </SettingsRow>
        <Description />
        <FAQ />
      </Spin>
    </Wrapper>
  )
}

const Wrapper = styled.div``
const InputWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`
const ExampleBlock = styled.div``
const TextRow = styled.div`
  margin-bottom: 5px;
`
const ErrorWrapper = styled.div`
  color: #f44336;
  font-size: 12px;
  font-weight: 300;
`
const Buttons = styled.div`
  display: flex;
  gap: 10px;
`
const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: flex-start;
`
const Value = styled.p`
  font-size: 16px;
`
const Edit = styled.span`
  position: 'relative';
  top: -2px;
  cursor: pointer;
`
