import { useState } from 'react'
import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import Main from 'features/Settings/Project/Main/Main'
import styled from 'styled-components'
import { Taxes } from 'features/Settings/Project/Taxes/Taxes'
import { ProjectNumber } from 'features/Settings/Project/ProjectNumber/ProjectNumber'
import Statuses from 'features/Settings/Project/Statuses'
import { useLocation } from 'react-router-dom'

export enum SettingsProjectTabs {
  GENERAL,
  TAXES,
  PROJECT_NUMBER,
  STATUSES,
}

const menuItems = [
  {
    id: 0,
    title: 'Default Project Settings',
  },
  {
    id: 1,
    title: 'Taxes',
  },
  {
    id: 2,
    title: 'Project ID Pattern',
  },
  {
    id: 3,
    title: 'Statuses',
  },
]

const ProjectSettingsPage = () => {
  const location = useLocation()

  const [activeTab, setActiveTab] = useState<SettingsProjectTabs>(
    location?.state?.activeTab || SettingsProjectTabs.GENERAL,
  )
  const handleChangeTabs = (tabId: SettingsProjectTabs) => setActiveTab(tabId)

  const renderContent = () => {
    if (activeTab === SettingsProjectTabs.GENERAL) {
      return <Main />
    }
    if (activeTab === SettingsProjectTabs.TAXES) {
      return <Taxes />
    }
    if (activeTab === SettingsProjectTabs.PROJECT_NUMBER) {
      return <ProjectNumber />
    }
    if (activeTab === SettingsProjectTabs.STATUSES) {
      return <Statuses />
    }
  }
  return (
    <Wrapper>
      <SubMenu
        activeItem={activeTab}
        menuItemsList={menuItems}
        onChange={handleChangeTabs}
      />
      <ContentWrapper>{renderContent()}</ContentWrapper>
    </Wrapper>
  )
}

export default ProjectSettingsPage

const Wrapper = styled.div``
const ContentWrapper = styled.div`
  padding: 20px 0;
`
