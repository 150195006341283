import { MenuItem, TextField } from '@material-ui/core'

import '../../style.scss'
import { useTranslation } from 'react-i18next'

const Row = ({
  title,
  name,
  onChange,
  propertyRelation,
  relationValue,
  refProps,
  placeholder,
  maxLength,
  type,
  onChangeType,
  clientId,
  organizationId,
  isOrganization,
  isAssociation,
  isContact,
  isAppointment,
  isClient,
  website,
  industry,
  isShowType = false,
  isPropertyForm,
  isCalls = false,
}) => {
  const { t } = useTranslation('contact')

  const showType =
    (!organizationId &&
      type.value !== 3 &&
      !clientId &&
      !isOrganization &&
      !isAssociation &&
      !isContact &&
      !isClient) ||
    isShowType

  return (
    <>
      {!!propertyRelation && showType && (
        <div className='relationWrapper'>
          <TextField
            size='small'
            label='Relation'
            variant='outlined'
            className='form-control'
            value={relationValue}
            name='relation'
            onChange={e => {
              onChange(e)
            }}
            autoComplete='new-password'
            fullWidth
            style={{ width: isShowType ? '460px' : '100%' }}
          />
        </div>
      )}
      <div className='nameWrapper'>
        <div className={`rowClientName rowClientName-first`}>
          <TextField
            size='small'
            label={title}
            variant='outlined'
            maxLength={maxLength}
            placeholder={placeholder}
            required={true}
            className='fullnameInput'
            value={name.value}
            name='name'
            onChange={e => onChange(e)}
            autoComplete='new-password'
            ref={refProps}
            autoFocus={
              (name.error || propertyRelation) && !clientId ? true : false
            }
            error={!!name.error}
            helperText={name.error}
            inputProps={{ maxLength: maxLength }}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
          />
          {/* {name.error && <div style={{ color: '#F5222D', fontSize: '0.875rem' }}>{name.error}</div>} */}
          {/* <ClientsInput />   */}
        </div>
        {/*<Input className='ant-input has_error'/>*/}
        {showType && !isCalls && (
          <div className='rowClientName rowClientName-second-relation'>
            <TextField
              type='text'
              variant='outlined'
              fullWidth={true}
              size='small'
              select
              label={t('type.title')}
              name={'type'}
              value={type.value === 1 ? 1 : 2}
              onChange={onChangeType}
              error={!!type.error}
              helperText={type.error}
              disabled={
                (clientId || organizationId || isClient) && !isPropertyForm
              }
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={1}>{t('type.organizatoin')}</MenuItem>
              <MenuItem value={2}>{t('type.individual')}</MenuItem>
            </TextField>
          </div>
        )}
        {!!propertyRelation && !showType && (
          <div className='rowClientName rowClientName-second-relation'>
            <TextField
              size='small'
              label='Relation'
              variant='outlined'
              className='form-control'
              value={relationValue}
              name='relation'
              onChange={e => {
                onChange(e)
              }}
              autoComplete='new-password'
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </div>
        )}
      </div>
      {type?.value === 1 && !isCalls && (
        <>
          <div className='orgWrapper'>
            <div className='rowClientName rowClientName-first'>
              <TextField
                size='small'
                label={'Website'}
                variant='outlined'
                maxLength={maxLength}
                className='websiteInput'
                value={website.value}
                name='website'
                onChange={e => onChange(e)}
                autoComplete='new-password'
                inputProps={{ maxLength: 250 }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <TextField
              size='small'
              label={'Industry'}
              variant='outlined'
              maxLength={maxLength}
              className='industryInput'
              value={industry.value}
              name='industry'
              onChange={e => onChange(e)}
              inputProps={{ maxLength: 250 }}
              autoComplete='new-password'
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </>
      )}
    </>
  )
}
export default Row
