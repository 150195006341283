import { Client, ClientType } from 'types/Client'
import { Property } from 'types/Property'
import { Proposal } from 'types/Proposal'
import {
  ContactBlock,
  Row,
  Title,
  Wrapper,
  Info,
  Icon,
} from './ProposalContacts.style'
import {
  ClientIcon,
  PropertyIcon,
  OrganizationIcon,
  NavigationIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { Link } from 'react-router-dom'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  ModuleName,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

type Props = {
  proposal: Proposal
  title?: string
  isDisableEvents?: boolean
  isSchedule?: boolean
}

export const ProposalContacts = ({ proposal, title }: Props) => {
  const { t } = useTranslation('common')
  const isSchedule = window.location.pathname === '/schedule'

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SCHEDULE),
  )

  const projectContacts = proposal?.contacts

  const primaryContact = projectContacts.find(contact => contact.is_primary)
  const nonPrimaryContacts = projectContacts.filter(
    contact => !contact.is_primary,
  )
  const sortedContacts = primaryContact
    ? [primaryContact, ...nonPrimaryContacts]
    : projectContacts

  return (
    <AccessControl
      visible={!isSchedule}
      scopes={[
        SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_ADDRESS,
        SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_CONTACTS,
        SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_NAME,
      ]}
    >
      <Wrapper className='proposal-contacts__print'>
        <Title>{title || t('heading.projectContacts')}</Title>

        <AccessControl
          visible={!isSchedule}
          scopes={[
            SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_NAME,
            SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_CONTACTS,
          ]}
        >
          <Row>
            {mapClients(
              sortedContacts,
              isSchedule,
              !!permissions[
                SchedulePermissions.SCHEDULE_CAN_EDIT_PROJECT_CONTACTS
              ],
              t,
            )}
          </Row>
        </AccessControl>

        {/*  <Row>
          {mapClients(
            proposal.organizations,
            ClientType.ORGANIZATION,
            isSchedule,
            contacts,
          )}
        </Row> */}

        <AccessControl
          visible={!isSchedule}
          scopes={[SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_ADDRESS]}
        >
          <Row>
            {mapProperties(
              proposal.properties,
              !!permissions[
                SchedulePermissions.SCHEDULE_CAN_EDIT_PROJECT_CONTACTS
              ],
              t,
            )}
          </Row>
        </AccessControl>
      </Wrapper>
    </AccessControl>
  )
}

const mapClients = (
  clients: Client[],
  // type: ClientType,
  isSchedule,
  contacts,
  t,
) => {
  return (
    !!clients.length && (
      <>
        {/* {type === ClientType.INDIVIDUAL ? <ClientIcon /> : <OrganizationIcon />} */}
        {clients?.map(
          ({ id, name, phones, emails, proposal_relation, type }) => (
            <ContactBlock key={id}>
              <Icon>
                {type === ClientType.ORGANIZATION ? (
                  <OrganizationIcon />
                ) : (
                  <ClientIcon />
                )}
              </Icon>
              <Info>
                <p>
                  <AccessControl
                    visible={!isSchedule}
                    scopes={[SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_NAME]}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      {!!contacts ? (
                        <Link to={`/clients/${id}?clientType=${type}`}>
                          {name}
                        </Link>
                      ) : (
                        <span>{name}</span>
                      )}

                      {!!proposal_relation && (
                        <span style={{ fontWeight: 400 }}>
                          {proposal_relation}
                        </span>
                      )}
                    </p>
                  </AccessControl>
                </p>
                <AccessControl
                  visible={!isSchedule}
                  scopes={[
                    SchedulePermissions.SCHEDULE_CAN_READ_CLIENT_CONTACTS,
                  ]}
                >
                  {phones?.map(({ type, phone }) => (
                    <p key={phone}>
                      <span>{type}:</span> {phone}
                    </p>
                  ))}
                  {!!emails?.length && (
                    <p>
                      <span>{t('heading.email')}:</span> {emails?.[0]?.email}
                    </p>
                  )}
                </AccessControl>
              </Info>
            </ContactBlock>
          ),
        )}
      </>
    )
  )
}

const mapProperties = (properties: Property[], propertiesPerm: boolean, t) => {
  const handleNavigate = (property: Property) => {
    window.open(
      `https://maps.google.com/maps?q=${property?.address ||
        ''}, + ${property?.city || ''}, + ${property?.state ||
        ''},  + ${property?.postcode || ''}`,
    )
  }

  return (
    <>
      {properties?.map(property => (
        <ContactBlock key={property.id} isProperty>
          <PropertyIcon />

          <Info>
            {!!property.proposal_relation && (
              <p className='property-relation'>{property.proposal_relation}</p>
            )}
            {!!propertiesPerm ? (
              <Link
                to={`/properties-form/${property.id}`}
                style={{ fontSize: 14, fontWeight: 500 }}
              >
                {property.address}
              </Link>
            ) : (
              <span>{property.address}</span>
            )}

            {!!property.unit && (
              <p>
                {t('heading.unit')}: {property.unit}
              </p>
            )}
            <p>
              {property.city}
              {`${property.postcode ? ',' : ''}`} {property.postcode}
            </p>
          </Info>

          {property.is_primary && (
            <Tooltip title={t('heading.navigate')} mouseLeaveDelay={0}>
              <span onClick={() => handleNavigate(property)}>
                <NavigationIcon
                  style={{ fontSize: 28, cursor: 'pointer', color: '#4186f4' }}
                />
              </span>
            </Tooltip>
          )}
        </ContactBlock>
      ))}
    </>
  )
}
