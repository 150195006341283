import axiosOrcatec from 'api/axiosInstance'

function googleAuth() {
  return axiosOrcatec.get('/google/redirect').then(res => res.data)
}

function logout() {
  return axiosOrcatec.delete('/google/drive/logout').then(res => res.data)
}

function getStatus() {
  return axiosOrcatec.get('/google/drive/auth-status').then(res => res.data)
}

function googleCallback(params) {
  return axiosOrcatec
    .post('/google/callback', null, { params })
    .then(res => res.data)
}

function uploadFormToGoogleDrive(formId: number) {
  return axiosOrcatec
    .post(`/google/drive/upload-form/${formId}`)
    .then(res => res.data)
}

const googleAPI = {
  logout,
  getStatus,
  googleAuth,
  googleCallback,
  uploadFormToGoogleDrive,
}

export default googleAPI
