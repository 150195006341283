import { Result, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { AuthAPI } from '../api'

export const UnsubscribeEmail = () => {
  const [loading, setLoading] = useState(false)

  const location = useLocation()

  useEffect(() => {
    const verifyEmail = async () => {
      setLoading(true)
      try {
        await AuthAPI.unsubscribeEmail({ token: location.search.slice(1) })
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    if (location.search.slice(1)) verifyEmail()
  }, [])

  return (
    <Spin spinning={loading}>
      <Wrapper>
        <Result
          status='success'
          title='You have successfully unsubscribed from our emails'
        />
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div`
  max-width: 600px;
  padding: 48px;
  border: 2px solid #165ed033;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;

  @media (max-width: 450px) {
    width: 375px;
    padding: 16px;
  }
`
