import { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import ReactInputMask from 'react-input-mask'
import MenuItem from '@material-ui/core/MenuItem'
import { DeleteIcon } from '../../../../../components/Icons/CommonIcons'
import SureModal from '../../../../../components/SureModal'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

const StyledRadio = withStyles({
  root: {
    color: '#191919',
    '&$checked': {
      color: '#191919',
    },
  },
  checked: {},
})(props => <Radio {...props} /* style={{ marginTop: '3px' }} */ />)

const RadioType = ({ value, onCheckPhonePref, index, disabled }) => {
  const { t } = useTranslation('contact')

  const [preference, setPreference] = useState(value ? value : '')

  useEffect(() => {
    onCheckPhonePref(index, preference)
  }, [preference])

  const handleClick = e => {
    if (preference === e.target.value) return setPreference('')
    setPreference(e.target.value)
  }

  return (
    <RadioGroup
      aria-label='preference'
      name='preference'
      value={preference}
      classes={{ root: 'radio-group' }}
    >
      <FormControlLabel
        classes={{ root: 'voice', label: 'label' }}
        value='voice'
        control={<StyledRadio onClick={handleClick} disabled={disabled} />}
        label={t('voicePreference')}
      />
      <FormControlLabel
        classes={{ root: 'text' }}
        value='text'
        control={<StyledRadio onClick={handleClick} disabled={disabled} />}
        label={t('textPreference')}
      />
    </RadioGroup>
  )
}

export const PhoneRow = ({
  isOne,
  item,
  index,
  onChange,
  onChangeSelect,
  removeRow,
  onCheckPhonePref,
  refProps,
  validateField,
}) => {
  const { t } = useTranslation('contact')

  const [remove, changeRemove] = useState(false)

  const renderPhoneField = () => {
    return (
      <TextField
        error={!!item.error}
        helperText={item.error}
        size='small'
        label={t('phone', { count: 1 })}
        variant='outlined'
        name='phones'
        className='phoneInput'
        maxLength={100}
        ref={refProps}
        InputLabelProps={{
          shrink: true,
        }}
      />
    )
  }
  return (
    <div className='phoneWrapper'>
      <div className='phone-actions'>
        <Popconfirm
          onConfirm={() => {
            changeRemove(false)
            removeRow()
          }}
          onCancel={() => {
            changeRemove(false)
          }}
          title={t('deletePhone')}
          disabled={isOne && !item.value.phone}
        >
          <button disabled={isOne && !item.value.phone} className='btn delete'>
            <DeleteIcon />
          </button>
        </Popconfirm>
      </div>
      <div className='phone__main-group'>
        <ReactInputMask
          mask='(999) 999-9999'
          value={item.value.phone}
          onChange={onChange}
          onBlur={validateField}
          disabled={false}
          maskChar='X'
        >
          {renderPhoneField}
        </ReactInputMask>

        <div className='phoneSelect'>
          <TextField
            size='small'
            variant='outlined'
            name='phone_type'
            title={t('phoneType.title')}
            onChange={e => onChangeSelect(e.target.value)}
            value={item.value.type}
            select
            InputLabelProps={{
              shrink: true,
            }}
            disabled={!item.value.phone}
            fullWidth
          >
            <MenuItem className='fontStyle' value='cell'>
              {t('phoneType.cell')}
            </MenuItem>
            <MenuItem className='fontStyle' value='work'>
              {t('phoneType.work')}
            </MenuItem>
            <MenuItem className='fontStyle' value='home'>
              {t('phoneType.home')}
            </MenuItem>
            <MenuItem className='fontStyle' value='other'>
              {t('phoneType.other')}
            </MenuItem>
          </TextField>
        </div>
      </div>
      <RadioType
        index={index}
        onCheckPhonePref={onCheckPhonePref}
        value={item.value.preference}
        disabled={!item.value.phone}
      />
      <SureModal
        modal={remove}
        close={() => {
          changeRemove(false)
        }}
        apply={() => {
          changeRemove(false)
          removeRow()
        }}
        okTitle='Yes'
        cancelTitle='No'
      />
    </div>
  )
}

export default PhoneRow
