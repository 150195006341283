import { RecurringOptions } from '../../RecurringModal'
import { RecurringInterval } from 'types/Appointment'
import styled from 'styled-components'
import { Select } from 'components/UIKit'
import moment from 'moment-timezone'
import { DATE_FORMAT } from 'constants/dateFormats'
import { EditIcon } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { getModuleStatus } from 'store/Orcatec/reducers/settings/subscription'
import { useAppSelector } from 'store/Orcatec/hooks'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Popover } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const generateOptionValue = (value: RecurringOptions, t) => {
  const { interval, repeat_every, ending_options } = value

  if (!interval || !repeat_every || !ending_options)
    return t('heading.repeatOptionsLowerCase')

  const single = {
    1: t('heading.daily'),
    2: t('heading.weekly'),
    3: t('heading.monthly'),
  }

  const firstPart =
    repeat_every === 1
      ? single[interval]
      : `${t('heading.every')} ${repeat_every} ${RecurringInterval[
          interval
        ].toLowerCase()}s`

  const endingPart =
    ending_options.end_type === 1
      ? `, until ${moment(ending_options.value).format(DATE_FORMAT)}`
      : ending_options.end_type === 2
      ? `, ${ending_options.value} times`
      : `, until cancelled`
  return firstPart + endingPart
}

interface Props {
  recurringOptions: RecurringOptions | null
  value: boolean
  isTooltipVisible?: boolean
  onChange: (value: boolean) => void
  onOpen: () => void
  onTooltipClose?: () => void
}

export const Recurrence = ({
  value,
  recurringOptions,
  isTooltipVisible,
  onChange,
  onOpen,
  onTooltipClose,
}: Props) => {
  const { t } = useTranslation('recurrence')

  const isRecurringModuleActive = useAppSelector(state =>
    getModuleStatus(state, 'recurring_appointments'),
  )

  const handleChange = (_e, value: boolean) => {
    onTooltipClose?.()

    if (!isRecurringModuleActive) {
      return openNotificationWithIcon('info', {
        message: t('messages.moduleOff'),
        description: t('messages.moduleOffDescription'),
      })
    }

    onChange(value)
  }

  return (
    <RepeatSection>
      <Popover
        title={
          <Title>
            <p>{t('heading.recurringMoved')}</p>
            <span onClick={onTooltipClose} style={{ cursor: 'pointer' }}>
              <CloseOutlined />
            </span>
          </Title>
        }
        open={isTooltipVisible}
        content={t('heading.recurringMovedDescription')}
        // overlayStyle={{ width: 250 }}
      >
        <Select
          label={t('heading.repeatOptions')}
          size='small'
          name='is_recurring'
          value={value}
          onChange={handleChange}
          options={[
            { value: false, label: t('heading.doesntRepeat') },
            { value: true, label: t('heading.repeat') },
          ]}
        />
      </Popover>

      {!!value && !!recurringOptions && (
        <Link onClick={onOpen}>
          {generateOptionValue(recurringOptions, t)}
          <EditIcon size={12} />
        </Link>
      )}
    </RepeatSection>
  )
}

const RepeatSection = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 150px max-content;
  align-items: end;
  /* margin-top: -20px; */
`

const Link = styled.p`
  cursor: pointer;
  color: #4285f4;
  display: inline-flex;
  gap: 5px;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
