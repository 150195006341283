import { ChangeEvent, FC, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { timezones } from 'containers/MainContent/Orcatec/TimeCards/timezone'
// import SelectField from 'containers/MainContent/Orcatec/components/Select'
import { Select } from 'components/UIKit'
import { useTranslation } from 'react-i18next'

interface ITarget {
  target: {
    value: string
    name: string
  }
}

interface IProps {
  value: string | null
  onChange: (e: ITarget) => void
  name?: string
  label?: string
  defaultValue?: string | null
}

export const AppointmentTimezone: FC<IProps> = ({
  value,
  onChange,
  name = 'timezone',
  label,
  defaultValue,
  ...rest
}) => {
  const { t } = useTranslation('appointment')

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const [findedTimezone] = timezones.filter(({ name }) =>
      name.includes(e.target.value),
    )
    const [selectedUtcValue] = findedTimezone.utc
    onChange({
      target: {
        value: selectedUtcValue,
        name,
      },
    })
  }, [])

  const inputValue = useMemo(
    () => timezones.find(({ utc }) => utc.includes(value))?.name,
    [value],
  )

  const options = useMemo(
    () => timezones.map(item => ({ value: item.name, label: item.label })),
    [],
  )

  const defaultOption = useMemo(
    () =>
      timezones
        .filter(({ utc }) => utc.includes(defaultValue))
        .map(item => ({ id: item.name, name: item.label }))?.[0],
    [defaultValue],
  )

  return (
    <Wrapper>
      <Select
        {...rest}
        label={label || t('heading.timeZone')}
        name={name}
        onChange={handleChange}
        value={inputValue}
        options={options}
        customValue={defaultOption}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``
