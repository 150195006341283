import { Switch, Tooltip } from 'antd'
import SettingsRow from 'components/UI/SettingsRow'
import { Select, TextField } from 'components/UIKit'
import { ProjectSettings } from 'features/Project/types'
import { formatStringToNumber } from 'helpers/numbers'
import NumberFormat from 'react-number-format'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'
import styled from 'styled-components'
import { Companies } from 'types/Company'

const OPTIONS = [
  {
    label: 0,
    value: 0,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 30,
    value: 30,
  },
  {
    label: 45,
    value: 45,
  },
  {
    label: 60,
    value: 60,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 90,
    value: 90,
  },
]

export const Settings = ({
  data,
  onChange,
}: {
  data: ProjectSettings
  onChange: (field: Partial<ProjectSettings>) => void
}) => {
  const company = useAppSelector(selectCompany)
  const companyFinancing = company?.financing?.enable_financing

  const PROJECT_SETTINGS = [
    {
      title: 'General',
      settings: [
        {
          title: 'Add-on items',
          description:
            'Enable to make items optional. Click the lock icon next to an item to let clients include/exclude it from the project scope with a checkbox',
          field_name: 'multiple_choice_items',
          helperText: '',
        },
        {
          title: 'Show information for customer',
          description:
            'Enable section for extra information for your client, media uploads, and customer comments',
          field_name: 'show_information',
        },
        {
          title: 'Show completion progress',
          description: 'Enable tracking progress of completion per item',
          field_name: 'completion_progress',
          hide: ![
            Companies.BuildX,
            Companies.HVAC,
            Companies.AirLoop,
            Companies.DivineEnergySolutions,
          ].includes(company.id),
        },
        {
          title: 'Multiple tabs for project',
          description:
            'Enable multiple customizable tabs to offer clients more choices, including options like "Good-Better-Best"',
          field_name: 'multiple_tabs',
          hide: false,
        },
        {
          title: 'Show tags ',
          description: '',
          field_name: 'show_tags',
        },
        {
          title: 'Show forms',
          description: 'Show custom forms attached to the project',
          field_name: 'show_forms',
        },
        {
          title: 'Show templates',
          description:
            'Enable section to create project templates and apply them to projects',
          field_name: 'show_templates',
        },
        {
          title: 'Show notes',
          description: '',
          field_name: 'show_notes',
        },
        {
          title: 'Show modules',
          description:
            'Include extra modules for added actions and alerts in the project',
          field_name: 'show_modules',
        },
        {
          title: 'Enable groups',
          description: 'Enable to group  single line items',
          field_name: 'show_groups',
        },
        {
          title: 'Enable sections',
          description: 'Enable to create multiple sections in the project',
          field_name: 'show_sections',
        },

        {
          title: 'Show additional labor cost',
          field_name: 'show_installation',
        },
        /*  {
          title: 'Simple item view',
          description:
            'Items will only include name, description, quantity, and price',
          field_name: 'simple_item_view',
        }, */
      ],
    },
    {
      title: 'Customer view',
      settings: [
        {
          title: 'Show tech contacts',
          description:
            'Technician contact information will be visible to customers',
          field_name: 'show_tech',
        },
        {
          title: 'Show QTY',
          description: 'Show quantity of items',
          field_name: 'show_items_qty',
        },
        {
          title: 'Show description only',
          description: 'Only item description will be visible',
          field_name: 'show_item_description_only',
        },
        {
          title: 'Show price per item',
          description: 'Display price per item in customer view',
          field_name: 'show_price',
        },
        {
          title: 'Show $0 prices',
          description:
            'When you turn off this option, only the name and description of items priced at $0 will show up for customers',
          field_name: 'hide_zero_price',
          disabled: !data?.show_price,
        },
        {
          title: 'Require signature',
          description:
            'To accept the project, clients must provide an electronic signature, their name, and agree to your Terms and Conditions',
          field_name: 'require_signature',
        },
      ],
    },
    {
      title: 'Payment',
      settings: [
        {
          title: 'Enable discounts',
          description: 'Add/Remove ability to add Discount to Project',
          field_name: 'show_discounts',
        },
        {
          title: 'Enable rebates',
          description: 'Add/Remove ability to add Rebate to Project',
          field_name: 'show_rebates',
        },
        {
          title: 'Enable taxes',
          description: 'Add/Remove ability to add Tax to Project',
          field_name: 'show_taxes',
        },
        {
          title: 'Add tips to payouts',
          description:
            'Automatically add tips to tech payouts in project insights',
          field_name: 'include_tips_to_insight',
        },
        {
          title: 'Auto-create balance in Contract',
          description:
            'Automatically generate balance upon Estimate acceptance',
          field_name: 'balance_auto_creation',
        },
        {
          title: 'Show tips',
          description: 'Enable tips for customer payments',
          field_name: 'show_tips',
        },
        {
          title: 'Enable Financing',
          description:
            'Allow your clients to apply for consumer financing directly from the project',
          field_name: 'financing',
          hide: !companyFinancing,
        },
        {
          title: 'Payment processing fee',
          description:
            'Automatically add a service fee to every credit card payment',
          field_name: 'payment_processing_fee',
          field_type: 'input',
        },
        {
          title: 'Default payment due',
          description: '',
          field_name: 'default_payment_due_date',
          field_type: 'input',
        },
      ],
    },
  ]
  return (
    <Wrapper>
      {PROJECT_SETTINGS.map(section => (
        <Section key={section.title}>
          <Title>{section.title}</Title>

          <SettingsWrapper>
            {section?.settings
              ?.filter(item => !item.hide)
              ?.map(item => (
                <SettingsRow
                  key={item.field_name}
                  description={item.description}
                  title={item.title}
                  width={250}
                  withInfoIcon
                  align='right'
                >
                  {item.field_name === 'payment_processing_fee' ? (
                    <NumberFormat
                      value={data.payment_processing_fee}
                      thousandSeparator
                      suffix='%'
                      customInput={TextField}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        onChange({
                          payment_processing_fee: formatStringToNumber(e),
                        })
                      }
                      onBlur={e => {
                        if (!e.target.value) {
                          onChange({
                            payment_processing_fee: 0,
                          })
                        }
                      }}
                      maxLength={6}
                      width='150px'
                    />
                  ) : item.field_name === 'default_payment_due_date' ? (
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                    >
                      <Select
                        name='default_payment_due_date'
                        showSearch={false}
                        value={data.default_payment_due_date}
                        onChange={e =>
                          onChange({
                            default_payment_due_date: e.target.value,
                          })
                        }
                        options={OPTIONS}
                        width='116px'
                      />
                      <span>days</span>
                    </div>
                  ) : item.field_name === 'show_taxes' ? (
                    <div style={{ textAlign: 'right' }}>
                      <Switch
                        checked={!!data[item.field_name]}
                        onChange={checked =>
                          onChange({ [item.field_name]: checked })
                        }
                        style={{ marginBottom: 20 }}
                      />

                      <div
                        style={{
                          display: 'flex',
                          gap: 10,
                          alignItems: 'center',
                        }}
                      >
                        <span>Tax rate: </span>
                        <NumberFormat
                          value={data.default_rate}
                          thousandSeparator
                          suffix='%'
                          customInput={TextField}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            onChange({
                              default_rate: formatStringToNumber(e),
                            })
                          }
                          onBlur={e => {
                            if (!e.target.value) {
                              onChange({
                                default_rate: 0,
                              })
                            }
                          }}
                          maxLength={6}
                          width='80px'
                          disabled={!data?.show_taxes}
                        />
                      </div>
                    </div>
                  ) : (
                    <Tooltip
                      title={
                        item.field_name === 'hide_zero_price' && item?.disabled
                          ? 'Can be changed if "Show price per item" enabled'
                          : ''
                      }
                    >
                      <Switch
                        checked={!!data[item.field_name]}
                        onChange={checked =>
                          onChange({ [item.field_name]: checked })
                        }
                        disabled={item?.disabled}
                      />
                    </Tooltip>
                  )}
                </SettingsRow>
              ))}
          </SettingsWrapper>
        </Section>
      ))}
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  margin: 30px 0;

  max-width: 500px;
`
export const Section = styled.div``
export const Row = styled.div`
  display: flex;

  & > p {
    flex: 0 0 400px;
  }
`
export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
`
export const SettingsWrapper = styled.div``
