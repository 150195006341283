import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Space, Divider, Alert } from 'antd'

import ReactHtmlParser from 'react-html-parser'
import { parsePreviewEmail } from 'features/Notification/helpers'

import { parsePhoneNumber } from 'features/Messaging/utils'
import { useAppSelector } from 'store/Orcatec/hooks'
import { TypeNotificationNum } from 'features/Notification/types'
import {
  removeSpace,
  removeTagsSms,
} from '../modals/NotificationModal/NotificationModal'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'

import { getTemplateLogoByIndusty } from 'api/NotificationModal'
import { useTranslation } from 'react-i18next'

const PreviewEmail = ({ email, company, logo, projectLink, attachments }) => {
  const { t } = useTranslation('notifications')

  return (
    <Space
      style={{
        justifyContent: 'center',
        width: '100%',
        background: 'white',
        padding: '20px',
      }}
      direction='vertical'
    >
      <Item>
        <b>{t('senderName')}:</b>
        <p>{email?.from_name}</p>
      </Item>
      <Item>
        <b>{t('replyTo')}:</b>
        <p>{email?.reply_to}</p>
      </Item>
      <Item>
        <b>{t('subject')}:</b>
        <p>{email?.subject}</p>
      </Item>
      {!!attachments?.length && (
        <Item>
          <b>{t('subject')}:</b>
          <AttachWrapper>{attachments}</AttachWrapper>
        </Item>
      )}

      <Divider />
      <LogoContainer>
        <img
          style={{
            width: 200,
            height: 200,
            margin: '0 auto',
            objectFit: 'contain',
          }}
          src={logo || `${process.env.REACT_APP_API_MEDIA}${company.logo}`}
          alt=''
        />
      </LogoContainer>

      <TextContainer>
        {ReactHtmlParser(
          parsePreviewEmail(email?.body, company.company_name),
          'text/html',
        )}
      </TextContainer>

      {TypeNotificationNum.TYPE_PROPOSAL === email?.type ? (
        <FooterContainer>
          <ViewButton>View Document</ViewButton>
          <MessageBrand>
            <BottomLink>
              If the link above doesn&apos;t work, please copy the link to your
              browser. {<br />} {projectLink}{' '}
            </BottomLink>
            <BrandLink href='https://orcatec.com/'>
              Create your Digital Invoice with Orcatec.com
            </BrandLink>
          </MessageBrand>
        </FooterContainer>
      ) : (
        <p style={{ textAlign: 'center' }}>
          Powered by <a href='https://orcatec.com/'>Orcatec.com</a>
        </p>
      )}
    </Space>
  )
}

const PreviewSms = ({ sms }) => {
  const { t } = useTranslation('notifications')

  return (
    <Space
      direction='vertical'
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}
    >
      {sms?.phone?.id && (
        <Item>
          <b>{t('from')}:</b>
          <p>
            {sms?.phone?.alias
              ? sms?.phone?.alias
              : parsePhoneNumber(sms?.phone?.number)}
          </p>
        </Item>
      )}

      <MessageWrapper>{removeSpace(removeTagsSms(sms?.body))}</MessageWrapper>
    </Space>
  )
}

export const PreviewHOC = ({
  children,
  email,
  sms,
  industryId,
  projectLink,
  attachments,
  withInfo,
}) => {
  const company = useAppSelector(state => state.orcatec.company)
  const { t } = useTranslation('notifications')

  const [editMode, setEditMode] = useState(false)
  const [logo, setLogo] = useState(null)

  const toggleEdit = () => {
    setEditMode(prev => !prev)
  }

  useEffect(() => {
    const fetchIndustryLogo = async () => {
      try {
        const { industry_logo } = await getTemplateLogoByIndusty(industryId)

        setLogo(industry_logo)
      } catch (error) {
        console.error(error)
      }
    }
    if (industryId) fetchIndustryLogo()
  }, [industryId])

  return (
    <Container>
      {!editMode ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '10px',
            }}
          >
            <MainButton title={t('edit')} onClick={toggleEdit} />
            {withInfo && (
              <Alert message={t('prompts.linkView')} type='info' showIcon />
            )}
          </div>
          <PreviewWrapper>
            {email && (
              <PreviewEmail
                logo={logo}
                email={email}
                projectLink={projectLink}
                company={company}
                attachments={attachments}
              />
            )}
            {sms && <PreviewSms sms={sms} />}
          </PreviewWrapper>
        </>
      ) : (
        <Wrapper>
          {' '}
          <MainButton
            style={{ marginBottom: '10px' }}
            title={t('preview')}
            onClick={toggleEdit}
          />
          {children}
        </Wrapper>
      )}
    </Container>
  )
}

const Wrapper = styled.div``
const Container = styled.div`
  min-height: 600px;
`
const PreviewWrapper = styled.div`
  background-color: #f6f6f6;
  padding: 20px;
  width: 100%;
  max-width: 700px;
`
const LogoContainer = styled.div`
  text-align: center;
  width: 100%;
`

const TextContainer = styled.div`
  text-align: left;
  width: 100%;
`

const FooterContainer = styled.div`
  text-align: left;
  margin-bottom: 10px;
`

const MessageBrand = styled.p`
  /* text-align: right; */
  font-size: 11px;
  margin-bottom: 0;
  color: #0000ee;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BrandLink = styled.a`
  text-align: right;
  text-decoration: none;
`

const BottomLink = styled.p`
  font-size: 8px;
  text-decoration: none;
  color: black;
  word-break: break-all;
`

const ViewButton = styled.div`
  background: #4285f4;
  padding: 5px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 2em;
  width: 190px;
  margin: auto;
  text-align: center;
  color: white;
  margin-bottom: 10px;
`

const MessageWrapper = styled.div`
  background: #00d0ea;
  border-radius: 15px 15px 0 15px;
  max-width: 400px;
  padding: 5px;
  align-items: flex-end;
  color: white;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const AttachWrapper = styled.div`
  & label {
    display: none;
  }
`
