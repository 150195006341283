import { Link } from 'react-router-dom'
import { Tour } from './types'
import { SettingsProjectTabs } from 'pages/Settings/Project/ProjectSettingsPage'
import { CustomFormsTabs } from 'pages/Forms/CustomFormPage'

export const guideStepsByModule: Record<string, Tour> = {
  dispatch: {
    title: 'Dispatch',
    location: '/dispatch',
    steps: [
      {
        target: 'button[data-testid="create-appointment-button"]',
        content: 'Click this button to create an appointment',
        disableBeacon: true,
        title: 'Create Appointment',
      },
      {
        target: 'button[data-testid="add-timeoff-button"]',
        content: 'Click here to create a time off for an employee',
        disableBeacon: true,
        title: 'Add time off',
      },
      {
        target: 'div[data-testid="date-calendar"]',
        content:
          "Here, you can view the selected date, select another date using the calendar icon, and view the Dispatch for today's schedule",
        disableBeacon: true,
        title: 'Date section',
      },
      {
        target: 'div[data-testid="dispatch-view-selector"]',
        content:
          "You can choose between 'Day View', 'Week View', 'List View', or 'Time Off List' for Dispatch using this field",
        disableBeacon: true,
        title: 'Dispatch View',
      },
      {
        target: 'div[data-testid="dispatch-size"]',
        content:
          'Customize your Dispatch view. Choose S, M, or L to adjust column width, font size, and event display. Find the perfect fit for your workflow.',
        disableBeacon: true,
        title: 'Dispatch Size',
      },
      {
        target: '.queues',
        content:
          'You can keep appointments in the queue. Users have the option to choose where to assign events: either to the queue or directly to the dispatch. Any appointment in the queue can be dragged and dropped onto the Dispatch at any available time slot. Regardless of its actual duration, it will occupy only a 1-hour slot on the Dispatch',
        disableBeacon: true,
        title: 'Dispatch Queue',
      },
      {
        target: '.queues',
        content: (
          <div>
            You can add new Queues or modify this one in the
            <Link to='/settings/dispatch' target='_blank'>
              {' '}
              Dispatch settings
            </Link>
          </div>
        ),
        disableBeacon: true,
        title: 'Dispatch Queue',
      },
      {
        target: 'div[data-testid="dispatch-column-header"]',
        content: (
          <div>
            You can click on the column to open column settings. To add a new
            column, go to
            <Link to='/settings/dispatch' target='_blank'>
              {' '}
              Dispatch settings
            </Link>
          </div>
        ),
        disableBeacon: true,
        title: 'Dispatch Column',
      },
      {
        target: '.playground',
        content: 'You can click on empty squere to create an appointment',
        disableBeacon: true,
        spotlightClicks: true,
        disableCloseOnEsc: true,
        disableOverlayClose: true,
        hideBackButton: true,
        hideNextButton: true,
        spotlightPadding: 3,
        hideFooter: true,
        title: 'Create appointment',
        data: {
          // timeout: 1500,
          triggerNextTour: 'appointment',
        },
      },
    ],
  },
  appointment: {
    title: 'Appointment',
    location: '/dispatch',
    hideFromMenu: true,
    steps: [
      {
        target: 'div[data-testid="appointment-statuses"]',
        content: 'You can change the appointment status using these icons',
        disableBeacon: true,
        title: 'Appointment Status',
      },
      {
        target: 'div[data-testid="appointment-notification-history-icon"]',
        content:
          'After clicking this button, you can view the notification history for this appointment, including upcoming and en route notifications, requests for review, and more',
        disableBeacon: true,
        title: 'Notification history',
      },
      {
        target: 'div[data-testid="appointment-source"]',
        content:
          'Here, you can select an existing source or create a new one for the appointment',
        disableBeacon: true,
        title: 'Appointment Source',
      },
      {
        target: 'div[data-testid="appointment-type"]',
        content:
          'Here, you can select an existing type or create a new one for the appointment',
        disableBeacon: true,
        title: 'Appointment Type',
      },
      {
        target: 'div[data-testid="appointment-preferred-user"]',
        content:
          'In this field, you can select a preferred employee for the appointment, however, it will not assign the appointment to this user automatically',
        disableBeacon: true,
        title: 'Preferred User',
      },
      {
        target: 'div[data-testid="appointment-date"]',
        content: 'You can select the needed dates for this appointment',
        disableBeacon: true,
        title: 'Appointment Date',
      },
      {
        target: 'div[data-testid="appointment-promised-arrival-time"]',
        content: 'You can select the expected arrival time of the employee',
        disableBeacon: true,
        title: 'Promised arrival time',
      },
      {
        target: 'div[data-testid="appointment-assigned-to"]',
        content:
          'In this dropdown, all created columns in the dispatch are displayed. You can select the appropriate column for the appointment, and after saving, it will appear in the chosen column in the dispatch',
        disableBeacon: true,
        title: 'Assigned to',
      },
      {
        target: 'div[data-testid="appointment-dispatch-time"]',
        content:
          'Here you can choose a time slot to schedule the appointment in the column on the Dispatch.',
        disableBeacon: true,
        title: 'Dispatch Time',
      },
      {
        target: 'div[data-testid="appointment-use-office-location"]',
        content:
          'You can use this checkbox to attach your office address to this appointment if it should take place at your office',
        disableBeacon: true,
        title: 'Use office location checkbox',
      },
      {
        target: 'div[data-testid="appointment-location"]',
        content:
          "You can search for your client's address or manually input the required address. Once the address is added, it will be saved in our system, allowing you to view related contacts, appointments, media, projects, or forms",
        disableBeacon: true,
        title: 'Appointment Address',
        spotlightPadding: 3,
      },
    ],
  },
  projectsTable: {
    title: 'Projects',
    location: '/projects',
    steps: [
      {
        target: 'button[data-testid="projects--create-project"]',
        content:
          'Press the highlighted button to begin preparing a proposition for your client.',
        disableBeacon: true,
        title: 'Create Project',
        hideCloseButton: true,
        hideNextButton: true,
        spotlightClicks: true,
        disableCloseOnEsc: true,
        disableOverlayClose: true,
        data: {
          timeout: 500,
        },
      },
      {
        target: 'div[data-testid="projects-modal--type-and-source"]',
        content:
          'Here, you can select an existing source and type or create a new one for the project',
        disableBeacon: true,
        title: 'Source and Type fields',
      },
      {
        target: 'div[data-testid="projects-modal--industry"]',
        content:
          'If your company operates in multiple industries, you can select the relevant industry here.',
        disableBeacon: true,
        title: 'Industry',
      },
      {
        target: 'button[data-testid="projects-modal--create-project"]',
        content:
          "Click the 'Create' button to complete the setup. You'll then be redirected to the project page, where you can manage all details and ensure smooth collaboration.",
        disableBeacon: true,
        title: 'Create',
        hideCloseButton: true,
        hideBackButton: true,
        hideNextButton: true,
        spotlightClicks: true,
        disableCloseOnEsc: true,
        disableOverlayClose: true,
        data: {
          triggerNextTour: 'project',
        },
      },
    ],
  },
  project: {
    title: 'Projects',
    location: '/projects',
    hideFromMenu: true,
    steps: [
      {
        target: 'div[data-testid="project--projectId"]',
        content: (
          <div>
            This is a unique Project ID that consists of a &quot;user project
            ID&quot;, year, month, date, and increment. You can edit the project
            ID here and customize the ID pattern for your company in the
            <Link
              to={{
                pathname: '/settings/project',
                state: {
                  activeTab: SettingsProjectTabs.PROJECT_NUMBER,
                },
              }}
            >
              {' '}
              Project ID Pattern
            </Link>
          </div>
        ),
        disableBeacon: true,
        title: 'Project ID',
      },
      {
        target: 'span[data-testid="project--actions--settings"]',
        content:
          'Settings allow you to customize your display preferences, including choosing which sections to show or hide, adjusting the processing fee, and setting the default payment due date',
        disableBeacon: true,
        title: 'Project Settings',
      },
      {
        target: 'span[data-testid="project--actions--projectInsights"]',
        content: 'Here, you can view project insights',
        disableBeacon: true,
        title: 'Project Insights',
      },
      {
        target: 'span[data-testid="project--actions--projectCopy"]',
        content: 'You can duplicate this project',
        disableBeacon: true,
        title: 'Duplicate',
      },
      {
        target: 'span[data-testid="project--actions--clientActivity"]',
        content:
          'This button allows you to track the viewing history of the project, including when and by whom it was viewed',
        disableBeacon: true,
        title: 'Viewing History',
      },
      {
        target: 'span[data-testid="project--actions--projectHistory"]',
        content:
          'Use this button to view the history of status changes for the project, including when the client signed it',
        disableBeacon: true,
        title: 'Project History',
      },
      {
        target: 'span[data-testid="project--actions--projectSend"]',
        content:
          'After clicking this button, you can send your project to the client via email or message. Additionally, you can copy the public link for customer viewing, review the notification history for your client, and modify your email',
        disableBeacon: true,
        title: 'Send Project',
      },
      {
        target: 'div[data-testid="project--switcher"]',
        content:
          "You can view and edit the customer view by clicking on the ‘Preview' button",
        disableBeacon: true,
        title: "Switcher 'Edit/Preview'",
        spotlightClicks: true,
      },
      {
        target: 'div[data-testid="project--project-contacts"]',
        content:
          'You can add a new or existing contact/property to this project',
        disableBeacon: true,
        title: 'Add Contacts',
      },
      {
        target: 'div[data-testid="project--project-details"]',
        content:
          'In this area, you can modify the following fields: Assignee, Additional Assignee, Type, Source, Industry, and Issue Date. You can also delete the project here',
        disableBeacon: true,
        title: 'Project Details',
      },
      {
        target: 'div[data-testid="project--forms"]',
        content: (
          <div>
            Create and add your customized forms to visits to efficiently
            collect and manage information. To do this, you need to create the
            template for these forms{' '}
            <Link
              to={{
                pathname: '/custom-form',
                state: {
                  activeTab: CustomFormsTabs.TEMPLATES,
                },
              }}
            >
              Forms -{'>'} Templates
            </Link>
          </div>
        ),
        disableBeacon: true,
        title: 'Forms',
      },
      {
        target: 'div[data-testid="project--templates-section"]',
        content:
          'You can save your project as a template and use it for similar projects. When creating a new project using this template, the system will automatically populate the following fields: industry, sections with groups and items, labor cost, discounts, rebates, taxes, fees, tags, project settings, customer information, and notes for workers',
        disableBeacon: true,
        title: 'Template sections',
      },
      {
        target: '.proposal-financing-tabs',
        content:
          "You can add customizable tabs to offer clients more choices, including options like 'Good-Better-Best'. Each tab can include a different setup of sections with groups and items, labor costs, discounts, rebates, taxes, and fees. You can also create, edit, duplicate, and delete tabs as neededs",
        disableBeacon: true,
        title: 'Tabs',
      },
      {
        target: 'div[data-testid="project--modules"]',
        content: (
          <div>
            Here, you can add modules to enable specific actions and alerts
            within the project. For instance, if supplies are in the status
            &rsquo;Need to order&rsquo;, the project status cannot be changed to
            &rsquo;Completed&rsquo;. Additionally, you can customize up to three
            modules according to your preferences in the{' '}
            <Link
              to={{
                pathname: '/settings/project',
              }}
            >
              Settings -{'>'} Project -{'>'} Default Project Settings
            </Link>
          </div>
        ),
        disableBeacon: true,
        title: 'Modules',
      },
      {
        target: 'div[data-testid="project--information-for-workers"]',
        content:
          "You can create visits related to this project for your employees and view the list of related visits. Additionally, you can leave notes or attach files for your employees. You can also mark all visits as completed and select the date of completion using the 'Work Completed' checkbox",
        disableBeacon: true,
        title: 'Information for Workers',
      },
      {
        target: 'button[data-testid="project--add-section"]',
        content:
          'You can create multiple sections in the project to separate various stages, parts, or units. Each section can have its own description, groups and items',
        disableBeacon: true,
        title: 'Sections',
      },
      {
        target: '.project-items-table',
        content:
          'Here you can add line items to your project by searching in your price pages or adding custom ones by typing in the details. You can also add description, item cost and attach files to the items here',
        disableBeacon: true,
        title: 'Items',
      },
      {
        target: 'button[data-testid="project--add-group"]',
        content:
          'Here you can create groups and assign individual items to them for better organization in the project',
        disableBeacon: true,
        title: 'Group',
      },
      {
        target: 'div[data-testid="project--discounts-rebates"]',
        content:
          'Using these buttons, you can add discounts and rebates to your project',
        disableBeacon: true,
        title: 'Discount, Rebates',
      },
      {
        target: 'div[data-testid="project--tax-fees"]',
        content:
          'Using these buttons, you can add taxes and fees to your project',
        disableBeacon: true,
        title: 'Tax, Fee',
      },
      {
        target: '.proposal-form-tabs_content-payment_description',
        content:
          'Here, you can edit the balances for this project (due date, amount, status, restrictions), allowing your client to pay directly through our system',
        disableBeacon: true,
        title: 'Payment Schedule',
      },
      {
        target: 'div[data-testid="project--customer-info"]',
        content:
          'You can leave notes and add attachments for your client, which they will be able to view on their customer view. Additionally, your client can leave comments for you, which you can access here',
        disableBeacon: true,
        title: 'Information for/from customer',
      },
    ],
  },
}
