import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 3px;
  background: #fff;
`

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;

  & > span {
    color: #656565;

    &:hover {
      color: #000;
    }
  }
`

export const ComponentSettings = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  align-items: center;
  gap: 10px 5px;
  padding: 5px;
  border-top: 1px dashed;
  margin-top: 20px;
  background-color: #f0f8ff;

  p {
    line-height: 1.33;
  }
`

export const Options = styled.div`
  .anticon-delete {
    &:hover {
      color: red;
    }
  }
`

export const Option = styled.div`
  display: grid;
  grid-template-columns: 15px auto 10px;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
`
