import { useState, useEffect, useCallback } from 'react'
import { notification } from 'antd'
import UserForm from './UserForm'
import * as actions from 'store/Orcatec/actions'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { isUserFullSettings } from 'helpers/isUserFullSettings'
import validator from 'validator'
import {
  updateUserPassword,
  getProposalIdentifier,
  generateInviteLink,
  changeNavigationInterface,
  getCurrentJournal,
} from 'api/User'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { deleteMedia } from 'api/Media'
import { revertOfTransformStringIfAddressInHere } from 'helpers/transformStringIfAddressInHere'
import { beautifyEmailInput } from 'helpers/beautifyEmailInput.js'
import { useAppSelector } from 'store/Orcatec/hooks'

import { Companies } from 'types/Company'
import { selectIsOwner } from 'features/Settings/UsersAndGroups/permissionSlice'

export const getFullAddressString = address => {
  return `${address.address}, ${address.zip || address.postcode}, ${
    address.city
  }, ${address.state}`
}

const initialAddress = {
  address: '',
  zip: '',
  city: '',
  state: '',
  lat: 37,
  lng: -95,
  unit: '',
}

const initialOfficeAddressData = {
  address: initialAddress,
  choosenAddress: initialAddress,
}

const initPasswordData = {
  old_password: '',
  old_password_show: false,
  password: '',
  password_show: false,
  password_confirmation: '',
  password_confirmation_show: false,
}

const initialUserData = {
  id: null,
  profile_picture: '',
  profile_picture_id: 0,
  crop: null,
  full_name: '',
  email: '',
  phone: '',
  number_prefix: '',
  title: '',
  status: 1,
  role_id: '',
  useAsTech: false,
  use_clock_in_with_gps: false,
  restrict_contract_access: false,
  permissions: [],
  address: initialAddress,
  choosenAddress: initialAddress,
  use_office_address: false,
  change_date_status: '',
  memorizedPropIdentifier: '',
  labor_rate: '0',
  commission_type: null,
  commission_percent: '',
  ...initPasswordData,
}

const initPasswordDataErrors = {
  old_password: '',
  password: '',
  password_confirmation: '',
}

const initialUserDataErrors = {
  profile_picture: '',
  full_name: '',
  email: '',
  phone: '',
  number_prefix: '',
  title: '',
  status: '',
  role_id: '',
  permissions: '',
  address: '',
  officeAddress: '',
  labor_rate: '',
  commission_type: null,
  commission_percent: '',
  ...initPasswordDataErrors,
}

const initialConfirmData = {
  showModal: false,
  data: {},
}

const permissionsList = {
  //dashboard: 'Dashboard',
  appointments: 'Dispatch',
  //mail: 'Mail',
  proposals: 'Projects',
  properties: 'Properties',
  clients: 'Contacts',
  todo: 'To Do',
  //serviceContract: 'Service Contracts',
  schedules: 'Schedule',
  //articles: 'Articles',
  //marketing: 'Marketing',
  time_cards: 'Time Cards',
  request_review: 'Reviews',
  //emailCampaign: 'Email Campaign',
  map: 'Map',
  settings: 'Settings',
  quickbooks: 'Quickbooks',
  work_schedule: 'Jobs',
  fast_appointment: 'Fast Appointment',
  fast_payment: 'Fast Payment',
  accounting: 'Accounting',
  messaging: 'Messaging',
}

const scrollToFirstError = errorsObj => {
  if (!errorsObj) return

  const firstErrorKey = Object.keys(errorsObj).find(key => !!errorsObj[key])
  if (firstErrorKey) {
    const element = document.getElementById('user-' + firstErrorKey)
    if (element) {
      element.scrollTop += 10
      element.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }
  return firstErrorKey
}

const UserFormContainer = ({
  user, // currentUser (for edit users) or me(for profile settings) from redux
  isProfileSettings = false,
  isMapFixingNeeded = false,
  currentPage, //path to current page - from props
  closeForm, //from props
  activeUserCheckbox,
  searchValue,
  ///////////////////////////
  officeAddress = initialAddress, //connect
  company, //connect
  uploadMedia, //connect
  createUser, //connect
  updateUser, //connect
  saveCompany, //connect
  fetchUserByToken, //connect
  fetchUsersList, //connect
}) => {
  if (!isProfileSettings && !!user && user.role_id === 1)
    isProfileSettings = true //if edit owner was opened not from profile settings
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const [isPasswordUpdating, setIsPasswordUpdating] = useState(false)
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)
  const [userData, setUserData] = useState(initialUserData)
  const [userDataErrors, setUserDataErrors] = useState(initialUserDataErrors)
  const [officeAddressData, setOfficeAddressData] = useState(
    initialOfficeAddressData,
  ) //uses for owner firs settings when no office address
  const [confirmData, setConfirmData] = useState(initialConfirmData)
  const [proposalIdentifier, setProposalIdentifier] = useState('')
  const [link, setLink] = useState('')
  const [isAccManagerFromAdmin, setIsAccManagerFromAdmin] = useState(false)
  // New Navigation
  const userID = useAppSelector(s => s.orcatec.user.me.id)
  // const isNewSidebar = useAppSelector(
  //   s => s.orcatec.user.me?.ui_settings?.is_vertical_sidebar,
  // )

  const headerType = useAppSelector(
    s => s.orcatec.user.me?.ui_settings?.header_view,
  )
  // const userRoleID = useAppSelector(s => s.orcatec.user.me.role_id)
  // const userPermissions = useAppSelector(s => s.orcatec.user.me.permissions)

  // const [newNavigation, setNewNavigation] = useState(isNewSidebar)
  const [layoutType, setLayoutType] = useState(headerType)
  const dispatch = useDispatch()

  const [disabledModules, setDisabledModules] = useState([])

  const isAdmin = useAppSelector(selectIsOwner(userID))

  useEffect(() => {
    getCurrentJournal()
      .then(({ pay_modules }) => {
        for (const key in pay_modules) {
          if (!pay_modules?.[key].paid) {
            setDisabledModules(prev => [...prev, key])
          }
        }
      })
      .catch(e => console.error(e))
  }, [])

  // useEffect(() => {
  //   setNewNavigation(isNewSidebar)
  // }, [isNewSidebar])

  useEffect(() => {
    setLayoutType(headerType)
  }, [headerType])

  useEffect(() => {
    if (user && user?.role_id === 11 && user?.login_from_admin) {
      setIsAccManagerFromAdmin(true)
    } else {
      setIsAccManagerFromAdmin(false)
    }
  }, [])

  useEffect(() => {
    if (!user.info.number_prefix)
      getProposalIdentifier().then(({ proposal_identifier }) => {
        setProposalIdentifier(proposal_identifier)
      })
  }, [user])

  useEffect(() => {
    if (user) {
      setCurrentEmail(user.email)
      const {
        id,
        full_name,
        email,
        phone,
        info,
        title,
        token_confirmation,
        status,
        role_id,
        useAsTech,
        permissions,
        use_clock_in_with_gps,
        restrict_contract_access,
        labor_rate,
        commission_type,
        commission_percent,
      } = user
      const address =
        !!info?.address?.address && !info.use_office_address
          ? info.address
          : !!info?.use_office_address && !!officeAddress?.address
          ? officeAddress
          : initialAddress

      setUserData({
        ...initialUserData,
        id,
        full_name,
        email,
        phone,
        number_prefix: info?.number_prefix
          ? info.number_prefix
          : proposalIdentifier,
        memorizedPropIdentifier: proposalIdentifier,
        profile_picture:
          !!info && !!info.profile_picture ? info.profile_picture : '',
        profile_picture_id:
          !!info && !!info.profile_picture_id ? info.profile_picture_id : 0,
        change_date_status:
          !!info && !!info.change_date_status
            ? moment(info.change_date_status).isValid()
              ? moment
                  .utc(info.change_date_status)
                  .local()
                  .format('MM/DD/YYYY hh:mma')
              : info.change_date_status
            : '',
        title,
        token_confirmation,
        status,
        role_id: role_id,
        useAsTech,
        use_clock_in_with_gps,
        restrict_contract_access,
        // permissions: Object.keys(permissions).filter(item => permissions[item]),
        address: address.address
          ? {
              ...address,
              address: getFullAddressString(address),
            }
          : address,
        choosenAddress: address,
        commission_type: commission_type ? commission_type : null,
        commission_percent: commission_percent ? commission_percent : '0',
        labor_rate: labor_rate ? labor_rate : '0',
        use_office_address:
          (address?.address && info.use_office_address) ||
          (address?.lat === officeAddress?.lat &&
            address?.lng === officeAddress?.lng),
      })
    } else {
      const office =
        !!officeAddress && !!officeAddress.address
          ? officeAddress
          : initialAddress
      setUserData({
        ...initialUserData,
        address: office.address
          ? {
              ...office,
              address: getFullAddressString(office),
            }
          : office,
        choosenAddress: office,
        use_office_address: !!office.address,
        number_prefix: proposalIdentifier,
        memorizedPropIdentifier: proposalIdentifier,
      })
    }
    setUserDataErrors(initialUserDataErrors)
    setConfirmData(initialConfirmData)
    setIsChangePasswordOpen(false)
  }, [user, proposalIdentifier])

  useEffect(() => {
    if (isChangePasswordOpen) {
      const element = document.getElementById('user-old_password')
      element.scrollTop += 10
      element?.scrollIntoView?.({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [isChangePasswordOpen])

  useEffect(() => {
    let timeout = null
    if (typeof isPasswordUpdating === 'string') {
      timeout = setTimeout(() => {
        setIsPasswordUpdating(false)
      }, 2000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isPasswordUpdating])

  const onChangeStatus = e => {
    setUserData(prev => ({ ...prev, status: +e.target.value }))
    setUserDataErrors(prev => ({ ...prev, profile_picture: '' }))
  }
  const onChangeRole = e => {
    const role_id = +e.target.value
    const { name } = e.target
    let permissions
    let useAsTech = false
    switch (role_id) {
      case 1: //owner
      case 2: //admin
        permissions = [
          'map',
          'dashboard',
          'appointments',
          'mail',
          'proposals',
          'properties',
          'clients',
          'todo',
          'serviceContract',
          'schedules',
          'articles',
          'marketing',
          'time_cards',
          'request_review',
          'emailCampaign',
          'settings',
        ]
        break
      case 3: //dispath
        permissions = [
          'map',
          'appointments',
          'mail',
          'proposals',
          'properties',
          'clients',
          'todo',
          'serviceContract',
        ]
        break
      case 4: //tech
      case 5: //worker
        permissions = [
          'dashboard',
          'mail',
          'proposals',
          'todo',
          'serviceContract',
          'schedules',
        ]
        useAsTech = true
        break
      /* case 5: //installer
        permissions = ['map', 'dashboard', 'mail', 'todo', 'schedules']
        break */
      case 6: //accountant
        permissions = [
          'fast_payment',
          'accounting',
          // 'dashboard',
          'mail',
          'proposals',
          'properties',
          'clients',
          // 'todo',
          // 'serviceContract',
          // 'time_cards',
          // 'request_review',
          // 'settings',
        ]
        break
      default:
        break
    }

    const filteredPermissions = permissions.filter(
      el => !disabledModules.includes(el),
    )

    setUserData(prev => ({
      ...prev,
      permissions: filteredPermissions,
      useAsTech,
      role_id,
    }))
    setUserDataErrors(prev => ({ ...prev, [name]: '', profile_picture: '' }))
  }

  const onChangePermissions = permissions => {
    setUserData(prev => ({ ...prev, permissions }))
    setUserDataErrors(prev => ({ ...prev, profile_picture: '' }))
  }
  const phoneValidator = (value, setError) => {
    const validValue = value.replace(/\D/g, '')
    const errorMessage = !validValue
      ? 'Please input your phone number'
      : validValue.length < 10
      ? 'The phone must be 10 digits'
      : ''
    setError(errorMessage)
    /* if (validValue.length < 10 && validValue.length > 0) {
      setError('The phone must be 10 digits')
    } else if (!validValue) {
      setError('Please input your phone number')
    } else if (validValue.length === 10) {
      setError('')
    } */
  }

  const handleChangeManualOfficeAddress = e => {
    const { name, value } = e.target

    if (name === 'officeAddress') {
      setOfficeAddressData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          address: value,
        },
        choosenAddress: {
          ...prev.choosenAddress,
          address: value,
        },
      }))
    } else if (name === 'map') {
      setOfficeAddressData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          lat: value.lat,
          lng: value.lng,
        },
        choosenAddress: {
          ...prev.choosenAddress,
          lat: value.lat,
          lng: value.lng,
        },
      }))
    } else {
      setOfficeAddressData(prev => ({
        ...prev,
        address: {
          ...prev.address,
          [name]: value,
        },
        choosenAddress: {
          ...prev.choosenAddress,
          [name]: value,
        },
      }))
    }
  }

  const onChangeTextField = useCallback(
    e => {
      const newUserDataErrors = { ...userDataErrors }
      let { name, value } = e.target
      name = revertOfTransformStringIfAddressInHere(name)
      switch (name) {
        case 'full_name': {
          //const isFullName = /^[a-zA-Z0-9-'`]{2,}(\s[a-zA-Z0-9-'`]+)+?$/.test(value)
          const isFullName = /^[a-zA-Zа-яА-ЯіїєґІЇЄҐ0-9- ʼ']+$/.test(value)
          newUserDataErrors[name] =
            !!value.length && isFullName ? '' : 'Please input Full name'
          break
        }
        case 'email': {
          newUserDataErrors[name] = value
            ? validator.isEmail(value)
              ? ''
              : 'Please input valid email'
            : 'Please input email address'
          break
        }
        case 'phone': {
          const x = value
            .replace(/\D/g, '')
            .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
          value = !x[2]
            ? x[1]
            : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')

          const setError = error => {
            newUserDataErrors[name] = error
          }
          //value = e.target.value
          phoneValidator(value, setError)
          break
        }
        case 'title': {
          newUserDataErrors[name] = value ? '' : 'Please input title'
          break
        }
        case 'number_prefix': {
          value = value
            ? value.length <= 3
              ? value.replace(/[^a-z]/gi, '').toUpperCase()
              : value.replace(/[^a-z]/gi, '').replace(/^([A-Z]{0,3})(.+)/, '$1')
            : ''
          newUserDataErrors[name] = value
            ? /^[A-Z]{2,3}$/.test(value)
              ? ''
              : 'Must be 2-3 letters'
            : 'Please input project identifier'
          break
        }
        case 'address': {
          newUserDataErrors[name] = value
            ? ''
            : 'Please input your day start location'
          break
        }
        case 'officeAddress': {
          newUserDataErrors[name] = value
            ? ''
            : 'Please input your office address'
          break
        }
        case 'old_password': {
          newUserDataErrors[name] = !value
            ? 'Please input your current password'
            : ''
          break
        }
        case 'password': {
          if (value.length > 32) value = value.substring(0, 32)
          const newReg = new RegExp(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,32}$/g,
          )
          newUserDataErrors[name] = value
            ? newReg.test(value)
              ? ''
              : 'Password must contain at least one capital letter, one regular letter, one digit and be minimum 6 symbols length'
            : 'Please enter your new password'
          newUserDataErrors.password_confirmation =
            !!userData.password_confirmation &&
            value !== userData.password_confirmation &&
            !newUserDataErrors[name]
              ? 'Passwords do not match'
              : ''

          break
        }

        case 'password_confirmation': {
          if (value.length > 32) value = value.substring(0, 32)
          newUserDataErrors[name] =
            value === userData.password ? '' : 'Passwords do not match'
          break
        }
        default:
          break
      }
      newUserDataErrors.profile_picture = ''

      if (name === 'officeAddress') {
        setOfficeAddressData(prev => ({
          ...prev,
          address: {
            ...initialAddress,
            lat: prev.address.lat,
            lng: prev.address.lng,
            address: value,
            unit: prev.address.unit,
          },
          choosenAddress: {
            ...initialAddress,
            lat: prev.choosenAddress.lat,
            lng: prev.choosenAddress.lng,
            unit: prev.address.unit,
          },
        }))
        setUserDataErrors(newUserDataErrors)
      } else if (name === 'address') {
        setUserData(prev => ({
          ...prev,
          [name]: {
            ...initialAddress,
            lat: prev[name].lat,
            lng: prev[name].lng,
            [name]: value,
            unit: prev.address.unit,
          },
          choosenAddress: {
            ...initialAddress,
            lat: prev.choosenAddress.lat,
            lng: prev.choosenAddress.lng,
          },
        }))
        setUserDataErrors(newUserDataErrors)
      } else if (name === 'labor_rate') {
        const isNumberInput = input => {
          const rgx = /^[0-9]*\.?[0-9]*$/
          return input.match(rgx)
        }

        if (isNumberInput(value)) {
          return setUserData(prev => ({
            ...prev,
            [name]: value,
          }))
        }
      } else if (name === 'commission_percent') {
        const isNumberInput = input => {
          const rgx = /^[0-9]*\.?[0-9]*$/
          return input.match(rgx)
        }

        if (isNumberInput(value)) {
          return setUserData(prev => ({
            ...prev,
            [name]: value,
          }))
        }
      } else if (name === 'unit') {
        setUserData(prev => {
          const prevAdress = JSON.parse(JSON.stringify(prev.address))
          return {
            ...prev,
            address: { ...initialAddress, ...prevAdress, [name]: value },
            choosenAddress: {
              ...initialAddress,
              ...prevAdress,
              address: prev.choosenAddress.address,
              [name]: value,
            },
          }
        })
      } else if (name === 'company-unit') {
        setOfficeAddressData(prev => {
          const prevAdress = JSON.parse(JSON.stringify(prev.address))
          return {
            ...prev,
            address: { ...initialAddress, ...prevAdress, unit: value },
            choosenAddress: {
              ...initialAddress,
              ...prevAdress,
              address: prev.choosenAddress.address,
              unit: value,
            },
          }
        })
      } else if (name === 'email') {
        setUserData(prev => ({
          ...prev,
          [name]: beautifyEmailInput(name, value),
        }))
        setUserDataErrors(newUserDataErrors)
      } else {
        setUserData(prev => ({
          ...prev,
          [name]: value,
        }))
        setUserDataErrors(newUserDataErrors)
      }
    },
    [userDataErrors],
  )

  const onCancelChangePassword = e => {
    e.preventDefault()
    setIsChangePasswordOpen(!isChangePasswordOpen)
    setUserData(prev => ({
      ...prev,
      ...initPasswordData,
    }))
    setUserDataErrors(prev => ({
      ...prev,
      ...initPasswordDataErrors,
    }))
  }

  const togglePasswordVisibility = name => () => {
    setUserData(prev => ({
      ...prev,
      [`${name}_show`]: !prev[`${name}_show`],
    }))
    setUserDataErrors(prev => ({ ...prev, profile_picture: '' }))
  }

  const validateFieldsAndScroll = () => {
    ///check if there are some error
    // eslint-disable-next-line no-unused-vars
    const {
      // old_password,
      // password,
      // password_confirmation,
      ...errorsWithoutPasswords
    } = userDataErrors
    let firstError = scrollToFirstError(errorsWithoutPasswords)

    if (firstError) return false
    //// if no errors then check required fields
    ///const newUserDataErrors = { ...userDataErrors }
    const restErrors = { ...errorsWithoutPasswords }
    restErrors.full_name = userData.full_name ? '' : 'Please input Full name'
    restErrors.email = userData.email ? '' : 'Please input email address'
    restErrors.phone = userData.phone ? '' : 'Please input phone number'
    restErrors.title = userData.title ? '' : 'Please input title'
    restErrors.title = userData.title ? '' : 'Please input title'
    restErrors.number_prefix = userData.number_prefix
      ? ''
      : 'Please input project identifier'
    // restErrors.role_id = userData.role_id ? '' : 'Please choose role'
    restErrors.status = !isNaN(userData.status) ? '' : 'Please choose status'
    restErrors.address = userData.use_office_address
      ? ''
      : userData.address.address
      ? !!userData.address.city &&
        !!userData.address.state &&
        !!userData.address.zip
        ? ''
        : 'Please select address from dropdown'
      : 'Please input your day start location'

    if (
      isProfileSettings &&
      user.role_id === 1 &&
      (!officeAddress.address ||
        !officeAddress.city ||
        !officeAddress.state ||
        !officeAddress.zip)
    ) {
      restErrors.officeAddress = officeAddressData.address.address
        ? !!officeAddressData.address.city &&
          !!officeAddressData.address.state &&
          !!officeAddressData.address.zip
          ? ''
          : 'Please select address from dropdown'
        : 'Please input your office address'
    }
    // restErrors.old_password = !userData.old_password && (!!userData.password || !!userData.password_confirmation) ? 'Please input your current password' : ''
    // restErrors.password = !userData.old_password && (!!userData.password || !!userData.password_confirmation) ? 'Please input your current password' : ''
    firstError = scrollToFirstError(restErrors)

    if (firstError) setUserDataErrors(prev => ({ ...prev, ...restErrors }))
    return !firstError
  }

  const validatePasswordsAndScroll = () => {
    const errorData = {
      old_password: userDataErrors.old_password,
      password: userDataErrors.password,
      password_confirmation: userDataErrors.password_confirmation,
    }
    let firstError = scrollToFirstError(errorData)
    if (firstError) return false
    errorData.old_password = userData.old_password
      ? ''
      : 'Please input your current password'
    errorData.password = userData.password ? '' : 'Please input new password'
    errorData.password_confirmation = userData.password_confirmation
      ? ''
      : 'Please confirm new password'
    firstError = scrollToFirstError(errorData)
    if (firstError) setUserDataErrors(prev => ({ ...prev, ...errorData }))
    return !firstError
  }

  const asyncErrorHandler = error => {
    // const errorStatus = error.response?.status || ''
    const errorMessage = Object.values?.(error.response?.data || {})?.[0] || ''

    openNotificationWithIcon('error', {
      message: errorMessage,
      // description: errorMessage,
    })

    if (!!error.response && error.response.status === 422) {
      if (error.response.data.scheduledAppointments) {
        return setConfirmData({
          showModal: true,
          data: error.response.data,
        })
      }
      const asyncErrors = error.response?.data
      const validationFields =
        asyncErrors &&
        Object.entries(asyncErrors.errors)?.reduce((acc, [key, value]) => {
          let field = key
          let error = value?.[0]
          if (field === 'info.number_prefix') {
            field = 'number_prefix'
          }
          if (field === 'phone') {
            if (/\b14\b/.test(error)) error = error?.replace(/\b14\b/, '10')
          }
          acc[field] = error
          return acc
        }, {})

      scrollToFirstError(validationFields)
      setUserDataErrors(prev => ({ ...prev, ...validationFields }))
    }
  }

  const getSuccessMessage = () => {
    if (user) {
      return currentEmail !== userData.email
        ? {
            message: 'Your changes have been saved successfully.',
            description: isAdmin
              ? 'Confirm changes of your email on your new email address.'
              : '',
          }
        : {
            message: 'Your changes have been saved successfully.',
            description: '',
          }
    } else {
      return {
        message: 'New user has been saved.',
        description: '',
      }
    }
  }

  const filteredPermissionsList = [
    Companies.HVAC,
    Companies.WestCost,
    Companies.Progressive,
  ].includes(company.id)
    ? { ...permissionsList, memberships: 'Memberships' }
    : permissionsList

  const handleSubmit = confirm => {
    const getClientsListIfNeeded = () => {
      if (currentPage === '/settings/users') {
        fetchUsersList(1, 10, searchValue, null, null, activeUserCheckbox)
      }
    }
    const isValid = validateFieldsAndScroll()

    if (isValid) {
      const status = isProfileSettings ? user.status : userData.status
      const change_date_status = user
        ? user.status !== status
          ? moment()
              .utc()
              .format('YYYY-MM-DD HH:mm:ss')
          : userData.change_date_status
          ? userData.change_date_status
          : ''
        : moment()
            .utc()
            .format('YYYY-MM-DD HH:mm:ss')
      const permissions = isAccManagerFromAdmin
        ? user.permissions
        : Array.isArray(userData.permissions)
        ? Object.keys(filteredPermissionsList).reduce((acc, currKey) => {
            acc[currKey] = userData.permissions.includes(currKey)
            return acc
          }, {})
        : user.permissions
      const address = userData.choosenAddress

      const {
        old_password,
        old_password_show,
        password,
        password_show,
        password_confirmation,
        password_confirmation_show,
        choosenAddress,
        use_office_address,
        number_prefix,
        profile_picture,
        profile_picture_id,
        labor_rate,
        commission_percent,
        commission_type,
        ...rest
      } = userData
      const info = {
        number_prefix,
        profile_picture,
        profile_picture_id,
        use_office_address,
        change_date_status,
        address,
      }
      const data = {
        ...rest,
        status,
        // change_date_status,
        permissions,
        // address,
        info,
        labor_rate: labor_rate ? labor_rate : '0',
        commission_percent: commission_percent ? commission_percent : '0',
        commission_type: commission_type ? commission_type : null,
      }

      // if (userRoleID === 8) {
      //   data?.permissions = userPermissions
      // }

      const saveUser = user
        ? newdata => updateUser(user.id, newdata, confirm)
        : newdata => createUser(newdata)

      const isOfficeAddress =
        !!officeAddress.address &&
        !!officeAddress.city &&
        !!officeAddress.state &&
        (!!officeAddress.zip || !!officeAddress.postcode)
      const companyData = {
        company_name: company.company_name,
        industry: company.industry,
        subscription_off: !!company.subscription_off,
        address: officeAddressData.choosenAddress,
        clock_out_type: company?.time_card_settings?.clock_out_type,
        clock_out_timeout:
          company?.time_card_settings?.clock_out_timeout || 600,
        clock_out_time: company?.time_card_settings?.clock_out_time,
      }

      const resolveSaveUser = () => {
        getClientsListIfNeeded()
        if (closeForm) {
          closeForm()
        }
        const data = {
          // is_vertical_sidebar: newNavigation,
          header_view: layoutType,
        }

        if (userID) {
          changeNavigationInterface(userID, data)
            .then(() => dispatch(fetchUserByToken()))
            .catch(e => console.error(e))
        }
        openNotificationWithIcon('success', {
          message: getSuccessMessage().message,
          description: getSuccessMessage().description,
          key: 'settings-user-save-success',
        })
        return 'done'
      }
      const finallySaveUser = () => {
        setIsSubmitting(false)
      }
      setIsSubmitting(true)

      if (data.info.profile_picture instanceof File) {
        const fd = new FormData()
        fd.append('media', data.info.profile_picture)
        uploadMedia(fd)
          .then(response => {
            data.info.profile_picture = response[0].preview
            data.info.profile_picture_id = response[0].id
            if (profile_picture_id) deleteMedia(profile_picture_id)
            if (isOfficeAddress) {
              saveUser(data)
                .then(resolveSaveUser)
                .catch(asyncErrorHandler)
                .finally(finallySaveUser)
            } else {
              Promise.all([
                saveUser(data),
                saveCompany(companyData, company.id),
              ])
                .then(resolveSaveUser)
                .catch(asyncErrorHandler)
                .finally(finallySaveUser)
            }
          })
          .finally(finallySaveUser)
      } else {
        if (profile_picture_id && !profile_picture)
          deleteMedia(profile_picture_id)
        if (isOfficeAddress) {
          saveUser(data)
            .then(resolveSaveUser)
            .catch(asyncErrorHandler)
            .finally(finallySaveUser)
        } else {
          Promise.all([saveUser(data), saveCompany(companyData, company.id)])
            .then(resolveSaveUser)
            .catch(asyncErrorHandler)
            .finally(finallySaveUser)
        }
      }
    }
  }

  const onUpdatePassword = useCallback(
    e => {
      e.preventDefault()
      const isValid = validatePasswordsAndScroll()
      if (isValid) {
        const data = {
          old_password: userData.old_password,
          password: userData.password,
          password_confirmation: userData.password_confirmation,
        }
        setIsPasswordUpdating(true)
        updateUserPassword(user.id, data)
          .then(() => {
            setIsChangePasswordOpen(!isChangePasswordOpen)
            setUserData(prev => ({
              ...prev,
              ...initPasswordData,
            }))
            setIsPasswordUpdating('Password was successfully updated')
            openNotificationWithIcon('success', {
              message: 'Success!',
              description: 'Password was successfully updated!',
            })
          })
          .catch(asyncErrorHandler)
          .finally(() => setIsPasswordUpdating(false))
      }
    },
    [validatePasswordsAndScroll, userData, asyncErrorHandler],
  )

  const onChangeUseOfficeAddress = e => {
    const { checked } = e.target

    setUserData(prev => ({
      ...prev,
      address: officeAddressData.address,
      use_office_address: checked,
    }))

    // if (e.target.checked) {
    //   const office =
    //     !!officeAddress &&
    //     !!officeAddress.address &&
    //     !!officeAddress.city &&
    //     !!officeAddress.state
    //       ? officeAddress
    //       : officeAddressData.choosenAddress
    //   setUserData(prev => ({
    //     ...prev,
    //     address: {
    //       ...office,
    //       address: office.address ? getFullAddressString(office) : '',
    //     },
    //     choosenAddress: office,
    //     use_office_address: e.target.checked,
    //   }))
    // } else {
    //   const choosenAddress =
    //     user &&
    //     !!user.info &&
    //     !!user.info.address &&
    //     !!user.info.address.address
    //       ? user.info.address
    //       : initialAddress
    //   const address = {
    //     ...choosenAddress,
    //     address: choosenAddress.address
    //       ? getFullAddressString(choosenAddress)
    //       : '',
    //   }
    //   setUserData(prev => ({
    //     ...prev,
    //     address: address,
    //     choosenAddress: choosenAddress,
    //     use_office_address: e.target.checked,
    //   }))
    // }
    setUserDataErrors(prev => ({
      ...prev,
      address: '',
    }))
  }

  const onHandleCloseForm = useCallback(() => {
    if (!user) {
      closeForm()
    } else {
      if (isUserFullSettings(user)) {
        closeForm()
      } else {
        handleSubmit()
      }
    }
  }, [closeForm, user, handleSubmit])

  const onChooseAddress = addressType => addresObj => {
    if (addresObj) {
      const normalizeAddress = type => ({
        address:
          type === 'toChoosenAddress'
            ? addresObj.address
            : getFullAddressString(addresObj),
        city: addresObj.city,
        state: addresObj.state,
        zip: addresObj.postcode ?? addresObj.zip ?? '',
        lat: +addresObj.lat,
        lng: +addresObj.lng,
        unit: addresObj.unit,
      })

      setUserDataErrors(prev => ({ ...prev, address: '', officeAddress: '' }))
      if (addressType === 'userAddress') {
        setUserData(prev => ({
          ...prev,
          address: {
            ...normalizeAddress('toAddress'),
            unit: prev.address.unit,
          },
          choosenAddress: {
            ...normalizeAddress('toChoosenAddress'),
            unit: prev.address.unit,
          },
        }))
      } else if (addressType === 'officeAddress') {
        setOfficeAddressData(prev => ({
          ...prev,
          address: {
            ...normalizeAddress('toAddress'),
            unit: prev.address.unit,
          },

          choosenAddress: {
            ...normalizeAddress('toChoosenAddress'),
            unit: prev.address.unit,
          },
        }))
        const isUserHaveAddress =
          !!userData.address.address &&
          !!userData.address.city &&
          !!userData.address.zip &&
          !!userData.address.state
        if (!isUserHaveAddress) {
          setUserData(prev => ({
            ...prev,
            choosenAddress: {
              ...normalizeAddress('toChoosenAddress'),
              unit: prev.address.unit,
            },
          }))
        }
      }
    } else {
      if (addressType === 'userAddress') {
        setUserData(prev => ({
          ...prev,
          address: initialAddress,
          choosenAddress: initialAddress,
        }))
        setUserDataErrors(prev => ({
          ...prev,
          address: 'Please input your day start location address',
        }))
      } else if (addressType === 'officeAddress') {
        setOfficeAddressData(initialOfficeAddressData)
        setUserDataErrors(prev => ({
          ...prev,
          address: 'Please input your office address',
        }))
      }
    }
  }

  const onChangeUseAsTech = useCallback(e => {
    setUserData(prev => ({ ...prev, useAsTech: e.target.checked }))
    setUserDataErrors(prev => ({ ...prev, profile_picture: '' }))
  }, [])

  const onChangeUseClockInWithGps = useCallback(e => {
    const { checked, name } = e.target
    setUserData(p => ({ ...p, [name]: checked }))
    setUserDataErrors(p => ({ ...p, profile_picture: '' }))
  }, [])

  const setCroppedLogo = useCallback(profile_picture => {
    setUserData(prev => ({ ...prev, profile_picture, crop: null }))
  }, [])

  const setLogo = useCallback(profile_picture => {
    setUserData(prev => ({ ...prev, profile_picture, crop: profile_picture }))
    setUserDataErrors(prev => ({ ...prev, profile_picture: '' }))
  }, [])

  const onCancelCrop = useCallback(() => {
    setUserData(prev => ({ ...prev, crop: null }))
  }, [])

  const setProfilePictureError = useCallback(errormessage => {
    setUserDataErrors(prev => ({ ...prev, profile_picture: errormessage }))
  }, [])

  const onCloseConfirmModal = useCallback(
    () => setConfirmData(initialConfirmData),
    [],
  )

  const handleResetProposalIdentifier = useCallback(() => {
    setUserDataErrors(prev => ({ ...prev, number_prefix: '' }))
    setUserData(prev => ({
      ...prev,
      number_prefix: userData.memorizedPropIdentifier,
    }))
  }, [userData])

  const handleGenerateLink = async e => {
    e.preventDefault()
    await generateInviteLink(user.id)
      .then(res => setLink(res.link))
      .catch(e => notification.error({ message: e?.message }))
  }

  const onChangeLayoutView = async layoutType => {
    try {
      setLayoutType(layoutType)

      // if (userID) {
      //   changeNavigationInterface(userID, data)
      //     .then(() => dispatch(fetchUserByToken()))
      //     .catch(e => console.error(e))
      // }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <UserForm
        permissionsList={filteredPermissionsList}
        userData={userData}
        currentStatus={user ? user.status : null}
        userDataErrors={userDataErrors}
        onChangeTextField={onChangeTextField}
        handleChangeManualOfficeAddress={handleChangeManualOfficeAddress}
        onSubmit={handleSubmit}
        onChangeRole={onChangeRole}
        onChangeStatus={onChangeStatus}
        onChangePermissions={onChangePermissions}
        onChangeUseOfficeAddress={onChangeUseOfficeAddress}
        onChangeUseAsTech={onChangeUseAsTech}
        onChangeUseClockInWithGps={onChangeUseClockInWithGps}
        onChooseAddress={onChooseAddress}
        officeAddressData={officeAddressData}
        onCancelChangePassword={onCancelChangePassword}
        togglePasswordVisibility={togglePasswordVisibility}
        onUpdatePassword={onUpdatePassword}
        isProfileSettings={isProfileSettings}
        isMapFixingNeeded={isMapFixingNeeded}
        isChangePasswordOpen={isChangePasswordOpen}
        isNumberPrefixExists={
          !!user && !!user.info && !!user.info.number_prefix
        }
        setIsChangePasswordOpen={setIsChangePasswordOpen}
        closeForm={onHandleCloseForm}
        isSubmitting={isSubmitting}
        initialAddress={initialAddress}
        isThereCompanyAddress={
          !!officeAddress.address &&
          !!officeAddress.city &&
          !!officeAddress.state &&
          (!!officeAddress.zip || !!officeAddress.postcode)
        }
        isThereCompanyAddressDataChoosenAddress={
          !!officeAddressData.choosenAddress.address &&
          !!officeAddressData.choosenAddress.city &&
          !!officeAddressData.choosenAddress.state &&
          (!!officeAddressData.choosenAddress.zip ||
            !!officeAddressData.choosenAddress.postcode)
        }
        setCroppedLogo={setCroppedLogo}
        setLogo={setLogo}
        isPasswordUpdating={isPasswordUpdating}
        onCancelCrop={onCancelCrop}
        profilePictureError={userDataErrors.profile_picture}
        setProfilePictureError={setProfilePictureError}
        //confirmation data when change status or use as tech
        confirmModal={confirmData.showModal}
        confirmModalData={confirmData.data}
        onCloseConfirmModal={onCloseConfirmModal}
        onResetProposalIdentifier={handleResetProposalIdentifier}
        onGenerateLink={handleGenerateLink}
        inviteLink={link}
        // isNewUser={isNewUser}
        // isVerticalSidebar={newNavigation}
        // setIsVerticalSidebar={setNewNavigation}
        disabledModules={disabledModules}
        onClearState={() => setUserData(initialUserData)}
        onChangeLayoutView={onChangeLayoutView}
        headerType={layoutType}
      />
      <br />
    </>
  )
}

const mapStateToProps = state => ({
  company: state.orcatec.company ? state.orcatec.company : {},
  officeAddress:
    !!state.orcatec.company && !!state.orcatec.company.address
      ? state.orcatec.company.address
      : {},
  system: state.orcatec.system,
})

export default connect(mapStateToProps, {
  fetchUsersList: actions.user.fetchUsersList,
  fetchCompany: actions.company.fetchCompany,
  fetchUser: actions.user.fetchUser,
  fetchUserByToken: actions.user.fetchUserByToken,
  createUser: actions.user.createUser,
  updateUser: actions.user.updateUser,
  uploadMedia: actions.user.uploadMedia,
  saveCompany: actions.company.saveCompany,
})(UserFormContainer)
