import { useState } from 'react'
import {
  fetchProperty,
  updatePropertyRelation,
} from '../../../../../../../../api/Property'
import TableActions from '../../../../../components/TableActionsNew'
import './index.scss'
import { Empty, Tooltip } from 'antd'
import { getAddressWithUnit } from '../../../../../../../../helpers/getAddressWithUnit'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import PropertyForm from 'containers/MainContent/Orcatec/Properties/components/PropertyForm'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { Link } from 'react-router-dom'
import { renderNotes } from '../utils'
import { WithModal } from 'hoc/WithModal'
import { useTranslation } from 'react-i18next'
const initialFieldValues = {
  relation: '',
  choosenValue: null,
}

const stopPropagation = e => {
  if (!!e && !!e.stopPropagation) e.stopPropagation()
}

const TableProperties = ({
  properties,
  onAddAttachedProperty,
  onDellAttachedProperty,
  clientId,
  newRow,
  showNewRow,
  onUpdateRelation,
  setWasEdited,
  isAppointment,
}) => {
  const { t } = useTranslation('property')

  const [fieldValues, setFieldValues] = useState(initialFieldValues)

  const onAdd = async entity => {
    try {
      setWasEdited(false)
      showNewRow(false)
      const data = await fetchProperty(entity?.id)

      const relation = fieldValues.relation
        ? fieldValues.relation
        : data.relationValue
        ? data.relationValue
        : ''
      onAddAttachedProperty({
        address: data.full_address
          ? data.full_address
          : `${getAddressWithUnit(data)}, ${data.postcode}`,
        note: relation,
        relation_note: relation,
        property_relation: relation,
        client_id: clientId,
        property_id: data.id, //fieldValues.choosenValue.property_id,
        fullProperty: data,
      })

      setFieldValues({ relation: '', choosenValue: null })
    } catch (error) {
      showNewRow(true)
    }
  }

  const closePropertyForm = obj => {
    showNewRow(false)
    setWasEdited(false)
  }

  const getPopupContainer = () =>
    document.getElementsByClassName('formClientWrapper')[0]

  const handleUpdatePropertyRelation = async (e, item) => {
    if (e.target.value === item?.property_relation) return

    console.log(item)

    await updatePropertyRelation({
      relation_id: item.relation_id,
      property_relation: e.target.value,
    })
    onUpdateRelation()

    openNotificationWithIcon('success', {
      message: 'Property relation has been updated',
    })
  }
  return (
    <div className='client-properties client-form-table-container'>
      {newRow && (
        <WithModal
          toggleModal={closePropertyForm}
          withModal={isAppointment}
          showModal={newRow}
          title={t('attachNewProperty')}
        >
          <div className='attach-new-property'>
            <InputField
              value={fieldValues.relation}
              onChange={e => {
                setFieldValues(prev => ({ ...prev, relation: e.target.value }))
                setWasEdited(true)
              }}
              className='relation-input'
              label={t('propertyRelation')}
            />
            <PropertyForm
              isClient
              setEntityId={onAdd}
              onCloseForm={closePropertyForm}
              createOnButtonClick
            />
          </div>
        </WithModal>
      )}
      {properties.length ? (
        <table className='client-form-table'>
          <thead>
            <tr>
              <th>{t('tabs.properties.address')}</th>
              <th>{t('tabs.properties.type')}</th>
              <th>{t('tabs.properties.relation')}</th>
              <th style={{ textAlign: 'center' }}>
                {t('tabs.properties.contacts')}
              </th>
              <th style={{ textAlign: 'center' }}>
                {t('tabs.properties.appointments')}
              </th>
              <th style={{ textAlign: 'center' }}>
                {t('tabs.properties.projects')}
              </th>
              <th style={{ textAlign: 'left' }}>
                {t('tabs.properties.notes')}
              </th>
              <th style={{ textAlign: 'center' }}>
                {t('tabs.properties.tags')}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {properties.map((item, idx) => {
              const getValue = name =>
                !!item.value && !!item.value[name]
                  ? item.value[name]
                  : item[name]
                  ? item[name]
                  : ''
              const property = getValue('fullProperty')
                ? getValue('fullProperty')
                : item.value
                ? item.value
                : item
              const address = `${getAddressWithUnit(property)}, ${
                property.postcode
              }`

              return (
                <tr key={idx}>
                  <Tooltip title={address}>
                    <td>
                      <Link
                        to={`/properties-form/${getValue('property_id')}`}
                        target='_blank'
                        style={{ textDecoration: 'underline' }}
                      >
                        {address}
                      </Link>
                    </td>
                  </Tooltip>
                  <td>
                    {getValue('type') === 1
                      ? t('type.commercial')
                      : t('type.residential')}
                  </td>
                  <td>
                    <InputField
                      defaultValue={getValue('property_relation')}
                      onClick={e => e.stopPropagation()}
                      onBlur={e => handleUpdatePropertyRelation(e, item.value)}
                      fullWidth={false}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.clients || []).length}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.appointments || []).length}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {(property.proposals || []).length}
                  </td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={property?.notes?.map?.(n => n.text).join?.('\n\n')}
                  >
                    {/*<td className='notes'>{property.notes || ''}</td>*/}
                    <td className='notes'>{renderNotes(property.notes)}</td>
                  </Tooltip>
                  <td style={{ textAlign: 'center' }}>
                    {(property.tags || []).length}
                  </td>
                  <td onClick={stopPropagation}>
                    <TableActions
                      todos={['detach']}
                      popConfirms={['property from this client']}
                      tooltips={['Detach property']}
                      callbacks={[
                        () => {
                          onDellAttachedProperty(idx)
                        },
                      ]}
                      getPopupContainer={getPopupContainer}
                      popConfirmPositions={['left']}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        !newRow && (
          <>
            <Empty description={t('tabs.properties.noProperties')} />
            <MainButton
              title={t('tabs.properties.addProperty')}
              className='add-property-button'
              onClick={() => showNewRow(prev => !prev)}
            />
          </>
        )
      )}
    </div>
  )
}

export default TableProperties
