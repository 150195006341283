import ENCommon from './common.json'
import ENDispatch from './dispatch.json'
import ENSettings from './settings.json'
import ENAppointment from './appointment.json'
import ENRecurrence from './recurrence.json'
import ENNavigation from './navigation.json'
import ENProperty from './property.json'
import ENProfile from './profile.json'
import ENForms from './forms.json'
import ENContact from './contact.json'
import ENProject from './project.json'
import ENNotifications from './notifications.json'

export default {
  common: ENCommon,
  dispatch: ENDispatch,
  settings: ENSettings,
  appointment: ENAppointment,
  recurrence: ENRecurrence,
  navigation: ENNavigation,
  property: ENProperty,
  profile: ENProfile,
  forms: ENForms,
  contact: ENContact,
  project: ENProject,
  notifications: ENNotifications,
}
