import React, { useState, useEffect } from 'react'
import './ClientsSuggestionsTable.scss'
import EmailRow from '../EmailRow'
import { getAddressWithUnit } from '../../../../../../../../helpers/getAddressWithUnit'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  ClientIcon,
  OrganizationIcon,
} from '../../../../../components/Icons/CommonIcons'
//import { getClientById } from '../../../../../../../api/Client'
import { Tooltip } from 'antd'
import { ClientType } from '../../../../../../../../types/Client'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const SecondRowWrapper = styled.div`
  padding: 6px;
  & p {
    color: rgba(0, 0, 0, 0.35);
  }
`

const Row = ({ item, onRowExpand }) => {
  const { t } = useTranslation('contact')

  const [open, setOpen] = useState(false)

  const onOpenInNewTab = (type, id) => {
    window.open(`/clients/${id}?clientType=${type}`, '_blank')
  }

  return (
    <div className='suggestions-table__row'>
      <div className='first'>
        <div>{item.name}</div>
        <div style={{ width: 80 }}>
          {item.type === ClientType.ORGANIZATION ? (
            <Tooltip title='Organization'>
              <i>
                <OrganizationIcon />
              </i>
            </Tooltip>
          ) : (
            <Tooltip title='Individual'>
              <i>
                <ClientIcon />
              </i>
            </Tooltip>
          )}
        </div>
        <div>{item.emails ? item.emails.length : '0'}</div>
        <div>{item.phones ? item.phones.length : '0'}</div>
        <div>{item.properties ? item.properties.length : '0'}</div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <MainButton
            style={{ padding: '0' }}
            title={t('suggestionsTable.openThisClient')}
            onClick={() => onOpenInNewTab(item.type, item.id)}
          />
          <MainButton
            style={{ padding: '0' }}
            title={t('suggestionsTable.useThisClient')}
            onClick={() => onRowExpand(item)}
          />
        </div>
        {/* <button className='btn btn-outline-secondary' onClick={() => setOpen(!open)}> */}
        {open ? (
          <KeyboardArrowUp
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
        ) : (
          <KeyboardArrowDown
            style={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          />
        )}
        {/* </button> */}
      </div>
      {open && (
        <div>
          <SecondRowWrapper>
            <p>{t('suggestionsTable.emails')}:</p>
            {!!item?.emails?.length
              ? item.emails.map(item => (
                  <span key={`email-${item.id}`}>{item ?? ''}, </span>
                ))
              : 'no emails yet.'}
          </SecondRowWrapper>
          <SecondRowWrapper>
            <p>{t('suggestionsTable.phones')}:</p>
            {!!item?.phones?.length
              ? item.phones.map(item => (
                  <span key={`phone-${item.id}`}>{item ?? ''}, </span>
                ))
              : 'no phones yet.'}
          </SecondRowWrapper>
          <SecondRowWrapper>
            <p>{t('suggestionsTable.address')}:</p>
            {!!item?.properties?.length
              ? item.properties.map(item => (
                  <span key={`property-${item.id}`}>{item ?? ''}, </span>
                ))
              : 'no address yet.'}
          </SecondRowWrapper>
        </div>
      )}
      {/* {open && (
        <div className='second'>
          <div className='name' />
          <div />
          <div className='emails'>
            {item.emails.map(item => (
              <p key={`email-${item.id}`}>{item ?? ''}</p>
            ))}
          </div>
          <div className='phones'>
            {item.phones.map(item => (
              <p key={`phone-${item.id}`}>{item ?? ''}</p>
            ))}
          </div>
          <div className='properties'>
            {item.properties.map(item => (
              <p key={`property-${item.id}`}>{item ?? ''}</p>
            ))}
          </div>
          <div className='actions' />
        </div>
      )} */}
    </div>
  )
}

const ClientsSuggestionsTable = ({
  // fetchClient,
  suggestions,
  // onChange,
  // onChangeClientId,
  // setClientId,
  onUseThisClient,
}) => {
  const { t } = useTranslation('contact')
  //const getFirstFive = suggestions.splice(5)

  suggestions.splice(5)

  return (
    <>
      <label htmlFor='clients-suggestions'>{t('suggestionsTable.title')}</label>

      <div
        id='clients-suggestions'
        className='clients-suggestions-wrapper suggestion'
      >
        <div className='suggestions-table'>
          <div className='suggestions-table__heading'>
            <div>{t('suggestionsTable.name')}</div>
            <div>{t('suggestionsTable.type')}</div>
            <div>{t('suggestionsTable.emails')}</div>
            <div>{t('suggestionsTable.phones')}</div>
            <div>{t('suggestionsTable.address')}</div>
            <div>{''}</div>
            <div>{''}</div>
          </div>
          <div className='suggestions-table__body'>
            {suggestions.map(item => (
              <Row key={item.id} item={item} onRowExpand={onUseThisClient} />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default ClientsSuggestionsTable
