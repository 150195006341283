import RUCommon from './common.json'
import RUDispatch from './dispatch.json'
import RUSettings from './settings.json'
import RUAppointment from './appointment.json'
import RURecurrence from './recurrence.json'
import RUNavigation from './navigation.json'
import RUProperty from './property.json'
import RUProfile from './profile.json'
import RUForms from './forms.json'
import RUContact from './contact.json'
import RUProject from './project.json'
import RUNotifications from './notifications.json'

export default {
  common: RUCommon,
  dispatch: RUDispatch,
  settings: RUSettings,
  appointment: RUAppointment,
  recurrence: RURecurrence,
  navigation: RUNavigation,
  property: RUProperty,
  profile: RUProfile,
  forms: RUForms,
  contact: RUContact,
  project: RUProject,
  notifications: RUNotifications,
}
