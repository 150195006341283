export const default_permissions = {
  type_source: [
    {
      permission_key: 'type_source_can_create',
      value: 1,
    },
  ],
  estimates: [
    {
      permission_key: 'estimates_list_can_read',
      value: 1,
    },
  ],
  crm: [
    {
      permission_key: 'crm_can_view_opportunities_list',
      value: 1,
    },
  ],
  project: [
    {
      permission_key: 'project_can_read',
      value: 4,
    },
    {
      permission_key: 'project_can_create',
      value: 2,
    },
    {
      permission_key: 'project_can_change_code',
      value: 0,
    },
    {
      permission_key: 'project_can_edit_contract',
      value: 0,
    },
    {
      permission_key: 'project_can_read_price',
      value: 4,
    },
    {
      permission_key: 'project_can_read_cost',
      value: 4,
    },
    {
      permission_key: 'project_can_delete',
      value: 0,
    },
    {
      permission_key: 'project_can_duplicate',
      value: 4,
    },
    {
      permission_key: 'project_can_change_status_from_estimate_to_contract',
      value: 0,
    },
    {
      permission_key: 'project_can_change_status_from_contract_to_completed',
      value: 0,
    },
    {
      permission_key: 'project_can_change_status_from_completed_to_contract',
      value: 0,
    },
    {
      permission_key: 'project_can_change_status_from_contract_to_estimate',
      value: 0,
    },
    {
      permission_key: 'project_can_change_status_from_contract_to_canceled',
      value: 0,
    },
    {
      permission_key: 'project_can_create_delete_unit_qty',
      value: 0,
    },
    {
      permission_key: 'project_can_view_item_description',
      value: 1,
    },
    {
      permission_key: 'project_can_read_templates',
      value: 2,
    },
    {
      permission_key: 'project_can_edit_template',
      value: 0,
    },
    {
      permission_key: 'project_can_delete_template',
      value: 2,
    },
    {
      permission_key: 'project_can_create_template',
      value: 1,
    },
    {
      permission_key: 'project_insights_can_read',
      value: 1,
    },
    {
      permission_key: 'project_insights_items_can_add',
      value: 0,
    },
    {
      permission_key: 'project_insights_items_suggestion_can_add',
      value: 0,
    },
    {
      permission_key: 'project_insights_items_suggestion_can_delete',
      value: 0,
    },
    {
      permission_key: 'project_insights_items_can_edit',
      value: 0,
    },
    {
      permission_key: 'project_insights_items_can_delete',
      value: 0,
    },
    {
      permission_key: 'project_insights_can_read_payouts',
      value: 0,
    },
    {
      permission_key: 'project_insights_can_add_payouts',
      value: 0,
    },
    {
      permission_key: 'project_insights_can_edit_payouts',
      value: 0,
    },
    {
      permission_key: 'project_insights_can_delete_payouts',
      value: 0,
    },
    {
      permission_key: 'project_settings_can_read',
      value: 4,
    },
    {
      permission_key: 'project_settings_can_edit_general',
      value: 4,
    },
    {
      permission_key: 'project_settings_can_edit_section',
      value: 4,
    },
    {
      permission_key: 'project_settings_can_edit_payment',
      value: 4,
    },
    {
      permission_key: 'project_settings_can_edit_relation',
      value: 0,
    },
    {
      permission_key: 'project_settings_can_edit_customer_view',
      value: 4,
    },
    {
      permission_key: 'project_notification_can_send',
      value: 0,
    },
    {
      permission_key: 'project_notification_can_change_text',
      value: 0,
    },
    {
      permission_key: 'project_notification_can_change_template',
      value: 0,
    },
    {
      permission_key: 'project_notification_can_include_attachments',
      value: 0,
    },
    {
      permission_key: 'project_relation_can_edit',
      value: 0,
    },
    {
      permission_key: 'project_access_to_contract',
      value: 0,
    },
    {
      permission_key: 'project_can_read_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'project_can_edit_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'project_can_delete_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'project_can_read_custom_forms',
      value: 0,
    },
    {
      permission_key: 'project_can_create_custom_forms',
      value: 0,
    },
    {
      permission_key: 'project_can_edit_custom_forms',
      value: 0,
    },
    {
      permission_key: 'project_can_delete_custom_forms',
      value: 0,
    },
    {
      permission_key: 'project_can_delete_progress_entry',
      value: 2,
    },
    {
      permission_key: 'project_can_read_tech_list_for_progress',
      value: 2,
    },

    {
      permission_key: 'project_payment_module_can_change_status',
      value: 0,
    },
    {
      permission_key: 'project_supply_module_can_change_status',
      value: 0,
    },
    {
      permission_key: 'project_custom_module_1_can_change_status',
      value: 0,
    },
    {
      permission_key: 'project_custom_module_2_can_change_status',
      value: 0,
    },
    {
      permission_key: 'project_custom_module_3_can_change_status',
      value: 0,
    },
  ],
  accounting: [
    {
      permission_key: 'accounting_can_read',
      value: 1,
    },
    {
      permission_key: 'accounting_can_export_table',
      value: 0,
    },
    {
      permission_key: 'accounting_can_add_payouts',
      value: 0,
    },
    {
      permission_key: 'accounting_can_read_balance_table',
      value: 0,
    },
    {
      permission_key: 'accounting_can_read_expenses_table',
      value: 0,
    },
    {
      permission_key: 'accounting_can_read_entries_table',
      value: 0,
    },
  ],
  notes: [
    {
      permission_key: 'notes_can_read',
      value: 1,
    },
    {
      permission_key: 'notes_can_create',
      value: 1,
    },
    {
      permission_key: 'notes_can_edit',
      value: 2,
    },
    {
      permission_key: 'notes_can_delete',
      value: 2,
    },
  ],
  schedule: [
    {
      permission_key: 'schedule_can_read',
      value: 1,
    },
    {
      permission_key: 'schedule_restrict_future_days',
      value: 0,
    },
    {
      permission_key: 'schedule_can_view_event_details',
      value: 1,
    },
    {
      permission_key: 'schedule_can_change_event_status',
      value: 1,
    },
    {
      permission_key: 'schedule_can_read_notification_history',
      value: 0,
    },
    {
      permission_key: 'schedule_can_request_for_review',
      value: 1,
    },
    {
      permission_key: 'schedule_can_create_project',
      value: 0,
    },
    {
      permission_key: 'schedule_can_duplicate_event',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_client_name',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_client_address',
      value: 1,
    },
    {
      permission_key: 'schedule_can_read_client_contacts',
      value: 0,
    },
    {
      permission_key: 'schedule_can_edit_project_contacts',
      value: 1,
    },
    {
      permission_key: 'schedule_can_view_event_status',
      value: 1,
    },
    {
      permission_key: 'schedule_can_view_project_details',
      value: 1,
    },
    {
      permission_key: 'schedule_can_view_related_jobs',
      value: 1,
    },
    {
      permission_key: 'schedule_can_view_related_jobs',
      value: 1,
    },
    {
      permission_key: 'schedule_can_read_related_entity',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_entity_main_info',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_entity_appointments',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_entity_projects',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_entity_memberships',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_property_contacts',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_property_media',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_contact_properties',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_contact_organizations',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_contact_associations',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_contact_messaging',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_related_contact_calls',
      value: 0,
    },
    {
      permission_key: 'schedule_can_change_event_frame',
      value: 0,
    },
    {
      permission_key: 'schedule_can_view_event_source',
      value: 0,
    },
    {
      permission_key: 'schedule_can_read_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'schedule_can_edit_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'schedule_can_delete_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'schedule_can_read_custom_forms',
      value: 0,
    },
    {
      permission_key: 'schedule_can_create_custom_forms',
      value: 0,
    },
    {
      permission_key: 'schedule_can_edit_custom_forms',
      value: 0,
    },
    {
      permission_key: 'schedule_can_delete_custom_forms',
      value: 0,
    },

    {
      permission_key: 'schedule_project_payment_module_can_change_status',
      value: 0,
    },
    {
      permission_key: 'schedule_project_supply_module_can_change_status',
      value: 0,
    },
    {
      permission_key: 'schedule_project_custom_module_1_can_change_status',
      value: 0,
    },
    {
      permission_key: 'schedule_project_custom_module_2_can_change_status',
      value: 0,
    },
    {
      permission_key: 'schedule_project_custom_module_3_can_change_status',
      value: 0,
    },
  ],
  dispatch: [
    {
      permission_key: 'dispatch_can_read',
      value: 1,
    },
    {
      permission_key: 'dispatch_can_read_event_details',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_create_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_edit_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_delete_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_change_status',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_duplicate_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_change_event_frame',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_read_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'dispatch_can_edit_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'dispatch_can_delete_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'dispatch_can_read_custom_forms',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_create_custom_forms',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_edit_custom_forms',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_delete_custom_forms',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_view_group_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_edit_group_event',
      value: 0,
    },
    {
      permission_key: 'dispatch_can_delete_group_event',
      value: 0,
    },
  ],
  map: [
    {
      permission_key: 'map_can_read',
      value: 1,
    },
  ],
  properties: [
    {
      permission_key: 'properties_list_can_read',
      value: 1,
    },
    {
      permission_key: 'properties_can_merge',
      value: 0,
    },
    {
      permission_key: 'properties_list_can_export',
      value: 0,
    },
    {
      permission_key: 'properties_can_create',
      value: 0,
    },
    {
      permission_key: 'properties_can_edit',
      value: 0,
    },
    {
      permission_key: 'properties_can_delete',
      value: 0,
    },
    {
      permission_key: 'properties_can_read_custom_forms',
      value: 0,
    },
    {
      permission_key: 'properties_can_edit_custom_forms',
      value: 0,
    },
    {
      permission_key: 'properties_can_delete_custom_forms',
      value: 0,
    },
  ],
  contacts: [
    {
      permission_key: 'contacts_list_can_read',
      value: 1,
    },
    {
      permission_key: 'contacts_list_can_export',
      value: 0,
    },
    {
      permission_key: 'contacts_can_merge',
      value: 0,
    },
    {
      permission_key: 'contacts_can_add',
      value: 0,
    },
    {
      permission_key: 'contacts_can_edit',
      value: 0,
    },
    {
      permission_key: 'contacts_can_delete',
      value: 0,
    },
  ],
  dashboard: [
    {
      permission_key: 'dashboard_can_read',
      value: 1,
    },
  ],
  time_cards: [
    {
      permission_key: 'time_cards_list_can_read',
      value: 1,
    },
    {
      permission_key: 'time_cards_user_time_card_can_read',
      value: 1,
    },
    {
      permission_key: 'time_cards_user_time_card_can_edit',
      value: 0,
    },
    {
      permission_key: 'time_cards_user_time_card_can_delete',
      value: 0,
    },
  ],
  forms: [
    {
      permission_key: 'forms_list_can_read',
      value: 1,
    },
    {
      permission_key: 'forms_detailed_list_can_read',
      value: 0,
    },
    {
      permission_key: 'forms_template_list_can_read',
      value: 0,
    },
    {
      permission_key: 'forms_template_can_delete',
      value: 0,
    },
    {
      permission_key: 'forms_can_verify',
      value: 0,
    },
    {
      permission_key: 'forms_can_send',
      value: 0,
    },
  ],
  reviews: [
    {
      permission_key: 'reviews_list_can_read',
      value: 1,
    },
  ],
  fast_payment: [
    {
      permission_key: 'fast_payment_can_create',
      value: 1,
    },
  ],
  todo: [
    {
      permission_key: 'todo_can_read',
      value: 1,
    },
    {
      permission_key: 'todo_can_delete',
      value: 0,
    },
  ],
  jobs: [
    {
      permission_key: 'jobs_list_can_read',
      value: 1,
    },
    {
      permission_key: 'jobs_can_delete',
      value: 0,
    },
    {
      permission_key: 'jobs_can_read_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'jobs_can_edit_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'jobs_can_delete_info_for_workers',
      value: 1,
    },
    {
      permission_key: 'jobs_can_read_custom_forms',
      value: 0,
    },
    {
      permission_key: 'jobs_can_create_custom_forms',
      value: 0,
    },
    {
      permission_key: 'jobs_can_edit_custom_forms',
      value: 0,
    },
    {
      permission_key: 'jobs_can_delete_custom_forms',
      value: 0,
    },
  ],
  instant_appointment: [
    {
      permission_key: 'instant_appointment_can_create',
      value: 1,
    },
  ],
  quickbooks: [
    {
      permission_key: 'quickbooks_can_read',
      value: 1,
    },
  ],
  settings: [
    {
      permission_key: 'settings_can_read',
      value: 1,
    },
    {
      permission_key: 'settings_can_read_consumer_payment',
      value: 0,
    },
    {
      permission_key: 'settings_can_read_subscription',
      value: 0,
    },
  ],
  messaging: [
    {
      permission_key: 'messaging_can_read',
      value: 2,
    },
  ],
  call_tracking: [
    {
      permission_key: 'call_tracking_can_read',
      value: 1,
    },
  ],
  memberships: [{ permission_key: 'memberships_can_read', value: 1 }],
}
