import React, { useState, useEffect } from 'react'
import Table from '../../../components/CustomTable'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { withRouter } from 'react-router-dom'
import * as actions from 'store/Orcatec/actions'
import AppointmentStatus from '../../../components/AppointmentStatus/AppointmentStatus'
import { renderNotes } from '../../../Clients/components/Form/components/utils'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const Appointment = ({
  appointments,
  isClear,
  appId,
  history,
  emptyText = 'No other appointments yet',
  toggleAppointmentForm,
  appointmentForm,
  isAppointment = false,
}) => {
  const { t } = useTranslation('property')
  // eslint-disable-next-line no-unused-vars
  const [isOpen, toggle] = useState(false)
  useEffect(() => {
    toggle(false)
  }, [isClear])

  const setPlacment = (type, item) => {
    if (type === 'misc') {
      return 'Misc'
    } else if (type === 'used') {
      return item.matrix_user
    } else {
      return 'Queue'
    }
  }

  const goTo = appointment => {
    isAppointment
      ? window.open(`/appointment/${appointment.id}`, '_self')
      : history.push(`/appointment/${appointment.id}`)
  }

  return (
    <div style={{ overflow: 'hidden' }} className='table-style'>
      <Table
        emptyText={emptyText}
        rows={appointments ? appointments : []}
        search={false}
        paggination={false}
        showEntries={false}
        className='appointment-table'
        toggleAppointmentForm={toggleAppointmentForm}
        modalOpened={appointmentForm}
        head={[
          {
            label: '',
          },
          {
            label: t('tabs.appointments.date'),
          },
          {
            label: t('tabs.appointments.type'),
          },
          {
            label: t('tabs.appointments.tech'),
          },
          {
            label: t('tabs.appointments.source'),
          },
          {
            label: t('tabs.appointments.notes'),
          },
        ]}
      >
        {appointments
          ?.sort((a, b) => b.id - a.id)
          ?.map((item, i) => {
            if (appId && Number(item.id) !== Number(appId)) {
              return (
                <tr
                  key={item.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => goTo(item)}
                >
                  <td>
                    {
                      <AppointmentStatus
                        statusId={item.status}
                        withStatusName={false}
                      />
                    }
                  </td>
                  <td>{item.date && moment(item.date).format('MM/DD/YYYY')}</td>
                  <td>
                    {item.is_lead
                      ? 'Public Appointment'
                      : item.appointment_type?.name || '-'}
                  </td>
                  <td>
                    <span className='ml-1'>
                      {setPlacment(item.matrix_type, item)}
                    </span>
                  </td>
                  <td>
                    {item.appointment_source && item.appointment_source.name}
                  </td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={item?.notes?.map(n => n.text).join?.('\n\n')}
                  >
                    <td style={{ textAlign: 'left' }}>
                      {renderNotes(item.notes)}
                    </td>
                  </Tooltip>
                </tr>
              )
            } else if (!appId) {
              return (
                <tr
                  key={item.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => goTo(item)}
                >
                  <td>
                    {
                      <AppointmentStatus
                        statusId={item.status}
                        withStatusName={false}
                      />
                    }
                  </td>
                  <td>{item.date && moment(item.date).format('MM/DD/YYYY')}</td>
                  <td>
                    {item.is_lead
                      ? 'Public Appointment'
                      : item.appointment_type?.name || '-'}
                  </td>
                  <td>
                    <span className='ml-1'>
                      {setPlacment(item.matrix_type, item)}
                    </span>
                  </td>
                  <td>
                    {item.appointment_source && item.appointment_source.name}
                  </td>
                  <Tooltip
                    overlayStyle={{ whiteSpace: 'pre-line' }}
                    title={item?.notes?.map(n => n.text).join?.('\n\n')}
                  >
                    <td style={{ textAlign: 'left' }}>
                      {renderNotes(item.notes)}
                    </td>
                  </Tooltip>
                </tr>
              )
            }
          })}
      </Table>
      {/*)}*/}
      {/*</Tab>*/}
    </div>
  )
}

const mapDispatchToProps = {
  getMatrixLog: actions.matrixLog.getMatrixLog,
  setCurrentAppointmentId: actions.appointment.setCurrentAppointmentId,
}
export default withRouter(connect(null, mapDispatchToProps)(Appointment))
