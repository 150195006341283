import { BookOutlined, CloseOutlined, MailOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import Tutorials from '../Tutorials'
import React, { useState } from 'react'
import { Button } from 'components/UIKit'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { bookDemo } from 'features/Support/api'
import Avatar from 'containers/MainContent/Orcatec/components/Avatar'
import { restartModuleTour } from 'features/Support/tourSlice'
import { useAppDispatch } from 'store/Orcatec/hooks'
import { IconPlay } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import { useHistory } from 'react-router-dom'
import { guideStepsByModule } from 'features/Support/components/UserGuide'

const menuItems = [
  {
    title: 'Tutorials',
    icon: <BookOutlined />,
    size: 20,
  },
  {
    title: 'Support Request',
    icon: <MailOutlined />,
    size: 20,
  },
  {
    title: 'Quick Start',
    icon: <IconPlay />,
    size: 24,
  },
]

interface Props {
  userName: string
  onOpenSupportTicket: () => void
  onClose: () => void
}

export const SupportWindow = ({
  // userName = 'Test Name',
  onClose,
  onOpenSupportTicket,
}: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [activeMenuItem, setActiveMenuItem] = useState('Tutorials')
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  async function submit() {
    setLoading(true)
    try {
      await bookDemo()
      openNotificationWithIcon('success', {
        message: "You're all set!",
        description:
          'Our support team will be in touch soon to arrange your personalized demo session.',
      })
      setSubmitted(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  function renderContent() {
    return activeMenuItem === 'Tutorials' ? (
      <>
        <Heading>
          <div>
            <WelcomeText>Tutorials</WelcomeText>
            {/* <WelcomeText>{userName} &#128522;</WelcomeText> */}
          </div>

          <CloseOutlined onClick={onClose} />
        </Heading>

        <Chat>
          {/* <SmileFace>&#129299;</SmileFace> */}
          <Avatar pictureURL={'/assets/images/getHelp/avatar.jpg'} />
          <span>
            <b>Help is here</b>! Browse our tutorials for quick tips and
            in-depth&nbsp;explanations.
          </span>
        </Chat>

        <Tutorials />
      </>
    ) : activeMenuItem === 'Quick Start' ? (
      <>
        <Heading>
          <WelcomeText>Quick Start</WelcomeText>

          <CloseOutlined onClick={onClose} />
        </Heading>

        <div style={{ marginBottom: 30, fontSize: 14 }}>
          <Block>
            <p style={{ marginBottom: 20 }}>
              Select a category to start a step-by-step walkthrough and discover
              all key features in action:
            </p>

            <div>
              {Object.keys(
                Object.fromEntries(
                  Object.entries(guideStepsByModule).filter(
                    ([, value]) => !value.hideFromMenu,
                  ),
                ),
              ).map(key => (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginBottom: 10,
                  }}
                  onClick={() => {
                    history.push(guideStepsByModule[key].location)
                    dispatch(restartModuleTour({ moduleId: key }))
                    onClose()
                  }}
                >
                  <p style={{ fontWeight: 500 }}>
                    {guideStepsByModule[key].title}
                  </p>
                  <Button size='small' type='link'>
                    Start
                  </Button>
                </div>
              ))}
            </div>
          </Block>
        </div>

        <div style={{ marginBottom: 30 }}>
          <Block>
            <Text>
              <p>
                <b>Book a personalized demo!</b> Let us know you’re interested,
                and we’ll schedule a session for you.
              </p>
            </Text>

            <DemoButton>
              <Button
                disabled={submitted}
                onClick={submit}
                type='primary'
                loading={loading}
              >
                Book a Demo
              </Button>
            </DemoButton>
          </Block>
        </div>
      </>
    ) : (
      <>
        <Heading>
          <WelcomeText>Support Request</WelcomeText>

          <CloseOutlined onClick={onClose} />
        </Heading>

        <Block>
          <Text>
            <p>
              <b>Need help?</b> Let us know what’s wrong, and we’ll assist you
              as soon as possible.
            </p>
          </Text>

          <DemoButton>
            <Button
              type='primary'
              onClick={() => {
                onClose()
                onOpenSupportTicket()
              }}
            >
              Create Support Request
            </Button>
          </DemoButton>
        </Block>
      </>
    )
  }

  return (
    <Wrapper>
      <Content>{renderContent()}</Content>

      <Footer>
        <Menu>
          {menuItems.map(item => (
            <MenuItem
              key={item.title}
              active={item.title === activeMenuItem}
              onClick={() => setActiveMenuItem(item.title)}
            >
              {React.cloneElement(item.icon, {
                style: {
                  fontSize: item.size,
                  color: item.title === activeMenuItem ? '#206FED' : '#4D4D4D',
                  marginBottom: 8,
                },
              })}
              <p>{item.title}</p>
            </MenuItem>
          ))}
        </Menu>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 340px;
  height: 620px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px 0px #00000033;

  background: url('/assets/images/getHelp/background.jpg');
  background-position: center;
  background-size: cover;

  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Content = styled.div`
  padding: 24px 32px;
`

const Heading = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
`

const Footer = styled.div`
  background-color: #fff;
  height: 68px;
  margin-top: auto;
`

const WelcomeText = styled.p`
  font-size: 20px;
`

const Chat = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;

  display: flex;
  gap: 15px;
  align-items: center;
`

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
`
const MenuItem = styled.p<{ active: boolean }>`
  cursor: pointer;
  text-align: center;
  color: ${props => (props.active ? '#206FED' : '#4D4D4D')};
`

const Text = styled.div`
  /* font-size: 16px; */

  p {
    margin-bottom: 10px;
  }
`

const DemoButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

const Block = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 10px;
`
