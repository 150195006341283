import { CheckboxChangeEvent } from 'antd/es/checkbox'
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import { Checkbox, Tooltip } from 'antd'
import { updateClientEmails } from 'api/NotificationModal'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
// import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import { TextFieldWithLabelAndError } from 'components/UIKit/TextField/TextField'
import { Fade } from '@material-ui/core'
import validator from 'validator'
import { IPhone } from 'features/Messaging/types'
import { ContactI } from '../../types'

import './EmailsList.scss'
import { useTranslation } from 'react-i18next'

interface EmailsListProps {
  phones: IPhone[]
  clientsList: ContactI[]
  setClientsList: Dispatch<SetStateAction<any>>
  selectedEmails: { clients: string[]; other: string[] }
  setSelectedEmails: Dispatch<SetStateAction<any>>
  selectedPhones: { clients: string[]; other: string[] }
  setSelectedPhones: Dispatch<SetStateAction<any>>
}

const EmailsList: React.FC<EmailsListProps> = ({
  phones,
  clientsList,
  setClientsList,
  selectedEmails,
  setSelectedEmails,
  selectedPhones,
  setSelectedPhones,
}) => {
  const { t } = useTranslation(['notifications', 'common'])

  const [addEmail, setAddEmail] = useState({
    user_id: 0,
    add: false,
    value: '',
    isValid: true,
    error: '',
  })
  const [loading, setLoading] = useState(false)

  const handleAddEmailToClient = ({
    type,
    id,
  }: {
    type: number
    id: number
  }) => {
    setLoading(true)

    const data = {
      id: 0,
      email: addEmail.value,
      entity_type: type,
      entity_id: id,
      preference: false,
      send_promo: false,
      send_reminder: false,
    }

    updateClientEmails(data)
      .then(({ email }) => {
        const newState = clientsList

        newState.forEach(client => {
          if (client.id === id) {
            client.emails = [...client.emails, email]
          }
        })

        setClientsList(newState)
        setAddEmail(prev => ({ ...prev, add: false, value: '' }))
        openNotificationWithIcon('success', {
          message: 'Success!',
          description: t('notifications.clientUpdated'),
        })
      })
      .catch(({ response }) => {
        openNotificationWithIcon('error', {
          message: `Error: ${response?.status}`,
          description: response?.data?.[Object.keys(response?.data)[0]],
        })
      })
      .finally(() => setLoading(false))
  }

  const handleAddEmail = (
    e: CheckboxChangeEvent | { target: { checked: boolean } },
    email: string,
  ) => {
    if (e.target.checked) {
      if (selectedEmails.clients.some(cEmail => cEmail === email)) {
        return
      } else {
        setSelectedEmails((prev: any) => ({
          ...prev,
          clients: [...prev.clients, email],
        }))
      }
    } else {
      setSelectedEmails((prev: { clients: any[] }) => ({
        ...prev,
        clients: prev.clients.filter((el: string) => el !== email),
      }))
    }
  }

  const handleAddEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    const isValid = validator.isEmail(value)

    setAddEmail(prev => ({
      ...prev,
      value,
      isValid,
      error: isValid ? '' : value.length ? t('prompts.invalidEmail') : '',
    }))
  }

  const handleAddPhone = (e: CheckboxChangeEvent, phone: string): void => {
    if (e.target.checked) {
      setSelectedPhones((p: { clients: any }) => ({
        ...p,
        clients: [...p.clients, phone],
      }))
    } else {
      const filteredPhones = selectedPhones.clients.filter(el => el !== phone)
      setSelectedPhones((p: any) => ({ ...p, clients: filteredPhones }))
    }
  }

  useEffect(() => {
    const getContactFromClient = (arr: ContactI[], key: string): string[] =>
      arr
        .filter(el => el?.[key])
        .map(el => el?.[key])
        .flat(1)

    const emails = getContactFromClient(clientsList, 'emails')
    const phones = getContactFromClient(clientsList, 'phones')

    setSelectedEmails(prev => ({
      ...prev,
      clients: [...prev.clients, ...emails],
    }))
    setSelectedPhones(prev => ({
      ...prev,
      clients: [...prev.clients, ...phones],
    }))
  }, [clientsList])

  interface LI {
    type: 'email' | 'phone'
    item: string
    checked: boolean
    onChange: (e: CheckboxChangeEvent) => void
  }

  const ListItem: React.FC<LI> = ({ type, item, checked, onChange }) => {
    const isDisabled = type === 'phone' && !phones.length

    return (
      <div>
        <Tooltip title={isDisabled ? 'Messaging service isn`t setup yet' : ''}>
          <Checkbox
            checked={isDisabled ? false : checked}
            className='checkbox'
            onChange={onChange}
            disabled={isDisabled}
          >
            <span className={type === 'phone' ? '' : 'user-email'}>{item}</span>
          </Checkbox>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className='emails-list-wrapper'>
      <h5 style={{ color: '#4D4D4D' }}>{t('contacts')}:</h5>

      {clientsList.map(({ name, emails, id, type, phones: clientPhones }) => (
        <div className='email-box' key={id}>
          <div className='left-box'>
            <p className='user-name'>{name}</p>
            {/*<p className='user-relation'>{client.property_relation ?? ''}</p>*/}
          </div>
          <div className='right-box'>
            <div className='top-box'>
              {/* ClientList Component */}
              <div className='top-row'>
                {emails ? (
                  emails.map(email => (
                    <ListItem
                      type='email'
                      item={email}
                      key={email}
                      checked={selectedEmails.clients.includes(email)}
                      onChange={e => handleAddEmail(e, email)}
                    />
                  ))
                ) : (
                  <span>{t('prompts.noEmails')}</span>
                )}

                {clientPhones &&
                  clientPhones.map(phone => (
                    <ListItem
                      type='phone'
                      item={phone}
                      key={phone}
                      checked={selectedPhones.clients.includes(phone)}
                      onChange={e => handleAddPhone(e, phone)}
                    />
                  ))}
              </div>
              {/* ClientList Component */}
            </div>
            {addEmail.add && addEmail.user_id === id && (
              <Fade in={true} timeout={500}>
                <div className='add-client'>
                  {/* <InputField
                    value={addEmail.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleAddEmailChange(e)
                    }
                    name='add-client-input'
                    error={addEmail.error}
                    inputProps={undefined}
                    className='add-client-input'
                    autoFocus
                  /> */}
                  <TextFieldWithLabelAndError
                    value={addEmail.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleAddEmailChange(e)
                    }
                    name='add-client-input'
                    error={addEmail.error}
                    className='add-client-input'
                    autoFocus
                    style={{ width: '100%' }}
                  />
                  <MainButton
                    className='add-client-btn'
                    title={t('contactsList.addEmail')}
                    onClick={() => handleAddEmailToClient({ type, id })}
                    isFetching={loading}
                    disabled={!addEmail.isValid}
                  />
                  <MainButton
                    className='cancel-btn'
                    title={t('button.cancel', { ns: 'common' })}
                    onClick={() =>
                      setAddEmail(prev => ({
                        ...prev,
                        user_id: id,
                        add: false,
                        value: '',
                      }))
                    }
                  />
                </div>
              </Fade>
            )}
            {!addEmail.add && (
              <span
                className='add-email-text'
                onClick={() =>
                  setAddEmail(prev => ({ ...prev, user_id: id, add: true }))
                }
              >
                {t('contactsList.addNewEmail')}
              </span>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default EmailsList
