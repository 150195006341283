import { useTranslation } from 'react-i18next'
import { PropertyAddress } from '../../types'
import AddressesTable from '../AdressesTable'
import {
  Footer,
  Content,
  Wrapper,
  MessageText,
  Heading,
} from './SearchDropdown.styles'
import { Button } from 'antd'

type Props = {
  data: PropertyAddress[]
  error?: boolean
  loading: boolean
  message: string
  publicView?: boolean
  wrapperClassName?: string
  onCancelChanges: () => void
  onClickManualInput?: () => void
  onChooseAddress: (address: PropertyAddress) => void
}

export const SearchDropdown = ({
  message,
  onClickManualInput,
  onCancelChanges,
  error,
  ...rest
}: Props) => {
  const { t } = useTranslation(['property', 'common'])

  return (
    <Wrapper
      className={rest?.wrapperClassName || 'search-input__table'}
      wide={!rest.publicView}
    >
      <Content>
        {!!message && (
          <Heading>
            <MessageText error={error}>{message}</MessageText>

            {error && (
              <Button size='small' onClick={onCancelChanges}>
                {t('button.cancel', { ns: 'common' })}
              </Button>
            )}
          </Heading>
        )}

        <AddressesTable {...rest} />
      </Content>

      {!!onClickManualInput && (
        <Footer>
          {t('searchDropdown.cantFindAddress')}{' '}
          <span onClick={onClickManualInput}>
            {t('searchDropdown.manualInput')}
          </span>
        </Footer>
      )}
    </Wrapper>
  )
}
