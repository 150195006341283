import ProjectLayout from './ProjectLayout'
import { ProjectView } from './types'
import { useHistory, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  getProject,
  projectResetted,
  selectProjectSlice,
} from './slices/projectSlice'
import { EditProject } from './components/EditProject/EditProject'
import { Button, Spin } from 'antd'
import ProjectPrevew from './components/ProjectPrevew'
import { getItemUnitsList } from './slices/projectItemsSlice'
import ProjectPreviewSidebar from './components/ProjectPrevew/components/ProjectPreviewSidebar'
import { getRelationContactToProject } from './slices/projectContactsSlice'
import ProjectSidebar from './components/EditProject/components/ProjectSidebar'
import { selectCompany } from 'store/Orcatec/selectors/company'
import { Companies } from 'types/Company'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'
import { fetchPaymentsList, setPayments } from 'store/Orcatec/actions/payments'
import { selectProjectTabsSlice } from './projectSelectors'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import {
  useListenGroupEvents,
  useListenProjectSigned,
} from 'containers/MainContent/Orcatec/Matrix/listeners'
import { getProjectStatuses } from './slices/projectStatusSlice'
import { getRelateJobs } from 'store/Orcatec/actions/jobs'
import { selectWorkOrder } from './slices/projectWorkOrdersSlice'
import UserGuide, {
  guideStepsByModule,
} from 'features/Support/components/UserGuide'
import { useTourGuide } from 'features/Support/hooks/useTourGuide'

export const Project = () => {
  const {
    isActive: tourIsActive,
    currentStep,
    nextTourScheduled,
    handleTourCallback,
    startTour,
  } = useTourGuide('project')

  useEffect(() => {
    if (nextTourScheduled === 'project') {
      startTour()
    }
  }, [])

  const history = useHistory()
  const dispatch = useDispatch()
  const { id, preview: isPreview } = useParams<{
    id: string
    preview: string | undefined
  }>()

  const company = useAppSelector(selectCompany)
  const { status: projectStatus, project } = useAppSelector(selectProjectSlice)
  const workOrder = useAppSelector(selectWorkOrder)
  const { projectTab: currentTab, status: tabStatus } = useAppSelector(
    selectProjectTabsSlice,
  )

  const [wasSigned, setWasSigned] = useState(false)

  const PROJECT_VIEW = isPreview ? ProjectView.PREVIEW : ProjectView.EDIT

  const updateSignature = useCallback(
    (event: {
      data: {
        proposalId: number
      }
    }) => {
      if (event?.data?.proposalId === project.id) {
        setWasSigned(true)
      }
    },
    [project?.id],
  )

  const ivalidateRelatedJobs = useCallback(
    data => {
      const [, id] = Object.entries(data)?.[0]
      const entityId = workOrder?.id || project?.id

      if (!!id && entityId === +id) {
        dispatch(getRelateJobs(data))
      }
    },
    [project?.id, workOrder?.id],
  )

  useListenProjectSigned(updateSignature)
  useListenGroupEvents(ivalidateRelatedJobs)

  const handleProjectViewChange = (view: ProjectView) =>
    history.push(
      view === ProjectView.EDIT ? `/projects/${id}` : `/projects/${id}/preview`,
    )

  useEffect(() => {
    const getData = async () => {
      const res = await dispatch(getProject({ projectId: +id }))

      if (res?.payload?.parent_id)
        return history.push(`/projects/${res?.payload?.parent_id}`)

      if ([404].includes(res?.payload?.response?.status)) {
        return history.push(`/404/project`)
      }

      if ([403].includes(res?.payload?.response?.status)) {
        return history.push(`/403/project`)
      }
    }

    if (id) {
      getData()
      dispatch(getRelationContactToProject({ projectId: +id }))
    }
  }, [id])

  useEffect(() => {
    makeBrowserTitle({ prefixForTitle: project?.code })
  }, [project])

  useEffect(() => {
    if (project.id && currentTab?.id) {
      dispatch(fetchPaymentsList(project?.id, 1, currentTab?.id))
    }
  }, [currentTab?.id])

  useEffect(() => {
    dispatch(getItemUnitsList())
    dispatch(getProjectStatuses())

    return () => {
      dispatch(
        setPayments({
          remaining_balance: 0,
        }),
      )
      dispatch(projectResetted())
    }
  }, [])

  const handleReaload = () => {
    window.location.reload()
  }

  const renderContent = () =>
    PROJECT_VIEW === ProjectView.EDIT ? <EditProject /> : <ProjectPrevew />

  const renderSidebar = () =>
    PROJECT_VIEW === ProjectView.EDIT ? (
      <ProjectSidebar />
    ) : (
      <>
        {[Companies.SoCal].includes(company.id) ? null : (
          <ProjectPreviewSidebar />
        )}
      </>
    )

  return (
    <ProjectLayout
      sidebarContent={
        <Spin spinning={[projectStatus, tabStatus].includes('loading')}>
          {renderSidebar()}
        </Spin>
      }
      projectView={PROJECT_VIEW}
      onProjectViewChange={handleProjectViewChange}
    >
      <Spin spinning={[projectStatus, tabStatus].includes('loading')}>
        {renderContent()}

        {wasSigned && (
          <Modal
            onCancel={handleReaload}
            footer={<Button onClick={handleReaload}>Close</Button>}
            visible
            title='Project updated'
          >
            <p
              style={{
                textAlign: 'center',
                marginBottom: 20,
                fontSize: 22,
                fontWeight: 500,
              }}
            >
              Proposal {project.code} was signed.
              <br /> Page will be reloaded
            </p>
          </Modal>
        )}
      </Spin>

      {tourIsActive && (
        <UserGuide
          steps={guideStepsByModule['project'].steps}
          onCallback={handleTourCallback}
          isActive={tourIsActive}
          stepIndex={currentStep}
        />
      )}
    </ProjectLayout>
  )
}
