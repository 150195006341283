import { useEffect } from 'react'
import { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import {
  markFirstVisitComplete,
  markTourCompleted,
  scheduleNextTour,
  setCurrentStep,
  startTour,
  stopTour,
} from '../tourSlice'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'

export const useTourGuide = (moduleId: string) => {
  const dispatch = useAppDispatch()

  const {
    completedTours,
    isFirstVisit,
    activeTour,
    running,
    currentStep,
    nextTourScheduled,
  } = useAppSelector(state => state.orcatec.tourGide)

  useEffect(() => {
    if (isFirstVisit && !completedTours[moduleId]) {
      startModuleTour()
      dispatch(markFirstVisitComplete())
    }
  }, [moduleId, isFirstVisit])

  const handleTourCallback = (data: CallBackProps) => {
    const { status, index, action, type, step } = data

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      dispatch(markTourCompleted({ moduleId }))

      if (
        ([STATUS.FINISHED] as string[]).includes(status) &&
        step?.data?.triggerNextTour
      ) {
        dispatch(scheduleNextTour(step.data.triggerNextTour))
      }
    }

    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)
    ) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1)

      if (type === EVENTS.STEP_AFTER && !!step?.data?.timeout) {
        dispatch(stopTour())

        setTimeout(() => {
          startModuleTour()
        }, step?.data?.timeout)

        return
      }

      dispatch(setCurrentStep(nextStepIndex))
    }
  }

  const handleUserAction = () => {
    dispatch(setCurrentStep(currentStep + 1))
  }

  const startModuleTour = () => {
    dispatch(startTour({ moduleId }))
  }

  const stopModuleTour = () => {
    dispatch(stopTour())
  }

  return {
    isActive: running && activeTour === moduleId,
    isTourCompleted: completedTours[moduleId],
    currentStep,
    nextTourScheduled,
    startTour: startModuleTour,
    stopTour: stopModuleTour,
    handleTourCallback,
    handleUserAction,
  }
}
