import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { getSubmittedForms, getFormById, deleteFormById } from 'api/CustomForms'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'
import DefaultColumnFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DefaultColumnFilter'
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface'

import useDebounce from 'hooks/useDebounce'
import { getValueFromLS } from 'hooks/useLocalStorage'
import TableLayout, {
  ActionsWrapper,
  IconsBlock,
  IconWrapper,
} from '../../../../containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'

import { Settings } from '@material-ui/icons'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'

import { ColumnProps } from 'antd/lib/table'
import Table from 'containers/MainContent/Orcatec/components/Table'

import { PaginationConfig } from 'antd/lib/pagination'

import DateRangeFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DateRangeFilter/DateRangeFilter'
import {
  CustomForm,
  CustomFormEntityType,
  ISubmittedTable,
} from 'features/Forms/types'
import styled from 'styled-components'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import TableActionsNew from 'containers/MainContent/Orcatec/components/TableActionsNew'
import { EditFormModal } from 'features/Forms/components/FormsBlock/components/EditFormModal'
import {
  VerifiedIcon,
  NotVerifiedIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
// import { isUserJuanMilanFromSafetyNet } from 'features/Settings/UsersAndGroups/userSlice'
import {
  DispatchIcon,
  JobsIcon,
  ProjectsIcon,
} from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { InfoIcon } from 'containers/MainContent/Orcatec/components/UI/InfoBlock/Icons'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import {
  NotificationTemplateType,
  // NotificationModalType,
} from 'features/Notification/types'
import validator from 'validator'
import {
  canSendFormSelect,
  canVerifyFormSelect,
} from 'features/Settings/UsersAndGroups/permissionSlice'

interface CustomColumnProps<T> extends ColumnProps<T> {
  name: string
  defaultWidth: number
  noFilter?: boolean
}

const LinkStyle = {
  color: '#1890ff',
  cursor: 'pointer',
}

const iconStyle = {
  fontSize: '18px',
  color: '#30be89',
}

const name = 'all_forms-table'

export const verifiedStatusFilter = [
  {
    text: 'Verified',
    value: true,
  },
  {
    text: 'Not verified',
    value: false,
  },
]

export const entityTypeIcons = title => {
  return {
    1: {
      icon: <ProjectsIcon />,
      label: 'Project',
    },
    2: {
      icon: <JobsIcon />,
      label: title,
    },
    3: {
      icon: <DispatchIcon />,
      label: 'Appointment',
    },
  }
}

export const entityTypeFilter = title => [
  {
    text: 'Project',
    value: '1',
  },
  {
    text: title,
    value: '2',
  },
  {
    text: 'Appointment',
    value: '3',
  },
]

export const FormsTable = () => {
  const settingsFromLC = getValueFromLS(`${name}-table_v1`)
  const isVisibleFor = useAppSelector(canVerifyFormSelect)
  const dictionary = useAppSelector(selectJobDictionary)
  //const isVisibleFor = true
  const timezone = useAppSelector(state => state.orcatec.company.timezone)
  const canSend = useAppSelector(canSendFormSelect)

  const [data, setData] = useState<ISubmittedTable[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: settingsFromLC?.page_size || 25,
    total: 10,
  })
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [openTableSettings, setOpenTableSettings] = useState<boolean>(false)
  const [openEditModal, setEditFormModal] = useState(false)
  const [form, setForm] = useState({})
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [checkedRows, setCheckedRows] = useState([])

  const [showEmailModal, setShowEmailModal] = useState(false)
  const debouncedSearchValue = useDebounce(search, 500)

  const fetchSubmittedFormsList = async (params?: object) => {
    setLoading(true)
    try {
      const { forms, meta } = await getSubmittedForms(params)

      setData(
        forms.map((form, index) => ({
          ...form,
          key: index,
        })),
      )
      setPagination(meta)
    } catch (error) {
      const errorStatus = error?.response?.status
      const errorMessage = Object?.values?.(error?.response?.data)?.[0]

      openNotificationWithIcon('error', {
        message: `Error: ${errorStatus}`,
        description: errorMessage,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSubmittedFormsList({
      per_page: settingsFromLC?.page_size || 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  const handleTableChange = async (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ISubmittedTable, string[]>>,
    sorter: SorterResult<ISubmittedTable>,
  ) => {
    try {
      await fetchSubmittedFormsList({
        search: debouncedSearchValue || undefined,
        page: pagination.current,
        sort_field: sorter.order ? sorter.field : null,
        order:
          sorter.order === 'ascend'
            ? 'asc'
            : sorter.order === 'descend'
            ? 'desc'
            : null,
        per_page: pagination.pageSize,
        ...filters,
      })

      setSortOrder(
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      )
      setSortField(sorter.order ? sorter.field : null)
      setTableFilters({ ...filters })
    } catch (error) {
      openNotificationWithIcon('error', { message: error?.message })
      return Promise.reject(error)
    }
  }

  const handleClick = (path: string) => {
    return window.open(path, '_blank')
  }
  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      setIsFullScreen(false)
    }
  }

  const handleEditForm = async record => {
    if (!record.id) return
    setLoading(true)
    try {
      const res = await getFormById(record.id)

      setForm(res)
      setCheckedRows([record])
      setEditFormModal(true)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  /*   const handleCloseEditForm = async editedFrom => {
    if (!editedFrom) return setEditFormModal(false)
    try {
      setData(prev =>
        prev.map(form =>
          form.id === editedFrom.id ? { ...form, ...editedFrom } : form,
        ),
      )
      setEditFormModal(false)
    } catch (error) {
      console.error(error)
    }
  } */

  const hadleFormUpdate = (editedForm: CustomForm) => {
    if (!editedForm) return

    setEditFormModal(false)

    setData(prev =>
      prev.map(form =>
        form.id === editedForm.id
          ? {
              ...form,
              ...editedForm,
              proposal_id: form.proposal_id,
              job_id: form.job_id,
            }
          : form,
      ),
    )
  }

  const handleFormClose = (
    formId: number,
    fieldToUpdate: Record<keyof CustomForm, string>,
  ) => {
    setData(prev =>
      prev.map(form =>
        form.id === formId
          ? {
              ...form,
              ...fieldToUpdate,
            }
          : form,
      ),
    )
    setEditFormModal(false)
  }

  const handleDeleteForm = async (form: CustomForm) => {
    setLoading(true)
    try {
      await deleteFormById(form.id)

      setData(forms => forms.filter(el => el.id !== form.id))
      setEditFormModal(false)

      openNotificationWithIcon('success', {
        message: 'Form was deleted successfully!',
      })
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  const tableActionProps = template => ({
    todos: ['view'],
    callbacks: [() => handleEditForm(template)],
    preloaders: [false],
    tooltips: ['View form', false],
    popConfirms: [false],
  })

  const onRow = (_: string, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  const handleCloseNNotificationModal = () => {
    const existsIds = checkedRows.map(item => item.id)

    setData(prev =>
      prev.map(item =>
        existsIds.includes(item.id) ? { ...item, is_sent: true } : item,
      ),
    )

    setCheckedRows([])
    setSelectedRowKeys([])
    setShowEmailModal(false)
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (
      selectedRowKeys: React.SetStateAction<never[]>,
      selectedRows: {
        map: (arg0: (row: any) => any) => React.SetStateAction<never[]>
      },
    ) => {
      setCheckedRows(
        selectedRows.map(row => ({ ...row, display_info: row.name })),
      )
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const columns: CustomColumnProps<ISubmittedTable[]> = [
    {
      title: 'Project ID',
      name: 'Project ID',
      dataIndex: 'proposal_code',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'proposal_code',
      defaultWidth: 150,
      sorter: true,
      render: (
        value: ISubmittedTable['proposal_code'],
        record: ISubmittedTable,
      ) => (
        <span
          style={LinkStyle}
          onClick={() => handleClick(`/projects/${record?.proposal_id}`)}
        >
          {value}
        </span>
      ),
    },
    {
      title: 'Event ID',
      name: 'Event ID',
      dataIndex: 'job_code',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'job_code',
      sorter: true,
      defaultWidth: 150,
      render: (value: ISubmittedTable['job_code'], record: ISubmittedTable) =>
        isVisibleFor ? (
          <span
            style={LinkStyle}
            // onClick={() => handleClick(`/jobs/${record.job_id}`)}
            onClick={() =>
              handleClick(
                `/${record.type === 2 ? 'jobs' : 'appointment'}/${
                  record.job_id
                }`,
              )
            }
          >
            {value}
          </span>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Type',
      name: 'Type',
      dataIndex: 'type',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'type',
      sorter: true,
      defaultWidth: 60,
      filterMode: 'tree',
      filters: entityTypeFilter(dictionary.singular),
      render: (type: CustomFormEntityType) => (
        <Tooltip title={entityTypeIcons(dictionary.singular)[type]?.label}>
          <i>{entityTypeIcons(dictionary.singular)[type]?.icon}</i>
        </Tooltip>
      ),
    },
    {
      title: 'Form Name',
      name: 'Form Name',
      dataIndex: 'name',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'name',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Form Name'} {...props} />
      ),
    },
    {
      title: 'Template Name',
      name: 'Template Name',
      dataIndex: 'title',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'title',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Template Name'} {...props} />
      ),
    },

    {
      title: 'Primary Field Label',
      name: 'Primary Field Label',
      dataIndex: 'main_field_label',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'main_field_label',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Primary Field Label'} {...props} />
      ),
    },
    {
      title: 'Primary Field Value',
      name: 'Primary Field Value',
      dataIndex: 'main_field_value',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'main_field_value',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Primary Field Value'} {...props} />
      ),
    },

    {
      title: 'Contact Emails',
      name: 'Contact Emails',
      dataIndex: 'primary_contact_emails',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'primary_contact_emails',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Contact Emails'} {...props} />
      ),
    },
    {
      title: 'Contact Phones',
      name: 'Contact Phones',
      dataIndex: 'primary_contact_phones',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'primary_contact_phones',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DefaultColumnFilter title={'Contact Phones'} {...props} />
      ),
    },
    {
      title: 'Project Created',
      name: 'Project created at',
      dataIndex: 'proposal_created_at',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'proposal_created_at',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DateRangeFilter
          nameLS='forms-list-filter'
          name='proposal_created_at'
          {...props}
        />
      ),
      render: (value: ISubmittedTable['proposal_created_at']) =>
        value
          ? moment
              .utc(value)
              .tz(timezone)
              .format('MM/DD/YYYY hh:mm a')
          : '--',
    },
    {
      title: 'Form Created',
      name: 'Form Created',
      dataIndex: 'created_at',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'created_at',
      sorter: true,
      defaultWidth: 200,
      filterDropdown: (props: FilterDropdownProps) => (
        <DateRangeFilter
          {...props}
          nameLS='forms-list-filter'
          name='created_at'
        />
      ),
      render: (value: ISubmittedTable['created_at']) =>
        value
          ? moment
              .utc(value)
              .tz(timezone)
              .format('MM/DD/YYYY hh:mm a')
          : '--',
    },
    {
      title: 'Form Updated',
      name: 'Form Updated',
      dataIndex: 'updated_at',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'updated_at',
      defaultWidth: 200,
      sorter: true,
      filterDropdown: (props: FilterDropdownProps) => (
        <DateRangeFilter
          {...props}
          nameLS='forms-list-filter'
          name='updated_at'
        />
      ),
      render: (value: ISubmittedTable['updated_at']) =>
        value
          ? moment
              .utc(value)
              .tz(timezone)
              .format('MM/DD/YYYY hh:mm a')
          : '--',
    },
    {
      title: 'Progress',
      name: 'Progress',
      dataIndex: 'fill_percentage',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'fill_percentage',
      defaultWidth: 150,
      sorter: true,
      noFilter: true,
      render: (
        value: ISubmittedTable['fill_percentage'],
        record: ISubmittedTable,
      ) =>
        record?.completed ? (
          <i style={iconStyle} className='mdi mdi-check-circle' />
        ) : (
          <span> {`${value ?? 0}%`}</span>
        ),
    },
    {
      title: 'Sent',
      name: 'Sent',
      dataIndex: 'is_sent',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      key: 'is_sent',
      defaultWidth: 150,
      sorter: true,
      noFilter: true,
      render: (value: ISubmittedTable['is_sent']) =>
        value ? (
          <i style={iconStyle} className='mdi mdi-check-circle' />
        ) : (
          <span> {`--`}</span>
        ),
    },

    {
      title: 'Actions',
      name: 'Actions',
      dataIndex: 'action',
      onCell: record =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
      noFilter: true,
      key: 'action',
      render: (value, record) => (
        <TableActionsNew {...tableActionProps(record)} />
      ),
    },
  ]

  return (
    <Wrapper>
      <TableLayout
        isFullScreen={isFullScreen}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        actions={
          <ActionsWrapper>
            <IconsBlock>
              {canSend && (
                <MainButton
                  onClick={() => setShowEmailModal(true)}
                  disabled={!checkedRows?.length}
                  title='Send'
                />
              )}

              <Tooltip title='You can search here by all values in the custom forms fields'>
                <span>
                  <InfoIcon />
                </span>
              </Tooltip>

              <InputField
                label='Search'
                value={search}
                style={{ width: '100%', maxWidth: '200px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />
              <IconWrapper
                onKeyDown={handleKeyPress}
                onClick={() => setIsFullScreen(prev => !prev)}
              >
                {isFullScreen ? (
                  <FullscreenExitOutlined style={{ fontSize: '22px' }} />
                ) : (
                  <FullscreenOutlined style={{ fontSize: '22px' }} />
                )}
              </IconWrapper>

              <IconWrapper onClick={() => setOpenTableSettings(true)}>
                <Tooltip placement='left' title='Columns settings'>
                  <Settings />
                </Tooltip>
              </IconWrapper>
            </IconsBlock>
          </ActionsWrapper>
        }
      >
        <Table
          name={name}
          columns={
            isVisibleFor
              ? [
                  ...columns,
                  {
                    title: 'Verified',
                    name: 'Verified',
                    dataIndex: 'is_verified',
                    onCell: record =>
                      record.key === clickedRowIndex
                        ? undefined
                        : ellipsisStyle,
                    filters: verifiedStatusFilter,
                    key: 'is_verified',
                    render: value =>
                      value ? <VerifiedIcon /> : <NotVerifiedIcon />,
                  },
                ]
              : columns
          }
          data={data}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          search={debouncedSearchValue}
          openTableSettings={openTableSettings}
          onCloseTableSettings={() => setOpenTableSettings(false)}
          rowKey={obj => obj?.key?.toString()}
          onRow={onRow}
          rowSelection={rowSelection}
        />
      </TableLayout>
      {openEditModal && (
        <EditFormModal
          action='update'
          handleCancel={handleFormClose}
          // handleCancel={() => setEditFormModal(false)}
          onSave={hadleFormUpdate}
          isModalOpen={openEditModal}
          form={form}
          onDeleteForm={handleDeleteForm}
          otherClientEmails={[
            ...new Set(
              checkedRows.map(item => item.primary_contact_emails).flat(),
            ),
          ].filter(item => item && validator.isEmail(item))}
        />
      )}
      {showEmailModal && (
        <NotificationModal
          title='Send Forms'
          showModal
          setShowModal={handleCloseNNotificationModal}
          templateType={NotificationTemplateType.GENERAL}
          entityIds={checkedRows.map(item => item.id)}
          otherClientEmails={[
            ...new Set(
              checkedRows.map(item => item.primary_contact_emails).flat(),
            ),
          ].filter(item => item && validator.isEmail(item))}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .custom-page-headers {
    /* position: absolute;
    right: 20px;
    top: -30px;
    z-index: 2; */
  }
  & .ant-table .ant-table-thead tr .ant-table-selection-column div {
    display: inline-flex !important;
  }
`
