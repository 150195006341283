import { Modal as AntdModal, ModalProps } from 'antd'
import { useState } from 'react'
import MainButton from '../../buttons/MainButton'
import { useTranslation } from 'react-i18next'

type Props = ModalProps & {
  showConfirmationOnExit?: boolean
  children: React.ReactNode
}

export const Modal = ({
  children,
  showConfirmationOnExit,
  onCancel,
  onOk,
  width = 'auto',
  ...rest
}: Props) => {
  const [loading, setLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const handleShowConfirmModal = () => {
    setShowConfirm(true)
  }

  const handleOk = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setLoading(true)
    await onOk?.(e)

    setLoading(false)
  }

  const handleConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleOk(e)
    setShowConfirm(false)
  }

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    onCancel?.(e)
    setShowConfirm(false)
  }

  return (
    <AntdModal
      centered
      destroyOnClose
      maskClosable={false}
      width={width}
      onCancel={showConfirmationOnExit ? handleShowConfirmModal : onCancel}
      onOk={handleOk}
      okText='Save'
      confirmLoading={loading}
      {...rest}
    >
      {children}

      <ConfirmationModal
        visible={showConfirm}
        onSave={handleConfirm}
        onCancel={handleCancel}
        onClose={() => setShowConfirm(false)}
        isLoading={loading}
      />
    </AntdModal>
  )
}

interface ConfirmationModalProps {
  visible: boolean
  isLoading?: boolean
  onSave: (e: React.MouseEvent<HTMLButtonElement>) => void
  onCancel: (e: React.MouseEvent<HTMLButtonElement>) => void
  onClose: () => void
}

export const ConfirmationModal = ({
  visible,
  onSave,
  onCancel,
  onClose,
  isLoading,
}: ConfirmationModalProps) => {
  const { t } = useTranslation('common')
  return (
    <AntdModal
      visible={visible}
      destroyOnClose
      maskClosable={false}
      closable={false}
      footer={null}
    >
      <h6>{t('prompt.unsavedChanges')}</h6>
      <p>{t('prompt.lostChanges')}</p>
      <div
        style={{
          display: 'flex',
          gap: 10,
          justifyContent: 'flex-end',
          marginTop: 20,
        }}
      >
        <MainButton
          title={t('button.dontSave')}
          type='delete'
          onClick={onCancel}
        />
        <MainButton
          title={t('button.cancel')}
          type='cancel'
          onClick={onClose}
        />
        {!!onSave && (
          <MainButton
            title={t('button.save')}
            onClick={onSave}
            disabled={isLoading}
          />
        )}
      </div>
    </AntdModal>
  )
}
