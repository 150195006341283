import styled from 'styled-components'
import { Collapse } from 'antd'

const { Panel } = Collapse

const DATA = [
  {
    head: 'What is a project ID?',
    message: 'A project ID is a unique number assigned to the project.',
  },
  {
    head: 'Can I create more than one custom project ID? ',
    message:
      'You can use an unlimited number of unique custom project IDs for different industries or types',
  },
  {
    head: 'What do the variables mean?',
    message: (
      <>
        <p>
          {`{UPI} - user project identifier (set by default after user creating the project)`}
        </p>
        <p>{`{yy} - year`}</p>
        <p>{`{mm} - month`}</p>
        <p>{`{dd} - day`}</p>
        <p>{`{00} - increment (required)`}</p>
        <p>
          {`Example: project ID ABC23060101 means it was created by
          user with project identifier ABC on 2023-06-01 and 01 is its ordinal
          number.`}
        </p>
      </>
    ),
  },
  {
    head: 'How do I create a custom project ID? ',
    message:
      'You can simply edit the pattern by adding, removing and changing the order of the variables. You can delete a variable completely, but you should NOT edit it, otherwise it will be displayed incorrectly. Please also note that the spaces are not allowed. ',
  },
  {
    head: 'Is it obligatory to create a custom project ID?',
    message:
      'The use of custom project IDs is optional. The default number will consist of project ID (set for the user in settings), year, month, date and ordinal number.',
  },

  {
    head: 'Can the same project ID be assigned to different projects?',
    message:
      'No, the project ID is unique. If you duplicate the project, the duplicate projects are created with the prefix - 01 (02,03 etc).',
  },
  {
    head: 'Can I edit the project ID?',
    message:
      'Yes, you are able to edit the custom project ID pattern which will be applied to all new projects and edit the ID of the created project in project settings by clicking on pencil icon.',
  },
]
export const FAQ = () => {
  return (
    <Wrapper>
      {DATA.map(({ head, message }) => (
        <Collapse key={head} ghost>
          <Panel key={head} header={<BoldTitle>{head}</BoldTitle>}>
            <RegularText>{message}</RegularText>
          </Panel>
        </Collapse>
      ))}
    </Wrapper>
  )
}

const BoldTitle = styled.p`
  font-weight: 500;
`

const RegularText = styled.p`
  padding-left: 20px;
`
const Wrapper = styled.div`
  & .ant-collapse-arrow {
    vertical-align: 1px !important;
  }
`
