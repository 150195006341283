import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { wasEditField } from '../../../../../../../../store/Orcatec/actions/ui/modals/modals'
import { deleteMedia, downloadFile as fetchFileUrl } from 'api/Media'
import { Empty, Checkbox } from 'antd'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import './Media.scss'
import { downLoadFile } from '../../../../../../../../helpers/downloadFile/downloadFile'
import { filterArrayOfObjectsToUniqueKeyValue } from '../../../../../../../../helpers/filterArrayOfObjectsToUniqueKeyValue'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import MediaGroup from './components/MediaGroup'
import MediaUnit from './components/MediaUnit'
import DocViewer from 'helpers/viewFIlesNew/ViewDoc'
import { SettingOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

const genExtra = () => (
  <SettingOutlined
    onClick={event => {
      event.stopPropagation()
    }}
  />
)

const getArrayFrom = items => (Array.isArray(items) ? items : [])

const Media = ({
  media,
  setFieldValues,
  onDeleteMedia,
  proposals,
  proposalsMediaArray = [],
  onAddMediaClick,
  onChange,
  show,
}) => {
  const { t } = useTranslation('property')

  const [selected, setSelected] = useState([])
  const [selectedFromProposal, setSelectedFromProposal] = useState([])
  // const [showProposalsMedia, setShowProposalsMedia] = useState(false)
  // const [showPropertyMedia, setShowPropertyMedia] = useState(false)
  const [downloadStatus, setDownloadStatus] = useState(false)
  const [downloadPercent, setDownloadPercent] = useState(0)
  // const [isOpenProposal, setIsOpenProposal] = useState([])
  const [documents, setDocuments] = useState(null)
  const [showFileViewer, setShowFileViewer] = useState(false)
  const [currentFileIdx, setCurrentFileIdx] = useState(null)
  const dispatch = useDispatch()

  const onChangeCheckbox = (obj, fromProposal) => e => {
    const { checked } = e.target
    const setter = fromProposal ? setSelectedFromProposal : setSelected
    if (checked) {
      setter(prev => [...prev, obj])
    } else {
      setter(prev => prev.filter(item => item.id !== obj.id))
    }
  }

  const showFileModalHeandle = (documents, idx) => {
    setShowFileViewer(true)
    setDocuments(documents)
    setCurrentFileIdx(idx)
  }

  const closeFileModalHeandle = () => {
    setShowFileViewer(false)
    setDocuments(null)
    setCurrentFileIdx(null)
  }

  const onSelectAll = e => {
    if (!!e && !!e.stopPropagation) {
      e.stopPropagation()
    }
    if (
      selected.length + selectedFromProposal.length !==
      proposalsMediaArray?.length + media.length
    ) {
      setSelectedFromProposal(proposalsMediaArray)

      setSelected(media)
    } else {
      setSelected([])
      setSelectedFromProposal([])
    }
  }
  const setWasEdit = val => {
    return dispatch(wasEditField(val))
  }
  const delMedia = id => {
    if (onDeleteMedia) return onDeleteMedia(id)
    setFieldValues(prev => ({
      ...prev,
      media: prev.media.filter(item => item.id !== id),
    }))
    setSelected(prev => {
      const newSelected = [...prev]
      const dellIdx = newSelected.findIndex(item => item.id === id)
      newSelected.splice(dellIdx, 1)
      return newSelected
    })
  }

  const onDellMedia = id => {
    if (onDeleteMedia) return onDeleteMedia(id)

    deleteMedia(id)
      .catch(error => {
        //no error code for now. When it will return error with code 404 (not found) set delMedia(id) in this line
      })
      .finally(() => {
        delMedia(id)
      })
  }

  const onDownloadSelected = async (type = 'zip') => {
    const selectedFiles = [...selected, ...selectedFromProposal]
    const length = selectedFiles.length
    if (length) {
      if (selectedFiles.length === 1) {
        setDownloadStatus(true)
        selectedFiles?.forEach(item => {
          fetchFileUrl(item.path)
            .then(data => {
              downLoadFile(data, item.display_name)
            })
            .then(() => setDownloadStatus(false))
        })
        return
      }
      if (type === 'zip') {
        setDownloadStatus(true)
        const zip = new JSZip()
        const sourceData = selectedFiles.map(el => ({
          path: el.path,
          name: el.display_name,
        }))

        const list = async arr => {
          const res = []
          for (let i = 0; i < arr.length; i++) {
            try {
              const data = await fetchFileUrl(arr[i].path)
              setDownloadPercent(prev => prev + Math.floor(100 / length))
              res.push({ file: data, name: arr[i].name })
            } catch (error) {
              setDownloadPercent(prev => prev + Math.floor(100 / length))
            }
          }
          return res
        }

        const listRes = await list(sourceData)

        listRes?.forEach(el => zip.file(el.name, el.file))
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, 'Orcatec')
          setDownloadStatus(false)
          setDownloadPercent(0)
          setWasEdit(false)
        })
      }
    }
  }

  const getMediaUnit = proposalFilesName => (item, idx, arr) => {
    const selectedForCheck = proposalFilesName ? selectedFromProposal : selected

    return (
      <MediaUnit
        key={`property_media_unit_${item.id ? item.id : idx}_${
          proposalFilesName ? proposalFilesName : ''
        }`}
        checked={!!selectedForCheck.find(file => file.id === item.id)}
        onChangeCheckbox={onChangeCheckbox(item, proposalFilesName)}
        onChange={onChange}
        item={item}
        isProposalImage={!!proposalFilesName}
        onDellMedia={!proposalFilesName ? onDellMedia : undefined}
        current={idx}
        showFileModalHeandle={showFileModalHeandle}
        files={proposalFilesName ? arr : media}
      />
    )
  }

  const mediaList = Array.isArray(media) ? media.map(getMediaUnit()) : []

  const proposalsMediaList = Array.isArray(proposals)
    ? proposals.reduce((acc, proposal, proposalIdx) => {
        const proposalCode = proposal.code ? proposal.code : ''
        const proposalId = proposal.id ? proposal.id : proposalIdx
        const filesForCustomer = getArrayFrom(proposal?.files)
        const filesFromCustomer = getArrayFrom(proposal?.files_from_customer)
        const filesForWorkers = getArrayFrom(proposal?.files_for_worker)
        const proposalTabs = getArrayFrom(proposal.proposal_tabs)
        const linkToProposal = `/projects/${proposalId}`
        let filesForInstallers = proposalTabs.length
          ? proposalTabs.reduce((acc, tab, tabIdx) => {
              const files = getArrayFrom(tab.files)
              if (files.length) {
                acc = acc.concat(files)
              }
              return acc
            }, [])
          : []

        filesForInstallers = filterArrayOfObjectsToUniqueKeyValue(
          filesForInstallers,
          'id',
        )

        if (
          !!filesForCustomer.length ||
          !!filesForInstallers.length ||
          !!filesForWorkers.length ||
          !!filesFromCustomer.length
        ) {
          acc.push(
            <Collapse defaultActiveKey={[]} expandIconPosition='right' ghost>
              <Panel
                extra={genExtra()}
                header={
                  <Heading>
                    <a
                      className='proposal-media__link'
                      href={linkToProposal}
                      rel='noreferrer'
                      target='_blank'
                    >{`Project ${proposalCode}`}</a>
                  </Heading>
                }
                key='1'
              >
                <div>
                  {!!filesForCustomer.length && (
                    <>
                      <span className='files-title'>{`Files for customer:`}</span>
                      <div className='items-container-files_for_customer'>
                        {filesForCustomer.map(
                          getMediaUnit('files_for_customer'),
                        )}
                      </div>
                    </>
                  )}
                  {!!filesFromCustomer.length && (
                    <>
                      <span className='files-title'>{`Files from customer:`}</span>
                      <div className='items-container-files_for_customer'>
                        {filesFromCustomer.map(
                          getMediaUnit('files_from_customer'),
                        )}
                      </div>
                    </>
                  )}
                  {!!filesForInstallers.length && (
                    <>
                      <span className='files-title'>{`Files for installers:`}</span>
                      <div className='items-container-files_for_installers'>
                        {filesForInstallers.map(
                          getMediaUnit('files_for_installers'),
                        )}
                      </div>
                    </>
                  )}
                  {!!filesForWorkers.length && (
                    <>
                      <span className='files-title'>{`Files for workers:`}</span>
                      <div className='items-container-files_for_installers'>
                        {filesForWorkers.map(getMediaUnit('files_for_workers'))}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            </Collapse>,
          )
        }

        return acc
      }, [])
    : []
  const intermediate =
    selected.length + selectedFromProposal.length > 0 &&
    selected.length + selectedFromProposal.length !==
      media.length + proposalsMediaArray.length
  const checked =
    selected.length + selectedFromProposal.length ===
    media.length + proposalsMediaArray.length

  return (
    <div
      className='media-container'
      style={{ display: show ? 'block' : 'none' }}
    >
      {!!mediaList.length || !!proposalsMediaList.length ? (
        <>
          <div className='btnGroupMediaWrapper'>
            <div className='m-t-10 m-b-10 btnGroupMedia' aria-label='Actions'>
              <Checkbox
                checked={checked}
                onChange={onSelectAll}
                indeterminate={intermediate}
              />
              {checked ? t('tabs.media.select') : t('tabs.media.unselect')}
              {selected.length + selectedFromProposal.length > 0 && (
                <MainButton
                  className='add-media-button'
                  title={
                    downloadStatus
                      ? `${t('tabs.media.downloading')}... ${downloadPercent}%`
                      : t('tabs.media.download')
                  }
                  onClick={() => onDownloadSelected()}
                  disabled={
                    (!selected.length && !selectedFromProposal.length) ||
                    downloadStatus
                  }
                />
              )}
              <MainButton
                title={t('tabs.media.addMedia')}
                className='add-media-button'
                onClick={onAddMediaClick}
              />
            </div>
          </div>
          {!!media?.length && (
            <MediaGroup
              mediaList={mediaList}
              title={t('tabs.media.propertyMedia')}
              // toggleMedia={togglePropertyMedia}
              // showMedia={showPropertyMedia}
              quantityItems={media.length}
              onChangeCheckbox={onChangeCheckbox}
              onChange={onChange}
            />
          )}

          {!!proposals?.length && (
            <MediaGroup
              mediaList={proposalsMediaList}
              title={t('tabs.media.projectsMedia')}
              // toggleMedia={toggleProposalsMedia}
              // showMedia={showProposalsMedia}
              quantityItems={proposalsMediaArray.length}
              onChange={onChange}
            />
          )}
        </>
      ) : (
        <div className='empty-wrapper'>
          <MainButton
            title={t('tabs.media.addMedia')}
            className='add-media-button'
            onClick={onAddMediaClick}
          />
          <Empty description={t('tabs.media.noMedia')} />
        </div>
      )}

      <DocViewer
        documents={documents}
        currentDocument={currentFileIdx}
        isModalVisible={showFileViewer}
        handleCancel={closeFileModalHeandle}
      />
    </div>
  )
}

export const MediaTab = Media

const Heading = styled.div``
