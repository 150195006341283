import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const DateModal = ({ modal, apply, close }) => {
  const { t } = useTranslation(['dispatch', 'common'])

  return (
    <Modal show={modal} onHide={close} backdrop='static' className='date-modal'>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>{t('promt.differentDate')}</p>
      </Modal.Body>
      <Modal.Footer>
        <button className='btn btn-custom-cancel' onClick={close}>
          {t('button.no', { ns: 'common' })}
        </button>
        <button className='btn btn-custom-info' onClick={apply}>
          {t('button.yes', { ns: 'common' })}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export { DateModal }
