import { TextField, Tooltip } from '@material-ui/core'
import React from 'react'
import validator from 'validator'
import { Divider } from 'antd'
import { AddIcon } from '../../../../../../components/Icons/CommonIcons'
// import Tags from '../../../../../../components/Tags/Tags'
import FeedbackRating from '../../../../Rating'
import ClientsSuggestionsTable from '../../ClientsSuggestionsTable/ClientsSuggestionsTable'
import EmailRow from '../../EmailRow'
import PhoneRow from '../../PhoneRow'
import Row from '../../Row'
import Notes from '../../../../../../components/Notes'
import InputField from '../../../../../../components/Input'
import Tags from '../../../../../../components/Tag/Tag'
import MembershipLabels from 'features/Membership/components/MembershipLabels'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { NotesPermissions } from 'features/Settings/UsersAndGroups'
import { useTranslation } from 'react-i18next'

const PersonalInfo = ({
  // onAddTag,
  // onDeleteTag,
  // onChangeTempTagValue,
  // tempTagValue,
  // tempTagValueError,
  clientFormValues,
  isUpdateClient,
  onOpenFeedbackModal,
  onChange,
  onChangeType,
  clientInputRow,
  propertyRelation,
  showSuggestions,
  findedClients,
  searchField,
  useClientFromSuggestions,
  addRow,
  removeRow,
  clientInputPhone,
  onCheck,
  onCheckPhonePref,
  onChangeSelect,
  isPhoneValid,
  setIsPhoneValid,
  clientInputEmail,
  isEmailValid,
  organizationId,
  isOrganization,
  isAssociation,
  isContact,
  isClient,
  isAppointment,
  isPropertyForm,
  validateField,
}) => {
  const { t } = useTranslation(['contact'])
  /*   const namePlaceholder =
    isOrganization || clientFormValues.type.value === 1 ? 'Name' : 'Client Name' */
  const nameTitle =
    isOrganization || clientFormValues.type.value === 1
      ? t('companyName')
      : t('fullName')
  const notesLabel =
    isOrganization || clientFormValues.type.value === 1
      ? t('notes')
      : t('clientNotes')
  /* const tagsLabel =
    isOrganization || clientFormValues.type.value === 1 ? 'Tags' : 'Client Tags' */
  const canAddPhone =
    isPhoneValid &&
    clientFormValues.phones.every(
      item => item.value.phone.replace(/\D/g, '').length === 10,
    )
  const canAddEmail =
    isEmailValid &&
    clientFormValues.emails.every(
      item => !!item.value.email && validator.isEmail(item.value.email),
    )

  const tagRoute =
    clientFormValues?.type?.value === 1 ? `organizations/` : `clients/`

  return (
    <>
      <div
        className='clientWrapperMain'
        style={{ marginRight: 0, marginLeft: 0 }}
      >
        <div className='formWrapper'>
          {/* {!isPageClientForm && <h3>{clientFormValues.id.value ? 'Edit Client' : 'Add New Client'}</h3>} */}
          {!!clientFormValues?.memberships?.length && (
            <div style={{ display: 'inline-flex', marginBottom: 10 }}>
              <p>Memberships: </p>
              <MembershipLabels
                memberships={clientFormValues?.memberships?.map(
                  membership => membership?.value,
                )}
              />
            </div>
          )}
          <Row
            title={nameTitle}
            maxLength={100}
            // placeholder={namePlaceholder}
            name={clientFormValues.name}
            website={clientFormValues.website}
            industry={clientFormValues.industry}
            relationValue={clientFormValues.relation.value}
            onChange={onChange}
            onChangeType={onChangeType}
            refProps={clientInputRow}
            propertyRelation={propertyRelation}
            type={clientFormValues.type}
            clientId={clientFormValues.id.value}
            organizationId={organizationId}
            isOrganization={isOrganization}
            isAssociation={isAssociation}
            isAppointment={isAppointment}
            isContact={isContact}
            isClient={isClient}
            isShowType
            isPropertyForm={isPropertyForm}
          />
          {clientFormValues.id?.value && (
            <div className='client-form__rating-container'>
              <Tooltip
                title={`Rating: ${
                  clientFormValues.vote.value ? clientFormValues.vote.value : 0
                }`}
              >
                <div
                  className='client-form__rating'
                  onClick={onOpenFeedbackModal}
                >
                  <FeedbackRating
                    size='small'
                    readOnly
                    value={
                      clientFormValues.vote.value
                        ? clientFormValues.vote.value
                        : 0
                    }
                    precision={0.5}
                  />
                  {!!clientFormValues.feedBackList &&
                    clientFormValues.feedBackList.length > 0 && (
                      <p className='rating-count'>
                        {clientFormValues.feedBackList.length}
                      </p>
                    )}
                </div>
              </Tooltip>
            </div>
          )}
          {showSuggestions && !!findedClients && searchField === 'name' && (
            <div className='suggestionWrapper'>
              {findedClients.length ? (
                <>
                  <ClientsSuggestionsTable
                    suggestions={findedClients}
                    onUseThisClient={useClientFromSuggestions}
                  />
                  <div style={{ marginBottom: '1.2rem' }} />
                </>
              ) : null}
            </div>
          )}
          {/* ---------------------------------------------------------------------------------------------- */}
          {!clientFormValues.phones.length && (
            <button
              className='add btn btn-info col-12 block'
              onClick={() => addRow('phones', 'Phone')}
            >
              t{'addPhone'}
              <i className='fa fa-plus-circle' />
            </button>
          )}
          {/* ---------------------------------------------------------------------------------------------- */}
          <div className='client-phones'>
            <div>
              <span
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  color: '#191919',
                }}
              >
                {t('phone', { count: 10 })} {/* plural for phones */}
              </span>
              <button
                className='btn add'
                disabled={!canAddPhone}
                onClick={() => {
                  if (canAddPhone) addRow('phones')
                }}
              >
                <AddIcon disabled={!canAddPhone} />
              </button>
            </div>
            {clientFormValues.phones.map((item, i) => (
              <PhoneRow
                placeholder='XXX-XXX-XXXX'
                isLast={clientFormValues.phones.length - 1 === i}
                isOne={clientFormValues.phones.length === 1}
                key={i}
                item={item}
                refProps={clientInputPhone}
                index={i}
                onChange={e => onChange(e, i)}
                addRow={() => addRow('phones')}
                removeRow={() => removeRow('phones', i)}
                onCheckPhonePref={onCheckPhonePref}
                onChangeSelect={e => onChangeSelect(e, i)}
                isPhoneValid={isPhoneValid}
                setIsPhoneValid={setIsPhoneValid}
                propertyRelation={propertyRelation}
                isUpdate={isUpdateClient}
                validateField={e => validateField(e, i)}
              />
            ))}
          </div>
          {showSuggestions && !!findedClients && searchField === 'phones' && (
            <div className='suggestionWrapper'>
              {findedClients.length ? (
                <>
                  <ClientsSuggestionsTable
                    suggestions={findedClients}
                    onUseThisClient={useClientFromSuggestions}
                  />
                  <div style={{ marginBottom: '2rem' }} />
                </>
              ) : null}
            </div>
          )}

          <div className='client-emails'>
            <div className='email-actions'>
              <span
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  color: '#191919',
                }}
              >
                {t('email', { count: 10 })}
              </span>
              <button
                className='btn add'
                disabled={!canAddEmail}
                onClick={() => {
                  if (canAddEmail) addRow('emails')
                }}
              >
                <AddIcon disabled={!canAddEmail} />
              </button>
            </div>
            {clientFormValues.emails.map((item, i) => (
              <EmailRow
                placeholder='example@mail.com'
                isLast={clientFormValues.emails.length - 1 === i}
                key={i}
                refProps={clientInputEmail}
                isOne={clientFormValues.emails.length === 1}
                item={item}
                index={i}
                onChange={e => onChange(e, i)}
                addRow={() => addRow('emails')}
                removeRow={() => removeRow('emails', i)}
                onCheck={onCheck}
                isEmailValid={isEmailValid}
                propertyRelation={propertyRelation}
                isUpdate={isUpdateClient}
                validateField={e => validateField(e, i)}
              />
            ))}
          </div>
          {showSuggestions && !!findedClients && searchField === 'emails' && (
            <div className='suggestionWrapper'>
              {findedClients.length ? (
                <>
                  <ClientsSuggestionsTable
                    suggestions={findedClients}
                    onUseThisClient={useClientFromSuggestions}
                  />
                  <div style={{ marginBottom: '1.2rem' }} />
                </>
              ) : null}
            </div>
          )}
        </div>
        <div
          className={
            clientFormValues?.id?.value
              ? 'clientNotesTagsWrapper notesTagsWrapper'
              : 'notesTagsWrapper'
          }
        >
          <div className='client-tags-wrapper'>
            {clientFormValues?.id?.value && (
              <Tags
                maxWidth='337px'
                route={tagRoute}
                showContent={true}
                id={clientFormValues?.id?.value}
              />
            )}
          </div>
          <div className='form-group-notes'>
            <AccessControl scopes={[NotesPermissions.NOTES_CAN_READ]}>
              {clientFormValues.id?.value ? (
                <Notes
                  // customBtnStyle={{ position: 'absolute', right: '0', top: '-30px' }}
                  route={`/${
                    clientFormValues?.type?.value === 1
                      ? 'organizations'
                      : 'clients'
                  }/${clientFormValues.id.value}/notes`}
                />
              ) : (
                <AccessControl scopes={[NotesPermissions.NOTES_CAN_CREATE]}>
                  <InputField
                    size='small'
                    variant='outlined'
                    label={notesLabel}
                    multiline
                    maxRows={5}
                    name='note'
                    style={{ maxWidth: '300px' }}
                    value={clientFormValues.note.value || ''}
                    error={
                      clientFormValues.note.value.length === 499 && {
                        note: ['Max length is 499 characters'],
                      }
                    }
                    onChange={e => onChange(e)}
                    inputProps={{
                      maxLength: 499,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </AccessControl>
              )}
            </AccessControl>
          </div>
        </div>
      </div>
    </>
  )
}

export default PersonalInfo
