import { Companies } from 'types/Company'

//TYTUM HVAC
export const tytumHvacDispetchers = [2776, 2450]

//Companies with language switcher
export const companiesWithLanguageSwitcher = [
  Companies.Sony,
  Companies['Asia Shvei Prom New'],
  Companies.HVAC,
  Companies['SD Hair Extensions Studio'],
]

export const companiesWithDefaultRuLanguage = [Companies['Asia Shvei Prom New']]

//Google Drive integration
export const companiesWithGoogleDrive = [
  Companies['Safety Ney'],
  Companies['HVAC'],
  Companies.Sony,
  Companies.REMOVAL_PRO,
]

export const usersWithGoogleDrive = [1316]
