import { Button, Checkbox, DatePicker, Select, Upload } from 'antd'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import styled from 'styled-components'
import { Component } from '../types'
import CanvasDraw from 'react-canvas-draw'
import { UploadOutlined } from '@ant-design/icons'
import moment from 'moment-timezone'
import SettingsRow from 'components/UI/SettingsRow'
// import { ViewFile } from 'helpers/viewFile/viewFile'
// import { Media } from 'types/Media'
import { PictureSize } from 'features/Forms/types'

const message =
  'This field is required. Please enter the necessary information before proceeding.'
// const fileStyle = {
//   width: '500px',
//   height: '500px',
//   borderRadius: '4px',
//   cursor: 'zoom-in',
// }
// const dellIconStyle = { fontSize: '1.2rem' }

export const getComponentByType = (
  component: Component,
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    component: Component,
  ) => void,
  error: Component,
  onChangeSignature: (canvas: CanvasDraw, component: Component) => void,
  handleUploadFile: (opt: any, component: Component) => void,
  // handleDeleteFile: (file: Media, component: Component) => void,
  print = false,
  primaryFieldId?: string,
  // usePreviewForImage?: boolean,
) => {
  switch (component?.type) {
    case 'text':
      return (
        <SettingsRow
          boldTitle={component.id === primaryFieldId}
          required={component?.required}
          title={component?.label}
        >
          <div>
            <InputField
              multiline={component.subtype === 'text'}
              onChange={e => onChange(e, component)}
              type={component.subtype}
              value={component.value}
              inputProps={{
                style: {
                  textTransform: component.uppercase ? 'uppercase' : 'initial',
                },
              }}
            />
            {!!error.error && (
              <ErrorRow error={error?.error}>{message}</ErrorRow>
            )}
          </div>
        </SettingsRow>
      )

    case 'note':
      return (
        <ComponentWrapper>
          {component.without_label ? (
            <ImageDescription
              align={component.descriptionAlign || 'left'}
              fontSize={component.descriptionSize || 14}
              fontWeight={component.descriptionWeight || 400}
            >
              {component.value}
            </ImageDescription>
          ) : (
            <SettingsRow
              boldTitle={component.id === primaryFieldId}
              required={component?.required}
              title={component?.label}
            >
              <ImageDescription
                align={component.descriptionAlign || 'left'}
                fontSize={component.descriptionSize || 14}
                fontWeight={component.descriptionWeight || 400}
              >
                {component.value}
              </ImageDescription>
            </SettingsRow>
          )}
        </ComponentWrapper>
      )

    case 'date':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === primaryFieldId}
            required={component?.required}
            title={component?.label}
          >
            <div>
              <DatePicker
                value={component.value ? moment(component.value) : ''}
                onChange={(date, dateStr) =>
                  onChange({ target: { value: dateStr } }, component)
                }
                format='MM/DD/YYYY'
              />
              {!!error.error && (
                <ErrorRow error={error?.error}>{message}</ErrorRow>
              )}
            </div>
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'select':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === primaryFieldId}
            required={component?.required}
            title={component?.label}
          >
            <div>
              <Select
                onChange={value => onChange({ target: { value } }, component)}
                options={component.options}
                style={{ width: 300 }}
                value={component.value}
                dropdownClassName='my-select-dropdown'
              />
              {!!error.error && (
                <ErrorRow error={error?.error}>{message}</ErrorRow>
              )}
            </div>
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'checkbox_group':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === primaryFieldId}
            required={component?.required}
            title={component?.label}
          >
            <Checkbox.Group
              value={component.value}
              options={component.options}
              onChange={value => onChange({ target: { value } }, component)}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
            {!!error.error && (
              <ErrorRow error={error?.error}>{message}</ErrorRow>
            )}
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'signature': {
      const renderSignature = () =>
        !print ? (
          <CanvasDraw
            brushRadius={1}
            brushColor='#000000'
            canvasWidth={400}
            onChange={canvas => onChangeSignature(canvas, component)}
            canvasHeight={200}
            hideGrid={true}
            hideInterface={true}
            imgSrc={component.value}
            loadTimeOffset={0}
            className='signature-canvas-container'
            style={{
              cursor: 'pointer',
              border: `1px solid grey`,
              borderRadius: '8px',
            }}
          />
        ) : !component.value ? (
          <p>no Signature</p>
        ) : (
          <img
            style={{
              border: '1px solid #cdcbcb',
              borderRadius: 4,
              width: '100%',
            }}
            src={component.value}
          />
        )
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === primaryFieldId}
            required={error?.required}
            title={component?.label}
          >
            <div>
              {renderSignature()}
              {!!error.error && (
                <ErrorRow error={error?.error}>{message}</ErrorRow>
              )}
            </div>
          </SettingsRow>
        </ComponentWrapper>
      )
    }

    case 'upload':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === primaryFieldId}
            required={error?.required}
            title={component?.label}
          >
            <div>
              <UploadWrapper>
                {!print && (
                  <Upload
                    multiple={component?.multiple}
                    listType='picture'
                    customRequest={opt => handleUploadFile(opt, component)}
                    showUploadList={false}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload file{component.multiple && 's'}
                    </Button>
                  </Upload>
                )}
                {!!component.value?.length && (
                  <FilesWrapper>
                    {component?.value?.map(file => (
                      /*  <ViewFile
                        key={file?.id}
                        file={file}
                        showName={true}
                        fileStyle={fileStyle}
                        dellIconStyle={dellIconStyle}
                        onDelete={
                          !print
                            ? () => handleDeleteFile(file, component)
                            : undefined
                        }
                        isFullViewNeeded={true}
                        isDellConfirmation={true}
                        customPreviewLink={
                          usePreviewForImage ? undefined : file.source
                        }
                      /> */
                      <div key={file.id}>
                        <div
                          style={{
                            width: 500,
                            height: 500,
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                            src={file.source}
                          />
                        </div>

                        <p
                          style={{
                            width: 500,
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                          }}
                        >
                          {file.display_name}
                        </p>
                      </div>
                    ))}
                  </FilesWrapper>
                )}
              </UploadWrapper>
              {!!error.error && (
                <ErrorRow error={error?.error}>{message}</ErrorRow>
              )}
            </div>
          </SettingsRow>
        </ComponentWrapper>
      )

      {
        /* <div key={file.id}>
                        <div
                          style={{
                            width: 500,
                            height: 500,
                            overflow: 'hidden',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            style={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                            src={file.source}
                          />
                        </div>

                        <p
                          style={{
                            width: 500,
                            textOverflow: 'ellipsis',
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                          }}
                        >
                          {file.display_name}
                        </p>
                      </div> */
      }

    case 'picture':
      return (
        <ComponentWrapper>
          {component.without_label ? (
            <>
              <ImageWrapper align={component.pictureAlign || 'flex-start'}>
                <Image
                  size={component.size || PictureSize.S}
                  value={component.value?.preview_source || ''}
                />
              </ImageWrapper>
              {component.description && (
                <ImageDescription
                  align={component.descriptionAlign || 'left'}
                  fontSize={component.descriptionSize || 14}
                  fontWeight={component.descriptionWeight || 400}
                >
                  {component.description}
                </ImageDescription>
              )}
            </>
          ) : (
            <SettingsRow
              boldTitle={component.id === primaryFieldId}
              required={component?.required}
              title={component?.label}
            >
              <ImageWrapper align={component.pictureAlign || 'flex-start'}>
                <Image
                  size={component.size || PictureSize.S}
                  value={
                    component.value?.preview_source ||
                    '/assets/images/placeholder-image.png'
                  }
                />
              </ImageWrapper>
              {component.description && (
                <ImageDescription
                  align={component.descriptionAlign || 'left'}
                  fontSize={component.descriptionSize || 14}
                  fontWeight={component.descriptionWeight || 400}
                >
                  {component.description}
                </ImageDescription>
              )}
            </SettingsRow>
          )}
        </ComponentWrapper>
      )

    default:
      return (
        <SettingsRow
          boldTitle={component.id === primaryFieldId}
          required={component?.required}
          title={component?.label}
        >
          <div>
            <InputField
              value={component.value}
              onChange={e => onChange(e, component)}
              label={component?.label}
            />
            {!!error.error && (
              <ErrorRow error={error?.error}>{message}</ErrorRow>
            )}
          </div>
        </SettingsRow>
      )
  }
}

const ComponentWrapper = styled.div`
  & .my-select-dropdown {
    z-index: 99999;
  }
`
const UploadWrapper = styled.div`
  .ant-upload-list-picture-container {
    max-width: 200px;
  }
`
interface ErrorRowProps {
  error?: boolean
}

const ErrorRow = styled.div<ErrorRowProps>`
  font-size: 12px;
  color: ${({ error }) => (error ? 'red' : 'transparent')};
`
const FilesWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
`

const ImageWrapper = styled.div<{
  align: 'flex-start' | 'flex-end' | 'center'
}>`
  display: flex;
  justify-content: ${props => props.align};
`

const Image = styled.div<{
  size: PictureSize
  value: string
}>`
  background-image: url(${props => props.value});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props =>
    props.value === '/assets/images/placeholder-image.png'
      ? '1px solid #ebebeb'
      : 'none'};
  border-radius: 4px;
`

const ImageDescription = styled.div<{
  fontSize: number
  fontWeight: number
  align: string
}>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.align};
  white-space: pre-wrap;
  line-height: 1.33;
`
