import { Select } from 'antd'
import { selectDispatchSettings } from 'features/Dispatch/dispatchSlice'
import { EventColorType } from 'features/Dispatch/types'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompanyTypes } from 'store/Orcatec/selectors/company'
import styled from 'styled-components'

const { Option } = Select

interface IEventType {
  id: number
  deleted_at: string | null
  name: string
  color: {
    hex: string
  }
}

interface Props {
  placeholder?: string
  value: number[]
  onChange: (choosenEmployees: number[]) => void
}

export const EventType = ({
  placeholder = 'Show all',
  value,
  onChange,
}: Props) => {
  const eventTypes: IEventType[] = useAppSelector(selectCompanyTypes)
  const { event_color_type } = useAppSelector(selectDispatchSettings)

  const hideTypeColor = event_color_type !== EventColorType['Event type']

  return (
    <Select
      mode='multiple'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: '100%' }}
      allowClear
    >
      {eventTypes
        ?.filter(type => !type.deleted_at)
        ?.map(type => (
          <Option key={type.id} value={type.id} label={type.name}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!hideTypeColor && (
                <Color color={type?.color?.hex || '#4B94D8'} />
              )}

              {type.name}
            </div>
          </Option>
        ))}
    </Select>
  )
}

const Color = styled.div`
  background-color: ${props => props?.color || '#626ed4'};
  height: 20px;
  width: 25px;
  border-radius: 4px;
  border: 1px solid;
  margin-right: 10px;
`
