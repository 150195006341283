import ReactJoyride, { CallBackProps, Step } from 'react-joyride'
import { TooltipComponent } from './TooltipComponent'

interface Props {
  isActive: boolean
  steps: Step[]
  stepIndex: number
  disableScrolling?: boolean
  onCallback: (data: CallBackProps) => void
}

export const UserGuide = ({
  isActive,
  steps,
  stepIndex,
  disableScrolling = false,
  onCallback,
}: Props) => {
  function handleChange(data: CallBackProps) {
    onCallback?.(data)
  }

  return (
    <ReactJoyride
      run={isActive}
      styles={{
        options: {
          // arrowColor: '#e3ffeb',
          // backgroundColor: '#e3ffeb',
          // overlayColor: 'rgba(79, 26, 0, 0.4)',
          primaryColor: '#4285f4',
          // textColor: '#004a14',
          // width: 900,
          zIndex: 9999,
        },
      }}
      tooltipComponent={TooltipComponent}
      stepIndex={stepIndex}
      steps={steps}
      continuous
      showSkipButton
      callback={handleChange}
      showProgress
      disableScrolling={disableScrolling}
      // hideCloseButton
    />
  )
}
