import { useState } from 'react'
import { SubMenu } from 'containers/MainContent/Orcatec/components/SubMenu/SubMenu'
import TableLayout, {
  AccoutingTitleWrapper,
} from '../../containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { FormBuilder } from 'features/Forms/FormBuilder/FormBuilder'
import { FormsTable } from 'features/Forms/tables/FormsTable/FormsTable'
import { DetailsTable } from 'features/Forms/tables/DetailsTable/DetailsTable'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { NoAccessResult } from 'features/Settings/UsersAndGroups/components/NoAccessResult/NoAccessResult'
import { useLocation } from 'react-router-dom'

export enum CustomFormsTabs {
  FORMS,
  DETAILED,
  TEMPLATES,
}

interface LocationState {
  activeTab: number
}

const title = 'Forms'

const menuItems = [
  {
    id: CustomFormsTabs.FORMS,
    title: 'All',
  },

  {
    id: CustomFormsTabs.DETAILED,
    title: 'Detailed',
  },
  {
    id: CustomFormsTabs.TEMPLATES,
    title: 'Templates',
  },
]
export const CustomFormPage = () => {
  const location = useLocation<LocationState>()

  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.FORMS),
  )
  const [activeTab, setActiveTab] = useState<CustomFormsTabs>(
    location?.state?.activeTab || CustomFormsTabs.FORMS,
  )
  const handleChangeTabs = (tabId: CustomFormsTabs) => setActiveTab(tabId)

  const renderContent = () => {
    if (CustomFormsTabs.FORMS === activeTab) {
      return !!permissions?.forms_list_can_read ? (
        <FormsTable />
      ) : (
        <NoAccessResult />
      )
    }
    if (CustomFormsTabs.TEMPLATES === activeTab) {
      return !!permissions?.forms_template_list_can_read ? (
        <FormBuilder />
      ) : (
        <NoAccessResult />
      )
    }
    if (CustomFormsTabs.DETAILED === activeTab) {
      return !!permissions?.forms_detailed_list_can_read ? (
        <DetailsTable />
      ) : (
        <NoAccessResult />
      )
    }
  }
  return (
    <>
      <TableLayout
        title={
          <AccoutingTitleWrapper>
            <p>{title}</p>
            <SubMenu
              activeItem={activeTab}
              menuItemsList={menuItems}
              onChange={handleChangeTabs}
            />
          </AccoutingTitleWrapper>
        }
      >
        {renderContent()}
      </TableLayout>
    </>
  )
}
