import styled from 'styled-components'
import { Error } from 'types/Error'
import { weekPrefix } from '../../helpers/helpers'
import { useTranslation } from 'react-i18next'

type SelectedDay = {
  weekNumber: number
  day: number
}
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
interface Props {
  value: SelectedDay[]

  onChange: (e: {
    target: {
      name: 'each'
      value: SelectedDay[]
    }
  }) => void
  error: Error | null
  disabled?: boolean
}

export const MonthDaysSelector = ({
  value,
  onChange,
  error,
  disabled,
}: Props) => {
  const { t } = useTranslation('recurrence')

  const handleDayToggle = (weekNumber: number, day: number) => {
    if (disabled) return

    const isSelected = value.some(
      selectedDay =>
        selectedDay.weekNumber === weekNumber && selectedDay.day === day,
    )

    onChange({
      target: {
        name: 'each',
        value: isSelected
          ? value.filter(
              selectedDay =>
                !(
                  selectedDay.weekNumber === weekNumber &&
                  selectedDay.day === day
                ),
            )
          : [...value, { weekNumber, day }],
      },
    })
  }

  const renderDaysForWeek = (weekNumber: keyof typeof weekPrefix) => {
    return (
      <Week>
        <Prefix>{weekPrefix[weekNumber]}</Prefix>
        {daysOfWeek.map((day, index) => (
          <Day
            key={day}
            checked={value.some(
              selectedDay =>
                selectedDay.weekNumber === weekNumber &&
                selectedDay.day === index,
            )}
            onClick={() => handleDayToggle(weekNumber, index)}
            error={!!error?.each}
            disabled={disabled}
          >
            {daysOfWeek[index][0]}
          </Day>
        ))}
      </Week>
    )
  }

  return (
    <Wrapper>
      <Heading>{t('modal.chooseDaysOfWeek')}:</Heading>
      {([1, 2, 3, 4] as const).map(num => renderDaysForWeek(num))}
      {error?.each && <ErrorText>* {t('modal.chooseDaysOfWeek')}</ErrorText>}
    </Wrapper>
  )
}

const Week = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  width: 300px;
`
const Wrapper = styled.div``
const Heading = styled.p`
  color: rgba(0, 0, 0, 0.54);
  font-size: 13px;
  margin-bottom: 5px;
`

const Prefix = styled.p`
  flex-basis: 25px;
`
const ErrorText = styled.p`
  color: #ee6161;
  font-size: 12px;
`

// const Day = styled.p<{ checked: boolean; error: boolean; disabled?: boolean }>`
//   display: flex;
//   border: 1px solid ${({ error }) => (error ? 'red' : '#000')};
//   border-radius: 50%;
//   background-color: ${({ checked, disabled }) =>
//     !checked ? '#fff' : disabled ? '#bcbbbb' : '#4285f4'};
//   color: ${({ checked }) => (checked ? '#fff' : '#000')};
//   width: 30px;
//   height: 30px;
//   justify-content: center;
//   align-items: center;
//   cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
// `
const Day = styled.p<{ checked: boolean; error?: boolean; disabled?: boolean }>`
  display: flex;
  border: 1px solid ${({ error }) => (error ? '#ee6161' : '#fff')};
  border-radius: 50%;
  background-color: ${({ checked, disabled }) =>
    !checked ? 'rgb(241, 243, 244)' : disabled ? '#bcbbbb' : '#4285f4'};
  color: ${({ checked }) => (checked ? '#fff' : '#70757a')};
  /* background-color: rgb(241, 243, 244);
    color: #70757a; */
  flex: 0 0 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 12px;
`
