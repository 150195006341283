import { Table, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import states from 'helpers/states'
import { useMemo } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import { PropertyAddress } from '../../types'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  table {
    font-size: 0.75rem;

    .pointer {
      cursor: pointer;
    }
  }
`

type Props = {
  data: PropertyAddress[]
  loading: boolean
  publicView?: boolean
  onChooseAddress: (property: PropertyAddress) => void
  // hideDropdown: () => void
}

export const AddressesTable = ({
  data,
  loading,
  publicView,
  onChooseAddress /* hideDropdown */,
}: Props) => {
  const { t } = useTranslation('property')

  const company = useAppSelector(state => state.orcatec.company)
  const columns: ColumnsType<PropertyAddress> = useMemo(
    () =>
      [
        {
          title: t('searchDropdown.table.address'),
          dataIndex: 'address',
          render: (address: string) => (
            <Tooltip title={address}>{address}</Tooltip>
          ),
        },
        {
          title: t('searchDropdown.table.city'),
          dataIndex: 'city',
          width: 120,
        },
        {
          title: t('searchDropdown.table.zip'),
          dataIndex: 'postcode',
          width: 60,
        },
        {
          title: t('searchDropdown.table.state'),
          dataIndex: 'state',
          width: 50,
          align: 'center' as const,
          render: (state: string) => states[state] || state,
        },
        {
          title: t('searchDropdown.table.clientName'),
          dataIndex: 'client_name',
          hidden: publicView,
          // width: 120,
        },
        {
          title: t('searchDropdown.table.projectName'),
          dataIndex: 'property_relation',
          hidden: company.id !== 264,
        },
        {
          title: t('searchDropdown.table.info'),
          dataIndex: 'clients',
          colSpan: 3,
          width: 50,
          render: (clients: string[]) => renderIcon(clients, 'account-circle'),
          hidden: publicView,
        },
        {
          title: t('searchDropdown.table.appointments'),
          dataIndex: 'appointments',
          colSpan: 0,
          width: 45,
          render: (appointments: string[]) =>
            renderIcon(appointments, 'calendar-blank'),
          hidden: publicView,
        },
        {
          title: t('searchDropdown.table.projects'),
          dataIndex: 'proposals',
          colSpan: 0,
          width: 55,
          render: (proposals: string[]) =>
            renderIcon(proposals, 'clipboard-text'),
          hidden: publicView,
        },
      ].filter(col => !col.hidden),
    [],
  )

  const handleRowClick = (record: PropertyAddress) => ({
    onClick: () => {
      onChooseAddress?.(record)
      // hideDropdown?.()
    },
  })

  return (
    <Wrapper>
      <Table
        dataSource={data}
        columns={columns}
        size='small'
        pagination={false}
        loading={loading}
        scroll={{ y: 250 }}
        onRow={handleRowClick}
        rowClassName='pointer'
      />
    </Wrapper>
  )
}

const renderIcon = (entity: string[], icon: string) => {
  if (!entity) return
  return (
    <Tooltip
      title={
        !!entity?.length &&
        entity.map((item: string, idx) => <p key={idx}>{item}</p>)
      }
    >
      <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
        <i style={{ fontSize: 20 }} className={`mdi mdi-${icon}`} />
        <span>({entity?.length})</span>
      </div>
    </Tooltip>
  )
}
