import { useRef } from 'react'
import { ProjectView } from '../types'
import {
  Content,
  ContentSwitcher,
  SidebarWrapper,
  SidebarContent,
  SwitcherButton,
  Wrapper,
  Sidebar,
} from './ProjectLayout.styles'
import useOnScreen from 'hooks/useOnScreen'

interface Props {
  children: JSX.Element
  sidebarContent: JSX.Element | null
  onProjectViewChange: (view: ProjectView) => void
  projectView: ProjectView
}

export const ProjectLayout = ({
  children,
  sidebarContent,
  onProjectViewChange,
  projectView,
}: Props) => {
  const sidebarRef = useRef(null)

  const isVisible = useOnScreen(sidebarRef, {
    threshold: 1,
    // rootMargin: '-20px 0px 0px 0px',
  })

  return (
    <Wrapper isEditView={projectView === ProjectView.EDIT}>
      <Content>{children}</Content>
      <SidebarWrapper>
        <Sidebar ref={sidebarRef} isSticky={isVisible}>
          <ContentSwitcher data-testid='project--switcher'>
            <SwitcherButton
              active={projectView === ProjectView.EDIT}
              onClick={() => onProjectViewChange(ProjectView.EDIT)}
            >
              Edit
            </SwitcherButton>
            <SwitcherButton
              active={projectView === ProjectView.PREVIEW}
              onClick={() => onProjectViewChange(ProjectView.PREVIEW)}
            >
              Preview
            </SwitcherButton>
          </ContentSwitcher>

          <SidebarContent>{sidebarContent}</SidebarContent>
        </Sidebar>
      </SidebarWrapper>
    </Wrapper>
  )
}
