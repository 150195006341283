import React, { useState, useEffect, useMemo } from 'react'

import { Tooltip } from 'antd'
import { Table } from 'containers/MainContent/Orcatec/components/Table/Table'
import { getDefaultTaxesList } from 'api/settings/Proposal'
import useDebounce from 'hooks/useDebounce'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import { ColumnProps } from 'antd/lib/table'
import TableLayout, {
  IconWrapper,
  ActionsWrapper,
  IconsBlock,
} from 'containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Settings } from '@material-ui/icons'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'

import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table/interface'

import { getValueFromLS } from 'hooks/useLocalStorage'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

interface CustomColumnProps<T> extends ColumnProps<T> {
  name: string
  defaultWidth: number
  noFilter?: boolean
}

interface IDefaultTaxes {
  created_at: string
  estimated_city_rate: string
  estimated_combined_rate: string
  estimated_country_rate: string
  estimated_special_rate: string
  id: number
  risk_level: number
  state: string
  state_rate: string
  tax_region_name: string
  updated_at: string
  zip_code: string
  key: string
}

const name = 'default-taxes-list'

export const DefaultTaxRate = () => {
  const settingsFromLC = getValueFromLS(`${name}-table_v1`)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 10,
  })
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)
  const [openTableSettings, setOpenTableSettings] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const debouncedSearchValue = useDebounce(search, 500)
  const fetchTaxes = async params => {
    setLoading(true)
    try {
      const { taxes, meta } = await getDefaultTaxesList(params)

      setData(taxes)
      setPagination(meta)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof IDefaultTaxes, string[]>>,
    sorter: SorterResult<IDefaultTaxes>,
  ) => {
    fetchTaxes({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      ...filters,
    })

    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })
  }

  const onRow = (record: IDefaultTaxes, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      setIsFullScreen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  // const handleExportTable = () => {
  //   setLoading(true)
  //   exportTableExpenses({
  //     search: debouncedSearchValue || undefined,
  //     sortField,
  //     sortOrder,
  //     ...tableFilters,
  //     columns: settingsFromLC?.columns,
  //   })
  //     .catch(e => openNotificationWithIcon('error', { message: e?.message }))
  //     .finally(() => setLoading(false))
  // }

  useEffect(() => {
    fetchTaxes({
      per_page: 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  const columns: CustomColumnProps<IDefaultTaxes>[] = useMemo(
    () => [
      {
        title: () => (
          <Tooltip placement='topLeft' title='State'>
            State
          </Tooltip>
        ),
        name: 'State',
        dataIndex: 'state',
        sorter: true,
        defaultWidth: 100,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['state']) => <span>{value}</span>,
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title='State Rate'>
            State Rate
          </Tooltip>
        ),
        name: 'State Rate',
        dataIndex: 'state_rate',
        sorter: true,
        defaultWidth: 100,
        noFilter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['state_rate']) => <span>{value}</span>,
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title='ZIP Code'>
            ZIP Code
          </Tooltip>
        ),
        name: 'ZIP Code',
        dataIndex: 'zip_code',
        sorter: true,
        defaultWidth: 100,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['zip_code']) => <span>{value}</span>,
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title='Tax Region Name'>
            Tax Region Name
          </Tooltip>
        ),
        name: 'Tax Region Name',
        dataIndex: 'tax_region_name',
        sorter: true,
        defaultWidth: 100,

        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['tax_region_name']) => (
          <span>{value}</span>
        ),
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title=' Combined Rate'>
            Combined Rate
          </Tooltip>
        ),
        name: ' Combined Rate',
        dataIndex: 'estimated_combined_rate',
        sorter: true,
        defaultWidth: 100,
        noFilter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['estimated_combined_rate']) => (
          <span>{value}</span>
        ),
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title=' Special Rate'>
            Special Rate
          </Tooltip>
        ),
        name: ' Special Rate',
        dataIndex: 'estimated_special_rate',
        sorter: true,
        defaultWidth: 100,
        noFilter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['estimated_special_rate']) => (
          <span>{value}</span>
        ),
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title=' Country Rate'>
            Country Rate
          </Tooltip>
        ),
        name: ' Country Rate',
        dataIndex: 'estimated_country_rate',
        noFilter: true,
        sorter: true,
        defaultWidth: 100,
        onCell: (record: IDefaultTaxes) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['estimated_country_rate']) => (
          <span>{value}</span>
        ),
      },
      {
        title: () => (
          <Tooltip placement='topLeft' title='Risk Level'>
            Risk Level
          </Tooltip>
        ),
        name: 'Risk Level',
        dataIndex: 'risk_level',
        noFilter: true,
        sorter: true,
        defaultWidth: 100,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: IDefaultTaxes['risk_level']) => <span>{value}</span>,
      },
    ],
    [],
  )

  return (
    <TableLayout
      isFullScreen={isFullScreen}
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      actions={
        <ActionsWrapper>
          <IconsBlock>
            <InputField
              label='Search'
              value={search}
              style={{ width: '100%', maxWidth: '200px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
            />
            <IconWrapper
              onKeyDown={handleKeyPress}
              onClick={() => setIsFullScreen(prev => !prev)}
            >
              {isFullScreen ? (
                <FullscreenExitOutlined style={{ fontSize: '22px' }} />
              ) : (
                <FullscreenOutlined style={{ fontSize: '22px' }} />
              )}
            </IconWrapper>

            {/* <TableExport
              handleExportTable={handleExportTable}
              loading={loading}
              currentCount={pagination.total}
            /> */}

            <IconWrapper onClick={() => setOpenTableSettings(true)}>
              <Tooltip placement='left' title='Columns settings'>
                <Settings />
              </Tooltip>
            </IconWrapper>
          </IconsBlock>
        </ActionsWrapper>
      }
    >
      <Table
        name={name}
        pagination={pagination}
        loading={loading}
        columns={columns}
        data={data}
        onChange={handleTableChange}
        onRow={onRow}
        search={debouncedSearchValue}
        openTableSettings={openTableSettings}
        onCloseTableSettings={() => setOpenTableSettings(false)}
        rowKey={obj => obj?.key?.toString()}
      />
    </TableLayout>
  )
}
