import {
  EquipmentModuleIcon,
  ModuleOneIcon,
  ModuleThreeIcon,
  ModuleTwoIcon,
  PaymentModuleIcon,
} from 'containers/MainContent/Orcatec/components/Icons/ProposalModuleIcons'
import ModalOrderEquipmentNew from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalOrderEquipments/ModalOrderEquipmentNew'
import ModalReminderContainer from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalReminderContainer'

import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { updateProjectTabField } from 'features/Project/slices/projectTabsSlice'
import { ProjectStatusGroup } from 'features/Project/types'
import { onClearOptionalModuleValues } from 'store/Orcatec/actions/proposal/proposalForm'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
// import { selectCompany } from 'store/Orcatec/selectors/company'
// import { selectAuthUser } from 'store/Orcatec/selectors/user'
import styled from 'styled-components'
// import { Companies } from 'types/Company'
// import { UserRole } from 'types/User'
import { useModulePermissions } from './useModulePermissions'
import { Tooltip } from 'antd'
// import useTechnicians from 'hooks/useTechnicians'

export const ProjectModules = ({ onOpenPaymentModule }) => {
  const dispatch = useAppDispatch()

  const permission = useModulePermissions()

  // const company = useAppSelector(selectCompany)
  // const me = useAppSelector(selectAuthUser)
  const project = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const modalOrderSupplyIsOpen = useAppSelector(
    state => state.orcatec.modalEquipment.isOpen,
  )

  const MODULES = [
    {
      name: 'Payment',
      icon: <PaymentModuleIcon status={currentTab.payment_status} />,
      disabled: !permission.payment,
      hasPermission: permission.payment,
    },
    {
      name: 'Supplies',
      icon: <EquipmentModuleIcon status={currentTab.status} />,
      disabled:
        project.status === ProjectStatusGroup.Completed || !permission.supplies,
      hasPermission: permission.supplies,
    },
    {
      name: currentTab.modules[0].name,
      icon: <ModuleOneIcon status={currentTab.modules[0].status} />,
      disabled:
        project.status === ProjectStatusGroup.Completed || !permission.module_1,
      hasPermission: permission.module_1,
    },
    {
      name: currentTab.modules[1].name,
      icon: <ModuleTwoIcon status={currentTab.modules[1].status} />,
      disabled:
        project.status === ProjectStatusGroup.Completed || !permission.module_2,
      hasPermission: permission.module_2,
    },
    {
      name: currentTab.modules[2].name,
      icon: <ModuleThreeIcon status={currentTab.modules[2].status} />,
      disabled:
        project.status === ProjectStatusGroup.Completed || !permission.module_3,
      hasPermission: permission.module_3,
    },
  ]

  const isModuleDisabled =
    project.status === ProjectStatusGroup.Canceled ||
    ([ProjectStatusGroup.Contract, ProjectStatusGroup.Completed].includes(
      project.status,
    ) &&
      project.signed_tab_id !== currentTab.id)

  const handleModuleClick = (index: number) => {
    // if (MODULES[index]?.disabled) return
    // if (
    //   index === 4 &&
    //   company.id === Companies['Big Joe Appliance Repair Service'] &&
    //   ![1337, 1339, 1340].includes(me.id) &&
    //   ![
    //     UserRole.OWNER,
    //     UserRole.SUPER_ADMIN,
    //     UserRole.DEFAULT_SUPER_ADMIN,
    //   ].includes(me.role_id)
    // )
    //   return

    if (index === 0) {
      return onOpenPaymentModule()
    }
    if (index === 1) {
      dispatch({ type: 'OPEN_MODAL_EQUIPMENT' })
      return
    }
    if (index === 2) {
      dispatch({ type: 'OPEN_MODAL_OPTIONAL_FIRST' })
      return
    }
    if (index === 3) {
      dispatch({ type: 'OPEN_MODAL_OPTIONAL_SECOND' })
      return
    }
    if (index === 4) {
      dispatch({ type: 'OPEN_MODAL_OPTIONAL_THIRD' })
      return
    }
  }

  const handleModuleChange = async (
    _: number,
    moduleIdx: number,
    filedName: string,
    value: string,
  ) => {
    const newModules = [...currentTab.modules]
    const updatingModule = { ...newModules[moduleIdx], [filedName]: value }
    newModules[moduleIdx] = updatingModule

    dispatch(
      updateProjectTabField({
        modules: newModules,
      }),
    )
  }

  const handleModuleReset = () => {
    dispatch(onClearOptionalModuleValues())
  }

  return (
    <Wrapper data-testid='project--modules'>
      {MODULES.map((module, index) => (
        <Tooltip
          key={index}
          title={
            !module.hasPermission &&
            `You don't have permission to edit this module`
          }
        >
          <Module
            onClick={() => {
              if (module.disabled || isModuleDisabled) return

              handleModuleClick(index)
            }}
            disabled={module.disabled || isModuleDisabled}
          >
            {module.icon}
            <span>{module.name}</span>
          </Module>
        </Tooltip>
      ))}

      {modalOrderSupplyIsOpen && (
        <ModalOrderEquipmentNew status={currentTab.status || 1} />
      )}

      <ModalReminderContainer
        module={0}
        onChange={handleModuleChange}
        // onChange={this.props.onChangeOptionalModuleValues}
        activeTab={project.activeTab}
        values={currentTab?.modules?.[0]}
        onReset={handleModuleReset}
        // onSave={this.props.updateOptionalModules}
        proposalStatus={project.status}
      />
      <ModalReminderContainer
        module={1}
        onChange={handleModuleChange}
        activeTab={project.activeTab}
        values={currentTab?.modules?.[1]}
        onReset={handleModuleReset}
        // onSave={this.props.updateOptionalModules}
        proposalStatus={project.status}
      />
      <ModalReminderContainer
        module={2}
        onChange={handleModuleChange}
        activeTab={project.activeTab}
        values={currentTab?.modules?.[2]}
        onReset={handleModuleReset}
        // onSave={this.props.updateOptionalModules}
        proposalStatus={project.status}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  cursor: pointer;
`
const Module = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  span {
    font-size: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
