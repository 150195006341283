import {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  MutableRefObject,
  Fragment,
} from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Modal, Popconfirm } from 'antd'
import { useReactToPrint } from 'react-to-print'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import moment, { Moment } from 'moment-timezone'
import jobAPI, { createRecurringJobChild } from 'api/Job'
import { getRelateJobs } from 'store/Orcatec/actions/jobs'
import {
  AppointmentStatusID,
  EventKind,
  // RecurringInterval,
  // RecurringMonthlyType,
  ActionScope,
} from 'types/Appointment'
import { CloseOutlined } from '@ant-design/icons'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Job } from 'types/Jobs'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { AppointmentSection } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSection/AppointmentSection'
import { BlockRoutingModal } from 'containers/MainContent/Orcatec/components/BlockRoutingModal'
import LoadingScreen from 'containers/MainContent/Orcatec/components/LoadingScreen'
import JobView from 'containers/MainContent/Orcatec/Proposals/components/JobView'
import { setForm } from 'store/Orcatec/actions/proposal/proposalForm'
import {
  Heading,
  Wrapper,
  IndividualPrintStyle,
  HeaderWrapper,
} from './JobForm.styles'

import useDebounce from 'hooks/useDebounce'
// import { getMatrixLog, getMatrixQueues } from 'store/Orcatec/actions/matrix/matrixLog'
import { setCurrentAppointmentId } from 'store/Orcatec/actions/appointment'
// import { getActualDate } from 'api/Appointment'
import {
  isSameDate,
  makePromisedTimeEnd,
  makePromisedTimeStart,
} from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/helpers/helpers'
import { mapUpdateNeeded } from 'store/Orcatec/actions/map'
import { getTechnicians } from 'api/User'
import CustomDrawer from 'containers/MainContent/Orcatec/components/CustomDrawer/CustomDrawer'
import { getEmailData } from 'api/emailHistory'
import {
  eventDeleted,
  getMatrixLog,
  selectDispatchSettings,
  setMatrixEvent,
} from 'features/Dispatch/dispatchSlice'
import { isShowUpcomingNotificationSelector } from 'store/Orcatec/selectors/appointment'

import { ConfirmationModal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { getRoutesSettings } from 'api/map'
import AppointmentPreferredTech from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPreferredTech/AppointmentPreferredTech'
import { getPropertyById } from 'api/Property'
import { Companies } from 'types/Company'
import { useEventAssignToOptions } from 'hooks/useEventAssignToOptions'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import { getNotificationSettings } from 'api/NotificationModal'
import {
  NotificationTemplateType,
  NotificationModalType,
  NotificationTitleType,
} from 'features/Notification/types'
import useEventSettings from 'hooks/useEventSettings'
import { selectQueueSettings } from 'features/Dispatch/queueSlice'
import AppointmentPrintPDF from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentPrintPDF/AppointmentPrintPDF'
import useHashRouteToggle from 'hooks/useHashRouteToggle'
import { useColumns } from 'hooks/useColumns'

import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  JobPermissions,
  DispatchPermission,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import {
  itemsSet,
  resetProjectItemsSlice,
} from 'features/Project/slices/projectItemsSlice'
import { PublicEventModal } from 'features/PublicEvent'
import {
  selectModulePermissions,
  selectUserPermissionsByModule,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import { ReviewStatus } from 'features/Reviews/components/ReviewStatus/ReviewStatus'
import { gatherItemsFromSections } from 'features/Project/helpers'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { NotificationModalV2 } from 'features/Notification/modals/NotificationModal/NotificationModalV2'
import { GroupEvents } from 'features/Visits/components/Group/GroupEvents'
import { GroupModal } from 'features/Visits/components/Group/GroupModal'
import { Project } from 'features/Project/types'
import { EventGroupAPI, RecurringEventsAPI } from 'features/Visits/api'
import { getWorkOrder } from 'features/Project/slices/projectWorkOrdersSlice'
// import { INITIAL_EVENT_COUNT, LEAD_TIME } from 'features/Visits/constants'
import {
  RecurringModal,
  RecurringOptions,
} from 'features/Visits/components/Recurring/RecurringModal'
import ActionScopeModal from 'features/Visits/components/Recurring/components/ActionScopeModal'
import { RecurringEvents } from 'features/Visits/components/Recurring/RecurringEvents'
import { useTranslation } from 'react-i18next'

const initialJob: Job = {
  approximate_time: 1,
  appointment_type_id: null,
  column_template_id: [],
  company_start_time: null,
  company_end_time: null,
  count_workers: 1,
  count_days: 1,
  date: null,
  is_recurring: false,
  // each: [],
  // ending_options: {
  //   end_type: 1,
  //   value: null,
  // },
  event_name: '',
  // interval: RecurringInterval.DAY,
  // initial_event_count: INITIAL_EVENT_COUNT,
  // lead_time: LEAD_TIME,
  kind: EventKind.JOB,
  // monthly_type: RecurringMonthlyType.DAILY,
  notes: '',
  preferred_technician_id: null,
  proposal_id: 0,
  // repeat_every: 1,
  time_start: '',
  time_end: '',
  queue_id: [],
  timezone: null,
}

export const initialErrors: Error = {
  preferred_technician_id: null,
  matrix_time_end: null,
  matrix_time_start: null,
  appointment_source_id: null,
  appointment_type_id: null,
  column_template_id: null,
  time_end: null,
  time_start: null,
  date: null,
  start_date: null,
  ending_options: null,
}

type Props = {
  currentDate?: string | Moment
  jobId?: number
  proposalId?: number
  onJobCreated?: () => void
  onCancel?: () => void
  isSchedule?: boolean
  drawerView?: boolean
  onCloseDrawer?: () => void
  visibleDrawer?: boolean
  matrix?: boolean
  workOrderId?: number
  workOrderName?: string
  onGroupEventClick?: (event: object) => void
}

export type HandleJobProps = {
  cancelJob: () => void
  deleteJob: () => void
  dublicateJob: () => void
  updateJob: () => void
}

export type Ref = MutableRefObject<HTMLElement | null>

export const JobForm = forwardRef<Ref, Props>(
  (
    {
      jobId,
      proposalId,
      onJobCreated,
      onCancel,
      isSchedule,
      drawerView,
      onCloseDrawer,
      visibleDrawer,
      matrix,
      currentDate,
      workOrderId,
      workOrderName,
      onGroupEventClick,
    },
    ref,
  ) => {
    const { t } = useTranslation(['appointment', 'common'])

    const [job, setJob] = useState({
      ...initialJob,
      event_name: workOrderName || '',
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(initialErrors)
    const [edited, setEdited] = useState(false)
    const [proposalData, setProposalData] = useState<Project | null>(null)
    const [workers, setWorkers] = useState([])
    const [fullJob, setFullJob] = useState<Job | null>(null)
    const [blockedRouteParams, setBlockedRouteParams] = useState({
      blockRoutingModal: false,
      nextLocation: '',
    })
    const [showDropModal, setShowDropModal] = useState(false)
    const [showError, setShowError] = useState(false)
    const [renderCount, setRenderCount] = useState(0)
    const [projectLink, setProjectLink] = useState('')
    const [initialEvent, setInitialEvent] = useState<Job | null>(null)
    const [showNotificationModal, setShowNotificationModal] = useState(false)
    const [upcomingNotificationModal, showUpcomingNotificationModal] = useState(
      false,
    )
    const [justSendNotification, setJustSendNotification] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const [routesSettings, setRoutesSettings] = useState(null)
    const [property, setProperty] = useState(null)
    const me = useAppSelector(state => state.orcatec.user.me)
    const isSavedChangesFields = useAppSelector(
      state => state.orcatec.ui.wasEdit,
    )
    const dictionary = useAppSelector(selectJobDictionary)
    const permissions = useAppSelector(selectModulePermissions())

    const modulePermission = useAppSelector(
      selectUserPermissionsByModule(ModuleName.DISPATCH),
    )
    const isSoCalCompany = useAppSelector(
      state => state?.orcatec?.company?.id === 264,
    ) //id=264

    // const dispatchSettings = useAppSelector(state => state?.orcatec?.user?.me?.settings_matrix)
    const dispatchSettings = useAppSelector(selectDispatchSettings)

    const company = useAppSelector(state => state?.orcatec?.company)
    const isShowUpcomingNotification = useAppSelector(
      isShowUpcomingNotificationSelector,
    )
    const dispatch = useDispatch()
    const history = useHistory()
    const eventSettings = useEventSettings()
    const { columns } = useColumns(moment().format('MM/DD/YYYY'))
    const debouncedChanges = useDebounce(proposalData, 1500)

    const { data: assignToOptions } = useEventAssignToOptions(
      (Array.isArray(job.date) && !job.is_recurring
        ? job.date?.[0]
        : job.start_date || job.date) || currentDate,
    )
    const { defaultQueues } = useAppSelector(selectQueueSettings)
    const [isActive, toggleActive] = useHashRouteToggle('#log-sheet')
    const openModal = () => toggleActive(true)
    const [openPublicEventModal, setOpenPublicEventModal] = useState(false)
    const [publicEvent, setPublicEvent] = useState({})
    const [assignToUpdated, setAssignToUpdated] = useState(false)
    const [isShownRfRModal, setIsShownRfRModal] = useState(false)

    const [groupModal, showGroupModal] = useState(false)
    const [revalidateGroupEvents, setRevalidateGroupEvents] = useState(0)

    // const [recurrenceModal, showRecurrenceModal] = useState(false)
    // const [recurringEdited, setRecurringEdited] = useState(false)
    // const [actionScope, setActionScope] = useState('')

    const [
      recurringOptions,
      setRecurringOptions,
    ] = useState<RecurringOptions | null>(null)
    const [recurrenceModal, showRecurrenceModal] = useState(false)
    const [recurringEdited, setRecurringEdited] = useState(false)
    const [actionScope, setActionScope] = useState('')
    const [isProcessingRecurring, setIsProcessingRecurring] = useState(false)

    const handleOpenPublicEventModal = event => {
      setOpenPublicEventModal(true)
      setPublicEvent(event)
      setJob(prev => ({
        ...prev,
        count_public_links: prev?.count_public_links || 0 + 1,
      }))
    }

    const handleClosePublicEventModal = () => {
      setOpenPublicEventModal(false)
      setPublicEvent({})
    }

    useEffect(() => {
      if (!job.id && assignToOptions?.length) {
        setJob(prev => ({
          ...prev,
          column_template_id: defaultQueues?.['job']
            ? [defaultQueues?.['job'].toString()]
            : [],
        }))
      }
    }, [assignToOptions.length])

    useEffect(() => {
      if (job.proposal_id) {
        const getProjectLinkForCustomerView = async () => {
          try {
            const data = await getEmailData(job.proposal_id, 1)
            setProjectLink(data.link)
          } catch (error) {
            console.error(error.message)
          }
        }

        getProjectLinkForCustomerView()
      }
    }, [job.proposal_id])

    useEffect(() => {
      const fetchRoutesSettings = async () => {
        try {
          const settings = await getRoutesSettings()
          setRoutesSettings(settings)
        } catch (error) {
          console.error(error)
        }
      }

      fetchRoutesSettings()
    }, [])

    useEffect(() => {
      const fetchWorkers = async () => {
        const data = await getTechnicians()

        // setWorkers([...data, { id: 'misc', name: 'Misc' }])
        setWorkers(data)
      }

      fetchWorkers()
    }, [])

    //Effects
    useEffect(() => {
      if (jobId) fetchData()
    }, [jobId])

    useEffect(() => {
      if (proposalId) {
        setJob(prev => ({ ...prev, proposal_id: proposalId }))
      }
    }, [proposalId])

    useEffect(() => {
      setRenderCount(prev => prev + 1)

      if (renderCount > 1 && isSchedule) {
        handleUpdateJob()
      }
    }, [debouncedChanges])

    useEffect(() => {
      if (showDropModal && job?.confirm) {
        handleCreateJob()
      }
    }, [showDropModal, job?.confirm])

    useEffect(() => {
      const getRecurrenceSettings = async (parentId: number) => {
        const data = await RecurringEventsAPI.getRecurringSettings(parentId)

        setIsProcessingRecurring(!data.is_completed)
      }

      if (job.parent_id) getRecurrenceSettings(job.parent_id)
    }, [job.parent_id])

    useEffect(() => {
      const getRelatedProperty = async () => {
        try {
          if (job?.entity_type === 1 && job?.entity_id && job?.id) {
            const property = await getPropertyById(job.entity_id)
            setProperty(property)
          }
        } catch (error) {
          console.error(error)
        }
      }
      getRelatedProperty()
    }, [job?.id])
    //Methods

    const mapUpdateNeededHeandle = () => {
      dispatch(mapUpdateNeeded(true))
    }

    const isCreateWOVisit = workOrderId && !job?.id

    const handleCreateJob = async () => {
      if (
        (job.is_recurring && !job.column_template_id) ||
        !job?.column_template_id?.length
      )
        return setError({
          column_template_id: 'This field is required',
        })

      setLoading(true)

      const jobToCreate = {
        ...job,
        time_start: makePromisedTimeStart(
          !eventSettings?.show_promise_time,
          job,
        ),
        time_end: makePromisedTimeEnd(!eventSettings?.show_promise_time, job),
        matrix_time_start: job?.matrix_time_start
          ? moment(job?.matrix_time_start).format('HH:mm')
          : null,
        matrix_time_end: job?.matrix_time_end
          ? moment(job?.matrix_time_end).format('HH:mm')
          : null,
        current_start_time:
          job.matrix_time_start || job.matrix_time_start === 0
            ? moment(job.matrix_time_start).hours() +
              (dispatchSettings?.end_hour > 24 &&
              moment(job.matrix_time_start).hours() < 12
                ? 24
                : 0)
            : null,
        current_end_time:
          job.matrix_time_end === 0
            ? 24
            : job.matrix_time_end
            ? +moment(job.matrix_time_end).hours() +
              (dispatchSettings?.end_hour > 24 &&
              moment(job.matrix_time_end).hours() < 12
                ? 24
                : 0)
            : null,
        column_template_id: Array.isArray(job.column_template_id)
          ? job.column_template_id?.filter(value => typeof value === 'number')
          : typeof job.column_template_id === 'number'
          ? [job.column_template_id]
          : [],
        queue_id: Array.isArray(job.column_template_id)
          ? job.column_template_id
              ?.filter(value => typeof value === 'string')
              ?.map((value: string) => +value)
          : typeof job.column_template_id === 'string'
          ? [+job.column_template_id]
          : [],

        timezone: eventSettings?.display_timezone
          ? job?.timezone || eventSettings?.timezone
          : null,
        wo_proposal_id: workOrderId,
        date: Array.isArray(job.date)
          ? job.date.map(date => moment(date).format('YYYY-MM-DD'))
          : typeof job.date === 'string'
          ? job.date
          : null,
        ...(job.is_recurring
          ? { ...recurringOptions, date: [recurringOptions?.date] }
          : {}),
      }

      try {
        const res = await jobAPI.createJob(jobToCreate)

        // onJobCreated?.()

        // if (job.notes.length) {
        //   await apiNotes.postEntityNote(`/jobs/${createdJob.id}/notes`, {
        //     text: job.notes,
        //   })
        // }

        openNotificationWithIcon('success', {
          message: `${dictionary.singular} had been created`,
        })

        dispatch(
          getRelateJobs(
            workOrderId
              ? { wo_proposal_id: workOrderId }
              : { proposal_id: job?.proposal_id },
          ),
        )

        //TODO: add socket event to signal new group visits has been created
        // setTimeout(() => {
        //   dispatch(
        //     getRelateJobs(
        //       workOrderId
        //         ? { wo_proposal_id: workOrderId }
        //         : { proposal_id: job?.proposal_id },
        //     ),
        //   )
        // }, 7000)

        mapUpdateNeededHeandle()

        const isNotQueue =
          Array.isArray(job?.column_template_id) &&
          !!job?.column_template_id?.filter(id => typeof id === 'number')
            ?.length

        const notificationSettings =
          res?.[0]?.industry_id &&
          (await getNotificationSettings(res?.[0]?.industry_id))

        const isShowNotificationUpcoming =
          company.id === Companies.WestCost || company.id === Companies.HVAC
            ? notificationSettings?.should_display_upcoming
            : isShowUpcomingNotification

        const isShowWoEventNotification =
          notificationSettings?.should_display_wo_event

        if (
          (isCreateWOVisit
            ? isShowWoEventNotification
            : isShowNotificationUpcoming) &&
          isNotQueue &&
          !jobToCreate.is_recurring
        ) {
          setFullJob({
            ...res?.[0],
            date: jobToCreate.date,
            column_template_id: jobToCreate.column_template_id,
            matrix_time_start: jobToCreate.matrix_time_start,
            matrix_time_end: jobToCreate.matrix_time_end,
          })

          showUpcomingNotificationModal(true)
          // isCreateWOVisit
          //   ? showUpcomingNotificationModal(true)
          //   : setShowNotificationModal(true)
          setEdited(false)
        } else onJobCreated?.()
      } catch (error) {
        setError(error?.response?.data)

        showUpcomingNotificationModal(false)
        // isCreateWOVisit
        //   ? showUpcomingNotificationModal(false)
        //   : setShowNotificationModal(false)
        if (error?.response?.data?.count_days) {
          setShowDropModal(true)
          const message = 'This time had been taken by another appointment'
          openNotificationWithIcon('error', { message })
        }
        if (error?.response?.data?.errors?.time?.[0]) {
          const message = error?.response?.data?.errors?.time?.[0]
          openNotificationWithIcon('error', { message })
        }
        if (error?.response?.data?.message) {
          const message = error?.response?.data?.message
          openNotificationWithIcon('error', { message })
        }
        setLoading(false)
      }
    }
    /* useEffect(() => {
      if (job.date) {
        getActualDate(job.date)
          .then(data =>
            setJob(prev => ({
              ...prev,
              company_start_time:
                data?.start_hour || dispatchSettings?.start_hour,
              company_end_time: data?.end_hour || dispatchSettings?.end_hour,
            })),
          )
          .catch(error => console.error(error))
      }
    }, [job?.date]) */
    //Methods

    const handleDeleteJob = async () => {
      if (job.parent_id && !job.action_scope) {
        setJob(prev => ({
          ...prev,
          action_scope: ActionScope.OnlyThisEvent,
        }))
        return setActionScope('Delete')
      }

      setLoading(true)

      try {
        await jobAPI.deleteJob(
          job.id,
          job?.action_scope ? { action_scope: job.action_scope } : undefined,
        )

        if (matrix) {
          dispatch(eventDeleted(job.id))
          onCloseDrawer?.()
          return redirectToMatrix(job)
        }

        history.push('/jobs-list')
        setLoading(false)
        mapUpdateNeededHeandle()

        openNotificationWithIcon('success', {
          message: `${dictionary.singular} has been deleted successfully`,
        })
      } catch (error) {
        console.error(error?.response?.data?.message || 'Invalid data')

        openNotificationWithIcon('error', {
          message: error?.response?.data?.message || 'Invalid data',
        })
      } finally {
        setLoading(false)
      }
    }

    const handleDuplicateJob = async () => {
      setLoading(true)

      try {
        const dupclicatedJob = await jobAPI.duplicateJob(job.id)

        //         const queueType = job.is_recurring ? 'jobs_recurring' : 'jobs_queue'
        //
        //         queryClient.invalidateQueries({ queryKey: [queueType] })

        openNotificationWithIcon('success', {
          message: `${dictionary.singular} has been duplicated successfully`,
        })

        if (!isSchedule) redirectToMatrix(dupclicatedJob)

        // history.push('/matrix')
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error.message,
        })
      } finally {
        setLoading(false)
        mapUpdateNeededHeandle()
      }
    }

    const handleUpdateJob = async () => {
      if (job.parent_id && recurringEdited && !job.action_scope) {
        setJob(prev => ({
          ...prev,
          action_scope: ActionScope.OnlyThisEvent,
        }))
        return setActionScope('Edit')
      }

      if (!isSchedule) setLoading(true)

      const isDateChanged = !isSameDate(initialEvent?.date, job.date)
      // const userIsAddedEntity = !apptHasEntity && !!appointment?.entity_id
      // const userIsChangeEntity = job?.entity_id !== apptHasEntity
      const initialStart = moment(initialEvent?.time_start, 'HH:mm:ss').format(
        'HH:mm',
      )
      const initialEnd = moment(initialEvent?.time_end, 'HH:mm:ss').format(
        'HH:mm',
      )
      const start = moment(job.time_start).format('HH:mm')
      const end = moment(job.time_end).format('HH:mm')
      const isTimeChanged = initialStart !== start || initialEnd !== end

      const notificationSettings = await getNotificationSettings(
        job.industry_id,
      )

      const isShowNotificationUpcoming =
        company.id === Companies.WestCost || company.id === Companies.HVAC
          ? notificationSettings?.should_display_upcoming
          : isShowUpcomingNotification

      const isNeedToShowNotification =
        typeof job.column_template_id === 'number' &&
        isShowNotificationUpcoming &&
        (isDateChanged ||
          isTimeChanged ||
          (initialEvent?.column_template_id === null &&
            !!job.column_template_id)) && //from queue to schedule with date set
        job.status === 1

      // if (isNeedToShowNotification) {
      //   setFullJob(job)
      //   setShowNotificationModal(true)
      // }

      try {
        const updatedJob = await jobAPI.updateJob(job.id, {
          ...job,
          proposal_info: proposalData,
          time_start: makePromisedTimeStart(
            !eventSettings?.show_promise_time,
            job,
          ),
          time_end: makePromisedTimeEnd(!eventSettings?.show_promise_time, job),
          matrix_time_start: job.matrix_time_start
            ? moment(job.matrix_time_start).format('HH:mm')
            : null,
          matrix_time_end: job.matrix_time_end
            ? moment(job.matrix_time_end).format('HH:mm')
            : null,
          current_start_time:
            job.matrix_time_start || job.matrix_time_start === 0
              ? moment(job.matrix_time_start).hours() +
                (dispatchSettings?.end_hour > 24 &&
                moment(job.matrix_time_start).hours() < 12
                  ? 24
                  : 0)
              : null,
          current_end_time:
            job.matrix_time_end === 0
              ? 24
              : job.matrix_time_end
              ? +moment(job.matrix_time_end).hours() +
                (dispatchSettings?.end_hour > 24 &&
                moment(job.matrix_time_end).hours() < 12
                  ? 24
                  : 0)
              : null,
          notes: null,
          column_template_id:
            typeof job.column_template_id === 'number'
              ? job.column_template_id
              : null,
          queue_id:
            typeof job.column_template_id === 'string'
              ? +job.column_template_id
              : null,
          date: job.date ? moment(job.date).format('YYYY-MM-DD') : null,
        })

        if (isNeedToShowNotification) {
          setFullJob({
            ...job,
            column_template_id: job?.column_template_id
              ? [job?.column_template_id]
              : null,
            date: job.date ? [job.date] : null,
          })
          // setShowNotificationModal(true)
          showUpcomingNotificationModal(true)
        }

        setEdited(false)

        if (drawerView) {
          dispatch(
            setMatrixEvent([
              {
                ...updatedJob,
                address: updatedJob?.property?.[0]?.address,
                postcode: updatedJob?.property?.[0]?.postcode,
                city: updatedJob?.property?.[0]?.city,
                state: updatedJob?.property?.[0]?.state,
                preferred_technician: {
                  name: updatedJob?.preferred_workers?.[0],
                },
                appointment_type: updatedJob?.appointment_type?.name,
                date: moment(updatedJob?.date).format('MM/DD/YYYY'),
                column_template_id: job.column_template_id,
                current_start_time:
                  job.matrix_time_start || job.matrix_time_start === 0
                    ? moment(job.matrix_time_start).hours() +
                      (dispatchSettings?.end_hour > 24 &&
                      moment(job.matrix_time_start).hours() < 12
                        ? 24
                        : 0)
                    : null,
                current_end_time:
                  job.matrix_time_end === 0
                    ? 24
                    : job.matrix_time_end
                    ? +moment(job.matrix_time_end).hours() +
                      (dispatchSettings?.end_hour > 24 &&
                      moment(job.matrix_time_end).hours() < 12
                        ? 24
                        : 0)
                    : null,
              },
            ]),
          )
        }

        if (!isSchedule) {
          openNotificationWithIcon('success', {
            message: `${dictionary.singular} has been updated successfully`,
            description: '',
            key: 'jobs-duplicate-success',
          })
        }

        return updatedJob
      } catch (error) {
        const asyncErrors = error?.response?.data?.errors
        setError(error?.response?.data)

        setShowNotificationModal(false)

        if (asyncErrors?.time) {
          setError(prev => ({
            ...prev,
            matrix_time_end: asyncErrors?.time?.[0],
            matrix_time_start: asyncErrors?.time?.[0],
          }))
        }
        openNotificationWithIcon('error', {
          message: 'Oops!',
          description:
            error?.response?.data?.message ||
            'It looks like some fields need your attention. Please review and fix the highlighted issues.',
        })

        // throw new Error(error)
        return error
      } finally {
        setLoading(false)
        mapUpdateNeededHeandle()
        if (actionScope) setActionScope('')
      }
    }

    const redirectToMatrix = async (job: Job) => {
      const date = job?.date || new Date()

      dispatch(setCurrentAppointmentId(job?.id))
      if (matrix) {
        return await dispatch(
          getMatrixLog({ date: moment(date).format('MM/DD/YYYY') }),
        )
      } else {
        await dispatch(
          getMatrixLog({ date: moment(date).format('MM/DD/YYYY') }),
        )
        history.push('/dispatch')
      }
      // await dispatch(getMatrixQueues(moment(date).format('MM/DD/YYYY')))
    }

    const fetchData = async () => {
      setLoading(true)

      try {
        const job = await jobAPI.getJob(jobId)

        setJob({
          ...job,
          time_start:
            job.time_start || job.time_start === 0
              ? moment(moment().format('YYYY-MM-DD') + ' ' + job.time_start)
              : null,
          time_end: job.time_end
            ? moment(moment().format('YYYY-MM-DD') + ' ' + job.time_end)
            : null,
          matrix_time_end: job?.matrix_time_end
            ? moment()
                .hours(job.matrix_time_end)
                .set('minute', 0)
            : null,
          matrix_time_start:
            job?.matrix_time_start || job.matrix_time_start == 0
              ? moment()
                  .hours(job.matrix_time_start)
                  .set('minute', 0)
              : null,
          column_template_id:
            job.column_template_id ||
            (job.queue_id ? job.queue_id.toString() : null),
        })

        setInitialEvent(job)

        setProposalData(job?.proposal_info)
        dispatch(setForm(job?.proposal_info, {}, false, ['HVAC'], [], ''))
        dispatch(resetProjectItemsSlice())

        dispatch(
          itemsSet(
            gatherItemsFromSections(job?.proposal_info?.proposal_tabs?.[0]),
          ),
        )
      } catch (error) {
        if (
          error?.status === 404 ||
          (!!error?.response && error?.response?.status === 404)
        ) {
          history.replace(`/404/event`)
        }
        setError(error?.response?.data)
      }
      setLoading(false)
    }

    const handleChangeAppointment = (
      e: { target: { name: string; value: any; checked: boolean } },
      ignoreEdit: boolean,
    ) => {
      let { name, value, checked } = e.target

      if (!ignoreEdit) {
        setEdited(true)
      }

      if (error?.[name]) {
        if (name === 'time_start') {
          setError(prev => ({
            ...prev,
            time_start: null,
            time_end: null,
          }))
        }

        if (name === 'matrix_time_start') {
          setError(prev => ({
            ...prev,
            matrix_time_start: null,
            matrix_time_end: null,
          }))
        }

        setError(prev => ({
          ...prev,
          [name]: null,
        }))
      }

      if (name === 'ending_options') {
        setError(prev => ({
          ...prev,
          ['ending_options.value']: null,
        }))
      }

      if (name === 'start_date' && recurringOptions?.date) {
        setRecurringOptions(prev => ({
          ...prev,
          date: value,
        }))

        if (
          recurringOptions.ending_options.end_type === 1 &&
          moment(recurringOptions.ending_options.value).isBefore(moment(value))
        ) {
          setRecurringOptions(prev => ({
            ...prev,
            ending_options: {
              ...recurringOptions.ending_options,
              value: moment(value).format('YYYY-MM-DD'),
            },
          }))
        }
      }

      if (name === 'preferred_technician_id' && !value) {
        return setJob(prev => ({
          ...prev,
          // assign_to_matrix: false,
          preferred_technician_id: value,
        }))
      }
      if (name === 'entity_type') value = checked ? 2 : 1

      // if (name === 'appointed_technician_id') {
      //   setJob(prev => ({
      //     ...prev,
      //     appointed_technician_id: value,
      //     assign_to_matrix: Array.isArray(value) && value.length === 1 && value.includes('queue') ? false : !!value,
      //   }))
      // }

      // if (name === 'is_recurring') {
      //   setJob(prev => ({
      //     ...prev,
      //     // assign_to_matrix: false,
      //     column_template_id: ['queue'],
      //     matrix_time_start: null,
      //     matrix_time_end: null,
      //   }))
      // }

      if (name === 'matrix_time_start' && !!value) {
        setJob(prev => ({
          ...prev,
          matrix_time_end: moment(value).add(
            company.id === Companies.WestCost ? 2 : 1,
            'h',
          ),
        }))
      }

      if (name === 'appointment-status') {
        setJob(prev => ({
          ...prev,
          status: value,
          status_logs: [
            ...prev.status_logs,
            {
              status: AppointmentStatusID[value],
              created_at: moment().format('YYYY/MM/DD'),
            },
          ],
        }))
      }

      if (name === 'time_start' && !!value && !job.time_end) {
        setJob(prev => ({
          ...prev,
          time_end: moment(value).add(+eventSettings.arrival_window, 'h'),
        }))
      }

      if (name === 'is_recurring') {
        setJob(prev => ({
          ...prev,
          // column_template_id: !!value ? null : [],
          // matrix_time_start: null,
          // matrix_time_end: null,
          // column_template_id: value
          //   ? defaultQueues?.['r_job']
          //     ? defaultQueues?.['r_job'].toString()
          //     : null
          //   : defaultQueues?.['job']
          //   ? [defaultQueues?.['job'].toString()]
          //   : [],
          column_template_id: [],
        }))

        if (value) showRecurrenceModal(true)
      }

      if (name === 'column_template_id') {
        if (
          job.is_recurring &&
          Array.isArray(job.column_template_id) &&
          !!job.column_template_id?.length &&
          value.some((id: number | string) => typeof id !== typeof value[0])
        ) {
          return openNotificationWithIcon('info', {
            message: 'Choose either Queue or Column values',
          })
        }
      }

      if (name === 'monthly_type') {
        setJob(prev => ({
          ...prev,
          each: [],
        }))
      }

      if (
        !job.id &&
        name === 'column_template_id' &&
        !assignToUpdated &&
        value?.length > 1
      ) {
        value = value?.slice(1)
      }

      setAssignToUpdated(true)

      setJob(prev => ({
        ...prev,
        [name]: value,
      }))

      if (
        !!job.parent_id &&
        [
          'appointment_source_id',
          'appointment_type_id',
          'column_template_id',
          'industry_id',
          'preferred_technician_id',
          'timezone',
          'time_start',
          'time_end',
          'matrix_time_start',
          'matrix_time_end',
        ].includes(name)
      ) {
        setRecurringEdited(true)
      }
    }
    const handleCancel = () => {
      fetchData()
      setEdited(false)
    }

    const handleBlockedRoute = (nextLocation: string) => {
      setBlockedRouteParams({
        blockRoutingModal: true,
        nextLocation: nextLocation,
      })
      return false
    }

    const heandleClearError = () => {
      setShowError(false)
    }

    const handleStatusUpdated = () => {
      dispatch(getRelateJobs({ proposal_id: job?.proposal_id }))

      setRevalidateGroupEvents(prev => prev + 1)
    }

    const handleSetProposalData = (data: Project) => {
      setEdited(true)

      setProposalData(data)
    }
    const getContentToPrint = useCallback(() => {
      return ref?.current
    }, [ref?.current])

    const handlePrint = useReactToPrint({
      content: getContentToPrint,
      pageStyle: IndividualPrintStyle,
    })

    const handleSendCustomForm = () => {
      setShowNotificationModal(true)
    }

    const handleCrateNextRecurringChild = async () => {
      setLoading(true)

      const child = await createRecurringJobChild({
        parent_id: job?.id,
      })

      setLoading(false)

      if (!child?.id)
        return openNotificationWithIcon('error', {
          message: 'There are no child appointments left',
        })

      dispatch(getRelateJobs({ proposal_id: job?.proposal_id }))
    }

    const handleChangeRfRStatus = (status: any) => {
      setJob(p => ({ ...p, review_status: status }))
    }
    const handleClickRfRModal = () => {
      setIsShownRfRModal(true)
    }

    const handleGroupModalOpen = async () => {
      try {
        // if (edited) await handleUpdateJob()

        showGroupModal(true)
      } catch (error) {
        console.error(error)
      }
    }

    const handleNotificationSend = async (groupId?: number) => {
      if (justSendNotification) return

      setJustSendNotification(true)

      let event = {
        ...job,
        column_template_id: [job.column_template_id],
        date: job.date ? [job.date] : null,
      }

      if (groupId) {
        const group = await EventGroupAPI.getEventGroup(groupId)

        event = {
          ...job,
          ...group,
          isGroup: true,
        }
      }

      setFullJob({
        ...event,
        matrix_time_start: moment(event.current_start_time, 'HH').format(
          'HH:mm',
        ),
        matrix_time_end: moment(event.current_end_time, 'HH').format('HH:mm'),
        industry_id: event.industry_id,
        wo_proposal_id: event.wo_proposal_id,
        proposal_id: event.proposal_id,
      })

      if (job.wo_proposal_id) dispatch(getWorkOrder(job.wo_proposal_id))

      showUpcomingNotificationModal(true)
    }

    const handleGroupDelete = () => {
      showGroupModal(false)

      if (!onCloseDrawer) return history.push('/jobs-list')

      onCloseDrawer?.()
    }

    const handleGroupSave = () => {
      showGroupModal(false)

      setTimeout(() => {
        setRevalidateGroupEvents(prev => prev + 1)
      }, 3000)

      if (!onCloseDrawer) return history.push('/jobs-list')

      onCloseDrawer?.()
    }

    const handleNotificationSubmited = () => {
      onJobCreated?.()
      showUpcomingNotificationModal(false)
    }

    const handleNotificationModalClose = () => {
      if (!job.id) {
        return handleNotificationSubmited()
      }

      if (!justSendNotification) onCloseDrawer?.()
      showUpcomingNotificationModal(false)
      setJustSendNotification(false)
    }

    const handleRecurrenceSave = (data?: RecurringOptions) => {
      if (data) {
        setRecurringOptions(data)
        setJob(prev => ({
          ...prev,
          start_date: data.date,
        }))
      }

      showRecurrenceModal(false)
    }

    return (
      <CustomDrawer
        withDrawer={drawerView}
        visible={visibleDrawer}
        mask={false}
        placement='right'
        headerStyle={{ padding: '10px' }}
        width={860}
        style={{ zIndex: 1000 }}
        closeIcon={
          edited ? (
            <div onClick={() => setShowConfirmationModal(true)}>
              <CloseOutlined />
            </div>
          ) : (
            <div onClick={onCloseDrawer}>
              <CloseOutlined />
            </div>
          )
        }
        extra={
          <HeaderWrapper>
            {isSoCalCompany && job?.id && (
              <MainButton title='Foreman Log Sheet' onClick={openModal} />
            )}
            {job?.id && (
              <MainButton
                title={t('button.share', { ns: 'common' })}
                onClick={() => handleOpenPublicEventModal(job)}
              />
            )}

            {!!job?.id && !!permissions?.reviews && (
              <MainButton
                type='cancel'
                onClick={handleClickRfRModal}
                title={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '3px',
                    }}
                  >
                    <p style={{ paddingTop: '1px' }}>
                      <ReviewStatus status={job?.review_status} />
                    </p>
                    <p style={{ paddingTop: '1px' }}>
                      {t('heading.requestForReview')}
                    </p>
                  </div>
                }
              />
            )}

            <MainButton
              title={t('button.print', { ns: 'common' })}
              onClick={handlePrint}
            />
            <AccessControl
              scopes={[DispatchPermission.DISPATCH_CAN_DUPLICATE_EVENT]}
              additionalAccess={!isSchedule}
            >
              {job?.id && (
                <MainButton
                  title={t('button.duplicate', { ns: 'common' })}
                  onClick={handleDuplicateJob}
                />
              )}
            </AccessControl>

            <AccessControl
              scopes={[DispatchPermission.DISPATCH_CAN_DELETE_EVENT]}
              additionalAccess={!isSchedule}
            >
              <Popconfirm
                title={t('prompt.deleteEvent')}
                onConfirm={handleDeleteJob}
                okText={t('button.yes', { ns: 'common' })}
                cancelText={t('button.no', { ns: 'common' })}
                destroyTooltipOnHide
                placement='bottom'
              >
                <MainButton
                  title={t('button.delete', { ns: 'common' })}
                  type='delete'
                />
              </Popconfirm>
            </AccessControl>

            {job?.id ? (
              <MainButton
                title={t('button.save', { ns: 'common' })}
                onClick={handleUpdateJob}
                isFetching={loading}
              />
            ) : (
              <MainButton
                title={`${t('button.create', { ns: 'common' })} ${
                  dictionary.singular
                }`}
                onClick={handleCreateJob}
              />
            )}
          </HeaderWrapper>
        }
      >
        <Wrapper ref={ref}>
          {!isSchedule && (
            <>
              {!!jobId && !drawerView && (
                <Heading className='heading_for-print'>
                  <h4>
                    {t('button.update', { ns: 'common' })} {dictionary.singular}{' '}
                    {!!job.job_code && <span>({job.job_code})</span>}
                  </h4>

                  <MainButton
                    title={t('button.print', { ns: 'common' })}
                    onClick={handlePrint}
                    style={{ marginLeft: 'auto' }}
                  />
                  {edited && (
                    <>
                      <MainButton
                        title={t('button.cancel', { ns: 'common' })}
                        type='cancel'
                        onClick={handleCancel}
                      />
                      <MainButton
                        title={t('button.save', { ns: 'common' })}
                        onClick={handleUpdateJob}
                        isFetching={loading}
                      />
                    </>
                  )}
                </Heading>
              )}

              <PlanLimits entity='events' />

              <div className='create-appointment-form__appoitment-section'>
                <AppointmentSection
                  assignToOptions={assignToOptions.filter(option =>
                    job.id &&
                    job.parent_id &&
                    typeof job.column_template_id !== 'string'
                      ? option.source === 'Columns'
                      : option,
                  )}
                  loading={loading}
                  data={job}
                  onChange={handleChangeAppointment}
                  onStatusUpdated={handleStatusUpdated}
                  error={error}
                  me={me}
                  setEdited={setEdited}
                  eventSettings={eventSettings}
                  onCreateNexRecurringtChild={handleCrateNextRecurringChild}
                  onClose={() => onCloseDrawer?.()}
                  onNotificationSend={handleNotificationSend}
                  recurringOptions={recurringOptions}
                  onOpenRecurringOptions={() => showRecurrenceModal(true)}
                />
                {(window.location.pathname === '/dispatch' ||
                  window.location.pathname === '/map') &&
                  routesSettings?.is_enabled &&
                  !job?.id && (
                    <AppointmentPreferredTech
                      property={property}
                      settings={routesSettings}
                      appointment={job}
                      onChange={setJob}
                    />
                  )}
              </div>

              {!!job.group_id && (
                <div style={{ margin: '1rem 0' }}>
                  <GroupEvents
                    eventType='jobs'
                    groupId={job.group_id}
                    currentEventId={job.id}
                    onEditGroup={handleGroupModalOpen}
                    onClick={event => {
                      if (!onGroupEventClick)
                        return window.open(
                          `/jobs/${event.id}`,
                          '_blank',
                          'noreferrer',
                        )

                      onGroupEventClick?.({ ...event, kind: EventKind.JOB })
                    }}
                    onSendGroupNotification={() => {
                      if (!job.group_id) return

                      handleNotificationSend(job.group_id)
                    }}
                    revalidate={revalidateGroupEvents}
                  />
                </div>
              )}

              {!!job.parent_id && (
                <div style={{ margin: '1rem 0' }}>
                  <RecurringEvents
                    eventType='jobs'
                    groupId={job.parent_id}
                    currentEventId={job.id}
                    onEditRecurrence={() => showRecurrenceModal(true)}
                    onClick={event => {
                      if (!onGroupEventClick)
                        return window.open(
                          `/jobs/${event.id}`,
                          '_blank',
                          'noreferrer',
                        )

                      onGroupEventClick?.({
                        ...event,
                        kind: EventKind.JOB,
                      })
                    }}
                    isGenerating={isProcessingRecurring}
                  />
                </div>
              )}
            </>
          )}

          {!!proposalData && (
            <JobView
              job={job}
              proposalData={proposalData}
              onChangeData={handleSetProposalData}
              linkToProject={projectLink}
              onSendForm={handleSendCustomForm}
              isSchedule={isSchedule}
            />
          )}

          {!job.id && (
            <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
              <MainButton
                title={t('button.cancel', { ns: 'common' })}
                type='cancel'
                onClick={onCancel}
              />
              <MainButton
                title={t('button.create', { ns: 'common' })}
                onClick={handleCreateJob}
              />
            </div>
          )}

          {!!jobId && !isSchedule && !drawerView && (
            <div style={{ display: 'flex', gap: 10, margin: '30px 0 20px' }}>
              {!!job?.id && (
                <MainButton
                  title={t('button.share', { ns: 'common' })}
                  onClick={() => handleOpenPublicEventModal(job)}
                />
              )}
              {!!job?.id && !!permissions?.reviews && (
                <MainButton
                  type='cancel'
                  onClick={handleClickRfRModal}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '3px',
                      }}
                    >
                      <p style={{ paddingTop: '1px' }}>
                        <ReviewStatus status={job?.review_status} />
                      </p>
                      <p style={{ paddingTop: '1px' }}>
                        {t('heading.requestForReview')}
                      </p>
                    </div>
                  }
                />
              )}
              {edited && (
                <MainButton
                  title={t('button.cancel', { ns: 'common' })}
                  type='cancel'
                  onClick={handleCancel}
                />
              )}

              <AccessControl scopes={[JobPermissions.JOBS_CAN_DELETE]}>
                <Popconfirm
                  title={t('prompt.deleteEvent')}
                  onConfirm={handleDeleteJob}
                  okText='Yes'
                  cancelText='No'
                  destroyTooltipOnHide
                >
                  <MainButton
                    title={`${t('button.delete', { ns: 'common' })} ${
                      dictionary.singular
                    }`}
                    type='delete'
                  />
                </Popconfirm>
              </AccessControl>

              <MainButton
                title={`${t('button.create', { ns: 'common' })} ${
                  dictionary.singular
                }`}
                onClick={handleDuplicateJob}
                style={{ marginLeft: 'auto' }}
              />

              {edited && (
                <MainButton
                  title={t('button.duplicate', { ns: 'common' })}
                  onClick={handleUpdateJob}
                />
              )}
            </div>
          )}

          {upcomingNotificationModal && (
            <NotificationModalV2
              title={t('heading.upcomingNotification')}
              templateType={
                fullJob?.wo_proposal_id
                  ? NotificationTemplateType.WORK_ORDER
                  : NotificationTemplateType.APPOINTMENT
              }
              notificationType={
                fullJob?.wo_proposal_id
                  ? NotificationModalType.WORK_ORDER_EVENT
                  : NotificationModalType.UPCOMING
              }
              industryId={fullJob?.industry_id}
              entity='proposals'
              entityId={fullJob?.wo_proposal_id || fullJob?.proposal_id}
              onSubmit={handleNotificationSubmited}
              onClose={handleNotificationModalClose}
              techs={assignToOptions
                .filter(
                  column =>
                    fullJob?.column_template_id?.includes(column.id) &&
                    !!column.main_tech_id,
                )
                .map(column => ({
                  id: column.main_tech_id,
                  name: column.main_tech_name,
                  emails: [column.main_tech_email],
                  phones: [column.main_tech_phone],
                }))}
              event={fullJob}
            />
          )}

          {showNotificationModal && (
            <NotificationModal
              title={t('heading.upcomingNotification')}
              templateType={NotificationTemplateType.APPOINTMENT}
              notificationType={NotificationModalType.UPCOMING}
              showModal={showNotificationModal}
              setShowModal={setShowNotificationModal}
              appointment={fullJob}
              customSubmit
              customSubmitFunc={() => {
                onCloseDrawer?.()
                // redirectToMatrix(job)
                onJobCreated?.()
              }}
            />
          )}

          {isShownRfRModal && (
            <NotificationModal
              title={NotificationTitleType.REQUEST_FOR_REVIEW}
              templateType={NotificationTemplateType.APPOINTMENT}
              notificationType={NotificationModalType.REQUEST_FOR_REVIEW}
              showModal={isShownRfRModal}
              setShowModal={setIsShownRfRModal}
              // clients={clientsForNotificationModal}
              appointment={job}
              changeStatus={handleChangeRfRStatus}
              customSubmit
              // customSubmitFunc={() => {
              //   onCloseDrawer?.()
              //   mapUpdateNeededHeandle()

              //   !matrix && redirectToMatrixPage()
              // }}
            />
          )}

          <Modal
            title='Ooops..'
            visible={showDropModal}
            onOk={() => {
              setJob(prev => ({ ...prev, confirm: true }))
            }}
            okText='Proceed'
            onCancel={() => {
              setShowDropModal(false)
              setLoading(false)
            }}
          >
            <h6>There are time slot conflicts for these days: </h6>

            {error?.count_days && (
              <div
                style={{
                  whiteSpace: 'pre',
                  marginBottom: 10,
                }}
              >
                {Object.entries(error?.count_days)?.map(([id, array]) => {
                  return (
                    <Fragment key={id}>
                      <p style={{ fontWeight: 'bold' }}>
                        {workers?.find(w => w.id == id)?.name}
                      </p>
                      <p style={{ paddingLeft: 10 }}>{array?.join('\r\n')}</p>
                    </Fragment>
                  )
                })}
              </div>
            )}

            <p>{`Would do you like to put conflict events in queue?`}</p>
          </Modal>

          {loading && <LoadingScreen />}

          {!matrix && (
            <>
              {' '}
              <BlockRoutingModal
                status={!!blockedRouteParams.blockRoutingModal}
                handleCancel={() =>
                  setBlockedRouteParams({
                    blockRoutingModal: false,
                    nextLocation: '',
                  })
                }
                handleDiscard={() => {
                  history.push(blockedRouteParams.nextLocation)
                }}
                handleSave={job.id ? handleUpdateJob : handleCreateJob}
                title='You have some unsaved changes.'
                buttonsTitle={{
                  save: 'Save and continue',
                  discard: 'Discard and continue',
                  cancel: 'Cancel',
                }}
              />
              <Prompt
                when={edited && !blockedRouteParams.blockRoutingModal}
                message={handleBlockedRoute}
              />
            </>
          )}

          {isSavedChangesFields &&
            showError &&
            openNotificationWithIcon(
              'error',
              { message: 'You should save changed property' },
              15,
              heandleClearError,
            )}

          <ConfirmationModal
            visible={showConfirmationModal}
            onSave={
              !!modulePermission[DispatchPermission.DISPATCH_CAN_EDIT_EVENT]
                ? () => {
                    handleUpdateJob().then(() => onCloseDrawer?.())
                  }
                : null
            }
            onCancel={() => {
              onCloseDrawer?.()
              redirectToMatrix(job)
            }}
            onClose={() => setShowConfirmationModal(false)}
          />

          {!!drawerView && (
            <AppointmentPrintPDF
              appointment={job}
              openModal={isActive}
              onCloseModal={() => toggleActive(false)}
              entityName={job?.client_name}
              column={columns?.find(
                column =>
                  column?.column_template_id === job?.column_template_id,
              )}
            />
          )}
          <PublicEventModal
            visible={openPublicEventModal}
            onCancel={handleClosePublicEventModal}
            event={publicEvent}
          />

          {groupModal && job.group_id && (
            <GroupModal
              eventSettings={eventSettings}
              groupId={job.group_id}
              eventKind={job.kind}
              onClose={() => showGroupModal(false)}
              onGroupDelete={handleGroupDelete}
              onSave={handleGroupSave}
            />
          )}

          {recurrenceModal && (
            <RecurringModal
              data={recurringOptions}
              parentId={job.parent_id}
              onSave={handleRecurrenceSave}
              onClose={() => {
                if (!recurringOptions) {
                  setJob(prev => ({
                    ...prev,
                    is_recurring: false,
                  }))
                }
                showRecurrenceModal(false)
              }}
              error={error}
            />
          )}

          {!!actionScope && (
            <ActionScopeModal
              actionScope={actionScope as 'Delete' | 'Edit'}
              value={job.action_scope || 1}
              loading={loading}
              onCancel={() => {
                setActionScope('')
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { action_scope, ...rest } = job
                setJob(rest)
              }}
              onSave={
                actionScope === 'Edit' ? handleUpdateJob : handleDeleteJob
              }
              onChange={value =>
                setJob(prev => ({
                  ...prev,
                  action_scope: value,
                }))
              }
            />
          )}
        </Wrapper>
      </CustomDrawer>
    )
  },
)

JobForm.displayName = 'JobForm'
