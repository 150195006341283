import React, { useState, useEffect } from 'react'
import { Badge, Button, Spin } from 'antd'
// import ToggleButton from 'containers/MainContent/Orcatec/components/buttons/ToggleButton/ToggleButton'
import { RelatedForms } from 'features/Forms/components/FormsBlock/components/RelatedForms'
import {
  attachTemplatesToProject,
  getTemplatesList,
  deleteFormById,
  getAttachedTemplatesToProject,
  getFormTemplates,
} from 'api/CustomForms'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { AddTemplateModal } from 'features/Forms/components/FormsBlock/components/AddTemplateModal'
import { AddFormModal } from 'features/Forms/components/FormsBlock/components/AddFormModal'
import { EditFormModal } from 'features/Forms/components/FormsBlock/components/EditFormModal'
import {
  ActionEnum,
  CustomFormTemplate,
  CountForms,
  CustomFormEntityType,
  CustomForm,
  CustomFormsOperation,
} from 'features/Forms/types'
import { TemplatesListModal } from './components/TemplatesListModal'
import styled from 'styled-components'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { RelatedFormsModal } from './components/RelatedFormsModal'
import { ModuleName } from 'features/Settings/UsersAndGroups'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { GeneralTableParams } from 'containers/MainContent/Orcatec/components/Table/types'
import { useTranslation } from 'react-i18next'

const initCount = {
  count_forms: 0,
  count_templates: 0,
}

interface IProps {
  entityId: number
  entityType: CustomFormEntityType
  chooseAllowableTemplates?: boolean
  showAllowableTemplates?: boolean
  id: number
  module: ModuleName
  relatedForms?: CustomForm[]
  hideTitle?: boolean
  getRelatedForm: (id: number) => Promise<CustomForm[]>
  getAttachedTemplate: (entityId: number) => CustomFormTemplate[]
  createRelatedForm: () => Promise<object | []>
  getCount: (id: number) => Promise<object | []>
  onSendForm: () => void
  onFormDelete?: (formId: number) => void
  onAttachTemplates: (
    entity_id: number,
    data: {
      templates: {
        id: number
        count: number
      }[]
      type: CustomFormEntityType
    },
  ) => void
}
export const FormsBlock = ({
  id,
  relatedForms,
  getRelatedForm,
  getAttachedTemplate,
  createRelatedForm,
  getCount,
  entityId,
  entityType,
  chooseAllowableTemplates,
  showAllowableTemplates,
  hideTitle,
  onFormDelete,
  onAttachTemplates,
}: IProps) => {
  const { READ, CREATE, DELETE } = usePermissionsByModule(CustomFormsOperation)
  const { t } = useTranslation(['forms', 'common'])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const [templateList, setTemplateList] = useState<CustomFormTemplate[]>([])
  const [loadingTemplates, setLoadingTemplates] = useState(false)
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 10,
  })

  const [template, setTemplate] = useState<CustomForm | null>(null)
  const [isModalFormOpen, setIsModalFormOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingRelatedForms, setLoadingRelatedForms] = useState(false)
  const [attachingTemplates, setAttachingTemplates] = useState(false)
  const [data, setData] = useState([])
  const [formData, setFormData] = useState([])
  const [formAction, setFormAction] = useState(ActionEnum.CREATE)
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])
  const [formCount, setFormCount] = useState<CountForms>(initCount)
  const [showAllRelatedForms, setShowAllRelatedForms] = useState(false)
  const [relatedForm, setRelatedForms] = useState<{
    data: Record<number, CustomForm>
    ids: number[]
  }>({
    data: {},
    ids: [],
  })
  const [templatesListModal, showTemplatesListModal] = useState(false)
  const [sliderItemsCount, setSliderItemsCount] = useState(3)

  const fetchAttachedTemplates = async () => {
    if (!getAttachedTemplate) return

    setLoading(true)
    try {
      const { data } = await getAttachedTemplate(entityId)
      setFormData(data)
      setSelectedRowKeys(data?.map(r => r.id))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchTemplateList = async () => {
    setLoading(true)
    try {
      const res = await getTemplatesList()
      setData(res?.data?.map(r => ({ ...r, key: r.id })))
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const fetchCountForms = async () => {
    if (!getCount) return

    setLoading(true)
    try {
      const res = await getCount(id)

      setFormCount(res)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const fetchRelatedFormList = async () => {
    setLoadingRelatedForms(true)
    try {
      const res = await getRelatedForm(id)

      setRelatedForms({
        data: transformArrayToObj(res),
        ids: res?.map(form => form.id),
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingRelatedForms(false)
    }
  }

  const handleDeleteForm = async (form: CustomForm) => {
    if (!DELETE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    setLoading(true)
    try {
      await deleteFormById(form.id)

      setRelatedForms(prev => ({
        ...prev,
        ids: prev.ids.filter(id => id !== form.id),
      }))
      setIsModalEditOpen(false)

      onFormDelete?.(form.id)

      openNotificationWithIcon('success', {
        message: 'Form had been deleted successfully!',
      })
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleCancelFormModal = () => {
    setIsModalFormOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
    fetchAttachedTemplates()
    fetchCountForms()
  }

  const handleCreateForm = (template: CustomFormTemplate) => {
    setFormAction(ActionEnum.CREATE)
    setIsModalFormOpen(false)
    setIsModalEditOpen(true)
    setTemplate(template)
  }
  const handleUpdateForm = (form: CustomForm) => {
    setFormAction(ActionEnum.UPDATE)
    setIsModalFormOpen(false)
    setIsModalEditOpen(true)
    setTemplate(form)
  }

  const hadleFormUpdate = (editedForm: CustomForm) => {
    if (!editedForm) return

    setIsModalEditOpen(false)
    setTemplate(null)

    setRelatedForms(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [editedForm.id]: editedForm,
      },
    }))
  }

  const handleFormClose = (
    formId: number,
    fieldToUpdate: Record<keyof CustomForm, string>,
  ) => {
    setRelatedForms(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [formId]: {
          ...prev.data[formId],
          ...fieldToUpdate,
        },
      },
    }))

    setIsModalEditOpen(false)
  }

  const handleFormAdd = () => {
    if (loading || loadingTemplates || attachingTemplates) return

    if (!CREATE) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    // if (templateList.length === 1) {
    //   return handleTemplateAttach(templateList)
    // }

    showTemplatesListModal(true)
  }

  useEffect(() => {
    fetchTemplateList()
  }, [])
  useEffect(() => {
    fetchAttachedTemplates()
    getTemplateList(pagination)
  }, [])

  useEffect(() => {
    fetchCountForms()
  }, [])

  useEffect(() => {
    if (!isModalEditOpen && !!getRelatedForm) {
      fetchRelatedFormList()
    }
  }, [isModalEditOpen, id])

  const getTemplateList = async (params: GeneralTableParams) => {
    setLoadingTemplates(true)

    try {
      const res = showAllowableTemplates
        ? await getAttachedTemplatesToProject(entityId, params)
        : await getFormTemplates(params)

      setPagination(res.meta)
      setTemplateList(res?.data.map(form => ({ ...form, key: form.id })))
    } catch (error) {
      if (error?.response?.status === 403) {
        openNotificationWithIcon('warning', {
          message: 'You do not have permissions',
        })
      }
      console.error(error)
    } finally {
      setLoadingTemplates(false)
    }
  }

  useEffect(() => {
    if (relatedForms?.length) {
      setRelatedForms({
        data: transformArrayToObj(relatedForms),
        ids: relatedForms?.map(form => form.id),
      })
    }
  }, [relatedForms?.length])

  const onChange = async (selectedRowKeys: React.Key[]) => {
    try {
      setSelectedRowKeys(selectedRowKeys)
      await attachTemplatesToProject(id, selectedRowKeys)
    } catch (error) {
      console.error(error)
    }
  }

  const rowSelection = {
    onChange,
    selectedRowKeys: selectedRowKeys,
  }

  const handleTemplateAttach = async (
    templates: CustomFormTemplate[],
    counts: { [id: number]: number },
  ) => {
    try {
      setAttachingTemplates(true)
      await onAttachTemplates(id, {
        type: entityType,
        templates: templates.map(template => ({
          id: template.id,
          count: counts[template.id],
        })),
      })
      await fetchRelatedFormList()

      showTemplatesListModal(false)
    } catch (error) {
      if (error.response?.data?.errors?.forms_count) {
        openNotificationWithIcon(
          'error',
          {
            message: error.response?.data?.errors?.forms_count,
          },
          15,
        )
      }
    } finally {
      setAttachingTemplates(false)
    }
  }

  if (!READ) return null

  return (
    <Spin spinning={loadingRelatedForms}>
      <Wrapper>
        <Heading>
          {!hideTitle && (
            <p style={{ fontWeight: 700 }}>
              {t('title')}{' '}
              <Badge
                style={{ background: '#4688f133', color: '#4688f1' }}
                count={relatedForm?.ids?.length}
                title={t('formsCount')}
              />
            </p>
          )}

          {chooseAllowableTemplates && (
            <span style={{ marginBottom: 10 }}>
              <Badge
                style={{ backgroundColor: '#4688f1' }}
                count={formCount.count_templates}
              >
                <MainButton
                  onClick={showModal}
                  title={t('allowableTemplates')}
                  type='cancel'
                />
              </Badge>
            </span>
          )}

          {!!onAttachTemplates && !!relatedForm?.ids?.length && (
            <Button title='Add Form' onClick={handleFormAdd}>
              + {t('addForm')}
            </Button>
          )}

          {relatedForm?.ids?.length > sliderItemsCount && (
            <p
              onClick={() => setShowAllRelatedForms(true)}
              style={{
                marginLeft: 'auto',
                color: '#4688f1',
                cursor: 'pointer',
              }}
            >
              {t('viewAll')}
            </p>
          )}
        </Heading>
        {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}></div> */}

        {relatedForm?.ids?.length ? (
          <RelatedForms
            showRelatedInfo={entityType === CustomFormEntityType.PROJECT}
            relatedForm={relatedForm.ids.map(id => relatedForm.data[id])}
            handleUpdateForm={handleUpdateForm}
            handleChangeSliderItemsCount={(count: number) =>
              setSliderItemsCount(count)
            }
          />
        ) : (
          <>
            {!!onAttachTemplates ? (
              <Button title='Add Form' onClick={handleFormAdd}>
                + {t('addForm')}
              </Button>
            ) : (
              'No forms added'
            )}
          </>
        )}
        {/* )} */}
        <AddTemplateModal
          loading={loading}
          data={data}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          rowSelection={rowSelection}
        />
        <AddFormModal
          handleCancel={handleCancelFormModal}
          isModalOpen={isModalFormOpen}
          loading={loading}
          data={formData}
          handleCreateForm={handleCreateForm}
        />

        {isModalEditOpen && (
          <EditFormModal
            action={formAction}
            form={template}
            // isModalOpen={isModalEditOpen}
            // handleCancel={handleCancelEditModal}
            onSave={hadleFormUpdate}
            handleCancel={handleFormClose}
            createRelatedForm={createRelatedForm}
            entityID={id}
            onDeleteForm={handleDeleteForm}
            showRelatedInfo={entityType === CustomFormEntityType.PROJECT}
          />
        )}

        {templatesListModal && (
          <TemplatesListModal
            data={templateList}
            onClose={() => showTemplatesListModal(false)}
            onSave={handleTemplateAttach}
            getTemplateList={getTemplateList}
            pagination={pagination}
            loading={loadingTemplates || attachingTemplates}
            // attachingTemplates={attachingTemplates}
            // showAllowableTemplates={!!showAllowableTemplates}
            // entityId={entityId}
          />
        )}

        {showAllRelatedForms && (
          <RelatedFormsModal
            data={relatedForm.ids.map(id => relatedForm.data[id])}
            onClose={() => setShowAllRelatedForms(false)}
            onFormClick={handleUpdateForm}
            showRelatedInfo={entityType === CustomFormEntityType.PROJECT}
          />
        )}
      </Wrapper>
    </Spin>
  )
}

const Wrapper = styled.div``
const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
