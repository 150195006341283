import { initializeTourState } from 'features/Support/tourSlice'
import { useEffect } from 'react'
import { useAppDispatch } from 'store/Orcatec/hooks'

export const TourProvider = ({ children }) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(initializeTourState())
  }, [])

  return children
}
