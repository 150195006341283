import React from 'react'
import styled from 'styled-components'

export const Description = () => {
  return (
    <Wrapper>
      <TextRow>
        You can create your custom project ID by this pattern by selecting the
        variables:
      </TextRow>
      <TextRow>
        <b>{`{UPI}`}</b> -
        {`user project identifier (2 to 3 letters, no special symbols allowed, set by default after  user creating the project)
`}
      </TextRow>

      <TextRow>
        <b>{`{yy}`}</b> - {`year`}
      </TextRow>
      <TextRow>
        <b>{`{mm}`}</b> - {`month (01 to 12)`}
      </TextRow>
      <TextRow>
        <b>{`{dd}`}</b> - {`day (01 to 31)`}
      </TextRow>
      <TextRow>
        <b>{`{00}`}</b> - {`increment (2 to 10 digits, required)`}
      </TextRow>

      <TextRow style={{ fontStyle: 'italic', fontSize: '11px' }}>
        {`*You can use following symbols: "{",  "}", "-"; spaces are not allowed. Please note that you can delete a variable completely, but you should NOT edit it, otherwise it will be displayed incorrectly!`}
      </TextRow>

      <TextRow>
        <p>
          <b>Examples:</b>
        </p>
        <p>
          This is how the project ID created by user with user project ID ABC on
          2023-06-02 and with ordinal number 01 will be displayed after
          different patterns:
        </p>
        <p>
          <b>{`{UPI}{yy}{mm}{dd}{00} `}</b> - {`project ID ABC23060201`}
        </p>
        <p>
          <b>{`{UPI}{yy}{mm}{00} `}</b> - {`project ID ABC230601`}
        </p>
        <p>
          <b>{`{UPI}{0000} `}</b> - {`project ID ABC0001`}
        </p>
      </TextRow>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
`
const TextRow = styled.div`
  margin-bottom: 5px;
`
