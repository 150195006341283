import { Component, PictureSize } from '../types'

type ComponentTemplate = Omit<Component, 'id'>

export const templates: Record<string, ComponentTemplate> = {
  input: {
    type: 'text',
    required: false,
    label: 'Text Field',
    subtype: 'text',
    value: '',
    uppercase: false,
  },
  note: {
    type: 'note',
    label: 'Note',
    value: '',
    without_label: false,
    descriptionSize: 14,
    descriptionWeight: 400,
    descriptionAlign: 'left',
  },
  select: {
    type: 'select',
    required: false,
    label: 'Select',
    value: [],
    options: [
      {
        id: 1,
        value: 1,
        label: 'Option 1',
      },
      {
        id: 2,
        value: 2,
        label: 'Option 2',
      },
      {
        id: 3,
        value: 3,
        label: 'Option 3',
      },
    ],
  },
  checkbox_group: {
    type: 'checkbox_group',
    required: false,
    label: 'Checkbox group',
    value: [],
    options: [
      {
        id: 1,
        value: 1,
        label: 'Option 1',
      },
      {
        id: 2,
        value: 2,
        label: 'Option 2',
      },
      {
        id: 3,
        value: 3,
        label: 'Option 3',
      },
    ],
  },
  date: {
    type: 'date',
    required: false,
    label: 'Date',
    value: '',
  },
  signature: {
    type: 'signature',
    required: false,
    label: 'Signature',
    value: '',
  },
  upload: {
    type: 'upload',
    required: false,
    label: 'File Upload',
    value: [],
    multiple: true,
  },
  picture: {
    type: 'picture',
    label: 'Picture',
    value: '',
    without_label: false,
    size: PictureSize.S,
    pictureAlign: 'flex-start',
    description: '',
    descriptionSize: 14,
    descriptionWeight: 400,
    descriptionAlign: 'left',
  },
}
