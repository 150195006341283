import { Select, TextField } from 'components/UIKit'
import { IExistingPhone } from 'features/Messaging/types'
import { parsePhoneNumber } from 'features/Messaging/utils'
import styled from 'styled-components'
import { Recipients } from '../../NotificationModalV2'
import { useTranslation } from 'react-i18next'

interface Props {
  data: Omit<Recipients, 'recipients'>
  phones: IExistingPhone[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const HeadInfo = ({ data, onChange, phones }: Props) => {
  const { t } = useTranslation('notifications')

  return (
    <Wrapper>
      <TextField
        value={data.from_name}
        onChange={onChange}
        label={t('senderName')}
        name='from_name'
        required
      />

      <TextField
        value={data.reply_to}
        onChange={onChange}
        label={t('replyTo')}
        name='reply_to'
        required
      />

      <TextField
        value={data.subject}
        onChange={onChange}
        label={t('subject')}
        name='subject'
        required
      />

      {!!phones.length && (
        <Select
          name='from_number'
          label={t('fromNumber')}
          value={data.from_number}
          onChange={onChange}
          options={phones.map(({ number, alias = '' }) => ({
            value: number,
            label: `${alias ?? ''} ${parsePhoneNumber(number)}`,
          }))}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > * {
    margin-bottom: 10px;
  }
`
