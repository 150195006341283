import React, { useState, useEffect } from 'react'
import momemt from 'moment-timezone'

import { Modal as BootstrapModal } from 'react-bootstrap'

import Modal from '../Modal'
import CustomTable from '../../../components/CustomTable'
import Preloader from '../../../components/Preloader'

import {
  types as ticketTypes,
  statuses as ticketStatuses,
} from '../../../../../../helpers/supportTickets'
import { fetchSupportTickets } from '../../../../../../api/supportTickets'

import './style.scss'

const head = [
  { label: 'ID', sort: true, sort_name: 'number' },
  { label: 'Subject', sort: true, sort_name: 'subject' },
  { label: 'Type', sort: true, sort_name: 'type' },
  { label: 'Date sent', sort: true, sort_name: 'created_at' },
  { label: 'Updated date', sort: true, sort_name: 'updated_at' },
  { label: 'Status', sort: true, sort_name: 'status' },
]

const ListingModal = ({ isShow, onHide, me, toCreate, toTicket }) => {
  const [tickets, setTickets] = useState([])

  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [tableControl, setTableControl] = useState({
    sort: 'updated_at',
    order: 'desc',
    page: 1,
    perPage: 10,
  })

  const loadTickets = () =>
    fetchSupportTickets(tableControl).then(response => {
      setTickets(response.data)
      setTotal(response.total)
    })

  useEffect(() => {
    if (isShow) {
      setIsLoading(true)
      loadTickets().finally(() => {
        setIsLoading(false)
      })
    }
  }, [isShow])

  useEffect(() => {
    loadTickets()
  }, [tableControl])

  const handleHide = () => {
    onHide()
  }

  const handleTableControl = params => {
    setTableControl({ ...tableControl, ...params })
  }

  const handleChangePage = page => {
    handleTableControl({ page })
  }

  const handleChangePerPage = perPage => {
    handleTableControl({ page: 1, perPage })
  }

  const handleSort = sort => {
    const order =
      sort !== tableControl.sort || tableControl.order === 'desc'
        ? 'asc'
        : 'desc'
    handleTableControl({ sort, order })
  }

  const tableRowsRender = tickets?.map((item, idx) => (
    <tr
      style={{ cursor: 'pointer' }}
      key={idx}
      onClick={() => {
        toTicket(item.id)
      }}
    >
      <td>
        <div className='tickets-first-column__container'>
          <span>{item.number}</span>
          {!!item.is_unseen && <div className='updates-ticket__flag'>New</div>}
        </div>
      </td>
      <td>{item.subject}</td>
      <td>{ticketTypes[item.type]}</td>
      <td>{momemt(item.created_at, 'X').format('DD/MM/YYYY, h:mma')}</td>
      <td>{momemt(item.updated_at, 'X').format('DD/MM/YYYY, h:mma')}</td>
      <td>{ticketStatuses[item.status]}</td>
    </tr>
  ))

  return (
    <Modal
      isShow={isShow}
      titleComponent={() => (
        <div>
          <BootstrapModal.Title>{`Hey ${me.full_name}!`}</BootstrapModal.Title>
          <div>Please submit a Request form below</div>
        </div>
      )}
      size='xl'
      onHide={handleHide}
    >
      {isLoading ? (
        <Preloader />
      ) : (
        <div>
          <CustomTable
            head={head}
            rows={tableRowsRender}
            total={total}
            pageSize={tableControl.perPage}
            page={tableControl.page}
            showSizeChanger
            onChangeLimit={handleChangePerPage}
            onSort={handleSort}
            onChangePage={handleChangePage}
          >
            {tableRowsRender}
          </CustomTable>
        </div>
      )}
      <div className='d-flex justify-content-end'>
        <button className='btn btn-custom-cancel' onClick={handleHide}>
          Close
        </button>
        <button className='btn btn-custom-info ml-2' onClick={toCreate}>
          Create new Help Request
        </button>
      </div>
    </Modal>
  )
}

export default ListingModal
