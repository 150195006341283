import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button } from 'antd'
import validator from 'validator'
import * as media from 'api/Media'
import Preloader from '../../../components/Preloader'
import { viewFile } from '../../../../../../helpers/viewFile/viewFile'
import { deleteMedia, cancelUploadRequest } from '../../../../../../api/Media'
import MainButton from '../../../components/buttons/MainButton'
import { TextField } from '@material-ui/core'
import { SmallPreloader } from '../../../components/Preloader/SmallPreloader'
import ImgCropper from '../CompanyInfo/ImgCropper/ImgCropper'
import getCroppedImg from '../CompanyInfo/ImgCropper/cropImage'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const loadImgFromUrlError = async value => {
  if (
    !value ||
    /^data:((?:\w+\/(?:(?!;).)+)?)((?:;[\w\W]*?[^;])*),(.+)$/.test(value)
  )
    return ''
  const isURL = validator.isURL(value, { require_protocol: true })
  if (value && !isURL) return 'Please enter valid url'
  else {
    const promise = new Promise(resolve => {
      const img = new Image()
      img.src = value
      img.crossOrigin = 'anonymous'
      img.onload = function() {
        resolve('')
      }
      img.onerror = function() {
        resolve("Can't load image from this link. Try another one")
      }
    })
    return promise
  }
}

const getFilePath = file =>
  file
    ? typeof file === 'string'
      ? file
      : `${process.env.REACT_APP_API_MEDIA}${file.path}`
    : ''

export const dataURLtoFile = (b64Data, filename) => {
  let arr = b64Data.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length
  //u8arr = new Uint8Array(n)
  const byteArray = new Uint8Array(n)
  while (n--) {
    byteArray[n] = bstr.charCodeAt(n)
  }
  const blob = new Blob([byteArray], { type: mime })
  const file = new File([blob], filename) /*  blobToFile(blob, filename) */
  return file
}

const AttachmentsModal = props => {
  const [localFile, setLocalFile] = useState(null)
  const [addText, changeAddText] = useState('')
  const [isUploadWindowOpening, setIsUploadWindowOpening] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCanceling, setIsCanceling] = useState(false)
  const [edit, setEdit] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [linkError, setlinkError] = useState('')

  const [fileToCrop, setFileToCrop] = useState(null)

  /////////////up data from children croppComponent ///////
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [imgSrc, setImgSrc] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [isCropping, setIsCropping] = useState(false)
  ///////
  // eslint-disable-next-line no-unused-vars
  const { show, file, fileNumber } = props.attachmentModal
  const { fileIdsToDelete, setfileIdsToDelete } = props
  const currentFileId =
    !!localFile && typeof localFile !== 'string' && !!localFile.id
      ? localFile.id
      : null

  const existedFilePath = getFilePath(file)
  const localFilePath = getFilePath(localFile) || addText
  const isSameNewAndOldPaths = existedFilePath === localFilePath
  const showLinkErrorIfDifferentNewAndOldPaths = !isSameNewAndOldPaths

  let inputRef = useRef()
  const resetState = () => {
    setLocalFile('')
    changeAddText('')
    setIsUploadWindowOpening(false)
    setIsUploading(false)
    setIsDeleting(false)
    setEdit(true)
    setIsSubmitting(false)
    setlinkError('')
    setIsCanceling(false)
    setFileToCrop(null)
  }

  useEffect(() => {
    if (show) {
      if (file) {
        setLocalFile(file)
        changeAddText(
          typeof file === 'string'
            ? file
            : file.path
            ? process.env.REACT_APP_API_MEDIA + file.path
            : '',
        )
      }
    } else {
      resetState()
    }
  }, [show])

  useEffect(() => {
    document.body.addEventListener('focusout', () => {
      setIsUploadWindowOpening(false)
    })
    return () => {
      document.body.removeEventListener('focusout', () => {
        setIsUploadWindowOpening(false)
      })
    }
  }, [])

  function enter() {
    setIsUploadWindowOpening(true)
    document.body.addEventListener('focusin', leave)
  }
  function leave() {
    setTimeout(function() {
      setIsUploadWindowOpening(false)
      document.body.removeEventListener('focusin', leave)
    }, 100)
  }

  const oploadFileToServer = file => {
    setIsUploading(true)
    setlinkError('')
    const fd = new FormData()
    fd.append('media', file)
    return media
      .upload(fd)
      .then(res => {
        setfileIdsToDelete(prev =>
          currentFileId ? [...prev, currentFileId] : prev,
        )
        setLocalFile(res[0])
        changeAddText('')
        setFileToCrop(null)
        return res[0]
      })
      .catch(error => {
        setlinkError('Upload failed. Please try again')

        openNotificationWithIcon('error', {
          message: error?.response?.data?.message || 'Server error',
        })
      })
      .finally(() => {
        setIsUploading(false)
        setIsUploadWindowOpening(false)
      })
  }

  const onChooseFile = e => {
    const file = e.target.files[0]
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/jpg'
    const isLt20M = file.size / 1024 / 1024 <= 20
    if (!isJpgOrPng) {
      return setlinkError('Please use jpg, jpeg or png file')
    } else if (!isLt20M) {
      return setlinkError("File for upload cant't be more 20Mb")
    }
    setlinkError('')
    setFileToCrop(file)
  }

  const setCroppedLogo = file => {
    oploadFileToServer(file).then(fileFromServer => onSave(fileFromServer))
  }

  const onCancelCrop = () => {
    setFileToCrop(null)
    //oploadFileToServer(fileToCrop)
  }

  const onUploadByLink = isSaving => {
    if (!!addText && !linkError && !localFile) {
      setIsUploading(true)
      if (isSaving) setIsSubmitting(true)
      media
        .uploadByLink(addText)
        .then(res => {
          if (isSaving) {
            if (currentFileId) deleteMedia(currentFileId)
            props.save(fileNumber, res[0])
            return props.toggleModal()
          }
          setfileIdsToDelete(prev =>
            currentFileId ? [...new Set([...prev, currentFileId])] : prev,
          )
          setLocalFile(res[0])
          changeAddText('')
          setIsUploading(false)
          setEdit(true)
        })
        .catch(() => {
          setIsUploading(false)
          if (isSaving) setIsSubmitting(false)
        })
    } else {
      setEdit(true)
    }
  }
  const onDeleteRow = () => {
    const fileId = (localFile || {}).id

    /* if (fileId) {
      setIsDeleting(fileId)
      deleteMedia(fileId)
        .then(() => {
          setLocalFile('')
          setIsDeleting(false)
          setEdit(false)
          changeAddText('')
        })
        .catch(() => {
          setIsDeleting(false)
        })
    } else { */
    setLocalFile('')
    setlinkError(
      file ? '' : 'Please input valid link or upload Logo from your device',
    )
    changeAddText('')
    setEdit(true)

    if (fileId) {
      setfileIdsToDelete(prev =>
        fileId ? [...new Set([...prev, fileId])] : prev,
      )
    }
    /* } */
  }

  const onSave = file => {
    if (!!addText && !linkError && !localFile && !file) {
      onUploadByLink(true)
    } else {
      props.save(fileNumber, file ? file : localFile)
      props.toggleModal()
    }
  }

  const showCroppedImage = async () => {
    try {
      setIsUploading(true)
      setIsCropping(true)
      const croppedImageInBase64 = await getCroppedImg(
        imgSrc,
        croppedAreaPixels,
        rotation,
      )
      const newFile = dataURLtoFile(
        croppedImageInBase64,
        fileToCrop.name,
        fileToCrop.mime_type,
      )
      setCroppedLogo(newFile)
      setIsCropping(false)
    } catch (e) {
      setIsCropping(false)
      setIsUploading(false)
    }
  }

  const onCancel = () => {
    cancelUploadRequest('Uploading was cancelled')
    if (
      (!file && !!currentFileId) ||
      (!!file &&
        !!localFile &&
        (typeof file === 'string' || file.id !== localFile.id))
    ) {
      fileIdsToDelete.forEach(fileId => {
        deleteMedia(fileId)
      })
      if (currentFileId && !linkError) deleteMedia(currentFileId)
      setfileIdsToDelete([])
    }

    props.toggleModal()
  }
  const onChangeAddText = e => {
    const { value } = e.target

    const samePathWithExisted = value === existedFilePath
    const newIdsToDelete = samePathWithExisted
      ? fileIdsToDelete.filter(id =>
          typeof file !== 'string' && !!file ? id !== file.id : true,
        )
      : currentFileId
      ? [...new Set([...fileIdsToDelete, currentFileId])]
      : fileIdsToDelete

    if (!value) {
      setlinkError(file ? '' : 'Load logo by link or from your device')
      setEdit(true)
      setFileToCrop(null)
    } else if (!samePathWithExisted) {
      loadImgFromUrlError(value).then(err => {
        setFileToCrop(err ? null : value)
        setlinkError(err)
      })
    } else {
      setlinkError('')
      setEdit(true)
    }
    setLocalFile(samePathWithExisted ? file : null)
    setfileIdsToDelete(newIdsToDelete)
    changeAddText(value)
  }

  const disableSave =
    isDeleting ||
    isUploading ||
    isUploadWindowOpening ||
    (isSameNewAndOldPaths && !fileToCrop) ||
    (!!linkError && showLinkErrorIfDifferentNewAndOldPaths)

  return (
    <>
      <Modal
        title={`Logo ${fileNumber}`}
        visible={show}
        onCancel={onCancel}
        className='settings-proposal-add-logo-modal'
        footer={[
          <Button
            key='cancel'
            variant='default'
            onClick={onCancel}
            className='main-button'
            style={{
              color: '#191919',
              border: '1px solid #D6D6D6',
              marginRight: '10px',
              height: '30px',
            }}
          >
            Cancel
          </Button>,
          <MainButton
            key='save'
            title='Save'
            onClick={
              disableSave
                ? () => {}
                : () => (!fileToCrop ? onSave() : showCroppedImage())
            }
            disabled={disableSave}
            isFetching={isSubmitting}
            className='main-button'
          />,
        ]}
        maskClosable={false}
      >
        {!!fileToCrop && !isUploading && !linkError && (
          <div className='d-flex justify-items-center'>
            <ImgCropper
              file={fileToCrop}
              setCroppedLogo={setCroppedLogo}
              label={`Logo ${fileNumber}`}
              onCancelCrop={onCancelCrop}
              hideButtons={true}
              ///////////////////
              setCropFromParent={setCrop}
              setRotationFromParent={setRotation}
              setZoomFromParent={setZoom}
              setCroppedAreaPixelsFromParent={setCroppedAreaPixels}
              setImgSrcFromParent={setImgSrc}
              setCroppedImageFromParent={setCroppedImage}
              setIsCroppingFromParent={setIsCropping}
            />
          </div>
        )}
        {!fileToCrop && !!localFile && !isUploading && !edit && (
          <div
            className='d-flex mt-2 mb-2 align-items-top file-row'
            style={{ alignItems: 'center' }}
          >
            <TextField
              id='settings-proposal-local-file'
              type='text'
              label={`Logo ${fileNumber}`}
              name='company_name'
              value={
                localFile
                  ? process.env.REACT_APP_API_MEDIA + localFile.path
                  : ''
              }
              variant='outlined'
              fullWidth={true}
              size='small'
              disabled={true}
            />
            <i
              className='mdi mdi-eye'
              onClick={() => {
                viewFile({ file: localFile, viewOnly: true })
              }}
            />

            {isDeleting ? (
              <div
                className='d-flex align-items-center'
                style={{ height: '40px' }}
              >
                <SmallPreloader />
              </div>
            ) : (
              <i
                className='mdi mdi-delete'
                onClick={() => {
                  onDeleteRow()
                }}
              />
            )}
          </div>
        )}
        {!fileToCrop && (!localFile || edit) && !isUploading && (
          <>
            <div className='d-flex mt-2 mb-2 align-items-top file-row'>
              <TextField
                id='settings-proposal-local-file'
                type='text'
                label={`Logo ${fileNumber}`}
                placeholder='Url to the file'
                name='company_name'
                value={addText}
                onChange={onChangeAddText}
                variant='outlined'
                error={!!linkError && showLinkErrorIfDifferentNewAndOldPaths}
                helperText={
                  showLinkErrorIfDifferentNewAndOldPaths ? linkError : ''
                }
                fullWidth={true}
                size='small'
              />
              {
                /* (!linkError || !showLinkErrorIfDifferentNewAndOldPaths) && !!addText ? (
                <i className='mdi mdi-check' onClick={onUploadByLink} />
              ) : ( */
                <>
                  {(!!localFile || (!!addText && !linkError)) && (
                    <i
                      className='mdi mdi-eye'
                      onClick={() => {
                        viewFile({
                          file: localFile ? localFile : addText,
                          viewOnly: true,
                        })
                      }}
                    />
                  )}
                  <input
                    style={{ display: 'none' }}
                    type='file'
                    ref={input => (inputRef = input)}
                    onChange={onChooseFile}
                    accept='.jpg, .jpeg, .png'
                  />
                  {!isUploadWindowOpening ? (
                    <i
                      className='mdi mdi-cloud-upload-outline'
                      onClick={() => {
                        enter()
                        inputRef.click()
                      }}
                    />
                  ) : (
                    <div
                      className='d-flex align-items-center'
                      style={{ height: '40px' }}
                    >
                      <SmallPreloader />
                    </div>
                  )}
                </>
                /* ) */
              }
              {isDeleting ? (
                <SmallPreloader />
              ) : !!localFile || !!addText ? (
                <i
                  className={
                    'mdi mdi-delete' +
                    (!!localFile || (!!addText && !linkError) ? ' ml-1' : '')
                  }
                  onClick={() => {
                    if (!localFile) changeAddText('')
                    else {
                      onDeleteRow()
                    }
                  }}
                />
              ) : null}
            </div>
          </>
        )}
        {isUploading && (
          <div className='d-flex justify-content-center w-100'>
            <Preloader />
          </div>
        )}
        {/* !fileToCrop && !!localFile && !isUploading && !edit && (
          <span
            className='main-button edit-button'
            onClick={e => {
              changeAddText(process.env.REACT_APP_API_MEDIA + localFile.path)
              setEdit(true)
            }}
          >{`Edit logo ${fileNumber}`}</span>
        ) */}
      </Modal>
    </>
  )
}

export default AttachmentsModal
