import { Empty, Table } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { unassignMembership } from 'api/Membership'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import TableActionsNew from 'containers/MainContent/Orcatec/components/TableActionsNew'
import { getContrastYIQ } from 'helpers/contrastColor'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { getMembershipStatus } from 'pages/Membership/PageMemberships'
import { useState } from 'react'
import styled from 'styled-components'
import AssignMembershipForm from '../AssignMembershipForm'
import {
  MembershipDiscountType,
  MembershipEntityType,
  MembershipLabel,
} from '../types'
import { removeMembership } from 'features/Contacts/slice/contactSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface Props {
  data: MembershipLabel[]
  entityType: MembershipEntityType
  entityId: string
  onUpdate?: () => void
  hideAddBtn: boolean
}
export const MembershipEntries = ({
  data,
  onUpdate,
  entityType,
  entityId,
  hideAddBtn = false,
}: Props) => {
  const { t } = useTranslation('property')

  const [openForm, setOpenForm] = useState(false)
  const dispatch = useDispatch()
  const columns: ColumnProps<MembershipLabel>[] = [
    {
      title: t('tabs.memberships.name'),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record) => {
        return (
          <MembershipName backgroundColor={record.color}>{text}</MembershipName>
        )
      },
    },
    {
      title: t('tabs.memberships.discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: (text, record) => {
        return `${
          record.discount_type === MembershipDiscountType.Amount ? '$' : ''
        }${text}${
          record.discount_type === MembershipDiscountType.Percent ? '%' : ''
        }`
      },
    },
    {
      title: t('tabs.memberships.startDate'),
      dataIndex: 'start_date',
      key: 'start_date',
    },
    {
      title: t('tabs.memberships.endDate'),
      dataIndex: 'end_date',
      key: 'end_date',
    },
    {
      title: t('tabs.memberships.status'),
      dataIndex: 'status',
      render: status => getMembershipStatus(status),
    },
    {
      title: t('tabs.memberships.actions'),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (value: any, membership) => {
        if (!membership.id) return
        return (
          <div className='attached-client-delete-confirm'>
            <TableActionsNew
              todos={['detach']}
              popConfirms={[t('tabs.memberships.deleteMembership')]}
              callbacks={[() => handleUnassignMembership(membership?.id)]}
              tooltips={[t('tabs.memberships.unassign')]}
              popConfirmPositions={['left']}
              tooltipPlacement='left'
            />
          </div>
        )
      },
      width: 50,
    },
  ]

  const toggleMembershipForm = e => {
    e.preventDefault()
    setOpenForm(!openForm)
  }

  const handleUnassignMembership = async (membershipId: number) => {
    try {
      await unassignMembership(entityType, entityId, { label_id: membershipId })
      dispatch(removeMembership({ label_id: membershipId }))
      onUpdate?.()

      openNotificationWithIcon('success', {
        message: t('tabs.memberships.unassignSuccess'),
      })
    } catch (error) {
      console.error(error)
      openNotificationWithIcon('error', {
        message: error?.response?.data?.message || 'Something went wrong',
      })
    }
  }
  return (
    <Wrapper>
      {openForm && (
        <AssignMembershipForm
          entityType={entityType}
          entityId={entityId}
          onClose={() => setOpenForm(false)}
          onUpdate={onUpdate}
        />
      )}

      {data?.length ? (
        <Table
          columns={columns}
          dataSource={data}
          size={'small'}
          pagination={false}
          style={{ maxWidth: 1000 }}
        />
      ) : (
        <Empty description={t('tabs.memberships.noMemberships')}>
          {!hideAddBtn && (
            <MainButton
              title={t('tabs.memberships.assign')}
              onClick={toggleMembershipForm}
              style={{ margin: '0 auto' }}
            />
          )}
        </Empty>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .ant-empty-footer {
    margin-top: 0;
  }
`
// const Heading = styled.div`
//   margin-bottom: 10px;
// `

const MembershipName = styled.span<{ backgroundColor: string }>(
  ({ backgroundColor }) => ({
    padding: 5,
    backgroundColor,
    borderRadius: 4,
    color: getContrastYIQ(backgroundColor),
  }),
)
