import {
  CalendarOutlined,
  CheckSquareOutlined,
  FontColorsOutlined,
  // FontColorsOutlined,
  FontSizeOutlined,
  PictureOutlined,
  UploadOutlined,
} from '@ant-design/icons'

import { FormBuilderContext } from 'features/Forms/FormBuilderContext'
import { useContext } from 'react'
import { uuid4 } from '@sentry/utils'

import { templates } from 'features/Forms/utils/componentTemplates'
import { Element, Wrapper } from './ComponentsList.styles'
import { Tooltip } from 'antd'

export const ComponentsList = () => {
  const { updateForm, form } = useContext(FormBuilderContext)
  const { componentIds, body: components } = form

  const handleAddElement = (type: string) => {
    const id = uuid4()

    updateForm({
      ...form,
      body: { ...components, [id]: { ...templates[type], id } },
      componentIds: [...componentIds, id],
    })
  }

  const disableSignature =
    Object.values(components)?.filter(item => item?.type === 'signature')
      ?.length >= 1

  return (
    <Wrapper>
      <h6>Components</h6>

      <Element onClick={() => handleAddElement('input')}>
        <FontSizeOutlined />
        Textfield
      </Element>
      <Element onClick={() => handleAddElement('note')}>
        <FontColorsOutlined />
        Note
      </Element>
      <Element onClick={() => handleAddElement('date')}>
        <CalendarOutlined />
        Date
      </Element>
      <Element onClick={() => handleAddElement('select')}>Select</Element>
      <Element onClick={() => handleAddElement('checkbox_group')}>
        <CheckSquareOutlined />
        Checkbox Group
      </Element>
      <Element onClick={() => handleAddElement('upload')}>
        <UploadOutlined />
        File Upload
      </Element>
      <Element onClick={() => handleAddElement('picture')}>
        <PictureOutlined />
        Picture
      </Element>
      {!disableSignature ? (
        <Element onClick={() => handleAddElement('signature')}>
          Signature
        </Element>
      ) : (
        <Tooltip title='Only one element can be selected at one form. '>
          <Element disabled={disableSignature}>Signature</Element>
        </Tooltip>
      )}
    </Wrapper>
  )
}
