import { useTranslation } from 'react-i18next'

export const OverflowIcon = ({ color = 'currentColor', withTitle = true }) => {
  const { t } = useTranslation('navigation')

  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        aria-hidden='true'
        role='img'
        width='35'
        height='35'
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 24 24'
      >
        <path
          fill={color}
          d='M16 12a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2m-6 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2Z'
        />
      </svg>
      {withTitle && <p>{t('more')}</p>}
    </div>
  )
}
