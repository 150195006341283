import React, { useEffect, useState } from 'react'
import {
  parsePhoneNumber,
  parsePhoneNumberBeforeSend,
} from 'features/Messaging/utils'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { Card, Modal, Popconfirm } from 'antd'
import { IExistingPhone } from 'features/Messaging/types'
import {
  forwardToNumber,
  deleteInternalNumber,
  addSourcesToNumber,
} from 'api/Calls'
import styled from 'styled-components'
import { useAppSelector } from 'store/Orcatec/hooks'
import ReactInputMask from 'react-input-mask'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import { SettingsRow } from 'containers/MainContent/Orcatec/components/UI/SettingsRow/SettingsRow'
import { useQueryClient } from '@tanstack/react-query'
import { AppointmentSources } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/components/AppointmentSources/AppointmentSources'
import { selectUserPermissionsByName } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  TypeSourcePermissions,
  ModuleName,
} from 'features/Settings/UsersAndGroups'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

interface Props {
  row: IExistingPhone
  fetchTableData: () => void
  toggleModal: () => void
  visible: boolean
  onChange: () => void
}

const style = { display: 'grid', gridTemplateColumns: '150px 1fr' }
// const phoneRegExp = /^[(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/
export const PhoneDetailsModal: React.FC<Props> = ({
  row,
  // handleDelete,
  toggleModal,
  visible,
  title,
}) => {
  const queryClient = useQueryClient()
  const sources = useAppSelector(state => state.orcatec.company.source)

  const canEditTypeSource = useAppSelector(
    selectUserPermissionsByName(
      ModuleName.TYPE_SOURCE,
      TypeSourcePermissions.TYPE_SOURCE_CAN_CREATE,
    ),
  )

  const [errors, setErrors] = useState(false)
  const [selectedRow, setSelectedRow] = useState(row)

  useEffect(() => {
    setSelectedRow({ ...row, sources: row?.sources?.map(s => s?.id) })
  }, [row])

  const onChange = (name, value) => {
    if (name === 'forward_to_number') {
      const num = parsePhoneNumberBeforeSend(value)
      const numLength = num.replace(/-|_/g, '').length

      const isError = numLength < 11

      setErrors(prev => ({ ...prev, [name]: isError }))
    }
    setSelectedRow(prev => ({ ...prev, [name]: value }))
  }

  const handleForwardToNumber = async () => {
    // setLoading(true)
    try {
      const { forward_to_number, id } = selectedRow
      const parsedNumber = parsePhoneNumberBeforeSend(forward_to_number)
      await forwardToNumber(id, { forward_to_number: parsedNumber })
      setSelectedRow(prev => ({ ...prev, forward_to_number: parsedNumber }))
      queryClient.invalidateQueries({ queryKey: ['calls/settings'] })
    } catch (error) {
      console.error(error)
    }
  }

  const handleAddSourcesToNumber = async () => {
    try {
      const { sources: source, id } = selectedRow
      await addSourcesToNumber(id, { source_id: source })
      const sourcesStr = sources
        .filter(s => source.includes(s.id))
        .map(s => s.name)
        .join(', ')
      setSelectedRow(prev => ({ ...prev, source_str: sourcesStr }))
      queryClient.invalidateQueries({ queryKey: ['calls/settings'] })
    } catch (error) {
      console.error(error)
    }
  }

  const handleDeleteNumber = async () => {
    try {
      await deleteInternalNumber(selectedRow.id.toString())
      queryClient.invalidateQueries({ queryKey: ['calls/settings'] })
      toggleModal()
    } catch (error) {
      console.error(error)
    }
  }

  const handleCancel = () => {
    if (errors?.forward_to_number) {
      return openNotificationWithIcon('error', {
        message: 'Enter a valid phone number',
      })
    } else {
      toggleModal()
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      title={title}
      zIndex={100}
      style={{ top: 20 }}
      destroyOnClose
      centered={true}
      width={600}
    >
      <Card bordered={false} size='small'>
        <DetailsContainer>
          <SettingsRow styles={style} title='Phone number'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span> {parsePhoneNumber(row?.number)}</span>
              <Popconfirm
                title='Are you sure?'
                onCancel={() => null}
                onConfirm={handleDeleteNumber}
              >
                <MainButton title='Delete' type='delete' />
              </Popconfirm>
            </div>
          </SettingsRow>

          <SettingsRow styles={style} title='Sources'>
            <EditableBlock
              closeOnBlur={false}
              editMode={false}
              value={selectedRow?.source_str}
              onSave={handleAddSourcesToNumber}
            >
              <AppointmentSources
                name='sources'
                withoutLabel
                onChange={e =>
                  onChange(
                    e.target.name,
                    !!e?.target?.value ? [e.target.value] : [],
                  )
                }
                value={selectedRow?.sources?.[0]}
                style={{ width: '200px' }}
                disableCRUD={!canEditTypeSource}
              />
            </EditableBlock>
          </SettingsRow>
          <SettingsRow styles={style} title='Forward to*'>
            <EditableBlock
              closeOnBlur={false}
              editMode={errors?.forward_to_number || false}
              value={parsePhoneNumber(selectedRow?.forward_to_number)}
              onSave={handleForwardToNumber}
              onCancel={() =>
                setSelectedRow(prev => ({
                  ...prev,
                  forward_to_number: row.forward_to_number,
                }))
              }
            >
              <ReactInputMask
                name='forward_to_number'
                onChange={e => {
                  onChange(e.target.name, e.target.value)
                }}
                style={{ width: '100px' }}
                mask='+1(999) 999-9999'
                value={selectedRow?.forward_to_number}
              >
                {() => (
                  <InputField
                    name='forward_to_number'
                    helperText={
                      !!errors?.forward_to_number &&
                      'Phone must match the following: (999) 999-9999'
                    }
                    fullWidth={true}
                    error={errors?.forward_to_number}
                  />
                )}
              </ReactInputMask>
            </EditableBlock>
          </SettingsRow>
        </DetailsContainer>
      </Card>
    </Modal>
  )
}
const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
