import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import App from './App'
import SureModalRedirect from './containers/MainContent/Orcatec/components/SureModalRedirect/SureModalRedirect'
import * as serviceWorker from './serviceWorker'
import Fallback from 'containers/MainContent/Orcatec/components/ErrorBoundary/Fallback'
import configureStore from 'store'
import { RouterProvider } from './helpers/RouterProvider'
import CookiesDetector from 'helpers/CookiesDetector/CookiesDetector'
import { errorHandler } from 'containers/MainContent/Orcatec/components/ErrorBoundary/errorHandler'
import packageJson from '../package.json'
import './i18n'

const tagManagerArgs = {
  gtmId: 'GTM-KKBM77Q',
}

//Google Analytics and GTM
if (window.location.host === 'app.orcatec.com') {
  ReactGA.initialize('G-WDP71F8DCD')

  TagManager.initialize(tagManagerArgs)
}

///Sentry
const dsn =
  process.env.REACT_APP_ENV !== 'production'
    ? 'https://de60d42df1fb40be90b8c499c592b0b8@o927788.ingest.sentry.io/5882958'
    : 'https://60017b7e576f4c9085f5c9147df4ad9c@o927788.ingest.sentry.io/5877320'

if (process?.env?.NODE_ENV !== 'development') {
  Sentry.init({
    release: 'Orcatec@' + packageJson?.version,
    dsn,
    integrations: [new Integrations.BrowserTracing()],
    ignoreErrors: [
      'Error: Network Error',
      'Error: ResizeObserver loop limit exceeded',
      'NetworkError',
      'ResizeObserver loop limit exceeded',
      'Non-Error promise rejection captured with keys',
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'console') {
        return null
      }
      return breadcrumb
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
  })
}
////Sentry

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const store = configureStore()
export const sessionStartTimeStamp = Date.now() //for compare with some new elements where id will be timestamp which will be created in current session

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter getUserConfirmation={SureModalRedirect}>
      <RouterProvider>
        <CookiesDetector>
          <Sentry.ErrorBoundary
            onError={errorHandler}
            fallback={() => <Fallback />}
          >
            {/* <CacheBuster>
              {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null
                if (!loading && !isLatestVersion) refreshCacheAndReload()

                return <App />
              }}
            </CacheBuster> */}
            <QueryClientProvider client={queryClient}>
              <App />
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Sentry.ErrorBoundary>
        </CookiesDetector>
      </RouterProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
