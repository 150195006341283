import { Button as AtndButton, ButtonProps } from 'antd'
import usePermissions from 'features/Settings/UsersAndGroups/hooks/usePermissions'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import styled from 'styled-components'

interface Props extends ButtonProps {
  children: React.ReactNode
  secondary?: boolean
  withPermissions?: string[]
}

export const Button = ({
  children,
  withPermissions,
  onClick,
  ...rest
}: Props) => {
  const hasPermissions = usePermissions(withPermissions)

  function handleButtonClick(e: React.MouseEvent<HTMLElement>) {
    if (!hasPermissions) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    onClick?.(e)
  }

  return (
    <StyledButton onClick={handleButtonClick} {...rest}>
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(AtndButton)<Props>`
  ${({ secondary }) =>
    secondary &&
    `
    background-color: #edeffe;
    color: #4285f4;
    border-color: #4285f4;

    // &:hover,
    &:focus {
      background-color: #edeffe;
      color: #4285f4;
      border-color: #4285f4;
    }
  `}
`
