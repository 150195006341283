import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import styled from 'styled-components'

interface Props {
  align?: 'left' | 'right' | 'center'
  children: React.ReactNode
  description?: string | React.ReactNode
  error?: string
  paddingLeft?: number
  required?: boolean
  title: string
  width?: number
  withInfoIcon?: boolean
  boldTitle?: boolean
  height?: number
}

export const SettingsRow = ({
  children,
  title,
  description,
  withInfoIcon,
  required,
  width,
  height,
  paddingLeft = 10,
  align = 'left',
  boldTitle,
}: Props) => {
  const content = <Description>{description}</Description>

  return (
    <SettingsRowStyled
      width={width}
      height={height}
      paddingLeft={paddingLeft}
      data-testid='settings-row'
    >
      <Title bold={!!boldTitle}>
        {required ? `${title} *` : `${title}`}

        {!!description && withInfoIcon && (
          <Popover
            title={title}
            content={content}
            placement='right'
            trigger={'click'}
          >
            <span
              style={{
                marginLeft: 5,
                cursor: 'help',
              }}
            >
              <QuestionCircleOutlined />
            </span>
          </Popover>
        )}
      </Title>

      <Content>
        <Settings align={align}>{children}</Settings>

        {!!description && !withInfoIcon && (
          <Description>{description}</Description>
        )}
      </Content>
    </SettingsRowStyled>
  )
}

const SettingsRowStyled = styled.div<{
  width?: number
  paddingLeft: number
  height?: number
}>(({ width = 200, paddingLeft, height }) => ({
  display: 'grid',
  alignItems: 'baseline',
  gridTemplateColumns: `${width}px 1fr`,
  gap: 10,
  minHeight: height ? `${height}px` : 'auto',
  padding: '12px 0',
  borderBottom: '1px solid #0000000f',
  paddingLeft: paddingLeft,

  '@media(max-width: 500px)': {
    gridTemplateColumns: `1fr`,
  },
}))

const Title = styled.p<{ bold: boolean }>`
  color: #707683;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`
const Description = styled.div`
  font-size: 0.8rem;
  font-style: italic;
  color: #707683;
  white-space: break-spaces;

  p {
    margin-bottom: 5px;

    span {
      font-weight: 600;
    }
  }
`

const Settings = styled.div<{ align: 'left' | 'right' | 'center' }>`
  display: flex;
  justify-content: ${props =>
    props.align === 'right'
      ? 'flex-end'
      : props.align === 'center'
      ? 'center'
      : 'flex-start'};
`
const Content = styled.div``
