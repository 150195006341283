import { ImageUploader } from 'components/UIKit/ImageUploader/ImageUploader'
import { useEffect, useState } from 'react'
import FileUploadService from 'services/FileUploadService'
import styled from 'styled-components'
import { Media } from 'types/Media'

interface Props {
  onUpload: (image: Media | null) => void
  value: Media | null
}

export const Picture = ({ value, onUpload }: Props) => {
  const [image, setImage] = useState<Media | null>(value)

  useEffect(() => {
    if (image !== value) onUpload(image)
  }, [image])

  const handleImageUpload = async (image: File) => {
    try {
      const [media] = await FileUploadService.upload(image)
      setImage(media)
    } catch (error) {
      console.error(error.reponse.data)
    }
  }

  const handleImageDelete = () => setImage(null)

  return (
    <Wrapper>
      <ImageUploader
        minimalistic
        image={image?.preview_source || ''}
        onUploadFinish={handleImageUpload}
        onDeleteImage={handleImageDelete}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`
