import { Modal, Tooltip, Popconfirm, Popover, Button } from 'antd'
import { deleteFormTemplate, getTableFormTemplates } from 'api/CustomForms'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import TableLayout, {
  ActionsWrapper,
  IconsBlock,
  IconWrapper,
} from 'containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import Table from 'containers/MainContent/Orcatec/components/Table'

import { FormBuilderContext } from 'features/Forms/FormBuilderContext'
import React, { useContext, useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'

import { getComponentByTypeSettings } from 'features/Forms/utils/getComponentByTypeSettings'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { Settings } from '@material-ui/icons'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import useDebounce from 'hooks/useDebounce'
import { SorterResult } from 'antd/es/table/interface'
import { PaginationConfig } from 'antd/lib/pagination'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import { TransitionProps } from '@material-ui/core/transitions'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import CloseIcon from '@material-ui/icons/Close'
import List from '@material-ui/core/List'
import Slide from '@material-ui/core/Slide'
import { ComponentsList } from 'features/Forms/components/ComponentsList/ComponentsList'
import {
  FormEditor,
  SaveHandler,
} from 'features/Forms/components/FormEditor/FormEditor'
import { FormViewer } from 'features/Forms/components/FormViewer/FormViewer'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { ModuleName, FormsPermissions } from 'features/Settings/UsersAndGroups'
import { selectSubscription } from 'store/Orcatec/reducers/settings/subscription'
import { InfoCircleOutlined } from '@ant-design/icons'
import { ConfirmationModal } from 'containers/MainContent/Orcatec/components/UI/Modal'
import { selectAuthUser } from 'store/Orcatec/selectors/user'

const linkStyle = {
  color: '#1890ff',
  cursor: 'pointer',
}
const tableName = 'templates_form-table'

const confirmEditText =
  'The original template has already been attached to the project and cannot be edited. Do you want to duplicate this template instead?'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />
})
const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: '#4285f4',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))

const FullScreenDialog = ({ handleClose, open, children }) => {
  const classes = useStyles()

  return (
    <div>
      <Dialog
        style={{ zIndex: 999 }}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar style={{ justifyContent: 'end' }}>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>{children}</List>
      </Dialog>
    </div>
  )
}
export const TemplatesTable = () => {
  const childRef = useRef<SaveHandler>(null)

  const timezone = useAppSelector(state => state.orcatec.company.timezone)
  const subscription = useAppSelector(selectSubscription)
  const me = useAppSelector(selectAuthUser)

  const settingsFromLC = getValueFromLS(`${tableName}-table_v1`)
  const { setForm, form, resetForm, wasEdited } = useContext(FormBuilderContext)
  const { body: components, componentIds, title } = form
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [formList, setFormList] = useState([])

  const [formListModal, setFormListModal] = useState(false)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )

  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: settingsFromLC?.page_size || 25,
    total: 10,
  })
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [openTableSettings, setOpenTableSettings] = useState<boolean>(false)
  const [confirmationModal, showConfirmationModal] = useState(false)

  const debouncedSearchValue = useDebounce(search, 500)

  const limitedSubsription =
    !me.subscription_off && !subscription?.modules?.custom_forms?.status

  const handleSelectForm = template => {
    setForm(template)
    setFormListModal(true)
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleEditForm = template => {
    setForm(template)
    handleClickOpen()
  }

  const handleDeleteTemplate = async (templateId: number) => {
    try {
      setLoading(true)
      await deleteFormTemplate(templateId)

      const newTemplates = { ...formList }
      delete newTemplates[templateId]

      handleClose()
      openNotificationWithIcon('success', {
        message: 'Template was deleted succsesfully',
      })
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getTemplateList = async params => {
    setLoading(true)
    try {
      const { data, meta } = await getTableFormTemplates(params)

      setPagination(meta)
      setFormList(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    getTemplateList({ per_page: 25, search: debouncedSearchValue || undefined })
    showConfirmationModal(false)
  }

  const handleCancel = () => {
    if (wasEdited) return showConfirmationModal(true)

    setOpen(false)
    showConfirmationModal(false)
    resetForm()
  }

  const handleSave = () => {
    childRef?.current?.save()

    showConfirmationModal(false)
  }

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ISubmittedTable, string[]>>,
    sorter: SorterResult<ISubmittedTable>,
  ) => {
    getTemplateList({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      ...filters,
    })

    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })
  }

  useEffect(() => {
    getTemplateList({
      per_page: settingsFromLC?.page_size || 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  return (
    <Wrapper>
      <TableLayout
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        actions={
          <ActionsWrapper>
            <IconsBlock>
              {limitedSubsription && (
                <Popover
                  title='Free version'
                  content='Your current plan supports only 1 template. Upgrade your plan to unlock unlimited templates and forms for your account.'
                >
                  <InfoCircleOutlined
                    style={{
                      fontSize: 20,
                      color: '#4285f4',
                      cursor: 'pointer',
                    }}
                  />
                </Popover>
              )}
              <Tooltip
                title={
                  limitedSubsription && !!formList.length
                    ? 'Your current plan supports only 1 template. Upgrade your plan to unlock unlimited templates and forms for your account'
                    : ''
                }
              >
                <Button
                  onClick={handleClickOpen}
                  style={{ width: '120px' }}
                  disabled={limitedSubsription && !!formList.length}
                  type='primary'
                >
                  Add template
                </Button>
              </Tooltip>
              <InputField
                label='Search'
                value={search}
                style={{ width: '100%', maxWidth: '200px' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />

              <IconWrapper onClick={() => setOpenTableSettings(true)}>
                <Tooltip placement='left' title='Columns settings'>
                  <Settings />
                </Tooltip>
              </IconWrapper>
            </IconsBlock>
          </ActionsWrapper>
        }
      >
        <Table
          name={tableName}
          columns={[
            {
              title: 'Title',
              name: 'Title',
              dataIndex: 'title',
              key: 'title',
              defaultWidth: 200,
              sorter: true,
              noFilter: true,
              render: value => <span style={linkStyle}>{value ?? '--'}</span>,
            },
            {
              title: 'Created at',
              name: 'Created at',
              dataIndex: 'created_at',
              key: 'created_at',
              defaultWidth: 200,
              sorter: true,
              noFilter: true,

              render: value =>
                moment(value, 'MM/DD/YYYY HH:mm:ss')
                  .tz(timezone)
                  .format('MM/DD/YYYY hh:mm a'),
            },
            {
              title: 'Created by',
              name: 'Created by',
              dataIndex: 'created_by',
              key: 'created_by',
              defaultWidth: 200,
              noFilter: true,
              sorter: true,
              render: value => value || '--',
            },
          ]}
          data={formList}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          search={debouncedSearchValue}
          openTableSettings={openTableSettings}
          onCloseTableSettings={() => setOpenTableSettings(false)}
          rowKey={obj => obj?.key?.toString()}
          onRow={record => {
            return {
              onClick: () => {
                handleSelectForm(record)
              },
            }
          }}
        />
      </TableLayout>

      <Modal
        visible={formListModal}
        title={title}
        onCancel={() => {
          setFormListModal(false)
          resetForm()
        }}
        width={800}
        destroyOnClose
        bodyStyle={{ height: '70vh', overflow: 'scroll' }}
        footer={
          <ControlsRow>
            <AccessControl
              module={ModuleName.FORMS}
              scopes={[FormsPermissions.FORMS_TEMPLATE_CAN_DELETE]}
            >
              <Popconfirm
                title='Are you sure you want to delete this template?'
                onCancel={() => null}
                onConfirm={() => {
                  handleDeleteTemplate(form.id)
                  setFormListModal(false)
                  resetForm()
                }}
              >
                <MainButton
                  type='delete'
                  style={{ marginRight: 'auto' }}
                  title='Delete'
                />
              </Popconfirm>
            </AccessControl>

            <MainButton
              type='custom-secondary'
              onClick={() => {
                handleEditForm({
                  ...form,
                  action: 'duplicate',
                  title: `Copy - ${form.title}`,
                })
                setFormListModal(false)
              }}
              title='Duplicate'
            />
            <Popconfirm
              title={confirmEditText}
              disabled={!form?.has_forms}
              style={{ width: '150px' }}
              okText='Duplicate'
              overlayStyle={{ width: '250px' }}
              onConfirm={() => {
                handleEditForm({
                  ...form,
                  action: 'duplicate',
                  title: `Copy - ${form.title}`,
                })
                setFormListModal(false)
              }}
            >
              <MainButton
                onClick={() => {
                  if (!form?.has_forms) {
                    setFormListModal(false)
                    handleEditForm(form)
                  }
                }}
                title='Edit'
              />
            </Popconfirm>
          </ControlsRow>
        }
        maskClosable={false}
      >
        {componentIds?.map(id => (
          <ComponentWrapper key={id} mainField={id === form.main_field_id}>
            {getComponentByTypeSettings(components[id], form.main_field_id)}
          </ComponentWrapper>
        ))}
      </Modal>

      {open && (
        <FullScreenDialog open handleClose={handleCancel}>
          <Content>
            <ComponentsListWrapper>
              <ComponentsList />
            </ComponentsListWrapper>

            <FormEditor
              ref={childRef}
              handleClose={handleClose}
              onCancel={handleCancel}
            />
            <FormViewer handleClose={handleClose} />
          </Content>
        </FullScreenDialog>
      )}

      <ConfirmationModal
        visible={confirmationModal}
        onSave={handleSave}
        onCancel={handleClose}
        onClose={() => showConfirmationModal(false)}
        isLoading={loading}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .custom-page-headers {
    position: absolute;
    right: 20px;
    top: -42px;
  }
  @media (max-width: 500px) {
    & .custom-page-headers {
      top: -32px;
    }
  }

  & .ant-table-row {
    cursor: pointer;
  }
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  padding: 30px;
`

const ControlsRow = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
`

const ComponentsListWrapper = styled.div`
  width: 150px;
  min-height: 300px;

  & > div {
    position: sticky;
    top: 20px;
  }
`

const ComponentWrapper = styled.div<{ mainField: boolean }>`
  & > div > p {
    font-weight: ${props => (props.mainField ? 700 : 400)};
  }
`
