import React, { useState, useEffect } from 'react'
import {
  Checkbox,
  Modal,
  Popconfirm,
  Tooltip,
  Radio,
  RadioChangeEvent,
  Space,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useHistory } from 'react-router-dom'
import * as actions from '../../../../../../store/Orcatec/actions'
import { getAppointment, createAppointment } from 'api/Appointment'
import { toggleModalProperty } from 'store/Orcatec/actions/proposal/proposalModalFiles'
import { deleteMedia } from 'api/Media'
import {
  deleteProperty,
  detachClientOrOrganization,
  attachClientOrOrganization,
  manageProperty,
} from 'api/Property'
// import { updateClient } from 'api/Client'
// import { updateOrganization } from 'api/Organizations'
import Appointments from 'containers/MainContent/Orcatec/CreateAppointment/components/Appointments'
import Clients from 'containers/MainContent/Orcatec/CreateAppointment/components/Clients/'
import LoadingScreen from 'containers/MainContent/Orcatec/components/LoadingScreen'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import MainInfo from './components/MainIfo'
import Media from 'containers/MainContent/Orcatec/CreateAppointment/components/Media'
import ModalFileUploadContainer from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalFileUploadContainer'
import TableProposals from 'containers/MainContent/Orcatec/Clients/components/Form/components/TableProposals'
import Tabs from 'containers/MainContent/Orcatec/components/Tabs/Tabs'
import useProperty, {
  PropertyRequestType,
} from 'containers/MainContent/Orcatec/Properties/hooks/useProperty'
import { filterArrayOfObjectsToUniqueKeyValue } from '../../../../../../helpers/filterArrayOfObjectsToUniqueKeyValue'
import { filterDeletedEntities } from 'helpers/filterDeletedEntities'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import AppointmentForm from '../AppointmentForm'

import moment from 'moment-timezone'
import { Property, PropertyType } from 'types/Property'
import { default as Select } from 'containers/MainContent/Orcatec/components/Select'

import './styles.scss'
import '../PagePropertyForm/style.scss'
import { Media as MediaType } from 'types/Media'
import { IAppointment } from 'types/Appointment'
import { Client } from 'types/Client'
import { useAppSelector } from 'store/Orcatec/hooks'
import { BlockRoutingModal } from 'containers/MainContent/Orcatec/components/BlockRoutingModal'

import { initialErrors } from 'containers/MainContent/Orcatec/CreateAppointmentV2/AppointmentForm/AppointmentForm'
import { WithModal } from '../../../../../../hoc/WithModal'
import { makeBrowserTitle } from 'helpers/makeBrowserTitle'
import { getMatrixLog } from 'features/Dispatch/dispatchSlice'
import MembershipEntries from 'features/Membership/MembershipEntries'
import { isShowUpcomingNotificationSelector } from 'store/Orcatec/selectors/appointment'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'

import {
  NotificationTemplateType,
  NotificationModalType,
} from 'features/Notification/types'
import { Companies } from 'types/Company'
import { getNotificationSettings } from 'api/NotificationModal'
import {
  ModuleName,
  PropertyPermissions,
  SchedulePermissions,
} from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import {
  selectUserPermissionsByModule,
  selectModulePermissions,
} from 'features/Settings/UsersAndGroups/permissionSlice'
import PropertyForms from '../PropertyForms'
import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
import { CustomFormsOperation } from 'features/Forms/types'
import { getAddressWithUnit } from 'helpers/getAddressWithUnit'
import {
  getProjectContacts,
  getRelationContactToProject,
} from 'features/Project/slices/projectContactsSlice'

import styled from 'styled-components'
import { PlanLimits } from 'features/Settings/Subscription/components/PlanLimits/PlanLimits'
import { useTranslation } from 'react-i18next'

const CnfirmationnWrapper = styled.div`
  padding: 0 0 20px 0;
`
const ConfirmationTitle = styled.p`
  font-size: 16px;
  margin-bottom: 6px;
  margin-top: -4px;
`

enum PropertyAction {
  UPDATE_EXISTING_PROPERTY = 1,
  CREATE_PROPERTY_WITH_CLIENTS = 2,
  CREATE_PROPERTY_WITHOUT_CLIENTS = 3,
}

const ConfirmationPopover = ({ /* isLoading, */ onSubmit }) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [value, setValue] = useState(1)

  const showPopconfirm = () => {
    setOpen(true)
  }

  const handleOk = () => {
    setConfirmLoading(true)
    onSubmit(value)
    setTimeout(() => {
      setOpen(false)
      setConfirmLoading(false)
    }, 2000)
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value)
  }

  return (
    <Popconfirm
      title={
        <CnfirmationnWrapper>
          <ConfirmationTitle>Select the action</ConfirmationTitle>
          <Radio.Group onChange={onChange} value={value}>
            <Space direction='vertical'>
              <Radio value={PropertyAction.UPDATE_EXISTING_PROPERTY}>
                Update existing property
              </Radio>
              <Radio value={PropertyAction.CREATE_PROPERTY_WITHOUT_CLIENTS}>
                Create a new property
              </Radio>
              <Radio value={PropertyAction.CREATE_PROPERTY_WITH_CLIENTS}>
                Create a new property and attach existing contacts
              </Radio>
            </Space>
          </Radio.Group>
        </CnfirmationnWrapper>
      }
      open={open}
      onConfirm={handleOk}
      okButtonProps={{ loading: confirmLoading }}
      onCancel={handleCancel}
      okText='Update'
    >
      <MainButton title='Update Property' onClick={showPopconfirm} />
    </Popconfirm>
  )
}

const initialState: Property = {
  address: '',
  postcode: '',
  unit: '',
  city: '',
  state: '',
  type: PropertyType.RESIDENTIAL,
  notes: '',
  tags: [],
  full_address: '',
  is_manual: false,
  owner_user_id: 0,
  lat: null,
  lng: null,
  id: 0,
}

interface Props {
  propertyId: number
  isAppointment: boolean
  createOnButtonClick?: boolean
  isProposal?: boolean
  isSchedule?: boolean
  isClient?: boolean
  resetFromParent: boolean
  setEntityId: ({
    id,
    entity_type,
  }: {
    id: number
    entity_type: number
  }) => void
  onCloseForm?: () => void
  setEntity?: (value) => void
  openedTab: string
  heandleGetEntities?: (property) => void
  onAddressError: (isError: boolean) => void
  onPropertyChoose?: (property: Property) => void
}

export const PropertyForm: React.FC<Props> = ({
  propertyId,
  isAppointment,
  isProposal,
  isClient,
  setEntityId,
  onCloseForm,
  openedTab,
  setEntity,
  resetFromParent,
  isSchedule,
  heandleGetEntities,
  setEntityName,
  required,
  createOnButtonClick,
  onAddressError,
  setDetached_contacts_ids,
  proposalId,
  onPropertyChoose,
}) => {
  const { t } = useTranslation('property')

  const history = useHistory()
  const dispatch = useDispatch()
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.SCHEDULE),
  )
  const { project_can_read } = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const { memberships, project /* forms */ } = useAppSelector(
    selectModulePermissions(),
  )
  const { READ } = usePermissionsByModule(CustomFormsOperation)

  const company = useAppSelector(state => state.orcatec.company)

  const isShowUpcomingNotification = useSelector(
    isShowUpcomingNotificationSelector,
  )

  const [currentProperty, setCurrentProperty] = useState<Property>(initialState)
  const [clientForm, showClientForm] = useState(false)
  const [appointmentForm, showAppointmentForm] = useState(false)
  const [showMedia, toggleShowMedia] = useState(false)
  const [mediaToDelete, setMediaToDelete] = useState<number[]>([])
  const [newProposalForm, showNewProposalForm] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [appointmentError, setAppointmentError] = useState(initialErrors)
  const [showModal, setShowModal] = useState(false)
  const [activeTab, setActiveTab] = useState('Main info')
  ////
  const [appointmentOfficeLocation, setAppointmentOfficeLocation] = useState(
    false,
  )

  const [selectedEntityId, setSelectedEntityId] = useState(0)
  const [fullAppointment, setFullAppointment] = useState(null)
  const [blockedRouteParams, setBlockedRouteParams] = useState({
    blockRoutingModal: false,
    nextLocation: '',
  })
  ///////
  // const [clientforNot, setClientsForNotificationModal] = useState([])
  const [
    showNotificationUpcomingAppointmentModal,
    setShowNotificationUpcomingAppointmentModal,
  ] = useState(false)
  const [edited, setEdited] = useState(false)
  const [onSaveNotesCallback, setOnSaveNotesCallback] = useState(null)
  const [dataForRequest, setDataForRequest] = useState<{
    id: number
    type: PropertyRequestType
    heandleCloseRouteModal?: () => void
  }>({
    type: '',
    id: 0,
  })
  const [errors, setErrors] = useState<{ [key: string]: string } | null>(null)
  const [loading, property, error] = useProperty({
    ...dataForRequest,
    payload: currentProperty,
  })

  const [mainFormEdited, setMainFormEdited] = useState(false)

  const isWasEdit = useAppSelector(state => state.orcatec.ui.wasEdit)

  useEffect(() => {
    if (window.location.pathname.includes('properties-form')) {
      makeBrowserTitle({ prefixForTitle: currentProperty?.address })
    }
  }, [currentProperty])

  const setWasEdit = (val: boolean) => {
    return dispatch(actions.modals.wasEditField(val))
  }
  const mediaCollection = currentProperty?.media ?? []

  const clientsAndOrganizations =
    !!currentProperty?.clients && !!currentProperty?.organizations
      ? filterDeletedEntities([
          ...currentProperty?.clients,
          ...currentProperty?.organizations,
        ])
      : []

  useEffect(() => {
    if (error) {
      setErrors(error)

      openNotificationWithIcon('error', { message: 'Invalid Data' })
      setDataForRequest({
        type: '',
        id: 0,
      })
    }
  }, [error])

  useEffect(() => {
    if (property) {
      const fullAddress = makePropertyFullAddress(property)
      setEntityName?.(fullAddress)
      setCurrentProperty(property)
    }

    if (
      !!(setEntityId || onPropertyChoose) &&
      dataForRequest.type === 'create' &&
      property?.id
    ) {
      setEntityId?.({ id: property.id, entity_type: 1 })
      onPropertyChoose?.(property)
    }

    if (!!setEntity && dataForRequest.type === 'get' && property?.id)
      setEntity(property)

    setDataForRequest(prev => ({ ...prev, type: '' }))

    heandleGetEntities && property && heandleGetEntities(property)
  }, [property])

  useEffect(() => {
    if (propertyId) setDataForRequest({ type: 'get', id: propertyId })
  }, [propertyId])

  useEffect(() => {
    if (resetFromParent) {
      setActiveTab('Main info')
      setCurrentProperty(initialState)
    }
  }, [resetFromParent])

  const handleBlockedRoute = (nextLocation: string) => {
    if (nextLocation?.pathname?.includes('properties-form')) {
      return
    }
    setBlockedRouteParams({
      blockRoutingModal: true,
      nextLocation: nextLocation,
    })
    return false
  }

  const heandleCloseRouteModal = () => {
    if (blockedRouteParams.nextLocation) {
      history.push(blockedRouteParams.nextLocation)
      setBlockedRouteParams({ blockRoutingModal: false, nextLocation: '' })
    }
  }

  const makePropertyFullAddress = property =>
    `${!!property.address && property.address}, ${
      property.unit ? `Unit ${property.unit}, ` : ''
    } ${!!property.city && property.city}, ${!!property.state &&
      property.state} ${!!property.postcode && property.postcode}.`

  const handleChangeProperty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setCurrentProperty(prev => ({
      ...prev,
      [name]: value,
    }))

    setEdited(true)
    setMainFormEdited(true)
    setErrors(null)
  }

  const handleChooseAddress = (property: Property) => {
    setWasEdit(false)
    setEdited(false) // add false when property was selected
    //on choose address from dropdown
    setCurrentProperty(prev => ({
      ...prev,
      ...property,
      // postcode: property?.postcode?.slice(0, 5),
      choosen: !!createOnButtonClick && true,
    }))

    if (property.id && !isAppointment && !isProposal && !isClient)
      history.replace(`/properties-form/${property.id}`)

    if (property.id && setEntityId)
      setEntityId({ id: property.id, entity_type: 1 })

    if (property.id) onPropertyChoose?.(property)

    if (currentProperty?.id)
      return setDataForRequest({ id: currentProperty?.id, type: 'update' })

    if (!property.id && !createOnButtonClick)
      setDataForRequest({ id: 0, type: 'create' })
  }

  const handleChangeMarker = ({ lat, lng }: { lat: number; lng: number }) => {
    setCurrentProperty(prev => ({ ...prev, lat, lng }))
    setEdited(true)
    setMainFormEdited(true)
  }

  ///////

  const toggleNewProposalForm = () => {
    showNewProposalForm(!newProposalForm)
  }

  const toggleClientForm = () => showClientForm(prev => !prev)
  // const toggleOrganizationForm = () => showOrganizationForm(prev => !prev)
  const toggleAppointmentForm = () => showAppointmentForm(prev => !prev)
  const toggleClientTrue = () => {
    showClientForm(true)
  }
  const toggleClientFalse = () => {
    showClientForm(false)
  }

  const hancleClosePropertyForm = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    if (onCloseForm) return onCloseForm()

    history?.push('/properties')
  }

  const onAttachClient = async (data: Client) => {
    const { relation_name: note, id } = data

    const attachedContact = await attachClientOrOrganization(
      currentProperty?.id,
      id,
      { note },
    )

    const newData = {
      ...currentProperty,
      clients: [...(currentProperty?.clients || []), attachedContact],
    }

    setCurrentProperty(newData)

    heandleGetEntities?.(newData)
    setEntity?.(newData)

    // if (currentProperty?.id) {
    //   const requestData = { ...data }
    //   if (!Array.isArray(requestData.relations_with_property))
    //     requestData.relations_with_property = []
    //   if (!requestData?.relations_with_property?.length)
    //     requestData.relations_with_property = [
    //       {
    //         [currentProperty?.id]: {
    //           note: data.relation_name,
    //         },
    //       },
    //     ]
    //   else {
    //     requestData.relations_with_property[0] = {
    //       ...requestData.relations_with_property[0],
    //       [currentProperty?.id]: {
    //         note: data.relation_name,
    //       },
    //     }
    //   }
    //   if (requestData?.type === 1) {
    //     updateOrganization(requestData.id, requestData).then(() => {
    //       if (!currentProperty.id) return

    //       setDataForRequest({ type: 'get', id: currentProperty.id })
    //     })
    //   } else {
    //     updateClient(requestData.id, requestData).then(() => {
    //       if (!currentProperty.id) return

    //       setDataForRequest({ type: 'get', id: currentProperty.id })
    //     })
    //   }
    // } else return Promise.resolve()
  }

  const onChoseProperty = () => {
    if (!currentProperty?.id) return

    setDataForRequest({ type: 'get', id: currentProperty?.id })
  }

  const addMedia = (items: MediaType[]) => {
    setCurrentProperty(prev => ({
      ...prev,
      media: [...mediaCollection, ...items],
    }))
  }

  const onDeleteMedia = (id: number) => {
    setCurrentProperty(prev => ({
      ...prev,
      media: mediaCollection.filter(m => m.id !== id),
    }))
    setMediaToDelete([...mediaToDelete, id])
    setEdited(true)
  }

  const onDeleteMediaUploaded = (id: number) => {
    if (id) {
      setCurrentProperty(prev => ({
        ...prev,
        media: mediaCollection.filter(m => m.id !== id),
      }))
      setMediaToDelete([...mediaToDelete, id])
      setEdited(true)
    }
  }
  const handleCreateAppointment = async (data: IAppointment) => {
    if (!currentProperty.id) return

    if (!data?.column_template_id?.length)
      return setAppointmentError(prev => ({
        ...prev,
        column_template_id: 'This field is required',
      }))

    setIsLoading(true)
    try {
      const response = await createAppointment({
        ...data,
        entity_id: !appointmentOfficeLocation
          ? currentProperty?.id
          : selectedEntityId,
        entity_type: !appointmentOfficeLocation
          ? 1
          : clientsAndOrganizations.find(item => item.id === selectedEntityId)
              ?.type === 1
          ? 3
          : 2,
        date: data?.date?.map(date => moment(date).format('YYYY-MM-DD')),
        time_start:
          data?.time_start || data?.time_start === 0
            ? moment(data?.time_start).format('HH:mm')
            : null,
        time_end: data?.time_end
          ? moment(data?.time_end).format('HH:mm')
          : null,
        matrix_time_start:
          data.matrix_time_start || data.matrix_time_start === 0
            ? moment(data.matrix_time_start).format('HH:mm')
            : null,
        matrix_time_end: data.matrix_time_end
          ? moment(data.matrix_time_end).format('HH:mm')
          : null,
        column_template_id: data.column_template_id?.filter(
          value => typeof value === 'number',
        ),
        queue_id: data.column_template_id
          ?.filter(value => typeof value === 'string')
          ?.map((value: string) => +value),
      })
      const notificationSettings = await getNotificationSettings(
        data.industry_id,
      )

      const isShowNotificationUpcoming =
        company.id === Companies.WestCost || company.id === Companies.HVAC
          ? notificationSettings?.should_display_upcoming
          : isShowUpcomingNotification

      if (data?.assign_to_matrix && isShowNotificationUpcoming) {
        const createdAppointment = await getAppointment(
          response.appointment?.[0],
        )
        // const clients = await getClientsForNotificationModal(
        //   response.appointment?.[0],
        // )
        await setFullAppointment(createdAppointment)
        // await setClientsForNotificationModal(clients)
        await setShowNotificationUpcomingAppointmentModal(true)

        dispatch(
          getMatrixLog({
            date: moment(data?.date ?? new Date()).format('MM/DD/YYYY'),
          }),
        )
      }

      // if (data.notes.length) {
      //   apiNotes
      //     .postEntityNote(`/appointments/${response?.appointment?.id}/notes`, { text: data.notes })
      //     .then(r => r)
      //     .catch(e => console.error(e))
      // }

      showAppointmentForm(false)
      setAppointmentError(initialErrors)

      if (!currentProperty.id) return
      setDataForRequest({ type: 'get', id: currentProperty.id })
      setIsLoading(false)

      openNotificationWithIcon('success', {
        message: 'Appointment has beed created successfully',
      })
    } catch (error) {
      let errors = error.response.data
      let message = error?.response?.data?.message || 'Invalid data'

      if (error?.response?.data?.count_days) {
        errors = error?.response?.data
        message = 'This time had been taken by another appointment'
      }
      setAppointmentError(errors)
      setIsLoading(false)
      openNotificationWithIcon('error', { message })
    }
  }

  const deleteRemovedMedia = () => {
    const promises: Promise<MediaType>[] = []

    mediaToDelete.forEach(mediaId => {
      promises.push(deleteMedia(mediaId))
    })
    Promise.all(promises)
  }

  const handleKeyPress: React.KeyboardEventHandler<HTMLFormElement> = e => {
    if (
      e.target.name !== 'notes' &&
      e.target.name !== 'description' &&
      e.key === 'Enter'
    )
      e.preventDefault() //Prevent submiting form on Enter (ignore notes field)
  }

  const handlePressContinueWithoutLocation = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault()

    handleSubmit(e)
    setShowModal(false)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault()

    if (
      (!currentProperty.lat || !currentProperty.lng) &&
      !showModal &&
      !currentProperty.id
    )
      return setShowModal(true)

    if (mediaToDelete) {
      await deleteRemovedMedia()
      setMediaToDelete([])
    }

    setDataForRequest({
      id: currentProperty?.id || 0,
      type: currentProperty?.id ? 'update' : 'create',
      heandleCloseRouteModal,
    })
    onSaveNotesCallback?.()
    setEdited(false)
    setWasEdit(false)
  }

  const handleDeleteProperty = async () => {
    if (!currentProperty.id) return

    try {
      await deleteProperty(currentProperty.id)
      openNotificationWithIcon('success', {
        message: 'Property has been deleted successfully',
        description: '',
        key: 'property-delete-success',
      })

      history.push('/properties')
    } catch (error) {
      openNotificationWithIcon('error', {
        message: 'Error',
        description: error?.response?.data?.error?.message,
        key: 'property-delete-error',
      })
    }
  }

  const handleUploadMedia = (files: MediaType[]) => {
    setCurrentProperty(prev => ({
      ...prev,
      // media: [...mediaCollection, ...files],
      media: files,
    }))
    setEdited(true)
    handleSubmit()
  }

  const handleChangeMedia = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
  ) => {
    const { value } = e.target

    const newMedia = currentProperty.media ? [...currentProperty.media] : []
    const editingItem = newMedia.find(media => media.id === id)
    if (!editingItem) return

    editingItem.description = value

    setCurrentProperty(prev => ({ ...prev, media: newMedia }))
    setEdited(true)
  }

  const onDetachClientOrOrganization = async (
    property_id: number,
    entity_id: number,
    isClient: number,
  ) => {
    try {
      await detachClientOrOrganization(property_id, entity_id, isClient)

      const newData = {
        ...currentProperty,
        clients: currentProperty.clients.filter(
          client => client.id !== entity_id,
        ),
      }

      setCurrentProperty(prev => ({
        ...prev,
        clients: prev.clients.filter(client => client.id !== entity_id),
      }))

      setDetached_contacts_ids?.(prev => [...prev, property_id])

      heandleGetEntities?.(newData)
      setEntity?.(newData)
      // await setDataForRequest({ type: 'get', id: currentProperty.id })
      // openNotificationWithIcon('success', {
      //   message: `${
      //     isClient ? 'Client' : 'Organization'
      //   } was detached successfully`,
      //   description: null,
      //   key: 'property-detach-success',
      // })
    } catch (error) {
      openNotificationWithIcon('error', {
        message: `${isClient ? 'Client' : 'Organization'} was not detached`,
        description: null,
        key: 'property-detach-error',
      })
    }
  }

  const onUpdateRelation = () => {
    if (!currentProperty?.id) return

    setDataForRequest({ type: 'get', id: currentProperty.id })
    setWasEdit(false)
  }

  const handleUpdateWithActions = async (
    action: PropertyAction,
  ): Promise<any> => {
    try {
      const data = {
        //new fields
        proposal_id: proposalId,
        property_action: action,
        //standard fields
        address: currentProperty.address,
        postcode: currentProperty.postcode,
        city: currentProperty.city,
        state: currentProperty.state,
        unit: currentProperty.unit,
        full_address: `${getAddressWithUnit(currentProperty)}, ${
          currentProperty.postcode
        }`,
        lat: currentProperty.lat,
        lng: currentProperty.lng,
        type: currentProperty.type,
      }

      await manageProperty(propertyId, data)

      await dispatch(getProjectContacts(proposalId))

      await dispatch(getRelationContactToProject({ projectId: proposalId }))

      setEdited(false)
      setWasEdit(false)

      setMainFormEdited(false)

      if (
        [
          PropertyAction.CREATE_PROPERTY_WITHOUT_CLIENTS,
          PropertyAction.CREATE_PROPERTY_WITH_CLIENTS,
        ].includes(action)
      ) {
        onCloseForm(true)
      }

      const type =
        action === PropertyAction.UPDATE_EXISTING_PROPERTY ? 'update' : 'create'

      openNotificationWithIcon('success', {
        message: `Property has been ${type}d successfully`,
        description: '',
        key: 'property-update-success',
      })
    } catch (error) {
      console.error(error)

      openNotificationWithIcon('error', {
        message: 'Error',
        description: Object.values(
          error?.response?.data || { message: 'Invalid Data' },
        ),
        key: 'property-delete-error',
      })
    }
  }

  const handleChangeTab = (tab: string) => setActiveTab(tab)

  const proposalsFiles = Array.isArray(currentProperty?.proposals)
    ? currentProperty?.proposals.reduce((acc: MediaType[], proposal) => {
        const setFiles = (files: MediaType[]) => {
          if (Array.isArray(files) && !!files?.length) {
            acc = [...acc, ...files]
          }
        }

        setFiles(proposal.files)
        setFiles(proposal.files_from_customer)
        setFiles(proposal.files_for_worker)

        if (Array.isArray(proposal.proposal_tabs)) {
          let tabsFiles: MediaType[] = []
          const setTabsFiles = (files: MediaType[]) => {
            if (Array.isArray(files) && !!files?.length) {
              tabsFiles = [...tabsFiles, ...files]
            }
          }
          proposal.proposal_tabs.forEach(tab => {
            setTabsFiles(tab.files)
          })
          tabsFiles = filterArrayOfObjectsToUniqueKeyValue(tabsFiles, 'id')
          acc = [...acc, ...tabsFiles]
        }
        return acc
      }, [])
    : []
  const mediaLength = mediaCollection?.length + proposalsFiles?.length

  useEffect(() => {
    if (edited) {
      setWasEdit(true)
    }
  }, [edited])

  return (
    <div className={`property-form${!currentProperty?.id ? ' new' : ''}`}>
      <form
        onChange={() => setWasEdit(true)}
        onSubmit={handleSubmit}
        onKeyPress={handleKeyPress}
        autoComplete='off'
      >
        <>
          <Tabs
            noMarginAndPaddingOfTabContent={{ rule: true, for: 'Clients' }}
            openedTab={openedTab}
            onChangeTab={handleChangeTab}
            resetTab={resetFromParent}
          >
            <div
              label={
                !permissions[
                  SchedulePermissions.SCHEDULE_CAN_READ_RELATED_ENTITY_MAIN_INFO
                ] && isSchedule
                  ? ''
                  : t('tabs.mainInfo')
              }
            >
              <MainInfo
                property={currentProperty}
                onChange={handleChangeProperty}
                onChooseAddress={handleChooseAddress}
                onChangeMarker={handleChangeMarker}
                onAddressError={onAddressError}
                errors={errors}
                onBlurUnitField={handleSubmit}
                setOnSaveNotesCallback={setOnSaveNotesCallback}
                resetFromParent={resetFromParent}
                required={required}
                isAppointment={isAppointment}
              />
            </div>

            <div
              onAdd={toggleClientForm}
              count={
                currentProperty?.clients
                  ? filterDeletedEntities(currentProperty?.clients)?.length
                  : 0
              }
              disabled={!currentProperty?.id}
              label={
                !permissions[
                  SchedulePermissions
                    .SCHEDULE_CAN_READ_RELATED_PROPERTY_CONTACTS
                ] && isSchedule
                  ? ''
                  : t('tabs.contacts.title')
              }
            >
              <Clients
                form={clientForm}
                propertyId={currentProperty?.id}
                clients={filterDeletedEntities(currentProperty?.clients)?.sort(
                  (a, b) => b.id - a.id,
                )}
                onAttachClient={onAttachClient}
                toggleFormClient={toggleClientForm}
                onChoseProperty={onChoseProperty}
                toggleFormClientFalse={toggleClientFalse}
                toggleClientTrue={toggleClientTrue}
                isClient={true}
                isSchedule={isSchedule}
                stepOfOpenedForm={0}
                onUpdateRelation={onUpdateRelation}
                onDetachClientOrOrganization={onDetachClientOrOrganization}
                isPropertyForm={true}
                withModal={!isProposal}
                showModal={clientForm}
              />
            </div>

            <div
              onAdd={toggleAppointmentForm}
              count={
                currentProperty?.appointments
                  ? currentProperty?.appointments.length
                  : 0
              }
              disabled={!currentProperty?.id}
              label={
                !permissions[
                  SchedulePermissions
                    .SCHEDULE_CAN_READ_RELATED_ENTITY_APPOINTMENTS
                ] && isSchedule
                  ? ''
                  : t('tabs.appointments.title')
              }
            >
              <>
                {appointmentForm && (
                  <WithModal
                    withModal={!isProposal}
                    showModal={appointmentForm}
                    toggleModal={() => {
                      showAppointmentForm(false)
                      setWasEdit(false)
                    }}
                    title='Create Appointment'
                  >
                    <PlanLimits entity='events' />

                    <div style={{ marginBottom: 20 }}>
                      <Checkbox
                        checked={appointmentOfficeLocation}
                        onChange={e => {
                          setAppointmentOfficeLocation(e.target.checked)
                          if (e.target.checked)
                            setSelectedEntityId(
                              clientsAndOrganizations?.[0]?.id,
                            )
                        }}
                        disabled={!clientsAndOrganizations?.length}
                      >
                        <Tooltip
                          title={
                            !clientsAndOrganizations?.length &&
                            'This client has no attached clients or organizations'
                          }
                        >
                          Appointment is going to take in my office
                        </Tooltip>
                      </Checkbox>

                      {appointmentOfficeLocation && (
                        <div style={{ maxWidth: 224, marginTop: 10 }}>
                          <Select
                            label='Choose client'
                            value={selectedEntityId}
                            options={clientsAndOrganizations?.map(item => ({
                              id: item.id,
                              name: item.name,
                            }))}
                            onChange={e => setSelectedEntityId(+e.target.value)}
                          />
                        </div>
                      )}
                    </div>
                    <AppointmentForm
                      className='appointment-tab'
                      error={appointmentError}
                      setError={setAppointmentError}
                      onClose={() => {
                        showAppointmentForm(false)
                        setWasEdit(false)
                      }}
                      setIsEditFields={setWasEdit}
                      onCreateAppointment={handleCreateAppointment}
                      isLoading={isLoading}
                      toggleAppointmentForm={toggleAppointmentForm}
                      appointmentForm={appointmentForm}
                    />
                  </WithModal>
                )}
                <Appointments
                  appointments={currentProperty?.appointments}
                  emptyText={t('tabs.appointments.noAppointments')}
                  toggleAppointmentForm={toggleAppointmentForm}
                  appointmentForm={appointmentForm}
                  isAppointment={isAppointment}
                />
              </>
            </div>

            <div
              onAdd={() =>
                dispatch(toggleModalProperty('property', mediaCollection))
              }
              count={mediaLength || 0}
              disabled={!currentProperty?.id}
              label={
                !permissions[
                  SchedulePermissions.SCHEDULE_CAN_READ_RELATED_PROPERTY_MEDIA
                ] && isSchedule
                  ? ''
                  : t('tabs.media.title')
              }
            >
              <Media
                media={mediaCollection}
                showMediaPopup={showMedia}
                setProperty={addMedia}
                toggleShowMediaPopup={() => toggleShowMedia(!showMedia)}
                onDeleteMedia={onDeleteMedia}
                onChange={handleChangeMedia}
                proposals={currentProperty?.proposals}
                proposalsMediaArray={proposalsFiles}
                onAddMediaClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.preventDefault()
                  dispatch(toggleModalProperty('property', mediaCollection))
                }}
                onDropMedia={undefined}
                isClear={undefined}
                property={undefined}
              />
            </div>
            {project && (
              <div
                count={
                  currentProperty?.proposals
                    ? currentProperty?.proposals.length
                    : 0
                }
                onAdd={!!project_can_read ? toggleNewProposalForm : null}
                disabled={!currentProperty?.id}
                label={
                  !permissions[
                    SchedulePermissions
                      .SCHEDULE_CAN_READ_RELATED_ENTITY_PROJECTS
                  ] && isSchedule
                    ? ''
                    : t('tabs.projects.title')
                }
              >
                <TableProposals
                  entity={{ id: currentProperty?.id, type: 'property_id' }}
                  proposals={currentProperty?.proposals || []}
                  newProposalForm={newProposalForm}
                  toggleNewProposalForm={toggleNewProposalForm}
                  closeClientForm={undefined}
                />
              </div>
            )}

            {!!memberships && (
              <div
                count={currentProperty?.memberships?.length}
                label={
                  !permissions[
                    SchedulePermissions
                      .SCHEDULE_CAN_READ_RELATED_ENTITY_MEMBERSHIPS
                  ] && isSchedule
                    ? ''
                    : t('tabs.memberships.title')
                }
              >
                <MembershipEntries
                  entityId={currentProperty?.id?.toString()}
                  entityType='properties'
                  data={currentProperty?.memberships}
                  onUpdate={() =>
                    setDataForRequest({ type: 'get', id: currentProperty?.id })
                  }
                />
              </div>
            )}

            {/* {[Companies.HVAC, Companies.REMOVAL_PRO, Companies.Sony].includes(
              company.id,
            ) && ( */}
            {READ && (
              <div label={t('tabs.forms.title')}>
                <PropertyForms propertyId={currentProperty?.id} />
              </div>
            )}
            {/* )} */}
          </Tabs>
        </>

        {currentProperty?.address && (
          <div className='property-form__action-buttons'>
            <AccessControl
              additionalAccess={
                !!currentProperty?.id &&
                !isAppointment &&
                !isProposal &&
                !isSchedule &&
                activeTab === 'Main info'
              }
              scopes={[PropertyPermissions.PROPERTIES_CAN_DELETE]}
              module={ModuleName.PROPERTIES}
            >
              <Popconfirm
                title='Are you sure you want to delete this property?'
                onConfirm={handleDeleteProperty}
                okText='Yes'
                cancelText='No'
              >
                <MainButton
                  type='delete'
                  title={t('deleteProperty')}
                  className='deleteBtn'
                />
              </Popconfirm>
            </AccessControl>

            {!isAppointment && !isSchedule && (
              <MainButton
                title={
                  edited
                    ? t('button.cancel', { ns: 'common' })
                    : onCloseForm
                    ? t('button.close', { ns: 'common' })
                    : t('button.goBack', { ns: 'common' })
                }
                type='cancel'
                onClick={hancleClosePropertyForm}
                className='mr-3'
              />
            )}

            {!!currentProperty?.id &&
              (edited || isWasEdit) &&
              ([Companies.ParamountPaintingInc].includes(company.id) &&
              isProposal &&
              mainFormEdited ? (
                <ConfirmationPopover
                  onSubmit={handleUpdateWithActions}
                  isLoading={isLoading}
                />
              ) : (
                <MainButton
                  title={t('updateProperty')}
                  isFetching={isLoading}
                />
              ))}

            {!currentProperty?.id &&
              currentProperty.city &&
              currentProperty.state &&
              currentProperty.postcode && (
                <MainButton
                  title={t('createProperty')}
                  isFetching={isLoading}
                />
              )}
          </div>
        )}
      </form>

      <ModalFileUploadContainer
        onDeleteMedia={onDeleteMediaUploaded}
        property_files={true}
        files={currentProperty?.media}
        handleSave={handleUploadMedia}
        disableAddByLink
        clientView
      />

      <Modal
        visible={showModal}
        onCancel={handlePressContinueWithoutLocation}
        onOk={() => setShowModal(false)}
        cancelText='Continue'
        okText='Set the pin'
      >
        <p>You didn&apos;t place the pin on a Map for GPS routing</p>
      </Modal>

      {loading && <LoadingScreen />}

      {activeTab === 'Main info' && !isAppointment && (
        <>
          <BlockRoutingModal
            status={!!blockedRouteParams.blockRoutingModal}
            handleCancel={() =>
              setBlockedRouteParams({
                blockRoutingModal: false,
                nextLocation: '',
              })
            }
            handleDiscard={() => history.push(blockedRouteParams.nextLocation)}
            handleSave={handleSubmit}
            title='You have some unsaved changes.'
            buttonsTitle={{
              save: 'Save and continue',
              discard: 'Discard and continue',
              cancel: 'Cancel',
            }}
            isLoading={loading}
          />
          <Prompt
            when={
              (edited || isWasEdit) && !blockedRouteParams?.blockRoutingModal
            }
            message={handleBlockedRoute}
          />
        </>
      )}

      {showNotificationUpcomingAppointmentModal && (
        <NotificationModal
          title='Upcoming Appointment Notification'
          showModal={showNotificationUpcomingAppointmentModal}
          setShowModal={setShowNotificationUpcomingAppointmentModal}
          templateType={NotificationTemplateType.APPOINTMENT}
          notificationType={NotificationModalType.UPCOMING}
          appointment={fullAppointment}
        />
      )}
    </div>
  )
}
