import './InstallersInfoBlock.scss'
import { ViewFile } from '../../../../../../../../../helpers/viewFile/viewFile'
import MainButton from '../../../../../../components/buttons/MainButton'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// import RelatedJobs from 'containers/MainContent/Orcatec/Jobs/components/RelatedJobs'

const inputProps = { maxLength: 3000 }
const fileStyle = {
  width: '100px',
  height: '100px',
  borderRadius: '4px',
  cursor: 'zoom-in',
}
const dellIconStyle = { fontSize: '1.2rem' }

const InstallersInfoBlock = ({
  fieldValue,
  onChangeTextField,
  onChangeFileName,
  onChangeDescription,
  deleteFile,
  files,
  openFileModal,
  fieldLabel,
  fieldName = 'notes_for_installers',
  buttonTitle,
  placeholder,
  getFileForModalDocViewer,
  onTextFieldBlur,
  loading,
}) => {
  const { t } = useTranslation('project')

  const charCounter = text => {
    const charLength = text?.length || 0
    return charLength
  }
  return (
    <div className='proposal-form-tabs_content-info_block-installers_info'>
      {!onChangeTextField ? (
        <>
          <p className='field-label'>{fieldLabel || t('customerInfo.notes')}</p>
          <p className='field-text'>{fieldValue}</p>
        </>
      ) : (
        <>
          {!!placeholder && <span className='placeholder'>{placeholder}</span>}
          <div className='block-installers_info__input'>
            <InputField
              // label={/* placeholder ? null :  */ fieldLabel}
              placeholder={t('customerInfo.startType')}
              multiline
              maxRows={10}
              name={fieldName}
              value={fieldValue ?? ''}
              onChange={onChangeTextField}
              inputProps={inputProps}
              onBlur={() => onTextFieldBlur?.('info_from_customer')}
              // style={{ marginBottom: 20 }}
            />
          </div>
          <p className='visible_for-print'>
            {fieldValue || t('customerInfo.noInfo')}
          </p>
          {inputProps?.maxLength && (
            <p className='block-installers_info__input--counter'>{`${charCounter(
              fieldValue,
            )}/${inputProps.maxLength}`}</p>
          )}
        </>
      )}
      {/* </div> */}
      <div className='files'>
        {files?.map((file, idx) => (
          <div
            key={file.id}
            className='proposal-form-tabs_content-info_block-installers_info-file'
          >
            {getFileForModalDocViewer ? (
              <div
                onClick={e => {
                  if (e.target?.classList?.contains('ant-tooltip-inner')) return
                  getFileForModalDocViewer(files, idx)
                }}
              >
                <ViewFile
                  file={file}
                  showName={true}
                  fileStyle={fileStyle}
                  dellIconStyle={dellIconStyle}
                  onDelete={deleteFile ? () => deleteFile(file.id, idx) : null}
                  isDellConfirmation={true}
                  tooltipPlacement='right'
                />
              </div>
            ) : (
              <ViewFile
                file={file}
                showName={true}
                fileStyle={fileStyle}
                dellIconStyle={dellIconStyle}
                onDelete={deleteFile ? () => deleteFile(file.id, idx) : null}
                isFullViewNeeded={true}
                isDellConfirmation={true}
                tooltip
              />
            )}
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
              }}
            >
              {onChangeFileName && (
                <div className='file-description'>
                  <FileName
                    fileName={file.display_name}
                    onChange={e => {
                      onChangeFileName(e, idx)
                    }}
                    onBlur={() => onTextFieldBlur?.('files_from_customer')}
                  />
                </div>
              )}
              <div className='file-description'>
                {onChangeDescription ? (
                  <InputField
                    label={t('customerInfo.fileDesc')}
                    value={file.description ?? ''}
                    onChange={e => onChangeDescription(e, idx)}
                    onBlur={() => onTextFieldBlur?.('files_from_customer')}
                    multiline
                    maxRows={4}
                    disabled={!onChangeDescription || loading}
                    inputProps={{ maxLength: 1000 }}
                  />
                ) : (
                  <p>{file?.description}</p>
                )}
              </div>
            </div>
            <p
              style={{ padding: ' 0 10px 10px 10px ' }}
              className='visible_for-print'
            >
              {file?.description}
            </p>
          </div>
        ))}
      </div>
      {!!openFileModal && (
        <div className='w-100'>
          <MainButton
            title={buttonTitle || t('customerInfo.filesFor')}
            onClick={openFileModal}
          />
        </div>
      )}
    </div>
  )
}

export default InstallersInfoBlock

export function extractFileNameWithoutExtension(fileName) {
  const parts = fileName.split('.')

  const name = parts.slice(0, -1).join('')
  const extension = parts[parts.length - 1]

  return {
    name,
    extension,
  }
}

const FileName = ({ fileName, onChange, onBlur }) => {
  const [name, setName] = useState('')
  const [fileExtension, setFileExtension] = useState('')

  useEffect(() => {
    if (fileName) {
      const { name, extension } = extractFileNameWithoutExtension(fileName)
      setName(name)
      setFileExtension(extension)
    }
  }, [])

  useEffect(() => {
    if (name) {
      onChange({
        target: {
          value: `${name}.${fileExtension}`,
        },
      })
    }
  }, [name])

  const handleChangeFileName = e => {
    const { value } = e.target

    setName(value)
  }

  return (
    <InputField
      label='File name'
      value={name}
      onChange={handleChangeFileName}
      onBlur={onBlur}
      // multiline
      // maxRows={4}
      // disabled={!onChangeFileName}
      inputProps={{ maxLength: 255 }}
    />
  )
}
