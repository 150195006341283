import { useCallback, useMemo } from 'react'
import { useAppSelector } from 'store/Orcatec/hooks'
import { userPermissions } from '../permissionSlice'

/**
 * Custom hook to check user permissions
 * @param requiredPermissions - Array of permission keys to check
 * @returns Object containing permission check methods
 */
const usePermissions = (requiredPermissions?: string[]) => {
  if (!requiredPermissions?.length) return true

  // Get permissions from Redux store
  const permissions = useAppSelector(userPermissions)

  const checkPermission = useCallback(
    (permissionKey: string): boolean => {
      const modulePrefix = permissionKey.split('_')[0]
      const modulePermissions = permissions[modulePrefix]
      if (!modulePermissions) return false
      return modulePermissions[permissionKey] > 0
    },
    [permissions],
  )

  // Memoize the results of checking all permissions
  const hasAllPermissions = useMemo(() => {
    return requiredPermissions.every(checkPermission)
  }, [requiredPermissions, checkPermission])

  // Memoize the results of checking any permissions
  /* const hasAnyPermission = useMemo(() => {
    return requiredPermissions.some(checkPermission)
  }, [requiredPermissions, checkPermission]) */

  /* return {
    hasPermission: hasAllPermissions,
    hasAnyPermission,
    checkSinglePermission: checkPermission,
  } */

  return hasAllPermissions
}

export default usePermissions
