import { Checkbox, notification, Popconfirm, Spin, Tooltip, Result } from 'antd'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { putProposalInsightsData } from '../../../../../../../api/ProposalAccounting'
import { priceToView } from '../../../../../../../helpers/thousandSeparator'
import {
  accountingSetItems,
  getProposalInsightsData,
  // onChangeField,
  // onSetField,
} from 'store/Orcatec/actions/proposal/proposalForm'

import { Close } from '@material-ui/icons'
import MainButton from '../../../../components/buttons/MainButton'
import BalanceTable from '../../BalanceTable'
import BlockItem from './components/BlockItem/BlockItem'
import RowItem from './components/RowList/RowItem'
import InputField from '../../../../components/Input'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import moment from 'moment-timezone'
import ModalFileUploadContainer from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalFileUploadContainer'
import { calculateTimeDiff } from './helpers/helpers'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import InfoIcon from '@material-ui/icons/Info'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Payouts } from './components/Payouts/Payouts'
import { ConfirmationModal } from 'containers/MainContent/Orcatec/components/UI/Modal'
// import { getAllUsers } from 'api/Accounting'
import styled from 'styled-components'
import './ModalAccounting.scss'

import { checkAccessControl } from 'features/Settings/UsersAndGroups/helpers/checkAccessControl'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { meSelector } from 'store/SuperAdmin/selectors'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { getUsersList } from 'api/User'
import { UserStatus } from 'types/User'
import {
  selectProject,
  selectProjectActiveTab,
} from 'features/Project/projectSelectors'
import { projectSettingsUpdated } from 'features/Project/slices/projectSlice'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { Project } from 'features/Project/types'

const commissionTypeOptions = [
  { value: 1, title: 'Potential Profit' },
  { value: 2, title: 'Gross Sale' },
  { value: 3, title: 'Current Profit' },
  { value: 4, title: 'Net Sales' },
]

const messageStatus =
  'You are not able to do any changes when project is in status completed'
// const messagePerm = 'Only owner, admin or accountant have ability to change'
const messagePerm = 'You do not have permission for this action.'

const statusNotCalcTime = ['Active', 'En Route', 'Completed', 'Cancelled']
const iconStyle = { color: '#4c53ef', fontSize: '15px' }
interface IProps {
  project?: Project
  totalToPay: string
  closeModal: () => void
  proposalID: number
  handleGetAccountingTableData: () => void
  user: string
  projectStatus: number
}

export enum proposalInsightsActionLevel {
  PROPOSAL_INSIGHTS_OPEN_LEVEL_1 = 1,
  PROPOSAL_INSIGHTS_OPEN_LEVEL_2 = 2,
  PROPOSAL_INSIGHTS_OPEN_LEVEL_3 = 3,
}
export enum ProposalInsightsSettingsName {
  HAS_ACCESS_TO_PROPOSAL_INSIGHTS = 'has_access_to_proposal_insights',
  PROPOSAL_INSIGHT_ACTION_LEVEL = 'proposal_insights_action_level',
}

enum ItemTypeEnum {
  EXPENSES = 1,
  ADVERSTING = 2,
  PRE_NET_EXPENSES = 3,
}
const infoIcon = (str = '') => (
  <Tooltip placement='left' title={str ?? ''}>
    <InfoIcon style={iconStyle} />
  </Tooltip>
)
const toFixed = (number, digitsAfterDot) => {
  const str = `${number}`
  if (str?.includes('.')) {
    return +str?.slice(0, str?.indexOf('.') + digitsAfterDot + 1) || +number
  }
  return +number
}

const ModalAccounting = ({
  // project,
  totalToPay = '',
  closeModal,
  proposalID = 0,
  handleGetAccountingTableData,
  user = '',
  projectStatus = 1,
}: IProps) => {
  // Selectors:
  const dispatch = useDispatch()
  const me = useAppSelector(meSelector)
  const proposalForm = useAppSelector(selectProject)
  const currentTab = useAppSelector(selectProjectActiveTab)
  const dictionary = useAppSelector(selectJobDictionary)
  // const userAccessAction: proposalInsightsActionLevel = useAppSelector(
  //   state => state.orcatec.user.me?.proposal_insights_action_level,
  // )
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )
  const accounting = useAppSelector(
    state => state?.orcatec?.proposalForm?.accounting,
  )

  const isProjectCompleted = projectStatus === 3
  // const canView =
  //   userAccessAction ===
  //   proposalInsightsActionLevel.PROPOSAL_INSIGHTS_OPEN_LEVEL_1
  // const canAdd =
  //   userAccessAction ===
  //   proposalInsightsActionLevel.PROPOSAL_INSIGHTS_OPEN_LEVEL_2
  // const canEdit =
  //   userAccessAction ===
  //   proposalInsightsActionLevel.PROPOSAL_INSIGHTS_OPEN_LEVEL_3

  const canView = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_CAN_READ],
    [user.user_id],
    me.id,
  )
  const canAdd = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_ITEMS_CAN_ADD],
    [user.user_id],
    me.id,
  )
  const canEdit = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_ITEMS_CAN_EDIT],
    [user.user_id],
    me.id,
  )
  const canDelete = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_ITEMS_CAN_DELETE],
    [user.user_id],
    me.id,
  )

  const canAddPayouts = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_CAN_ADD_PAYOUTS],
    [user.user_id],
    me.id,
  )
  const canEditPayouts = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_CAN_EDIT_PAYOUTS],
    [user.user_id],
    me.id,
  )
  const canDeletePayouts = checkAccessControl(
    permissions[ProjectPermissions.PROJECT_INSIGHTS_CAN_DELETE_PAYOUTS],
    [user.user_id],
    me.id,
  )

  const canDeleteSuggestion = !!permissions?.project_insights_items_suggestion_can_delete

  const canAddSuggestion = !!permissions?.project_insights_items_suggestion_can_add

  const balances = useAppSelector(
    state =>
      state?.orcatec?.modalPayment?.payments?.payment_balances ||
      (proposalForm && currentTab?.payment?.payment_balances) ||
      [],
  )

  const tips = currentTab?.fees
    ?.filter(item => item.description === 'Tips')
    ?.reduce((acc, item) => (acc += +item?.price), 0)

  // Blocks data
  const [expensesArray, setExpensesArray] = useState(accounting.expenses || [])
  const [preNetExpensesArray, setPreNetExpensesArray] = useState(
    accounting.pre_net_expenses || [],
  )
  const [advertisingArray, setAdvertisingArray] = useState(
    accounting.advertising || [],
  )
  const [typeRepairArray, setTypeRepairArray] = useState(
    accounting.appointment || { history: [], rates: [] },
  )
  const [workDatesArray, setWorkDatesArray] = useState(
    accounting.work_dates || { history: [], rates: [] },
  )

  const [commission, setCommission] = useState(accounting.commission)
  const [totalCommission, setTotalCommission] = useState(0)
  // isTouched
  const [isProposalInsightsTouched, setIsProposalInsightsTouched] = useState(
    false,
  )
  // Totals
  const [totalExpensesCost, setTotalExpensesCost] = useState(0)
  const [totalPreNetExpensesCost, setTotalPreNetExpensesCost] = useState(0)
  const [totalAdvertisingCost, setTotalAdvertisingCost] = useState(0)
  const [totalAppointmentTypeCost, setTotalAppointmentTypeCost] = useState(0)
  const [totalWorkDatesCost, setTotalWorkDatesCost] = useState(0)
  const [sumPayout, setSumPayout] = useState(0)
  // Others
  const [isFetching, setIsFetching] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [includeTips, setIncludeTips] = useState(false)

  // Total Materials
  const [totalMaterials, setTotalMaterials] = useState({
    job_cost_materials: accounting.job_cost_materials,
    total_materials: accounting.total_materials,
  })
  const [users, setUser] = useState([])
  const message = isProjectCompleted ? messageStatus : messagePerm

  const roundNumber: (number: number | string, decimals?: number) => string = (
    number,
    decimals = 2,
  ) => {
    if (typeof number === 'string') {
      number = parseFloat(number)
    }

    return number.toLocaleString('en-US', {
      maximumFractionDigits: decimals,
      useGrouping: false,
    })
  }

  const saveItemsBeforeFileUpload = async () => {
    await dispatch(
      accountingSetItems({
        expenses: expensesArray,
        advertising: advertisingArray,
        appointment: typeRepairArray,
        work_dates: workDatesArray,
        include_tips_to_insight: includeTips,
        pre_net_expenses: preNetExpensesArray,
        commission,
        ...totalMaterials,
      }),
    )
  }

  const saveItemsBeforeDeleting = async () => {
    const data = {
      expenses: expensesArray,
      advertising: advertisingArray,
      appointment: typeRepairArray,
      work_dates: workDatesArray,
      include_tips_to_insight: includeTips,
      pre_net_expenses: preNetExpensesArray,
      commission,
      ...totalMaterials,
    }
    await putProposalInsightsData(proposalID, data)
      .then(() => {
        setIsProposalInsightsTouched(false)
      })
      .catch(e => {
        notification.error({ message: e?.message })
      })
  }
  const fromMinutsToHourAndMinuts = (hours: number, minutes: number) => {
    const duration = moment.duration(minutes + hours * 60, 'minutes')
    return {
      hours: duration.hours() + duration.days() * 24,
      minutes: duration.minutes(),
    }
  }

  const calcTotalLaborTime = rates => {
    const accum = { hours: 0, minutes: 0 }
    const jobTime = rates?.reduce((acc, e) => {
      acc.hours = acc.hours + +e?.natural_time?.hours
      acc.minutes = acc.minutes + +e?.natural_time?.minutes
      return acc
    }, accum)

    return fromMinutsToHourAndMinuts(jobTime?.hours, jobTime?.minutes)
  }

  const calcWorkerSpendTime = (
    arrayLogs: { date: string; status: string }[],
  ) => {
    const accum = { hours: 0, minutes: 0 }
    return arrayLogs
      .sort?.((a, b) => moment.utc(a.date).diff(moment.utc(b.date)))
      .reduce?.((acc, { status, date }, i, arr) => {
        if (!statusNotCalcTime?.includes(status)) {
          const time = calculateTimeDiff(arr?.[i + 1]?.date || moment(), date)
          acc.hours = acc.hours + time?.hours
          acc.minutes = acc.minutes + time?.minutes
          return acc
        }
        return acc
      }, accum)
  }

  const calculateWorkJobCost = (
    hours: number,
    minutes: number,
    rate: number,
  ) => {
    return (Math.floor((+hours + +minutes / 60) * 100) / 100) * rate || 0
  }

  const heandleChangeField = (bool, field) => {
    // dispatch(onSetField(bool, field))
    dispatch(projectSettingsUpdated({ [field]: bool }))
  }

  const cancelModalHandler = () => {
    closeModal?.()
    setIsProposalInsightsTouched(false)
    setCommission(p => ({ ...p, percent: 0 }))
  }

  const saveModalHandler = () => {
    if (isProjectCompleted) {
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
    setIsLoading(true)
    setIsFetching(true)
    // Collect data:
    const data = {
      expenses: expensesArray,
      advertising: advertisingArray,
      appointment: typeRepairArray,
      work_dates: workDatesArray,
      include_tips_to_insight: includeTips,
      pre_net_expenses: preNetExpensesArray,
      commission,
      ...totalMaterials,
    }
    dispatch(accountingSetItems(data))
    heandleChangeField(includeTips, 'include_tips_to_insight')

    putProposalInsightsData(proposalID, data)
      .then(r => {
        const {
          advertising,
          expenses,
          appointment,
          work_dates,
          pre_net_expenses,
          include_tips_to_insight,
          commission,
          job_cost_materials,
          total_materials,
        } = r?.data

        setAdvertisingArray(advertising)
        setExpensesArray(expenses)
        setTypeRepairArray(appointment)
        setWorkDatesArray(work_dates)
        setPreNetExpensesArray(pre_net_expenses)
        setIncludeTips(include_tips_to_insight)
        setCommission(commission)
        setTotalMaterials({
          job_cost_materials,
          total_materials,
        })
      })
      .then(() => {
        notification.success({ message: 'Saved successfully!' })
        setIsFetching(false)
        setIsProposalInsightsTouched(false)
        setIsLoading(false)
        // setEdit(false)
      })
      .then(() => handleGetAccountingTableData?.())

      .catch(e => {
        setIsFetching(false)
        setIsLoading(false)
        // setEdit(false)
        notification.error({ message: e?.message })
      })
      .finally(() => {
        setShowConfirmationModal(false)
        closeModal?.()
      })
  }

  const handlePercentageField = (e: ChangeEvent<HTMLInputElement>) => {
    if (!canEdit || isProjectCompleted) {
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }

    const { value } = e.target
    const isNumberInput = input => {
      const rgx = /^[0-9]*\.?[0-9]*$/
      return input.match(rgx)
    }
    if (isNumberInput(value)) {
      setCommission(p => ({ ...p, percent: value }))
    }
  }

  const handleCheckTotalMaterials = (e: ChangeEvent<HTMLInputElement>) => {
    if (!canEdit || isProjectCompleted) {
      setIsProposalInsightsTouched(false)
      return openNotificationWithIcon('warning', {
        message,
        key: 'commission',
        maxCount: 2,
      })
    }
    setTotalMaterials(p => ({ ...p, job_cost_materials: e.target.checked }))
    if (isProposalInsightsTouched === false) setIsProposalInsightsTouched(true)
  }

  useEffect(() => {
    // Add no-scroll class to body for disable scroll when modal is mounted
    const id = setTimeout(() => {
      document.body?.classList?.add('no-scroll')
    }, 1000)

    return () => {
      clearTimeout(id)
      document.body?.classList?.remove('no-scroll')
    }
  }, [])

  useEffect(() => {
    const fetchProposalInsightsData = async () => {
      setIsLoading(true)
      try {
        await dispatch(getProposalInsightsData(proposalID))
      } catch (error) {
        openNotificationWithIcon('error', {
          message: error?.message || 'Error',
        })
      } finally {
        setIsLoading(false)
      }
    }
    if (canView) fetchProposalInsightsData()
  }, [proposalID])

  useEffect(() => {
    // Update local data if something change in Redux
    setExpensesArray(accounting.expenses)
    setPreNetExpensesArray(accounting.pre_net_expenses)
    setAdvertisingArray(accounting.advertising)
    setTypeRepairArray(accounting.appointment)
    setWorkDatesArray(accounting.work_dates)
    setCommission(accounting.commission)
    setTotalMaterials({
      job_cost_materials: accounting.job_cost_materials,
      total_materials: accounting.total_materials,
    })
    setIncludeTips(accounting?.include_tips_to_insight)
  }, [accounting])

  useEffect(() => {
    setTotalExpensesCost(
      expensesArray
        .map(item => item.price)
        .reduce((acc, curr) => acc + +curr, 0),
    )
  }, [expensesArray])

  useEffect(() => {
    setTotalAdvertisingCost(
      advertisingArray
        .map(item => item.price)
        .reduce((acc, curr) => acc + +curr, 0),
    )
  }, [advertisingArray])

  useEffect(() => {
    setTotalPreNetExpensesCost(
      preNetExpensesArray
        ?.map(item => item.price)
        .reduce((acc, curr) => acc + +curr, 0),
    )
  }, [preNetExpensesArray])

  useEffect(() => {
    if (typeRepairArray?.rates?.length) {
      setTotalAppointmentTypeCost(0)
      for (let i = 0; i < typeRepairArray.rates?.length; i++) {
        setTotalAppointmentTypeCost(
          prev =>
            prev +
            +typeRepairArray.rates[i].time *
              +typeRepairArray.rates[i].cost_per_hour,
        )
      }
    } else {
      setTotalAppointmentTypeCost(0)
    }
  }, [typeRepairArray.rates])
  useEffect(() => {
    if (typeRepairArray?.history?.length) {
      let newArr = []
      newArr = {
        ...typeRepairArray,
      }
      const spendTime = calcWorkerSpendTime(typeRepairArray?.history)
      const spendCost = calculateWorkJobCost(
        +spendTime?.hours,
        +spendTime?.minutes,
        +typeRepairArray?.user?.labor_rate,
      )
      newArr = {
        ...typeRepairArray,
        cost: spendCost,
        workTime: spendTime,
      }
      setTypeRepairArray(newArr)
    }
  }, [typeRepairArray?.history])

  useEffect(() => {
    if (workDatesArray?.rates?.length) {
      setTotalWorkDatesCost(0)
      for (let i = 0; i < workDatesArray.rates?.length; i++) {
        setTotalWorkDatesCost(
          prev =>
            prev +
            +workDatesArray.rates[i].time *
              +workDatesArray.rates[i].cost_per_hour,
        )
      }
    } else {
      setTotalWorkDatesCost(0)
    }
  }, [workDatesArray.rates])
  useEffect(() => {
    if (workDatesArray?.history?.length) {
      let newArr = []
      newArr = {
        ...workDatesArray,
      }
      for (let i = 0; i < workDatesArray.history?.length; i++) {
        const spendTime = calcWorkerSpendTime(
          workDatesArray?.history?.[i]?.status_logs,
        )
        const spendCost = calculateWorkJobCost(
          +spendTime?.hours,
          +spendTime.minutes,
          +workDatesArray?.history?.[i]?.user?.labor_rate,
        )
        newArr.history[i] = {
          ...workDatesArray.history[i],
          cost: spendCost,
          workTime: spendTime,
        }
      }

      setWorkDatesArray(newArr)
    }
  }, [workDatesArray?.history])

  const CHECK_IS_TOTAL_MATERIALS = totalMaterials?.job_cost_materials
    ? +totalMaterials.total_materials
    : 0

  const PARTS_FOR_REIMBURSMENT = expensesArray?.reduce((acc, el) => {
    if (el?.is_reimbursed) {
      acc += Number(el.price)
      return acc
    }
    return acc
  }, 0)

  // const GROSS_SALE = +totalToPay + (!includeTips ? +tips : 0)
  const GROSS_SALE = !includeTips ? +totalToPay : +totalToPay - tips

  useEffect(() => {
    if (commission?.type === 1) {
      setTotalCommission(() => {
        const totalCost =
          totalExpensesCost +
          totalAdvertisingCost +
          totalAppointmentTypeCost +
          totalWorkDatesCost +
          totalPreNetExpensesCost +
          CHECK_IS_TOTAL_MATERIALS
        const total1 = Number(GROSS_SALE || 0) - Number(totalCost)
        const percentage1 = Number(commission.percent) / 100
        const result = Number(total1 * percentage1)
        return result
      })
    } else if (commission?.type === 2) {
      setTotalCommission(() => {
        const total2 = Number(GROSS_SALE || 0)
        const percentage2 = Number(commission.percent) / 100
        const result = total2 * percentage2
        return result
      })
    } else if (commission?.type === 3) {
      setTotalCommission(() => {
        const total3 =
          TOTAL_BALANCE_PAID -
          (totalExpensesCost +
            totalAdvertisingCost +
            totalAppointmentTypeCost +
            totalWorkDatesCost +
            totalPreNetExpensesCost +
            CHECK_IS_TOTAL_MATERIALS +
            (!includeTips ? 0 : +tips))
        const percentage3 = Number(commission.percent) / 100
        const result = total3 * percentage3
        return result
      })
    } else if (commission?.type === 4) {
      setTotalCommission(() => {
        const total4 = GROSS_SALE - totalPreNetExpensesCost
        const percentage4 = Number(commission.percent) / 100
        const result = total4 * percentage4
        return result
      })
    }
  }, [
    commission.type,
    commission.percent,
    totalExpensesCost,
    totalAdvertisingCost,
    totalAppointmentTypeCost,
    totalWorkDatesCost,
    totalToPay,
    expensesArray,
    workDatesArray,
    typeRepairArray,
    totalMaterials,
    totalPreNetExpensesCost,
    includeTips,
    tips,
  ])

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await getUsersList({
          pagination: false,
          status: [UserStatus.Active, UserStatus.Inactive, UserStatus.Pending],
        })

        setUser(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchUsers()
  }, [])

  const TOTAL_BALANCE_DUE = balances
    .filter(balance => balance.status !== 3)
    .reduce((a, b) => {
      return a + +b.amount
    }, 0)
  const TOTAL_BALANCE_PAID =
    balances?.length > 0
      ? balances
          .filter(balance => balance.status === 3) // status 3 === 'Paid'
          .map(balance => balance.amount)
          .reduce((a, b) => a + b, 0)
      : 0
  const TOTAL_JOB_POTENTIAL_PROFIT =
    Number(GROSS_SALE) -
    (totalExpensesCost +
      totalAdvertisingCost +
      totalAppointmentTypeCost +
      totalWorkDatesCost +
      totalPreNetExpensesCost +
      CHECK_IS_TOTAL_MATERIALS)

  const TOTAL_JOB_CURRENT_PROFIT =
    TOTAL_BALANCE_PAID -
    (totalExpensesCost +
      totalAdvertisingCost +
      totalAppointmentTypeCost +
      totalWorkDatesCost +
      totalPreNetExpensesCost +
      CHECK_IS_TOTAL_MATERIALS) -
    (!includeTips ? 0 : +tips)

  const TOTAL_JOB_COST =
    totalExpensesCost +
    totalAdvertisingCost +
    totalAppointmentTypeCost +
    totalWorkDatesCost +
    totalPreNetExpensesCost +
    CHECK_IS_TOTAL_MATERIALS
  // const TOTAL_PAYOUTS = totalCommission + PARTS_FOR_REIMBURSMENT

  const TOTAL_PAYOUTS_WITH_TIPS =
    totalCommission + PARTS_FOR_REIMBURSMENT + (includeTips ? +tips : 0)

  const TOTAL_LABOR = totalAppointmentTypeCost + totalWorkDatesCost

  // const PAYOUTS_TOTAL_SUM = useMemo(() => payouts?.reduce((acc, el) => +el.amount + +acc, 0), [payouts])
  const PAYOUTS_TOTAL_SUM = sumPayout

  const TOTAL_POTINTIAL_PROFIT_AFTER_PAYOUTS =
    TOTAL_JOB_POTENTIAL_PROFIT - PAYOUTS_TOTAL_SUM

  const TOTAL_CURRENT_PROFIT_AFTER_PAYOUTS =
    TOTAL_JOB_CURRENT_PROFIT - PAYOUTS_TOTAL_SUM

  const PAYOUT_BALANCE = TOTAL_PAYOUTS_WITH_TIPS - PAYOUTS_TOTAL_SUM

  const TOTAL_PRE_NET_EXPENSSES = totalPreNetExpensesCost
  const NET_SALES = GROSS_SALE - totalPreNetExpensesCost

  const calcPercantage = value => {
    const total = +NET_SALES

    const val = total ? (+value / total) * 100 : 0

    return val ? `${roundNumber(val, 2)}%` : ''
  }

  return !canView ? (
    <Result
      status='403'
      title='403'
      subTitle={messagePerm}
      extra={
        <MainButton
          style={{ margin: '0 auto' }}
          onClick={cancelModalHandler}
          title='Close'
        />
      }
    />
  ) : (
    <>
      <div className='close-button-header'>
        <span
          className='close-button'
          onClick={
            isProposalInsightsTouched
              ? () => setShowConfirmationModal(true)
              : cancelModalHandler
          }
        >
          <Close />
        </span>
      </div>
      <div className={'accounting-header'}>
        <p className={'accounting-title'}>Project Insights</p>
        <span className='accounting-subtitle'>Project#:</span>
        <strong>
          {proposalForm.code ? proposalForm.code : 'No project code'}
        </strong>
        <p>
          <span className='accounting-subtitle'>Project Assigned To:</span>
          <strong>{user?.full_name || proposalForm?.user?.full_name}</strong>
        </p>
      </div>
      {isLoading ? (
        <div className='accounting-spin__wrapper'>
          <Spin className='accounting-spin' />
        </div>
      ) : (
        <Wrapper>
          <div className='total-line'>
            <span className='total-line-title'>Total:</span>
            <span className='total-line-value'>
              {priceToView(totalToPay)}
              {/* {!!+totalToPay && <span className='percent_value'>{`  ${calcPercantage(totalToPay)}`}</span>} */}
            </span>
          </div>

          <div className='total-line'>
            <span className='total-line-title'>
              <Tooltip title='Add all Materials to Expenses'>
                <Checkbox
                  checked={totalMaterials.job_cost_materials}
                  onChange={handleCheckTotalMaterials}
                >
                  <span
                    style={{ fontSize: '16px' }}
                    className='total-line-title'
                  >
                    Total Materials:
                  </span>
                </Checkbox>
              </Tooltip>
            </span>
            <span className='total-line-value'>
              {priceToView(totalMaterials.total_materials || 0)}
              <span className='percent_value'>{`  ${calcPercantage(
                totalMaterials.total_materials,
              )}`}</span>
            </span>
          </div>

          {balances?.length > 0 && (
            <>
              <h5>Payment Schedule</h5>
              <BalanceTable balances={balances} />
            </>
          )}

          {TOTAL_BALANCE_DUE !== 0 && (
            <div className='total-line'>
              <span className='total-line-title'>Total Balance Due:</span>
              <span className='total-line-value'>
                {priceToView(TOTAL_BALANCE_DUE)}
              </span>
            </div>
          )}
          {TOTAL_BALANCE_PAID !== 0 && (
            <div className='total-line'>
              <span className='total-line-title'>Total Balance Paid:</span>
              <span className='total-line-value'>
                {priceToView(TOTAL_BALANCE_PAID)}
              </span>
            </div>
          )}
          <RowItem
            rowTitle={'Pre Net Expenses'}
            listData={preNetExpensesArray}
            setListData={setPreNetExpensesArray}
            total={totalPreNetExpensesCost}
            setIsProposalInsightsTouched={setIsProposalInsightsTouched}
            proposalID={proposalID}
            setIsLoading={setIsLoading}
            saveItemsBeforeDeleting={saveItemsBeforeDeleting}
            saveItemsBeforeFileUpload={saveItemsBeforeFileUpload}
            canEdit={canEdit}
            canAdd={canAdd}
            message={message}
            type={ItemTypeEnum.PRE_NET_EXPENSES}
            isProjectCompleted={isProjectCompleted}
            canView={canView}
            canDelete={canDelete}
            canDeleteSuggestion={canDeleteSuggestion}
            canAddSuggestion={canAddSuggestion}
          />

          <RowItem
            rowTitle={'Expenses'}
            listData={expensesArray}
            setListData={setExpensesArray}
            total={totalExpensesCost}
            setIsProposalInsightsTouched={setIsProposalInsightsTouched}
            proposalID={proposalID}
            setIsLoading={setIsLoading}
            saveItemsBeforeDeleting={saveItemsBeforeDeleting}
            saveItemsBeforeFileUpload={saveItemsBeforeFileUpload}
            canEdit={canEdit}
            canAdd={canAdd}
            message={message}
            type={ItemTypeEnum.EXPENSES}
            isProjectCompleted={isProjectCompleted}
            canView={canView}
            canDelete={canDelete}
            canDeleteSuggestion={canDeleteSuggestion}
            canAddSuggestion={canAddSuggestion}
          />

          <RowItem
            rowTitle={'Advertising Cost'}
            listData={advertisingArray}
            setListData={setAdvertisingArray}
            total={totalAdvertisingCost}
            setIsProposalInsightsTouched={setIsProposalInsightsTouched}
            proposalID={proposalID}
            setIsLoading={setIsLoading}
            saveItemsBeforeDeleting={saveItemsBeforeDeleting}
            saveItemsBeforeFileUpload={saveItemsBeforeFileUpload}
            canEdit={canEdit}
            canAdd={canAdd}
            message={message}
            type={ItemTypeEnum.ADVERSTING}
            isProjectCompleted={isProjectCompleted}
            canView={canView}
            canDelete={canDelete}
            canDeleteSuggestion={canDeleteSuggestion}
            canAddSuggestion={canAddSuggestion}
          />

          <BlockItem
            blockTitle={'Appointment'}
            title={'Work hours'}
            blockData={typeRepairArray}
            setBlockData={setTypeRepairArray}
            total={totalAppointmentTypeCost}
            setIsProposalInsightsTouched={setIsProposalInsightsTouched}
            proposalID={proposalID}
            setIsLoading={setIsLoading}
            saveItemsBeforeDeleting={saveItemsBeforeDeleting}
            canEdit={canEdit}
            canAdd={canAdd}
            createFromAppointment={typeRepairArray?.id}
            calcTotalLaborTime={calcTotalLaborTime}
            message={message}
            users={users}
            createdUserId={proposalForm?.created_user_id}
            createdUserRate={
              users?.find(item => item.id === proposalForm?.created_user_id)
                ?.labor_rate
            }
            isProjectCompleted={isProjectCompleted}
            canView={canView}
            canDelete={canDelete}
            canDeleteSuggestion={canDeleteSuggestion}
            canAddSuggestion={canAddSuggestion}
          />

          <BlockItem
            blockTitle={dictionary.plural}
            title={'Work Dates'}
            blockData={workDatesArray}
            setBlockData={setWorkDatesArray}
            total={totalWorkDatesCost}
            setIsProposalInsightsTouched={setIsProposalInsightsTouched}
            proposalID={proposalID}
            setIsLoading={setIsLoading}
            saveItemsBeforeDeleting={saveItemsBeforeDeleting}
            canEdit={canEdit}
            canAdd={canAdd}
            createFromAppointment={typeRepairArray?.id}
            calcTotalLaborTime={calcTotalLaborTime}
            message={message}
            isJob
            users={users}
            createdUserId={proposalForm?.created_user_id}
            isProjectCompleted={isProjectCompleted}
            canView={canView}
            canDelete={canDelete}
            canDeleteSuggestion={canDeleteSuggestion}
            canAddSuggestion={canAddSuggestion}
            createdUserRate={
              users?.find(item => item.id === proposalForm?.created_user_id)
                ?.labor_rate
            }
          />
          <div className='accounting-footer'>
            <div className='total-line'>
              <span className='total-line-title'>
                {infoIcon('Total amount for the project without tips ')}Total to
                pay without tips:
              </span>
              <span className='total-line-value'>
                {/* <span>{priceToView(GROSS_SALE)}</span> */}
                <span>{priceToView(+totalToPay - +tips)}</span>
              </span>
            </div>

            <div className='total-line'>
              <span className='total-line-title'>
                <Tooltip title='Add Tips to payouts '>
                  <Checkbox
                    checked={includeTips}
                    style={{ marginLeft: '6px', marginTop: '2px' }}
                    onChange={() => {
                      if (!canEdit || isProjectCompleted) {
                        setIsProposalInsightsTouched(false)
                        return openNotificationWithIcon('warning', {
                          message,
                          key: 'commission',
                          maxCount: 2,
                        })
                      }
                      setIsProposalInsightsTouched(true)
                      setIncludeTips(!includeTips)
                    }}
                  ></Checkbox>
                </Tooltip>
                {infoIcon(
                  'Total tips for the project. Will be removed from Gross Sale if added to tech payouts',
                )}
                Tips:{'  '}
              </span>
              <span className='total-line-value'>
                <span>{priceToView(tips)}</span>
              </span>
            </div>

            <div className='accounting-footer'>
              <div className='total-line'>
                <span className='total-line-title'>
                  {infoIcon('Total revenue for the project before deductions.')}
                  Gross Sale:
                </span>
                <span className='total-line-value'>
                  <span>{priceToView(GROSS_SALE)}</span>
                  {/* <span>{priceToView(totalToPay)}</span> */}
                </span>
              </div>
              {!!TOTAL_PRE_NET_EXPENSSES && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('Sum of items price in block')}Total Pre Net
                    Expenses:
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(TOTAL_PRE_NET_EXPENSSES)}
                      {/* <span className='percent_value'>{`  ${calcPercantage(TOTAL_PRE_NET_EXPENSSES)}`}</span> */}
                    </span>
                  </span>
                </div>
              )}

              {!!NET_SALES && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('{Gross Sales} - {Total pre net expenses}')}Net
                    Sales:
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(NET_SALES)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        NET_SALES,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}

              <div className='separator'></div>
              {!!CHECK_IS_TOTAL_MATERIALS && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon(
                      'Total of all parts that are added to the project',
                    )}
                    Total Materials:
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(totalMaterials.total_materials || 0)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        totalMaterials.total_materials,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              {!!totalExpensesCost && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {' '}
                    {infoIcon('Total Expenses Cost')}Total Expenses:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(totalExpensesCost)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        totalExpensesCost,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              {!!totalAdvertisingCost && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {' '}
                    {infoIcon('Total Advertising Cost')} Total Advertising Cost:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(totalAdvertisingCost)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        totalAdvertisingCost,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              {!!TOTAL_JOB_COST && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('{Labor Rates} * {Time}')}Labor Cost:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(TOTAL_LABOR)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        TOTAL_LABOR,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              {!!TOTAL_JOB_COST && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon(
                      '{Total Materials Cost} + {Expenses Cost} + {Advertising Cost} + {Labor Rates Cost} +  {Pre Net Expenses Cost} ',
                    )}{' '}
                    Total Cost:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(TOTAL_JOB_COST)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        TOTAL_JOB_COST,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              <div className='separator'></div>
              {!!TOTAL_JOB_POTENTIAL_PROFIT && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('{Gross Sale} - {Total Cost}')}Total Potential
                    Profit:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(TOTAL_JOB_POTENTIAL_PROFIT)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        TOTAL_JOB_POTENTIAL_PROFIT,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}
              {!!TOTAL_JOB_CURRENT_PROFIT && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {' '}
                    {infoIcon('{Balances Paid} - {Total Cost} - {Tips}')}Total
                    Current Profit:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(TOTAL_JOB_CURRENT_PROFIT)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        TOTAL_JOB_CURRENT_PROFIT,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}

              <div className='separator'></div>
              {!!PARTS_FOR_REIMBURSMENT && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon(
                      'The cost of all selected checkboxes in Expenses items',
                    )}
                    Parts for Reimbursement:{' '}
                  </span>
                  <span className='total-line-value'>
                    <span>
                      {priceToView(PARTS_FOR_REIMBURSMENT)}
                      <span className='percent_value'>{`  ${calcPercantage(
                        PARTS_FOR_REIMBURSMENT,
                      )}`}</span>
                    </span>
                  </span>
                </div>
              )}

              {!!GROSS_SALE && (
                <div className='commission-row'>
                  <div className='bottom-row'>
                    <p style={{ marginTop: '0' }} className='block-title'>
                      Commission:
                    </p>
                    <div className='inputs'>
                      <InputField
                        className='commission-type'
                        value={commission?.type}
                        onChange={e => {
                          if (!canEdit || isProjectCompleted) {
                            setIsProposalInsightsTouched(false)
                            return openNotificationWithIcon('warning', {
                              message,
                              key: 'commission',
                              maxCount: 2,
                            })
                          }
                          setCommission(p => ({ ...p, type: e.target.value }))
                          setIsProposalInsightsTouched(true)
                        }}
                        fullWidth={false}
                        select
                      >
                        {commissionTypeOptions.map((opt, i) => (
                          <MenuItem key={i} value={opt.value}>
                            {opt.title}
                          </MenuItem>
                        ))}
                      </InputField>
                      <InputField
                        className='percentage'
                        fullWidth={false}
                        style={{ width: '100px' }}
                        value={commission?.percent}
                        onChange={e => {
                          handlePercentageField(e)
                          setIsProposalInsightsTouched(true)
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>%</InputAdornment>
                          ),
                        }}
                      />

                      <div className='text'>
                        <span>
                          {infoIcon(
                            `Selected percentage from Gross Sale or Potential Profit or Current Profit or Net Sales `,
                          )}
                          Total Commision:
                        </span>
                      </div>
                    </div>
                    <div className='value'>
                      <span className='total-line-value'>
                        {`${priceToView(totalCommission || 0)}`}
                        <span className='percent_value'>{`  ${calcPercantage(
                          totalCommission || 0,
                        )}`}</span>
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className='separator'></div>

              {/* <div className='total-line'>
              <span className='total-line-title'>
                <Tooltip title={'Add tips to payouts'}>
                  <Checkbox
                    checked={includeTips}
                    style={{ marginLeft: '6px', marginTop: '2px' }}
                    onChange={() => {
                      if (!canEdit || isProjectCompleted) {
                        setIsProposalInsightsTouched(false)
                        return openNotificationWithIcon('warning', {
                          message,
                          key: 'commission',
                          maxCount: 2,
                        })
                      }
                      setIsProposalInsightsTouched(true)
                      setIncludeTips(!includeTips)
                    }}
                  ></Checkbox>
                </Tooltip>
                {infoIcon('Total tips by project balances')}Tips:{'  '}
              </span>
              <span className='total-line-value'>
                <span>{priceToView(tips)}</span>
              </span>
            </div> */}
              {!!TOTAL_PAYOUTS_WITH_TIPS && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon(
                      includeTips
                        ? '{Parts for Reimbursement} + {Total Comission} + {Tips}'
                        : '{Parts for Reimbursement} + {Total Comission} ',
                    )}
                    Total Payout:{'  '}
                  </span>
                  <span className='total-line-value'>
                    <span>{priceToView(TOTAL_PAYOUTS_WITH_TIPS)}</span>
                  </span>
                </div>
              )}

              <AccessControl
                author={[user.id]}
                module={ModuleName.PROJECT}
                scopes={[ProjectPermissions.PROJECT_INSIGHTS_CAN_READ_PAYOUTS]}
              >
                <Payouts
                  sumToAdd={toFixed(
                    TOTAL_PAYOUTS_WITH_TIPS - PAYOUTS_TOTAL_SUM,
                    2,
                  )}
                  setSumPayout={setSumPayout}
                  proposalID={proposalID}
                  canEdit={canEditPayouts}
                  canAdd={canAddPayouts}
                  canDelete={canDeletePayouts}
                  user={user}
                />
              </AccessControl>

              <div className='total-line'>
                <span className='total-line-title'>
                  {infoIcon('Sum of existing payouts that added by user')}Money
                  Paid:{' '}
                </span>
                <span className='total-line-value'>
                  {priceToView(PAYOUTS_TOTAL_SUM)}
                </span>
              </div>

              {!!TOTAL_POTINTIAL_PROFIT_AFTER_PAYOUTS && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('{Total potential profit} - {Money Paid}')}Total
                    Potential Profit After Payouts:{' '}
                  </span>
                  <span className='total-line-value'>
                    {priceToView(TOTAL_POTINTIAL_PROFIT_AFTER_PAYOUTS)}
                    <span className='percent_value'>{`  ${calcPercantage(
                      TOTAL_POTINTIAL_PROFIT_AFTER_PAYOUTS,
                    )}`}</span>
                  </span>
                </div>
              )}

              {!!TOTAL_CURRENT_PROFIT_AFTER_PAYOUTS && (
                <div className='total-line'>
                  <span className='total-line-title'>
                    {infoIcon('{Total current profit} - {Money Paid}')}Total
                    Current Profit After Payouts:{' '}
                  </span>
                  <span className='total-line-value'>
                    {priceToView(TOTAL_CURRENT_PROFIT_AFTER_PAYOUTS)}
                    <span className='percent_value'>{`  ${calcPercantage(
                      TOTAL_CURRENT_PROFIT_AFTER_PAYOUTS,
                    )}`}</span>
                  </span>
                </div>
              )}

              <div className='total-line'>
                <span className='total-line-title'>
                  {infoIcon('{Total Payout} - {Money Paid}')}Payout Balance:{' '}
                </span>
                <span className='total-line-value'>
                  {/* need to fix - as costil */}
                  {roundNumber(PAYOUT_BALANCE) === '-0'
                    ? priceToView(0)
                    : priceToView(roundNumber(PAYOUT_BALANCE, 4))}
                </span>
              </div>
            </div>
          </div>
        </Wrapper>
      )}
      <div className='accounting-footer-buttons'>
        {isProposalInsightsTouched ? (
          <>
            <Popconfirm
              title={
                'Are you sure to close this window? Unsaved changes will be discarded.'
              }
              onConfirm={cancelModalHandler}
              disabled={!isProposalInsightsTouched}
            >
              <MainButton
                type='cancel'
                title='Cancel'
                className='main-button cancelBtn'
              />
            </Popconfirm>
            <MainButton
              title='Save'
              onClick={saveModalHandler}
              className='main-button'
              isFetching={isFetching}
            />
          </>
        ) : (
          <MainButton
            title={'Close'}
            onClick={() => closeModal?.()}
            type={'cancel'}
          />
        )}
      </div>
      <ConfirmationModal
        visible={showConfirmationModal}
        onSave={saveModalHandler}
        onCancel={cancelModalHandler}
        onClose={() => setShowConfirmationModal(false)}
      />

      <ModalFileUploadContainer disableAddByLink />
    </>
  )
}

export default ModalAccounting

interface WrapperProps {
  onlyView: boolean
}

const Wrapper = styled.div<WrapperProps>`
  pointer-events: ${props => (props.onlyView ? 'none' : 'all')};
`
