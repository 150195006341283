import {
  AppointmentListItem,
  exportAppointmentsTable,
  getAppointmentsList,
} from 'api/Appointment'
import React, { useEffect, useMemo, useState } from 'react'

import Table from 'containers/MainContent/Orcatec/components/Table'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import useDebounce from 'hooks/useDebounce'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import { FilterDropdownProps, SorterResult } from 'antd/lib/table/interface'
import { ColumnProps } from 'antd/lib/table'
import { TablePaginationConfig } from 'antd/es/table/interface'
// import { ColumnProps, SorterResult, PaginationConfig } from 'antd/lib/table'
import * as S from './AppointmentsList.style'
import { Link } from 'react-router-dom'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import TimeOffTable from '../TimeOff/TimeOffTable'
import moment from 'moment-timezone'
import { Tooltip, Tag, Select, Checkbox } from 'antd'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { ITechnician } from './types'
import { appointmentStatusFilter, categoryFilter } from './helpers'
import TableLayout, {
  IconWrapper,
  IconsBlock,
} from '../../../Layouts/TableLayout/TableLayout'
import { Settings } from '@material-ui/icons'
import { TableExport } from 'containers/MainContent/Orcatec/components/Table/components/TableExport/TableExport'
import TablePopover from '../../../components/Table/components/TablePopover/index'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'store/Orcatec/hooks'
import { toggleTimeOffModal } from 'store/Orcatec/reducers/timeoff'
import AutocompliteFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/AutocompliteFilter/AutocompliteFilter'
import {
  typesForTableSelector,
  sourcesForTableSelector,
} from 'store/Orcatec/selectors/company'
import DateRangeFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DateRangeFilter/DateRangeFilter'
import { DispatchPermission } from 'features/Settings/UsersAndGroups'
import { AccessControl } from 'features/Settings/UsersAndGroups/components/AccessControl/AccessControl'
import { EventKind } from 'types/Appointment'
import { DispatchEvent } from 'features/Dispatch/types'
import { useTranslation } from 'react-i18next'

interface Props {
  pageView: number
  onChangeView: (pageView: number) => void
}

export const AppointmentsList: React.FC<Props> = ({
  onChangeView,
  pageView,
}) => {
  const { t } = useTranslation(['dispatch', 'common'])

  const [appointments, setAppointments] = useState<AppointmentListItem[]>([])
  const [loading, setLoading] = useState(false)
  const settingsFromLC = getValueFromLS('appointments-table_v1')
  // const timezone = useAppSelector(
  //   s => s.orcatec?.company?.timezone || moment.tz.guess(),
  // )
  const technicians: ITechnician[] = useAppSelector(state =>
    state.orcatec?.company?.technicians?.map(t => ({
      id: t.id,
      name: t.full_name,
    })),
  )
  const types = useAppSelector(typesForTableSelector)
  const sources = useAppSelector(sourcesForTableSelector)
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: settingsFromLC?.page_size || 25,
    total: 10,
  })
  // const [technicians] = useState<ITechnician[] | []>([])

  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)

  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const debouncedSearchValue = useDebounce(search, 500)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [openTableSettings, setOpenTableSettings] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [deletedOnly, showDeleteOnly] = useState(
    settingsFromLC?.deletedOnly ?? 0,
  )

  const fetchAppointments = async (params?: object) => {
    setLoading(true)
    try {
      const { data, meta } = await getAppointmentsList(params)

      setAppointments(
        data.map((appointment, index) => ({ ...appointment, key: index })),
      )
      setPagination(meta)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    fetchAppointments({
      per_page: settingsFromLC?.page_size || 25,
      sort_field: sortField,
      order: sortOrder,
      search: debouncedSearchValue || undefined,
      deletedOnly,
      ...tableFilters,
      // ...additionalFilters,
    })
  }, [debouncedSearchValue, deletedOnly])

  // const techFilter = useMemo(() => technicians.map(tech => ({ text: tech.name, value: tech.id })), [technicians])
  const tagRender = tags => {
    return tags?.length
      ? tags?.map((tag, idx) => (
          <Tag
            style={{
              margin: '4px',
              backgroundColor: '#cce2f6',
            }}
            key={idx}
          >
            {tag?.length > 10 ? (
              <Tooltip title={tag}>{`${tag.slice(0, 10)}...`}</Tooltip>
            ) : (
              tag
            )}
          </Tag>
        ))
      : '-'
  }
  const columns: ColumnProps<DispatchEvent>[] = useMemo(
    () => [
      {
        title: t('eventLists.status'),
        name: t('eventLists.status'),
        dataIndex: 'status',
        sorter: true,
        render: (value: number, record) => (
          <Tooltip
            overlayStyle={{ whiteSpace: 'pre-line' }}
            title={record?.status_logs?.map?.((item, idx) => (
              <p key={idx}>{`${item.status +
                ' ' +
                moment
                  .utc(item.created_at)
                  .local()
                  .format('MM/DD/YYYY hh:mm A')}`}</p>
            ))}
          >
            <span>
              <AppointmentStatus statusId={value} disableToolTip />
            </span>
          </Tooltip>
        ),
        defaultWidth: 50,
        filters: appointmentStatusFilter,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
      },
      {
        title: 'ID',
        name: 'ID',
        dataIndex: 'appointment_number',
        sorter: true,

        render: (text: number, record: DispatchEvent) =>
          record?.deleted_at ? (
            <span>{text}</span>
          ) : (
            <Link
              className='link'
              to={`/${record.kind === EventKind.JOB ? 'jobs' : 'appointment'}/${
                record.id
              }`}
            >
              {text ?? '-'}
            </Link>
          ),
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 50,
      },
      {
        title: t('eventLists.category'),
        name: t('eventLists.category'),
        dataIndex: 'appointment_category',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 70,
        filters: categoryFilter,
        filterMode: 'tree',
      },
      {
        title: t('eventLists.location'),
        name: t('eventLists.location'),
        dataIndex: 'appointment_location',
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.city'),
        name: t('eventLists.city'),
        dataIndex: 'property_city',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.state'),
        name: t('eventLists.state'),
        dataIndex: 'property_state',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.zip'),
        name: t('eventLists.zip'),
        dataIndex: 'property_postcode',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.unit'),
        name: t('eventLists.unit'),
        dataIndex: 'property_unit',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.propertyTag'),
        name: t('eventLists.propertyTag'),
        dataIndex: 'property_tags',
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex
            ? { style: { whiteSpace: 'pre-wrap' } }
            : ellipsisStyle,
        render: tags => tagRender(tags),
        sorter: false,
        defaultWidth: 200,
        // defaultFilteredValue: additionalFilters.property_tags,
      },
      {
        title: t('eventLists.clientName'),
        name: t('eventLists.clientName'),
        dataIndex: 'client_name',
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        sorter: true,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.eventDate'),
        name: t('eventLists.eventDate'),
        dataIndex: 'appointment_date',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        // filterDropdown: (props: FilterDropdownProps) => (
        //   <DateFilter {...props} />
        // ),
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeFilter
            {...props}
            nameLS='appointment-list-filtілнer'
            name='appointment_date'
          />
        ),
        defaultWidth: 100,
      },
      {
        title: t('eventLists.arrivalTime'),
        name: t('eventLists.arrivalTime'),
        dataIndex: 'promised_arrival_time_start',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.eta'),
        name: t('eventLists.eta'),
        dataIndex: 'eta',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.dateCreated'),
        name: t('eventLists.dateCreated'),
        dataIndex: 'created_at',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        filterDropdown: (props: FilterDropdownProps) => (
          <DateRangeFilter
            {...props}
            nameLS='appointment-list-filter'
            name='created_at'
          />
        ),
        defaultWidth: 100,
        render: (data: string) => (
          <span>{moment(data).format('MM/DD/YYYY hh:mm A ')}</span>
        ),
      },
      {
        title: t('eventLists.createdBy'),
        name: t('eventLists.createdBy'),
        dataIndex: 'user_created_full_name',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        filterDropdown: (props: FilterDropdownProps) => (
          <AutocompliteFilter
            title='User Name'
            values={technicians}
            {...props}
          />
        ),
        defaultWidth: 100,
      },

      {
        title: t('eventLists.deletedDate'),
        name: t('eventLists.deletedDate'),
        dataIndex: 'deleted_at',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        render: (data: string) => (
          <span>{data ? moment(data).format('MM/DD/YYYY hh:mm A ') : '-'}</span>
        ),
      },

      {
        title: t('eventLists.routeTime'),
        name: t('eventLists.routeTime'),
        dataIndex: 'route_time',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.timeSpent'),
        name: t('eventLists.timeSpent'),
        dataIndex: 'time_spent',
        sorter: true,
        noFilter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
      },
      {
        title: t('eventLists.type'),
        name: t('eventLists.type'),
        dataIndex: 'appointment_type',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        filters: types,
        filterSearch: true,
        filterMode: 'tree',
      },
      {
        title: t('eventLists.tech'),
        name: t('eventLists.tech'),
        dataIndex: 'tech',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        filterDropdown: (props: FilterDropdownProps) => (
          <AutocompliteFilter title='Tech' values={technicians} {...props} />
        ),
      },
      {
        title: t('eventLists.source'),
        name: t('eventLists.source'),
        dataIndex: 'appointment_source',
        sorter: true,
        onCell: (record: DispatchEvent) =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        defaultWidth: 100,
        filters: sources,
        filterSearch: true,
        filterMode: 'tree',
      },
      {
        title: t('eventLists.notes'),
        name: t('eventLists.notes'),
        dataIndex: 'notes',
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (notes, record) =>
          TablePopover(notes, record, 'Notes', clickedRowIndex),
        sorter: true,
        editable: true,
        defaultWidth: 100,
      },
    ],
    [technicians, clickedRowIndex],
  )

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof DispatchEvent, string[]>>,
    sorter: SorterResult<DispatchEvent>,
  ) => {
    fetchAppointments({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      deletedOnly,
      ...filters,
      // ...additionalFilters,
    })
    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })
  }

  const onRow = (record: DispatchEvent, rowIndex: number) => ({
    onClick: () => {
      setClickedRowIndex(rowIndex)
    },
  })

  const handleExportTable = () => {
    setLoading(true)
    exportAppointmentsTable({
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      deletedOnly,
      ...tableFilters,

      columns: JSON.parse(localStorage.getItem('appointments-table_v1'))
        ?.columns,
    })
      .catch(e => console.error(e?.message))
      .finally(() => setLoading(false))
  }

  const handleAddTimeOff = () => dispatch(toggleTimeOffModal())

  return (
    <TableLayout
      title={
        pageView === 3
          ? t('eventLists.title')
          : t('heading.dispatchView.select.timeOffList')
      }
      className='appointment-list'
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      addButton={
        <>
          <AccessControl
            scopes={[DispatchPermission.DISPATCH_CAN_CREATE_EVENT]}
          >
            {pageView === 3 ? (
              <S.AppointmentListHeading>
                <Link to={'/appointment'}>
                  <MainButton title={t('heading.createAppointment')} />
                </Link>
              </S.AppointmentListHeading>
            ) : (
              <MainButton
                title={t('heading.addTimeoff')}
                onClick={handleAddTimeOff}
              />
            )}
          </AccessControl>

          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              {t('heading.dispatchView.tooltip')}:
            </span>
            <Select
              // defaultValue={3}
              style={{ width: 140 }}
              value={pageView}
              onChange={onChangeView}
              options={[
                {
                  value: 1,
                  label: t('heading.dispatchView.select.dayView'),
                },
                {
                  value: 2,
                  label: t('heading.dispatchView.select.weekView'),
                },
                {
                  value: 3,
                  label: t('heading.dispatchView.select.listView'),
                },
                {
                  value: 4,
                  label: t('heading.dispatchView.select.timeOffList'),
                },
              ]}
            />
          </div>
        </>
      }
      actions={
        <S.AppointmentListHeadingButtons>
          {pageView === 3 && (
            <IconsBlock>
              <Checkbox
                checked={!!deletedOnly}
                onChange={e => showDeleteOnly(e.target.checked ? 1 : 0)}
                disabled={loading}
              >
                {t('eventLists.showDeletedEventsOnly')}
              </Checkbox>

              <InputField
                label={t('heading.search', { ns: 'common' })}
                value={search}
                onChange={e => setSearch(e.target.value)}
                fullWidth={false}
              />
              <TableExport
                handleExportTable={handleExportTable}
                loading={loading}
                currentCount={pagination?.total}
              />
              <IconWrapper onClick={() => setOpenTableSettings(true)}>
                <Tooltip placement='left' title='Columns settings'>
                  <Settings />
                </Tooltip>
              </IconWrapper>
            </IconsBlock>
          )}
        </S.AppointmentListHeadingButtons>
      }
    >
      {pageView === 3 ? (
        <>
          <Table
            name='appointments'
            columns={columns}
            data={appointments}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            onRow={onRow}
            search={debouncedSearchValue}
            openTableSettings={openTableSettings}
            additionalFilters={{ deletedOnly }}
            onCloseTableSettings={() => setOpenTableSettings(false)}
          />
        </>
      ) : (
        <TimeOffTable />
      )}
    </TableLayout>
  )
}
