import { Radio } from 'antd'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { getProposalStatus } from 'containers/MainContent/Orcatec/Proposals/helpers/getProposalStatus'
import { priceToView } from 'helpers/thousandSeparator'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import './styles.scss'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  selectInvestmentTotal,
  selectProjectDiscountsTotal,
  selectProjectFeesTotal,
  selectProjectRebatesTotal,
  selectProjectSettings,
  selectProjectTaxTotal,
  selectProjectTotal,
} from 'features/Project/projectSelectors'

const FinancingCalculations = ({
  financing,
  // tabIndex = 0,
  // isShowTaxes,
  hidePrices,
  isCurrentTab,
  allTotalPrices,
}) => {
  if (!financing) return null

  const {
    investmentSubtotal,
    discounts,
    rebates,
    totalAfterRebatesAndInstants,
    feeTotal,
    tax,
    totalToPay,
  } = financing /* ?.[tabIndex] || financing?.[Object.keys(financing)?.[0]] || {} */

  const subtotalPrice =
    isCurrentTab && allTotalPrices?.investmentSubtotal
      ? allTotalPrices.investmentSubtotal
      : investmentSubtotal
  const instantsPrice =
    isCurrentTab && allTotalPrices?.instants
      ? allTotalPrices.instants
      : discounts
  const rebatesPrice =
    isCurrentTab && allTotalPrices?.rebates ? allTotalPrices.rebates : rebates
  const totalAfterRebatesAndInstantsPrice =
    isCurrentTab && allTotalPrices?.totalAfterRebatesAndInstants
      ? allTotalPrices.totalAfterRebatesAndInstants
      : totalAfterRebatesAndInstants
  const totalToPayPrice =
    isCurrentTab && allTotalPrices?.totalToPay
      ? allTotalPrices.totalToPay
      : totalToPay

  return (
    <div className='financing-calculations'>
      {!!investmentSubtotal &&
        investmentSubtotal !== totalToPay &&
        !hidePrices && (
          <div className='calculation_row'>
            {/* {isShowTaxes && ( */}
            <>
              <p>Subtotal:</p> <p>{priceToView(subtotalPrice)}</p>
            </>
            {/* )} */}
          </div>
        )}
      {!!discounts && !hidePrices && (
        <div className='calculation_row'>
          <p>Discounts:</p> <p>{priceToView(instantsPrice)}</p>
        </div>
      )}
      {!!rebates && !hidePrices && (
        <div className='calculation_row'>
          <p>Rebates:</p> <p>{priceToView(rebatesPrice)}</p>
        </div>
      )}
      {(!!instantsPrice || !!rebatesPrice) &&
        totalAfterRebatesAndInstantsPrice !== totalToPayPrice &&
        !hidePrices && (
          <div className='calculation_row'>
            <p>{`After ${
              instantsPrice && rebatesPrice
                ? 'Discounts and Rebates'
                : rebatesPrice
                ? 'Rebates'
                : 'Discounts'
            }:`}</p>{' '}
            <p>{priceToView(totalAfterRebatesAndInstantsPrice)}</p>
          </div>
        )}
      {!!tax && !hidePrices && (
        <div className='calculation_row'>
          <p>Tax:</p> <p>{priceToView(tax)}</p>
        </div>
      )}
      {!!feeTotal && !hidePrices && (
        <div className='calculation_row'>
          <p>Fees:</p> <p>{priceToView(feeTotal)}</p>
        </div>
      )}
    </div>
  )
}

const ProposalTabs = ({
  proposal: {
    tabs_info,
    signed_tab_id,
    status,
    // financing,
    financing_info,
    setting,
  },
  activeTabIdx,
  onChangeTab,
  onAddNewTab,
  priorityTabId,
  onChangePriorityTab,
  isClientView,
  isLoading,
  allTotalPrices,
  hidePrices,
  currentTabId,
}) => {
  const investmentSubtotal = useAppSelector(selectInvestmentTotal)
  const totalToPay = useAppSelector(selectProjectTotal)
  const feeTotal = useAppSelector(selectProjectFeesTotal)
  const tax = useAppSelector(selectProjectTaxTotal)
  const rebates = useAppSelector(selectProjectRebatesTotal)
  const discounts = useAppSelector(selectProjectDiscountsTotal)

  // const isSetTemplate = useSelector(
  //   state => state.orcatec?.proposalForm.priority_tab_id,
  // )

  const isMobileApp = () =>
    window?.ReactNativeWebView && window?.ReactNativeWebView?.postMessage

  const onClickInApp = event => {
    window.ReactNativeWebView.postMessage(JSON.stringify(event))
  }

  const projectSettings = useSelector(selectProjectSettings)
  const handleClickApply = () => {
    if (isMobileApp())
      return onClickInApp({
        key: 'open_file',
        value: financing_info?.[0]?.link,
      })

    window.open(financing_info?.[0]?.link, '_blank')
  }

  const getTabFinancing = tabId => {
    if (tabId === currentTabId)
      return {
        investmentSubtotal,
        discounts,
        rebates,
        totalAfterRebatesAndInstants: investmentSubtotal - discounts - rebates,
        feeTotal,
        tax,
        totalToPay,
      }

    return financing_info.find(tab => tab.tab_id === tabId)
  }

  useEffect(() => {
    if (tabs_info?.length === 1 && activeTabIdx === null)
      onChangeTab(0, tabs_info[0]?.id)
  }, [])

  const isShowTabs = projectSettings?.multiple_tabs || setting?.multiple_tabs

  return (
    <>
      {isShowTabs ? (
        <div
          className={`proposal-financing-tabs${
            tabs_info.length === 1 ? ' single' : ''
          }`}
        >
          {[...tabs_info]
            ?.sort((a, b) => a.position - b.position)
            .map((tab, tabIndex) => (
              <div
                key={tabIndex}
                // className={`proposal-financing-tab ${currentTab === i || currentTab === null ? 'active' : ''}`}
                className={`proposal-financing-tab ${
                  activeTabIdx === tabIndex ? 'active' : ''
                } ${activeTabIdx === null ? 'all-active' : ''}`}
                onClick={() =>
                  tabs_info?.length >= 2 ? onChangeTab(tabIndex, tab.id) : null
                }
              >
                <div className='tab-info'>
                  <p className='tab-title'>
                    {!isClientView
                      ? tab.title
                      : tab.title !== 'Tab Title'
                      ? tab.title
                      : null}
                  </p>

                  {tab?.image && (
                    <div
                      className='tab-image'
                      title='tab-image'
                      style={{
                        backgroundImage: `url(${process.env
                          .REACT_APP_API_MEDIA + tab?.image?.preview})`,
                      }}
                    />
                  )}

                  {!!tab?.description?.length && (
                    <div className='tab-description'>
                      {tab?.description?.split('\n')?.map((item, index) => (
                        <p key={index}>{item}</p>
                      ))}
                    </div>
                  )}
                </div>

                <FinancingCalculations
                  financing={getTabFinancing(tab.id)}
                  tabIndex={tabIndex}
                  isCurrentTab={activeTabIdx === tabIndex}
                  allTotalPrices={allTotalPrices}
                  hidePrices={hidePrices}
                />

                {!!getTabFinancing(tab.id)?.totalToPay && !hidePrices && (
                  <div className='financing-total'>
                    <p>Total:</p>{' '}
                    <p>
                      {priceToView(
                        isClientView && activeTabIdx === tabIndex
                          ? allTotalPrices?.totalToPay
                          : getTabFinancing(tab.id)?.totalToPay,
                      )}
                    </p>
                  </div>
                )}

                {setting?.financing && !!financing_info?.[0]?.link && (
                  <div className='financing-conditions'>
                    <p>
                      As low as ${financing_info?.[tabIndex]?.payPerMonth}
                      &nbsp;per&nbsp;month*
                    </p>
                    {activeTabIdx !== null && (
                      <MainButton title='Apply' onClick={handleClickApply} />
                    )}
                  </div>
                )}

                <div className='tabs-buttons'>
                  {tabs_info?.length > 1 && (
                    <>
                      <MainButton
                        title={
                          activeTabIdx === tabIndex
                            ? 'Hide option'
                            : 'View option'
                        }
                        onClick={() => onChangeTab(tabIndex, tab.id)}
                        className={activeTabIdx === tabIndex ? 'hide' : ''}
                      />
                    </>
                  )}

                  {!!priorityTabId && getProposalStatus(status) === 'Estimate' && (
                    <Radio
                      checked={priorityTabId === tab.id}
                      value={tab.id}
                      onClick={e => {
                        e.stopPropagation()
                        onChangePriorityTab(+e.target.value)
                      }}
                    />
                  )}
                </div>

                {status !== 1 && (
                  <small className='align-self-end ml-2'>
                    {' '}
                    {tab.id === signed_tab_id ? 'contract' : 'view only'}{' '}
                  </small>
                )}
              </div>
            ))}

          {onAddNewTab &&
            getProposalStatus(status) === 'Estimate' &&
            tabs_info?.length < 5 && (
              <MainButton
                title='Add new tab'
                type='cancel'
                className='add-new-tab'
                onClick={onAddNewTab}
                isFetching={isLoading}
                // disabled={!isSetTemplate}
              />
            )}
        </div>
      ) : null}
    </>
  )
}

export default ProposalTabs
