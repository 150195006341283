import React from 'react'
import moment from 'moment-timezone'
import AppointmentStatus from '../../../../components/AppointmentStatus/AppointmentStatus'
import { Modal, Tooltip } from 'antd'
import InfoIcon from '@material-ui/icons/Info'
import { TextField } from '@material-ui/core'
import MainButton from '../../../../components/buttons/MainButton'

import './AppointmentStatusSwitcher.scss'
import { AppointmentStatusID, IAppointment } from 'types/Appointment'
import { Job } from 'types/Jobs'
import { useTranslation } from 'react-i18next'

const appointmentStatuses = [1, 5, 2, 3, 4]

interface AppointmentStatusSwitcherProps {
  appointment: IAppointment | Job
  showCancellationModal: boolean
  cancellationOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeStatus: (
    appointmentId: number | undefined,
    status: AppointmentStatusID,
  ) => void
  setShowCancellationModal: (status: boolean) => void
  isSchedule?: boolean
  canEdit: boolean
  statusHistoryVisible?: boolean
  withText?: boolean
}

const AppointmentStatusSwitcher: React.FC<AppointmentStatusSwitcherProps> = ({
  appointment,
  cancellationOnChange,
  handleChangeStatus,
  showCancellationModal = false,
  setShowCancellationModal,
  isSchedule,
  canEdit = true,
  statusHistoryVisible = true,
  withText = false,
}) => {
  const { t } = useTranslation('appointment')
  const { t: tCommon } = useTranslation('common')
  const getBlockedStatuses = (recurring: boolean /* inQueue: boolean */) => {
    const blockedStatuses = recurring
      ? [2, 5] /* : inQueue ? [2, 3, 4, 5] */
      : []
    const blockedMessage = recurring
      ? t('blockedMessages.disabledRecParent')
      : t('blockedMessages.disabledInQueue')

    return (
      <div
        style={withText ? { maxWidth: '400px' } : {}}
        className='appointment-statuses-container'
      >
        {appointmentStatuses.map((item, idx) => (
          <div
            key={idx}
            className={`status-container${
              appointment.status === item ? ' active' : ' inactive'
            } ${blockedStatuses.includes(item) ? 'blocked' : ''} ${
              !canEdit ? 'disabled' : ''
            }`}
            onClick={() => {
              if (blockedStatuses.includes(item) || !canEdit) return null

              return item === 4 && appointment.status !== 4
                ? (() => {
                    setShowCancellationModal(true)
                    cancellationOnChange({ target: { name: '', value: '' } })
                  })()
                : handleChangeStatus(appointment.id, item)
            }}
          >
            <AppointmentStatus
              statusId={item}
              customTitle={
                !canEdit
                  ? tCommon('notification.noAccess')
                  : blockedStatuses.includes(item)
                  ? blockedMessage
                  : undefined
              }
            />
            {withText && (
              <p
                style={{
                  color: '#007bff',
                  cursor: 'pointer',
                  textAlign: 'center',
                  marginTop: '-18px',
                }}
              >
                {appointmentStatusesTitle[idx]}
              </p>
            )}
          </div>
        ))}
      </div>
    )
  }

  const appointmentStatusesTitle = [
    t('status.active'),
    t('status.enRoute'),
    t('status.inProgress'),
    t('status.completed'),
    t('status.cancelled'),
  ]

  return (
    <div
      className='appointment-statuses-wrapper'
      data-testid='appointment-statuses'
    >
      {getBlockedStatuses(
        !!appointment.is_recurring,
        typeof appointment.column_template_id === 'string' && !isSchedule,
      )}
      {statusHistoryVisible && (
        <Tooltip
          overlayStyle={{ whiteSpace: 'pre-line', minWidth: '350px' }}
          title={appointment.status_logs?.map?.((item, idx) => (
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              key={idx}
            >{`${item.status +
              ' ' +
              moment
                .utc(item.created_at)
                .local()
                .format('MM/DD/YYYY hh:mm A') +
              (item?.user?.full_name
                ? ' - ' + item?.user?.full_name
                : '')}`}</p>
          ))}
        >
          <InfoIcon
            style={{ marginLeft: '-5px', fontSize: '20px', color: '#4c53ef' }}
          />
        </Tooltip>
      )}
      <Modal
        visible={showCancellationModal}
        footer={null}
        destroyOnClose={true}
        bodyStyle={{ padding: '30px' }}
        wrapClassName={''}
        maskClosable
        onCancel={() => setShowCancellationModal(false)}
      >
        <h4>{t('heading.reasonOfCancel')}:</h4>
        <TextField
          fullWidth
          label={t('heading.cancellationReason')}
          variant='outlined'
          size='small'
          value={appointment.canceled_info?.reason}
          onChange={cancellationOnChange}
          inputProps={{ maxLength: 100 }}
        />
        <div
          className='buttons-wrapper'
          style={{
            display: 'flex',
            marginTop: '15px',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <MainButton
            disabled={!appointment.canceled_info?.reason?.length}
            title={t('heading.cancelAppointment')}
            onClick={() =>
              handleChangeStatus(appointment.id, AppointmentStatusID.Cancelled)
            }
          />
        </div>
      </Modal>
    </div>
  )
}

export default AppointmentStatusSwitcher
