import moment from 'moment-timezone'

export const errorMsg = {
  empty: 'Project Number Pattern is required',
  invalid:
    'The increment is required and should be in the next format {00}, with a flexible count of zeros ranging from 2 to 10. Only words without spaces or symbols are allowed.',
}

export const parseString = (inputString: string, projectUPI: string) => {
  const variables = {
    UPI: projectUPI || 'EXP',
    yy: moment()
      .tz(moment.tz.guess())
      .format('YY'),
    mm: moment()
      .tz(moment.tz.guess())
      .format('MM'),
    dd: moment()
      .tz(moment.tz.guess())
      .format('DD'),
    '00': '01',
    '000': '001',
    '0000': '0001',
    '00000': '00001',
    '000000': '000001',
    '0000000': '0000001',
    '00000000': '00000001',
    '000000000': '000000001',
    '0000000000': '0000000001',
  }

  let parsedString = inputString

  // eslint-disable-next-line no-useless-escape
  parsedString = parsedString.replace(/[ \!@#$%._^&*()]/g, '')

  for (const variable in variables) {
    const value = variables[variable]
    parsedString = parsedString.replaceAll(`{${variable}}`, value)
  }

  return parsedString
}

export const validateInput = (inputValue: string) => {
  const regex = /^(?=.*\{0{2,10}\})[\w{}-]+$/
  if (!inputValue) {
    return { isValid: false, error: errorMsg.empty }
  }

  if (!regex.test(inputValue)) {
    return { isValid: false, error: errorMsg.invalid }
  }

  return { isValid: true, error: '' }
}
