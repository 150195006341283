import { createRef, useEffect, useState } from 'react'
import { Popconfirm, Select } from 'antd'

import { ViewFile } from 'helpers/viewFile/viewFile'

import * as actions from 'store/Orcatec/actions'

import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import '../Proposal.scss'
import { connect, useDispatch } from 'react-redux'
import validator from 'validator'

import AttachmentModal from 'containers/MainContent/Orcatec/Settings/components/Proposal/AttachmentModal'
import { TextField } from '@material-ui/core'

import {
  EquipmentModuleIcon,
  PaymentModuleIcon,
  ModuleOneIcon,
  ModuleTwoIcon,
  ModuleThreeIcon,
} from 'containers/MainContent/Orcatec/components/Icons/ProposalModuleIcons'
import { Prompt } from 'react-router-dom'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

import { defaultProposalModuleNames } from 'common/constants'

import Tabs from 'containers/MainContent/Orcatec/components/Tabs/Tabs'
import { Editor } from '@tinymce/tinymce-react'
// import { Editor } from 'components/UI/Editor/Editor'

import Preloader from 'containers/MainContent/Orcatec/components/Preloader'

import { CopyIconThin } from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'

import ProposalSettings from 'containers/MainContent/Orcatec/Proposals/components/ProposalSettings'
// import { useScreenshot } from 'use-react-screenshot'

// import SelectField from 'containers/MainContent/Orcatec/components/Select'

import { SMSWrapper } from 'containers/MainContent/Orcatec/Settings/SettingsProject.styles'

import SubMenu from 'containers/MainContent/Orcatec/components/SubMenu'
import { updateProjectSettings, getProjectSettings } from 'api/Project'
import EditableBlock from 'containers/MainContent/Orcatec/components/UI/EditableBlock'
import SettingsRow from 'components/UI/SettingsRow'
import {
  getTemplatesListByType,
  getTemplateSettingByIndusty,
  updateTemplateSettingsByIndustry,
} from 'api/NotificationModal'
// import { Companies } from 'types/Company'
import { addIndustry } from 'store/Orcatec/actions/settings/user'
import { Settings } from '../Settings/Settings'
import { ProjectSettings } from 'features/Project/types'

export interface IProjectSettings {
  industry_id: number
}
export interface IProjectRelation {
  user_id: number
  source_id: number
  type_id: number
  date_of_sale: number
}

const initSubmitting = { fetchint: false, success: false, error: false }

const mainLogoSize = { width: '186px', height: '186px' }
const secondaryLogoSize = { width: '124px', height: '124px' }
const initModules = [
  'Payment',
  'Supplies',
  'module_one',
  'module_two',
  'module_three',
]
const logoPlaceholderTextStyle = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '18px',
  color: '#191919',
}

const initLogosArray = new Array(7).fill('')
const onbeforeunload = e => {
  e.preventDefault()
  e.returnValue =
    'Are you sure you want to leave the page without saving changes?'
}

const menuItems = [
  {
    id: 0,
    title: 'Project Settings',
  },
  {
    id: 1,
    title: 'Project Header Setup',
  },
  {
    id: 2,
    title: 'Notification Template',
  },
]

const initialState = {
  logo1: '',
  logo2: '',
  logo3: '',
  logo4: '',
  logo5: '',
  logo6: '',
  logo7: '',
  link1: '',
  link2: '',
  link3: '',
  link4: '',
  link5: '',
  link6: '',
  link7: '',
  module_one: defaultProposalModuleNames.module_one,
  module_two: defaultProposalModuleNames.module_two,
  module_three: defaultProposalModuleNames.module_three,
  show_price: false,
  balance_auto_creation: true,
  show_tech: true,
  show_item_description_only: false,
  show_taxes: false,
  show_information: false,
  multiple_tabs: false,
  completion_progress: false,
  require_signature: true,
  default_payment_due_date: 30,
  hide_zero_price: false,
  multiple_choice_items: false,
  include_tips_to_insight: false,
  show_discounts: false,
  show_rebates: false,
  show_items_qty: false,
  wasEdited: false,
  default_rate: '7.715',
  gross_margin: 0,
  payment_processing_fee: 0,
  additional_email_text: '',
  compact_view: true,
  tabs_info: [],
  header: '',
  name: '',
  show_forms: true,
  show_groups: true,
  show_installation: true,
  show_modules: true,
  show_notes: true,
  show_sections: true,
  show_tags: true,
  show_templates: true,
  show_tips: true,
}

const initialErrors = {
  logo1: '',
  logo2: '',
  logo3: '',
  logo4: '',
  logo5: '',
  logo6: '',
  logo7: '',
  link1: '',
  link2: '',
  link3: '',
  link4: '',
  link5: '',
  link6: '',
  link7: '',
  module_one: '',
  module_two: '',
  module_three: '',
  taxRate: '',
}

const inputProps35 = { maxLength: 35 }

/* function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
} */

const Main = ({
  proposal: { settings: proposalSettings },
  // form,
  fetchProposal,
  saveProposal,
  deleteIndustry,
  companyLogo,
  clearProposal,
  ...props
}) => {
  const dispatch = useDispatch()

  const [submittingData, setSubmittingData] = useState(initSubmitting)
  const [attachmentModal, setAttachmentModal] = useState({
    show: false,
    file: null,
    fileNumber: 1,
  })

  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState(initialErrors)
  const [settings, setSettings] = useState<IProjectSettings>({ industry_id: 0 })
  const [fileIdsToDelete, setfileIdsToDelete] = useState([])
  const [renderConter, setRenderCounter] = useState(0)
  const [editHeader, setEditHeader] = useState(false)
  // const [isSubmitDisabled, setSubmitDisabled] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [emailProposal, setEmailProposal] = useState({
    email: {
      sms_text:
        '[ProjectHeader (Estimate, Invoice, etc.)] from [company_name] Please follow the link below to see your [ProjectHeader (Estimate, Invoice, etc.)] [link_to_project]',
      from_name: '[company_name]',
      reply_to: '[company_email]',
      subject:
        '[ProjectHeader (Estimate, Invoice, etc.)]' +
        ' from ' +
        '[company_name]',
      body:
        '<p>Dear client.</p>' +
        '<p>This is [tech_name], with [company_name].</p>' +
        '<p>Please follow this Link to see your [ProjectHeader (Estimate, Invoice, etc.)]</p>' +
        '<p>If you have any questions, please contact us at [company_email] or [company_phone_number]</p>' +
        '<p>Thank you!&nbsp;</p>',
    },
  })
  const [templates, setTemplates] = useState([])
  const [templateSettings, setTemplateSettings] = useState({})
  // const prevAmount = usePrevious({ editHeader })

  // useEffect(() => {
  //   if (prevAmount?.editHeader === true && editHeader === false) {
  //     getImage()
  //   }
  // }, [editHeader])

  const ref = createRef(null)

  // const [image, takeScreenshot] = useScreenshot()
  // const getImage = () => takeScreenshot(ref.current)

  const toggleAttachmentModal = (file, fileNumber) => {
    if (attachmentModal.show) {
      setAttachmentModal({
        show: false,
        file: null,
        fileNumber: 1,
      })
    } else {
      setAttachmentModal({
        show: true,
        file,
        fileNumber,
      })
    }
  }

  const initData = data => {
    const newState = { ...state }
    if (data && data.id) {
      // eslint-disable-next-line no-unused-vars
      const {
        id,
        module_one,
        module_two,
        module_three,
        logos,
        show_price,
        balance_auto_creation,
        show_tech,
        show_item_description_only,
        default_rate = 0,
        gross_margin,
        payment_processing_fee = 0,
        compact_view,
        tabs_info,
        header,
        // header_snapshot,
        name,
        industry_id,
        show_taxes,
        show_information,
        multiple_tabs,
        completion_progress,
        hide_zero_price,
        multiple_choice_items,
        // hide_section_items,
        show_discounts,
        show_rebates,
        show_items_qty,
        require_signature,
        default_payment_due_date,
        // additional_email_text,
        email_template,
        include_tips_to_insight,
        show_forms,
        show_groups,
        show_installation,
        show_modules,
        show_notes,
        show_sections,
        show_tags,
        show_templates,
        show_tips,
      } = data
      newState.id = id
      newState.name = name
      newState.industry_id = industry_id
      newState.header = header ? header : newState.header
      /* newState.header_snapshot = header_snapshot
        ? header_snapshot
        : newState.header_snapshot */
      newState.module_one = module_one ? module_one : newState.module_one
      newState.module_two = module_two ? module_two : newState.module_two
      newState.module_three = module_three
        ? module_three
        : newState.module_three
      newState.show_price = !!show_price
      newState.balance_auto_creation = balance_auto_creation
      newState.show_tech = !!show_tech
      newState.multiple_choice_items = multiple_choice_items
      newState.hide_zero_price = hide_zero_price
      newState.show_item_description_only = show_item_description_only
      newState.default_rate = default_rate
      newState.compact_view = compact_view
      newState.tabs_info = tabs_info
      newState.wasEdited = false
      newState.show_taxes = show_taxes
      newState.show_information = show_information
      newState.multiple_tabs = multiple_tabs
      newState.completion_progress = completion_progress
      newState.show_discounts = show_discounts
      newState.show_rebates = show_rebates
      newState.show_items_qty = show_rebates
      newState.gross_margin = gross_margin
      newState.show_items_qty = show_items_qty
      newState.payment_processing_fee = payment_processing_fee
      newState.require_signature = require_signature
      newState.default_payment_due_date = default_payment_due_date
      newState.include_tips_to_insight = include_tips_to_insight
      newState.show_forms = show_forms
      newState.show_groups = show_groups
      newState.show_installation = show_installation
      newState.show_modules = show_modules
      newState.show_notes = show_notes
      newState.show_sections = show_sections
      newState.show_tags = show_tags
      newState.show_templates = show_templates
      newState.show_tips = show_tips
      // newState.additional_email_text = additional_email_text

      setEmailProposal({
        has_attachments: email_template?.has_attachments,
        email: {
          sms_text:
            email_template?.sms_text ??
            '[ProjectHeader (Estimate, Invoice, etc.)] from [company_name] Please follow the link below to see your [ProjectHeader (Estimate, Invoice, etc.)] [link_to_project]',
          from_name: email_template?.from_name ?? '[company_name]',
          reply_to: email_template?.reply_to ?? '[company_email]',
          subject:
            email_template?.subject ??
            `[ProjectHeader (Estimate, Invoice, etc.)] from [company_name]`,
          body:
            email_template?.text ??
            '<p>Dear client.</p>' +
              '<p>This is [tech_name], with [company_name].</p>' +
              '<p>Please follow this Link to see your [ProjectHeader (Estimate, Invoice, etc.)]</p>' +
              '<p>If you have any questions, please contact us at [company_email] or [company_phone_number]</p>' +
              '<p>Thank you!&nbsp;</p>',
        },
      })
      if (!!logos && !!Object.keys(logos).length) {
        initLogosArray.forEach((_, idx) => {
          const num = idx + 1
          newState[`logo${num}`] = logos[`logo${num}`]
            ? logos[`logo${num}`]
            : ''
          newState[`link${num}`] =
            !!logos[`logo${num}`] && !!logos[`logo${num}`].company_link
              ? logos[`logo${num}`].company_link
              : ''
        })
      } else {
        initLogosArray.forEach((_, idx) => {
          const num = idx + 1
          newState[`logo${num}`] = `logo${num}`
        })
      }

      if (
        ((!newState.logo4 ||
          (!!newState.logo4 &&
            /^storage_\d+\/logotype\//.test(newState.logo4.preview) &&
            newState.logo4.preview !== companyLogo)) &&
          !!companyLogo) ||
        !module_one ||
        !module_two ||
        !module_three
      ) {
        newState.wasEdited = true
      }
    } else {
      // newState.wasEdited = true
    }
    newState.logo4 =
      !newState.logo4 && !!companyLogo
        ? { preview: companyLogo, path: companyLogo, company_link: '' }
        : !!newState.logo4 &&
          !!companyLogo &&
          /^storage_\d+\/logotype\//.test(newState.logo4.preview) &&
          newState.logo4.preview !== companyLogo
        ? { ...newState.logo4, preview: companyLogo, path: companyLogo }
        : newState.logo4
    setState(newState)
  }

  useEffect(() => {
    fetchProposal()

    return () => clearProposal()
  }, [])

  useEffect(() => {
    setRenderCounter(prev => prev + 1)
  }, [state.header])

  useEffect(() => {
    if (state.wasEdited) window.addEventListener('beforeunload', onbeforeunload)
    else window.removeEventListener('beforeunload', onbeforeunload)
    return () => {
      window.removeEventListener('beforeunload', onbeforeunload)
    }
  }, [state.wasEdited])

  useEffect(() => {
    initData(proposalSettings)
  }, [proposalSettings])

  useEffect(() => {
    const fetchTemplateList = async () => {
      try {
        const res = await getTemplatesListByType('proposal')
        setTemplates(res)
      } catch (error) {
        console.error(error)
      }
    }
    fetchTemplateList()
  }, [])

  useEffect(() => {
    const fetchSettingsByIndustry = async () => {
      try {
        const res = await getTemplateSettingByIndusty(state?.industry_id)
        setTemplateSettings(res)
      } catch (error) {
        console?.error(error)
      }
    }
    if (state?.industry_id) fetchSettingsByIndustry()
  }, [state.industry_id])

  useEffect(() => {
    const fetchProjectSettings = async () => {
      try {
        const res = await getProjectSettings()
        setSettings(res.data.settings)
      } catch (error) {
        console.error(error)
      }
    }
    fetchProjectSettings()
  }, [])

  const onChangeTextField = e => {
    const { name, value } = e.target
    const newErrors = { ...errors }
    if (/^link\d$/.test(name)) {
      const isURL = validator.isURL(value, { require_protocol: true })
      newErrors[name] =
        !value || (!!value && isURL)
          ? ''
          : 'Please input valid link starting with http(s)://'
      setErrors(newErrors)
    }
    const isNumberInput = input => {
      const rgx = /^[0-9]*\.?[0-9]*$/
      return input.match(rgx)
    }
    if (
      (name === 'taxRate' ||
        name === 'gross_margin' ||
        name === 'payment_processing_fee') &&
      !isNumberInput(value)
    )
      return

    setState({
      ...state,
      [name]: value,
      wasEdited: true,
    })
  }

  /* const onChangeCheckbox = e => {
    const { name, checked } = e.target
    setState({ ...state, [name]: checked, wasEdited: true })
  } */
  /*  const onChangeSectionCheckbox = e => {
    const { name, checked } = e.target

    setState({
      ...state,
      hide_section_items: { ...state.hide_section_items, [name]: checked },
      wasEdited: true,
    })
  } */

  const handleSettingsUpdate = (field: Partial<ProjectSettings>) => {
    setState(prev => ({
      ...prev,
      ...field,
      wasEdited: true,
    }))
  }

  const handleCancelChanges = async () => {
    try {
      const res = await getTemplateSettingByIndusty(state?.industry_id)
      setTemplateSettings(res)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSaveTeplates = async name => {
    const value = templateSettings[name]
    try {
      await updateTemplateSettingsByIndustry(state?.industry_id, {
        [name]: value,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeSettingsTemplate = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setTemplateSettings(prev => ({ ...prev, [name]: value }))
  }

  const onBlurModule = e => {
    e.preventDefault()
    const { name, value } = e.target
    if (!value)
      setState({ ...state, [name]: initialState[name], wasEdited: true })
  }

  const open = data => {
    const proposalState = {
      user: props.me,
      proposal_settings: data ? data : proposalSettings,
      company: props.company,
    }
    localStorage.setItem('proposalState', JSON.stringify(proposalState))
    const currentIndustry = state.industry_id
      ? `?industryId=${state.industry_id}`
      : ''
    window.open(`/project/preview${currentIndustry}`, '_blank')
  }

  const openPreview = e => {
    e.preventDefault()
    e.stopPropagation()
    if (state.wasEdited) onSubmit(true)
    else open()
  }

  const onSubmit = isForPreview => {
    const notValid = Object.keys(errors).some(key => !!errors[key])
    if (!notValid) {
      const {
        module_one,
        module_two,
        module_three,
        show_price,
        balance_auto_creation,
        show_tech,
        hide_zero_price,
        multiple_choice_items,
        show_item_description_only,
        default_rate,
        gross_margin,
        payment_processing_fee,
        compact_view,
        name,
        header,
        // header_snapshot,
        id,
        // additional_email_text,
        show_taxes,
        show_information,
        multiple_tabs,
        completion_progress,
        // hide_section_items,
        show_discounts,
        show_rebates,
        show_items_qty,
        require_signature,
        default_payment_due_date,
        include_tips_to_insight,
        show_forms,
        show_groups,
        show_installation,
        show_modules,
        show_notes,
        show_sections,
        show_tags,
        show_templates,
        show_tips,
      } = state
      const data = {
        id: id ? id : undefined,
        module_one,
        module_two,
        module_three,
        show_price,
        balance_auto_creation,
        show_tech,
        hide_zero_price,
        multiple_choice_items,
        show_item_description_only,
        show_taxes,
        show_information,
        require_signature,
        multiple_tabs,
        completion_progress,
        image: {},
        default_logo: companyLogo
          ? process.env.REACT_APP_API_MEDIA + companyLogo
          : '',
        default_rate: default_rate || 0,
        gross_margin,
        payment_processing_fee,
        compact_view,
        name,
        header,
        // hide_section_items,
        show_discounts,
        show_rebates,
        show_items_qty,
        default_payment_due_date,
        include_tips_to_insight,
        show_forms,
        show_groups,
        show_installation,
        show_modules,
        show_notes,
        show_sections,
        show_tags,
        show_templates,
        show_tips,
        // additional_email_text,
        email_template: {
          has_attachments: emailProposal?.has_attachments,
          sms_text: emailProposal.email?.sms_text,
          text: emailProposal.email.body,
          from_name: emailProposal.email.from_name,
          reply_to: emailProposal.email.reply_to,
          subject: emailProposal.email.subject,
        },
        // header_snapshot: image || header_snapshot,
      }
      initLogosArray.forEach((_, idx) => {
        const num = idx + 1
        data.image[`logo${num}`] = state[`logo${num}`]
          ? JSON.stringify({
              ...state[`logo${num}`],
              company_link: state[`link${num}`],
            })
          : ''
      })

      setSubmittingData({ ...initSubmitting, loading: true })

      return saveProposal(data)
        .then(res => {
          if (!data.id) {
            dispatch(
              addIndustry({
                id: res?.item?.industry_id,
                name: res?.item?.name,
              }),
            )
          }
          setfileIdsToDelete([])
          if (isForPreview === true) {
            return open(res.item)
          }
          openNotificationWithIcon('success', {
            message: 'Your changes have been saved successfully',
            description: '',
            key: 'settings-proposal-save-success',
          })
        })
        .catch(e => {
          openNotificationWithIcon('error', {
            message: `${Object?.values?.(
              e?.response?.data?.errors || {},
            )?.[0] ||
              e.response?.status ||
              ''}`,
            key: 'settings-proposal-save-error',
          })
        })
        .finally(() => {
          /* setSubmittingData({ loading: false, success: false, error: true })
          setTimeout(() => { */
          setSubmittingData(initSubmitting)
          /* }, 2500) */
        })
    }
  }

  const onDeleteLogo = (logo, num) => () => {
    if (!!logo.id && !fileIdsToDelete.includes(logo.id)) {
      setfileIdsToDelete(prev => [...prev, logo.id])
    }
    setState({
      ...state,
      [`logo${num}`]:
        num === 4
          ? {
              preview: companyLogo,
              path: companyLogo,
              company_link: state[`link${num}`],
            }
          : '',
      [`link${num}`]: num === 4 ? state[`link${num}`] : '',
      wasEdited: true,
    })
  }

  const openAttachmentModal = (logo, num) => e => {
    e.preventDefault()
    toggleAttachmentModal(logo, num)
  }

  const logos = initLogosArray.map((_, idx) => {
    const num = idx + 1
    const size = num === 4 ? mainLogoSize : secondaryLogoSize
    const link = state[`link${num}`]
    const logo = state[`logo${num}`]
    const customFullViewLink =
      !!logo && !!link && !!validator.isURL(link, { require_protocol: true })
        ? link
        : ''
    return (
      <div key={idx} className='settings-proposal-card'>
        {logo.path ? (
          <ViewFile
            file={logo}
            fileStyle={size}
            dellIconStyle={{
              allwaysVisible: true,
              fontSize: '18px',
              color: 'unset',
            }}
            //customDellIconClassName='mdi mdi-delete'
            onDelete={
              num === 4 && logo.preview === companyLogo
                ? null
                : onDeleteLogo(logo, num)
            }
            isFullViewNeeded={!!customFullViewLink}
            customFullViewLink={customFullViewLink}
          />
        ) : (
          <div
            className='empty d-flex justify-content-center align-items-center'
            style={{
              ...size,
              border: '1px solid #d6d6d6',
              borderRadius: '4px',
            }}
          >
            <span style={logoPlaceholderTextStyle}>{`Logo${num}`}</span>
          </div>
        )}
        <div style={{ width: '124px' }}>
          <MainButton
            title='Upload'
            className='main-button'
            onClick={openAttachmentModal(logo, num)}
          />
          <TextField
            id={`settings-proposal-link${num}`}
            type='url'
            label={`Logo ${num} link`}
            name={`link${num}`}
            value={state[`link${num}`]}
            onChange={onChangeTextField}
            variant='outlined'
            error={!!errors[`link${num}`]}
            helperText={errors[`link${num}`]}
            fullWidth={true}
            size='small'
          />
        </div>
      </div>
    )
  })

  const modules = initModules.map(name => {
    const inputValue = name.includes('module_') ? state[name] : name
    const nameForInputSettings = name.toLowerCase()
    const disabled = !name.includes('module_')
    const icon =
      name === 'Supplies' ? (
        <EquipmentModuleIcon />
      ) : name === 'Payment' ? (
        <PaymentModuleIcon />
      ) : name === 'module_one' ? (
        <ModuleOneIcon />
      ) : name === 'module_two' ? (
        <ModuleTwoIcon />
      ) : (
        <ModuleThreeIcon />
      )
    return (
      <div
        key={nameForInputSettings}
        className='settings-proposal-modules-module'
      >
        <div>{icon}</div>
        <TextField
          id={`settings-proposal-${nameForInputSettings}`}
          type='text'
          label='Module name'
          name={nameForInputSettings}
          value={inputValue}
          onBlur={onBlurModule}
          onChange={onChangeTextField}
          variant='outlined'
          fullWidth={true}
          size='small'
          disabled={disabled}
          inputProps={inputProps35}
        />
      </div>
    )
  })

  const setFile = (fileNumber, file) => {
    setState({
      ...state,
      [`logo${fileNumber}`]: file,
      wasEdited: true,
    })
  }

  const handleDeleteIndustry = () => {
    if (!state.id) {
      fetchProposal()
    } else {
      deleteIndustry(state.industry_id)
    }
  }

  const handleDublicateIndustry = () => {
    if (!state.id) return

    setState(prev => {
      delete prev.id
      return {
        ...prev,
        name: '',
        tabs_info: [
          ...prev.tabs_info,
          {
            name: 'Tab Title',
          },
        ],
      }
    })
  }

  const handleAddNewTab = () => {
    if (!state.id) return

    setState(prev => ({
      ...initialState,
      tabs_info: [
        ...prev.tabs_info,
        {
          name: 'Tab Title',
        },
      ],
    }))
  }

  const handleEditorChange = content => {
    if (renderConter > 1) {
      setState(prev => ({
        ...prev,
        header: content,
        wasEdited: true,
      }))
    } else {
      setState(prev => ({
        ...prev,
        header: content,
      }))
    }
  }

  const handleChangeTab = tabName => {
    const tab = state.tabs_info.find(tab => tab.name === tabName)
    const tabId = tab?.id
    if (!tabId) return

    fetchProposal(tabId)
    setRenderCounter(0)
  }

  if (!state.tabs_info.length) return <Preloader />

  const handleChangeSubTab = id => {
    setActiveTab(id)
  }

  const handleChangeSettings = e => {
    const { name, value } = e.target
    setSettings(prev => ({ ...prev, [name]: value }))
  }

  const handleSaveSettings = async (key: string) => {
    try {
      const res = await updateProjectSettings({ [key]: settings[key] })
      setSettings(prev => ({ ...prev, [key]: res.data.settings?.[key] }))

      openNotificationWithIcon('success', {
        message: 'Setting was saved successfully!',
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <h6 className='page-titile'>Project Industries </h6>
      <SettingsRow
        withInfoIcon
        description='Default industry for creating new project and Fast Payment'
        title='Default Industry'
      >
        <EditableBlock
          onSave={() => handleSaveSettings('industry_id')}
          value={
            state?.tabs_info?.find(i => i?.id === settings?.industry_id)?.name
          }
        >
          <Select
            defaultOpen
            onChange={value =>
              handleChangeSettings({ target: { name: 'industry_id', value } })
            }
            style={{ width: '200px' }}
            value={settings?.industry_id}
            options={state?.tabs_info?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))}
          />
        </EditableBlock>
      </SettingsRow>

      <Tabs
        onAddTab={handleAddNewTab}
        addTabLabel='Add new Industry'
        disableAddNewTab={!state.id}
        onChangeTab={handleChangeTab}
        currentTabLabel={state.name}
      >
        {state.tabs_info?.map(industry => {
          return (
            <div label={industry.name} key={industry.id || industry.name}>
              <div className='settings-proposal-industry'>
                <div className='industy-name'>
                  <TextField
                    type='text'
                    name='name'
                    label='Name of Industry'
                    value={state.name}
                    onChange={onChangeTextField}
                    variant='outlined'
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </div>
                <div className='industry-buttons'>
                  <Popconfirm
                    title='Are you sure you want to delete this industry?'
                    onConfirm={handleDeleteIndustry}
                    okText='Yes'
                    cancelText='No'
                    className='payment-balance-confirm'
                  >
                    <MainButton
                      title='Delete industry'
                      type='delete'
                      disabled={state.tabs_info.length === 1}
                    />
                  </Popconfirm>
                  {activeTab === 0 && (
                    <MainButton
                      onClick={onSubmit}
                      title='Save'
                      isFetching={submittingData.loading}
                      className='main-button button_save'
                      // disabled={isSubmitDisabled}
                    />
                  )}
                  <div
                    className='dublicate-industry'
                    onClick={handleDublicateIndustry}
                  >
                    <CopyIconThin />
                    <span>Duplicate</span>
                  </div>

                  <div
                    onClick={openPreview}
                    className='settings-proposal-logos-header-preview-btn'
                  >
                    <i className='fas fa-eye' />
                    Project Preview
                  </div>
                </div>
              </div>
              <SubMenu
                activeItem={activeTab}
                menuItemsList={menuItems}
                onChange={handleChangeSubTab}
              />
              {activeTab === 1 && (
                <>
                  {' '}
                  <div className='settings-proposal-logos-header'>
                    {/* <span>Project Header Setup</span> */}
                  </div>
                  <div className='proposal-settings-header-wrapper'>
                    <div className='settings-proposal-logos-wrapper'>
                      <div className='settings-proposal-logos-content'>
                        {logos}
                      </div>
                    </div>
                    {editHeader ? (
                      <div className='settings-proposal-editor'>
                        {/* <Editor
                          // initialValue=""
                          value={state.header}
                          onEditorChange={handleEditorChange}
                        /> */}
                        <Editor
                          apiKey={process.env.REACT_APP_TINY_EDITOR_API_KEY}
                          // initialValue=""
                          value={state.header}
                          init={{
                            nowrap: true,
                            force_br_newlines: true,
                            force_p_newlines: false,
                            forced_root_block: '', // Needed for 3.x
                            height: 300,
                            plugins:
                              'directionality link template hr toc lists table',
                            menubar: false,
                            fontsize_formats:
                              '8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 28px 30px 32px 36px',
                            lineheight_formats: '1 1.1 1.2 1.3 1.4 1.5 2',
                            block_formats:
                              'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6',
                            content_style:
                              'h1, h2, h3, h4, h5, h6, p, pre, table, tr, td, th {margin: 0; padding: 0!important; line-height: 1}' +
                              ' body {line-height: 1}',
                            toolbar:
                              'fontselect formatselect fontsizeselect lineheight| bold italic strikethrough forecolor backcolor formatpainter| alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent | table tableprops tablerowprops tablecellprops',
                          }}
                          onEditorChange={handleEditorChange}
                        />

                        <MainButton
                          style={{ margin: '10px 0' }}
                          onClick={() => {
                            onSubmit()
                            setEditHeader(false)
                          }}
                          title='Save'
                        />
                      </div>
                    ) : (
                      <>
                        <div style={{ width: '100%' }} ref={ref}>
                          <ProposalSettings
                            settings={{ header: state.header }}
                          />
                        </div>
                        <MainButton
                          style={{ margin: '10px 0' }}
                          onClick={() => {
                            setEditHeader(true)
                          }}
                          title='Edit'
                        />
                      </>
                    )}
                  </div>
                </>
              )}
              {activeTab === 2 && (
                <>
                  <div className='settings-proposal-logos-header'>
                    {/* <span>Project SMS Setup</span> */}
                  </div>

                  <SMSWrapper>
                    <SettingsRow
                      title={'Default Project Template'}
                      description={
                        <>
                          <p>
                            If notification is sent outside of the times
                            <sup>*</sup> window, system will wait until next day
                            to send email.
                          </p>
                          <small>
                            * - we will use your company timezone from company
                            info.
                          </small>
                        </>
                      }
                      withInfoIcon
                      width={220}
                    >
                      <EditableBlock
                        // editMode={!membership.id}
                        value={
                          templates?.find(
                            t =>
                              t?.id === templateSettings?.project_template_id,
                          )?.name
                        }
                        onSave={() => handleSaveTeplates('project_template_id')}
                        onCancel={handleCancelChanges}
                      >
                        <Select
                          defaultOpen
                          style={{ width: 220 }}
                          value={templateSettings?.project_template_id}
                          onChange={value =>
                            onChangeSettingsTemplate({
                              target: {
                                name: 'project_template_id',
                                value,
                              },
                            })
                          }
                          options={templates?.map(t => ({
                            value: t.id,
                            label: t.name,
                          }))}
                        ></Select>
                      </EditableBlock>
                    </SettingsRow>
                  </SMSWrapper>
                </>
              )}

              {activeTab === 0 && (
                <>
                  {' '}
                  <div className='settings-proposal-logos-header'>
                    {/* <span>Modal Windows Setup</span> */}
                  </div>
                  <div className='settings-proposal-modules'>
                    {modules}
                    <div className='settings-proposal-modules settings-proposal-modules-module-1' />
                    <div className='settings-proposal-modules settings-proposal-modules-module-2' />
                    <div className='settings-proposal-modules settings-proposal-modules-module-3' />
                  </div>
                  {/* <div className='settings-proposal-tax-rate'>
                    <div className='settings-proposal-tax-rate-label'>
                      Default Tax:
                    </div>
                    <TextField
                      id={`settings-proposal-taxRate`}
                      type='text'
                      name='taxRate'
                      value={state.taxRate}
                      onBlur={onBlurModule}
                      onChange={onChangeTextField}
                      variant='outlined'
                      style={{ width: 80, marginBottom: 0 }}
                      size='small'
                      inputProps={{ maxLength: 5 }}
                    />
                    <span className='ml-1'>%</span>
                  </div> */}
                  {/* <div className='settings-proposal-tax-rate'>
                    <div className='settings-proposal-tax-rate-label'>
                      Default Gross margin:
                    </div>
                    <TextField
                      id={`settings-proposal-taxRate`}
                      type='text'
                      name='gross_margin'
                      value={state.gross_margin}
                      // onBlur={onBlurModule}
                      onChange={onChangeTextField}
                      variant='outlined'
                      style={{ width: 80, marginBottom: 0 }}
                      size='small'
                      inputProps={{ maxLength: 6 }}
                    />
                    <span className='ml-1'>%</span>
                  </div> */}
                  <Settings data={state} onChange={handleSettingsUpdate} />
                  {/* <div className='settings-proposal-tax-rate'>
                    <div className='settings-proposal-tax-rate-label'>
                      Default Payment Processing Fee:
                    </div>
                    <TextField
                      id={`settings-proposal-taxRate`}
                      type='text'
                      name='payment_processing_fee'
                      value={state.payment_processing_fee}
                      // onBlur={onBlurModule}
                      onChange={onChangeTextField}
                      variant='outlined'
                      style={{ width: 80, marginBottom: 0 }}
                      size='small'
                      inputProps={{ maxLength: 4 }}
                    />
                    <span className='ml-1'>%</span>
                  </div>
                  <div className='settings-proposal-tax-rate'>
                    <div className='settings-proposal-tax-rate-label'>
                      Default payment due:
                    </div>
                    <SelectField
                      options={[
                        { id: 0, name: 0 },
                        { id: 15, name: 15 },
                        { id: 30, name: 30 },
                        { id: 45, name: 45 },
                        { id: 60, name: 60 },
                        { id: 75, name: 75 },
                        { id: 90, name: 90 },
                      ]}
                      name='default_payment_due_date'
                      value={state.default_payment_due_date}
                      onChange={onChangeTextField}
                      style={{ width: 80 }}
                    />
                    <span className='ml-1'>days.</span>
                  </div>
                  <div className='settings-proposal-checkboxes'>
                    <Checkbox
                      checked={state.show_price}
                      onChange={onChangeCheckbox}
                      name='show_price'
                    >
                      Display price of each item on the project
                    </Checkbox>
                    <br />

                    <Checkbox
                      checked={state.show_tech}
                      onChange={onChangeCheckbox}
                      name='show_tech'
                    >
                      Show Tech information on Customer view
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.show_items_qty}
                      onChange={onChangeCheckbox}
                      name='show_items_qty'
                    >
                      Show QTY information on Customer view
                    </Checkbox>
                    <br />

                    <Checkbox
                      checked={state.balance_auto_creation}
                      onChange={onChangeCheckbox}
                      name='balance_auto_creation'
                    >
                      Auto-create balance for remaining total in Contract
                    </Checkbox>
                    <br />

                    {[
                      Companies.BuildX,
                      Companies.HVAC,
                      Companies.AirLoop,
                      Companies.DivineEnergySolutions,
                    ].includes(props.company.id) && (
                      <>
                        <Checkbox
                          checked={state.completion_progress}
                          onChange={onChangeCheckbox}
                          name='completion_progress'
                        >
                          Display Completion Progress
                        </Checkbox>
                        <br />
                      </>
                    )}
                    <Checkbox
                      checked={state.multiple_choice_items}
                      onChange={onChangeCheckbox}
                      name='multiple_choice_items'
                    >
                      Add-on Items
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.hide_zero_price}
                      onChange={onChangeCheckbox}
                      name='hide_zero_price'
                    >
                      Hide $0 on Customer view
                    </Checkbox>
                    <br />

                    <Checkbox
                      checked={state.show_item_description_only}
                      onChange={onChangeCheckbox}
                      name='show_item_description_only'
                    >
                      Show Item description only
                    </Checkbox>
                    <br />

                    <Checkbox
                      checked={!state.disable_tips}
                      onChange={() => {
                        setState(prev => ({
                          ...prev,
                          disable_tips: !state?.disable_tips,
                          include_tips_to_insight: state?.disable_tips,
                        }))
                      }}
                      name='disable_tips'
                    >
                      Enable tips in Project payment
                    </Checkbox>
                    <br />

                    <Checkbox
                      disabled={state.disable_tips}
                      checked={state.include_tips_to_insight}
                      onChange={onChangeCheckbox}
                      name='include_tips_to_insight'
                    >
                      Automatically add tips to tech payouts in project insights
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.show_taxes}
                      onChange={onChangeCheckbox}
                      name='show_taxes'
                    >
                      Show Taxes
                    </Checkbox>
                    <br />
                    {state.show_taxes && (
                      <div className='settings-proposal-tax-rate'>
                        <div
                          style={{ fontSize: '14px', marginLeft: '30px' }}
                          className='settings-proposal-tax-rate-label'
                        >
                          Tax Rate:
                        </div>
                        <TextField
                          id={`settings-proposal-taxRate`}
                          type='text'
                          name='taxRate'
                          value={state.taxRate}
                          onBlur={onBlurModule}
                          onChange={onChangeTextField}
                          variant='outlined'
                          style={{ width: 80, marginBottom: 0 }}
                          size='small'
                          inputProps={{ maxLength: 5 }}
                        />
                        <span className='ml-1'>%</span>
                      </div>
                    )}
                    <Checkbox
                      checked={state.show_information}
                      onChange={onChangeCheckbox}
                      name='show_information'
                    >
                      Show Information for customer
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.multiple_tabs}
                      onChange={onChangeCheckbox}
                      name='multiple_tabs'
                    >
                      Multiple tabs for project
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.show_discounts}
                      onChange={onChangeCheckbox}
                      name='show_discounts'
                    >
                      Disable Discounts in Project sections
                    </Checkbox>
                    <br />

                    <Checkbox
                      checked={state.show_rebates}
                      onChange={onChangeCheckbox}
                      name='show_rebates'
                    >
                      Disable Rebates in Project sections
                    </Checkbox>
                    <br />
                    <Checkbox
                      checked={state.require_signature}
                      onChange={onChangeCheckbox}
                      name='require_signature'
                    >
                      Require signature
                    </Checkbox>
                  </div> */}
                </>
              )}
              {activeTab === 0 && (
                <MainButton
                  style={{ marginTop: '20px', marginLeft: '0px' }}
                  onClick={onSubmit}
                  title='Save'
                  isFetching={submittingData.loading}
                  className='main-button button_save'
                  // disabled={isSubmitDisabled}
                />
              )}

              <Prompt
                when={state.wasEdited}
                message='Are you sure you want to leave the page without saving changes?'
              />
              <AttachmentModal
                attachmentModal={attachmentModal}
                save={setFile}
                toggleModal={toggleAttachmentModal}
                fileIdsToDelete={fileIdsToDelete}
                setfileIdsToDelete={setfileIdsToDelete}
              />
            </div>
          )
        })}
      </Tabs>

      {activeTab !== 0 && !editHeader && (
        <MainButton
          style={{ marginTop: '20px' }}
          onClick={onSubmit}
          title='Save'
          isFetching={submittingData.loading}
          className='main-button button_save'
          // disabled={isSubmitDisabled}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  proposal: state.orcatec.proposal,
  companyLogo: state.orcatec.company.logo,
  company: state.orcatec.company,
  me: state.orcatec.user.me,
})

const mapDispatchToProps = {
  fetchProposal: actions.proposal.fetchProposal,
  saveProposal: actions.proposal.saveProposal,
  deleteIndustry: actions.proposal.deleteIndustry,

  clearProposal: actions.proposal.clearItem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
