/**
 * Get the contrasting color for any hex color
 * @param  {String} hexcolor value
 * @return {String} The contrasting color (black or white)
 */
export function getContrastYIQ(hexcolor: string): string {
  // Ensure valid hex format
  if (!/^#?[0-9A-Fa-f]{3,6}$/.test(hexcolor)) return '#000'

  let hex = hexcolor.replace(/^#/, '')

  // Convert 3-character hex to 6-character hex
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(char => char + char)
      .join('')
  }

  // Convert to RGB values
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Calculate YIQ contrast ratio
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 140 ? '#000' : '#fff'
}
