import { Popover, Tooltip } from 'antd'
import { Button } from 'components/UIKit'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface ButtonsProps {
  disableSend: boolean
  cancelButtonText?: string
  loading: boolean
  onSubmit: () => void
  onCancel: () => void
  onCopyLink?: () => void
  onShowHistory?: () => void
  onOpenEmailClient?: () => void
}
export const Footer = ({
  cancelButtonText,
  disableSend,
  loading,
  onSubmit,
  onCancel,
  onCopyLink,
  onShowHistory,
  onOpenEmailClient,
}: ButtonsProps) => {
  const { t } = useTranslation('notifications')

  return (
    <Buttons>
      <Button
        onClick={() => {
          onCancel?.()
        }}
      >
        {cancelButtonText || t('cancel')}
      </Button>

      {!!onShowHistory && (
        <Button
          style={{
            backgroundColor: 'rgba(52,159,166,.75)',
            borderColor: 'rgba(52, 159, 166, 0.75)',
            color: '#fff',
          }}
          onClick={onShowHistory}
        >
          {t('showHistory')}
        </Button>
      )}

      {!!onCopyLink && (
        <Popover content={'Copied to clipboard!'} trigger={'click'}>
          <Button title={''} onClick={onCopyLink}>
            {t('copyLink')}
          </Button>
        </Popover>
      )}

      {!!onOpenEmailClient && (
        <Button onClick={onOpenEmailClient}>{t('openEmailClient')}</Button>
      )}
      <Tooltip
        title={disableSend ? t('messageList.selectAtLeast') : ''}
        mouseLeaveDelay={0}
      >
        <div>
          <Button
            disabled={disableSend}
            loading={loading}
            onClick={onSubmit}
            type='primary'
          >
            {t('send')}
          </Button>
        </div>
      </Tooltip>
    </Buttons>
  )
}

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
