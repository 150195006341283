import React from 'react'
import './style.scss'
import moment from 'moment-timezone'
import { separateDateTimeByStr } from 'helpers/date/separateDateTimeByStr'
import Notes from 'containers/MainContent/Orcatec/components/Notes'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { ProposalContacts } from 'containers/MainContent/Orcatec/Proposals/components/JobView/components/ProposalContacts/ProposalContacts'
import { useTranslation } from 'react-i18next'

const AppointmentToPrint = ({
  appointment,
  tech,
  isJob,
  jobTitle,
  showPromisedTime,
}) => {
  const { t } = useTranslation('appointment')
  const { t: tCommon } = useTranslation('common')
  return (
    <>
      <div className='print_wrapper'>
        <article style={{ minWidth: '500px' }} className='job_section'>
          {isJob ? (
            <h1>
              {jobTitle} ${t('heading.details')}{' '}
              <span className='job_article__name'>
                ({appointment?.job_code})
              </span>
            </h1>
          ) : (
            <h1>{t('heading.appointmentDetails')}</h1>
          )}
          {!!appointment?.date && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                {isJob
                  ? jobTitle + '  ' + `${t('heading.date')}: `
                  : `${t('heading.appointmentDate')}:`}
              </span>
              <span className='job_article__value'>
                {appointment?.date
                  ? moment(appointment.date).format('MM/DD/YYYY')
                  : ''}
              </span>
            </div>
          )}

          {!!appointment?.time_start && showPromisedTime && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                {t('heading.promisedArrivalTime')}:{' '}
              </span>
              <div className='job-time_wrapper'>
                <span className='job_article__value '>
                  {t('heading.from')}:{' '}
                  {appointment?.time_start
                    ? moment(appointment?.time_start).format('hh:mm a')
                    : ''}
                </span>
                <span className='job_article__value'>
                  {t('heading.to')}:{' '}
                  {appointment?.time_end
                    ? moment(appointment?.time_end).format('hh:mm a')
                    : ''}
                </span>
              </div>
            </div>
          )}

          {!isJob && (
            <>
              {!!appointment?.preferred_technician_id &&
                !!tech &&
                !!tech?.length && (
                  <div className='job_article'>
                    <span className='job_article__name'>
                      {' '}
                      {t('heading.preferredTech')}:{' '}
                    </span>
                    <span className='job_article__value'>
                      {tech?.filter(
                        t => t?.id === appointment?.preferred_technician_id,
                      )?.[0]?.name || t('heading.noPreferredWorker')}
                    </span>
                  </div>
                )}
            </>
          )}

          {!!appointment?.matrix_time_start && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                {t('heading.scheduleTime')}:{' '}
              </span>
              <div className='job-time_wrapper'>
                <span className='job_article__value '>
                  {t('heading.from')}:{' '}
                  {!!appointment?.matrix_time_start ||
                  appointment.matrix_time_start === 0
                    ? moment(appointment?.matrix_time_start).format('hh:mm a')
                    : ''}
                </span>
                <span className='job_article__value'>
                  {t('heading.to')}:{' '}
                  {!!appointment?.matrix_time_start ||
                  appointment?.matrix_time_start === 0
                    ? moment(appointment?.matrix_time_end).format('hh:mm a')
                    : ''}
                </span>
              </div>
            </div>
          )}

          {!!appointment?.matrix_user && (
            <div className='job_article'>
              <span className='job_article__name'>
                {' '}
                {t('heading.assignedTo')}:{' '}
              </span>
              <span className='job_article__value'>
                {appointment?.matrix_user}
              </span>
            </div>
          )}

          {isJob && (
            <>
              {/* <div className='job_article'>
            <span className='job_article__name'> Approximate Completion Time Per Day: </span>
            <span className='job_article__value'>{appointment?.approximate_time}</span>
          </div> */}
              <div className='job_article'>
                <span className='job_article__name'>
                  {t('heading.daysNeeded')}:{' '}
                </span>
                <span className='job_article__value'>
                  {appointment?.count_days}
                </span>
              </div>
              <div className='job_article'>
                <span className='job_article__name'>
                  {' '}
                  {t('heading.workersNeeded')}:{' '}
                </span>
                <span className='job_article__value'>
                  {appointment?.count_workers}
                </span>
              </div>
            </>
          )}
          {!!appointment?.preferred_technician_id &&
            !!tech &&
            !!tech?.length &&
            isJob && (
              <div className='job_article'>
                <span className='job_article__name'>
                  {' '}
                  {t('heading.leadPreferredWorker')}{' '}
                </span>
                <span className='job_article__value'>
                  {tech?.filter(
                    t => t?.id === appointment?.preferred_technician_id,
                  )?.[0]?.name || t('heading.noPreferredWorker')}
                </span>
              </div>
            )}

          {!!appointment?.created_at && (
            <div className='job_article'>
              <span className='job_article__name'>
                {t('heading.appointmentCreatedBy')}:
              </span>
              <span className='job_article__value'>
                {appointment.username === null
                  ? t('heading.publicAppointment')
                  : `${appointment.username} ${tCommon(
                      'heading.on',
                    )} ${separateDateTimeByStr(
                      tCommon('heading.at'),
                      appointment.created_at,
                    )}`}
              </span>
            </div>
          )}
        </article>
        {!!appointment?.proposal_info && (
          <article className='job_section'>
            <ProposalContacts proposal={appointment.proposal_info} />
          </article>
        )}
      </div>

      {appointment?.id ? (
        <Notes
          route={`/${isJob ? `jobs` : 'appointments'}/${appointment.id}/notes`}
          forPrint={true}
        />
      ) : (
        !!appointment?.notes && (
          <InputField
            label={t('heading.appointmentNotes')}
            value={appointment?.notes}
            multiline
            className='appointment-notes select-appt'
          />
        )
      )}
    </>
  )
}

export default AppointmentToPrint
