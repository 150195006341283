import { Select } from 'components/UIKit'
import { useLanguage } from 'contexts/LanguageContext'
import styled from 'styled-components'

export const LanguageSwitcher = ({ label }: { label?: string }) => {
  const { currentLanguage, changeLanguage, supportedLanguages } = useLanguage()

  const languageNames = {
    en: {
      name: 'English',
      flag: '🇺🇸',
    },
    ru: { name: 'Русский', flag: '🇷🇺' },
  }

  function handleLanguageChange(_: Event, value: any) {
    changeLanguage(value)
  }

  return (
    <Select
      name='language'
      label={label}
      value={currentLanguage}
      options={supportedLanguages.map(lng => ({
        value: lng,
        label: (
          <LabelWithIcon>
            {/* <span>{languageNames[lng].flag}</span> */}
            <span>{languageNames[lng].name}</span>
          </LabelWithIcon>
        ),
      }))}
      onChange={handleLanguageChange}
    />
  )
}

const LabelWithIcon = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
