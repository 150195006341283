import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { useAppDispatch, useAppSelector } from 'store/Orcatec/hooks'
import styled from 'styled-components'
import ProjectTemplates from './ProjectTemplates'
import { useState } from 'react'
import { ProjectAPI } from 'api/Project'
import { selectProject } from 'features/Project/projectSelectors'
import { getProject } from 'features/Project/slices/projectSlice'
import { ModalProposalTemplate } from 'containers/MainContent/Orcatec/Proposals/components/Modals/ModalProposalTemplate/ModalProposalTemplate'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { postProposalTemplate } from 'api/Proposal'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { updateUserTemplate } from 'store/Orcatec/actions/settings/user'
import {
  ModuleName,
  ProjectPermissions,
} from 'features/Settings/UsersAndGroups'
import { selectUserPermissionsByModule } from 'features/Settings/UsersAndGroups/permissionSlice'
import { Tooltip } from 'antd'

export const Templates = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useAppDispatch()

  const me = useAppSelector(selectAuthUser)
  const project = useAppSelector(selectProject)
  const permissions = useAppSelector(
    selectUserPermissionsByModule(ModuleName.PROJECT),
  )

  const [loading, setLoading] = useState(false)
  const [loadTemplateModal, showLoadTemplateModal] = useState(false)
  const [saveTemplateModal, showSaveTemplateModal] = useState(false)

  const handleProjectTemplateApply = async (templateId: number) => {
    setLoading(true)

    try {
      await ProjectAPI.loadProjectTemplate(project.id, templateId)
      await dispatch(getProject({ projectId: project.id }))
    } catch (error) {
      console.error('error')
    } finally {
      setLoading(false)
      showLoadTemplateModal(false)
    }
  }

  const saveProposalTemplate = async (
    newTemplateName: string,
    sharedIds: number[],
    isNewTemlateDefault: boolean,
  ) => {
    const data = {
      user_id: me.id,
      owner_template_name: me.full_name,
      is_shared: sharedIds,
      type: 1, //1-proposal
      entity_id: project.id,
      title: newTemplateName,
    }

    try {
      const template = await postProposalTemplate(data)
      openNotificationWithIcon('success', {
        message: 'Template has been saved',
      })

      if (isNewTemlateDefault) {
        await dispatch(
          updateUserTemplate(me.id, { template_id: template.id || null }),
        )
      }

      showSaveTemplateModal(false)
    } catch (error) {
      console.error(error)
    }
  }

  const onShowLoadTemplateModal = () => {
    if (!permissions?.[ProjectPermissions.PROJECT_CAN_READ_TEMPLATES]) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    showLoadTemplateModal(true)
  }

  const onShowSaveTemplateModal = () => {
    if (!permissions?.[ProjectPermissions.PROJECT_CAN_CREATE_TEMPLATE]) {
      return openNotificationWithIcon('warning', {
        message: 'You have no permissions to perform this action',
      })
    }

    showSaveTemplateModal(true)
  }

  return (
    <Wrapper data-testid='project--templates-section'>
      <Tooltip
        title={
          disabled &&
          'You can load a template only if\xa0the\xa0project is in Estimate status'
        }
      >
        <div>
          <MainButton
            title='Load Template'
            onClick={onShowLoadTemplateModal}
            disabled={disabled}
          />
        </div>
      </Tooltip>
      <MainButton title='Save Template' onClick={onShowSaveTemplateModal} />

      {loadTemplateModal && (
        <ProjectTemplates
          onCancel={() => showLoadTemplateModal(false)}
          onProjectTemplateAplly={handleProjectTemplateApply}
          isLoading={loading}
        />
      )}

      {saveTemplateModal && (
        <ModalProposalTemplate
          title={'Save template'}
          modal
          onCancel={() => showSaveTemplateModal(false)}
          onSave={saveProposalTemplate}
          me={me}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`
