import { DownOutlined, RightOutlined } from '@ant-design/icons'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
  children: JSX.Element
  title?: string
}

export const SidebarBlock = ({ children, title, ...rest }: Props) => {
  const [showContent, setShowContent] = useState(true)

  const toggleContent = () => setShowContent(!showContent)

  return (
    <Wrapper {...rest}>
      {title && (
        <Heading onClick={toggleContent}>
          {title}
          {showContent ? (
            <DownOutlined style={{ fontSize: 12 }} />
          ) : (
            <RightOutlined style={{ fontSize: 12 }} />
          )}
        </Heading>
      )}
      {showContent && <Content>{children}</Content>}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #e6e8ec;
  border-radius: 4px;
  margin-bottom: 20px;
`

const Heading = styled.div`
  padding: 20px;
  border-bottom: 1px solid #f1f2fd;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`
const Content = styled.div`
  padding: 20px;
  overflow: hidden;
`
