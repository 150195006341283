import { InputNumber, Popconfirm, Radio, RadioChangeEvent, Spin } from 'antd'
import { Button, DatePicker, InfoPlate, Select } from 'components/UIKit'
import Modal from 'containers/MainContent/Orcatec/components/UI/Modal'
import styled from 'styled-components'
import { RecurringInterval, RecurringMonthlyType } from 'types/Appointment'
import { Error } from 'types/Error'
import WeekDays from './components/WeekDays'
// import DatePicker, { DateObject } from 'react-multi-date-picker'
// import { CalendarOutlined } from '@ant-design/icons'
import MonthDaysSelector from 'features/Appointment/Form/components/MonthDaysSelector'
import { useEffect, useState } from 'react'
import { RecurringEventsAPI } from 'features/Visits/api'
import moment, { Moment } from 'moment-timezone'
// import { LEAD_TIME } from 'features/Visits/constants'
import { useRecurringEventQty } from './components/Recurrence/useRecurringEventQty'
import { useTranslation } from 'react-i18next'

export interface RecurringOptions {
  date: string
  each: number[]
  ending_options: {
    end_type: 1
    value: string | number | null
  }
  id: number
  interval: RecurringInterval
  // initial_event_count: number
  is_cancelled: boolean
  is_completed: boolean
  // lead_time: number
  make_first_appointment: false
  monthly_type: RecurringMonthlyType
  repeat_every: 1
}

const initialData: RecurringOptions = {
  date: moment().format('YYYY-MM-DD'),
  each: [],
  ending_options: {
    end_type: 1,
    value: moment().format('YYYY-MM-DD'),
  },
  id: 0,
  interval: RecurringInterval.DAY,
  // initial_event_count: INITIAL_EVENT_COUNT,
  is_completed: false,
  is_cancelled: false,
  // lead_time: LEAD_TIME,
  make_first_appointment: false,
  monthly_type: RecurringMonthlyType.DAILY,
  repeat_every: 1,
}

interface Props {
  data: RecurringOptions | null
  parentId?: number
  onSave: (data?: RecurringOptions) => void
  onClose: () => void
}

export const RecurringModal = ({ data, parentId, onSave, onClose }: Props) => {
  const { t } = useTranslation(['recurrence', 'common'])

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<RecurringOptions>(data || initialData)
  const [error, setError] = useState<Error | null>(null)

  const eventQty = useRecurringEventQty(state)

  useEffect(() => {
    const getRecurrenceSettings = async (parentId: number) => {
      setLoading(true)
      const data = await RecurringEventsAPI.getRecurringSettings(parentId)
      setLoading(false)

      setState(data)
    }

    if (parentId) getRecurrenceSettings(parentId)
  }, [parentId])

  const onOptionsChanged = (e: {
    target: {
      name: string
      value: unknown
    }
  }) => {
    const { name, value } = e.target

    setState(prev => ({
      ...prev,
      [name]: value,
    }))

    if (error?.[name]) {
      setError(prev => ({
        ...prev,
        [name]: '',
      }))
    }
  }

  const handleChangeEach = (value: number[]) => {
    onOptionsChanged({
      target: {
        name: 'each',
        value: value.sort((a, b) => a - b),
      },
    })
  }

  const handleChangeInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    onOptionsChanged({
      target: {
        name: 'interval',
        value,
      },
    })

    onOptionsChanged({
      target: {
        name: 'each',
        value: [],
      },
    })
  }

  const handleChangeEvery = value => {
    onOptionsChanged({
      target: {
        name: 'repeat_every',
        value,
      },
    })
  }

  /*   const handleDateChange = (_date: DateObject | null, value) => {
    onOptionsChanged({
      target: {
        name: 'date',
        value: value.validatedValue[0],
      },
    })
  } */

  const handleChangeMonthType = e => {
    const { value } = e.target

    onOptionsChanged({
      target: {
        name: 'monthly_type',
        value: +value,
      },
    })

    onOptionsChanged({
      target: {
        name: 'each',
        value: [],
      },
    })
  }

  const handleChangeEndingOptionsType = (e: RadioChangeEvent) => {
    const { value } = e.target

    onOptionsChanged({
      target: {
        name: 'ending_options',
        value: {
          end_type: value,
          value:
            value === 2
              ? 1
              : value === 1
              ? data?.date || moment().format('YYYY-MM-DD')
              : false,
        },
      },
    })
  }

  const handleChangeEndingOptionsOccurrences = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    onOptionsChanged({
      target: {
        name: 'ending_options',
        value: {
          end_type: 2,
          value: e.target.value ?? 1,
        },
      },
    })
  }

  const handleChangeEndingOptionsDate = (date: Moment | null) => {
    onOptionsChanged({
      target: {
        name: 'ending_options',
        value: {
          end_type: 1,
          value: date ? moment(date?.toString()).format('YYYY-MM-DD') : null,
        },
      },
    })
  }

  const handleSave = async (isStop?: boolean) => {
    if (state.interval !== RecurringInterval.DAY && !state.each.length) {
      setError({ each: 'Select days for recurring events' })
      return
    }

    if (!parentId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, ...rest } = state

      return onSave({
        ...rest,
        start_date: state.date,
      })
    }

    setLoading(true)

    try {
      await RecurringEventsAPI.updateRecurringSettings(state.id, {
        ...state,
        is_cancelled: isStop,
      })
      onSave?.()
    } catch (error) {
      setError(error?.response?.data?.errors)
    } finally {
      setLoading(false)
    }
  }

  // const isPlural = state.repeat_every > 1 ? 's' : ''
  const isStopped = !!state.is_completed && !!state.is_cancelled

  return (
    <Modal
      open
      title={t('modal.title')}
      width={450}
      onCancel={onClose}
      onOk={() => handleSave()}
      footer={
        <Footer>
          {!!state.id && (!state.is_completed || isStopped) && (
            <Popconfirm
              onConfirm={() => handleSave(true)}
              title='Are you sure you want to stop recurrence?'
            >
              <Button danger loading={loading} disabled={isStopped}>
                {isStopped ? 'Recurrence stopped' : 'Stop recurrence'}
              </Button>
            </Popconfirm>
          )}
          {state.id ? (
            <Button style={{ marginLeft: 'auto' }} onClick={onClose}>
              Close
            </Button>
          ) : (
            <>
              <Button style={{ marginLeft: 'auto' }} onClick={onClose}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={() => handleSave()}
                loading={loading}
              >
                Save
              </Button>
            </>
          )}
        </Footer>
      }
    >
      <Spin spinning={loading}>
        <Wrapper>
          <Row>
            <Title>{t('modal.startDate')}</Title>

            <DatePicker
              allowClear={false}
              value={state.date}
              onChange={value => {
                setState(prev => ({
                  ...prev,
                  date: moment(value).format('YYYY-MM-DD'),
                }))

                if (
                  state.ending_options.end_type === 1 &&
                  moment(state.ending_options.value).isBefore(moment(value))
                ) {
                  setState(prev => ({
                    ...prev,
                    ending_options: {
                      ...state.ending_options,
                      value: moment(value).format('YYYY-MM-DD'),
                    },
                  }))
                }
              }}
              error={error?.date}
              disabled={!!parentId}
            />
          </Row>
          <Row>
            <Title>{t('modal.repeatEvery')}</Title>
            <div style={{ display: 'flex', gap: 10 }}>
              <InputNumber
                required
                name='repeat_every'
                type='number'
                value={state.repeat_every}
                parser={value => {
                  // Remove non-numeric characters and convert to integer
                  return value?.replace(/[^\d]/g, '') || ''
                }}
                onChange={handleChangeEvery}
                onBlur={() => {
                  if (!state?.repeat_every) {
                    handleChangeEvery(1)
                  }
                }}
                min={1}
                max={30}
                disabled={!!parentId}
              />

              <Select
                name='interval'
                value={state.interval}
                onChange={handleChangeInterval}
                options={[
                  {
                    value: 1,
                    label: t('heading.day', { count: state.repeat_every }),
                  },
                  {
                    value: 2,
                    label: t('heading.week', { count: state.repeat_every }),
                  },
                  {
                    value: 3,
                    label: t('heading.month', { count: state.repeat_every }),
                  },
                ]}
                error={error?.interval}
                disabled={!!parentId}
              />
            </div>
          </Row>

          {state.interval === RecurringInterval.WEEK && (
            <Row>
              <Title>{t('modal.repeatOn')}</Title>
              <WeekDays
                value={state.each}
                onChange={handleChangeEach}
                error={error}
                disabled={!!parentId}
              />
            </Row>
          )}

          {state.interval === RecurringInterval.MONTH && (
            <>
              <Row>
                <Title>{t('modal.type')}</Title>
                <Select
                  name='monthly_type'
                  value={state.monthly_type}
                  options={[
                    {
                      value: 1,
                      label: t('modal.daysOfMonth'),
                    },
                    {
                      value: 2,
                      label: t('modal.daysOfWeek'),
                    },
                  ]}
                  onChange={handleChangeMonthType}
                  disabled={!!parentId}
                />
              </Row>

              {state.monthly_type === RecurringMonthlyType.DAILY ? (
                <Row>
                  <Title>{t('modal.daysOfMonth')}</Title>

                  <Select
                    showSearch
                    required
                    name='each'
                    mode='multiple'
                    value={state?.each || []}
                    options={Array.from({ length: 31 }, (_, i) => ({
                      value: i + 1,
                      label: i + 1,
                    }))}
                    onChange={e => handleChangeEach(e?.target?.value)}
                    listItemHeight={32}
                    error={error?.each}
                    placeholder={t('modal.placeholder.selectDaysOfMonth')}
                    disabled={!!parentId}
                  />
                </Row>
              ) : (
                <Row>
                  <Title>{t('modal.daysOfWeek')}</Title>

                  <MonthDaysSelector
                    value={state.each}
                    onChange={onOptionsChanged}
                    error={error}
                    disabled={!!parentId}
                  />
                </Row>
              )}
            </>
          )}

          <Row>
            <Title>{t('modal.ends.title')}</Title>
            <div style={{ display: 'flex', gap: 2 }}>
              <div>
                <Radio.Group
                  onChange={handleChangeEndingOptionsType}
                  value={state?.ending_options?.end_type}
                  disabled={!!parentId}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 17,
                      position: 'relative',
                      top: 5,
                    }}
                  >
                    <Radio value={1}>{t('modal.ends.on')}</Radio>
                    <Radio value={2}>{t('modal.ends.after')}</Radio>
                    <Radio value={3}>{t('modal.ends.never')}</Radio>
                  </div>
                </Radio.Group>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                <DatePicker
                  allowClear={false}
                  value={
                    state.ending_options.end_type === 1 &&
                    state.ending_options.value
                      ? moment(state.ending_options.value)
                      : null
                  }
                  onChange={handleChangeEndingOptionsDate}
                  placeholder={t('modal.placeholder.selectEndDate')}
                  disabled={state.ending_options.end_type !== 1 || !!parentId}
                  disabledDate={currentDate =>
                    state.date
                      ? currentDate.isBefore(moment(state.date))
                      : false
                  }
                />

                <div>
                  <InputNumber
                    required
                    type='number'
                    value={
                      state.ending_options?.end_type === 2
                        ? state.ending_options.value || 1
                        : 1
                    }
                    parser={value => {
                      // Remove non-numeric characters and convert to integer
                      return value?.replace(/[^\d]/g, '') || ''
                    }}
                    onChange={value =>
                      handleChangeEndingOptionsOccurrences({
                        target: { name: 'ending_options', value },
                      })
                    }
                    onBlur={e => {
                      if (!e.target.value) {
                        handleChangeEndingOptionsOccurrences({
                          target: { name: 'ending_options', value: 1 },
                        })
                      }
                    }}
                    min={1}
                    max={1000}
                    disabled={state.ending_options.end_type !== 2 || !!parentId}
                  />
                  <span>
                    {' '}
                    {t('modal.ends.occurrence', {
                      count: +(state?.ending_options?.value || 1),
                    })}
                    {/* {state.ending_options?.end_type === 2 &&
                      state?.ending_options?.value > 1 &&
                      's'} */}
                  </span>
                </div>
              </div>
            </div>
          </Row>

          {!state.id && state.ending_options.end_type !== 3 && (
            <p>
              <span style={{ fontWeight: 500 }}>{`${eventQty ??
                0} ${t('modal.event', { count: eventQty })}`}</span>{' '}
              {t('modal.willBeCreated')}
            </p>
          )}

          {!!state.id && getMessage(state.is_completed, state.is_cancelled, t)}
        </Wrapper>
      </Spin>
    </Modal>
  )
}

const getMessage = (isCompleted: boolean, isCancelled: boolean, t) => {
  const isStopped = !!isCompleted && !!isCancelled
  const isInProcess = !isCompleted && !isCancelled

  return (
    <InfoPlate
      type='info'
      size='small'
      customColor={isStopped ? '#FDA428' : isInProcess ? '#206FED' : '#3aa32e'}
    >
      {isStopped
        ? t('messages.recurrenceStopped')
        : isInProcess
        ? t('messages.inProcess')
        : t('messages.recurrenceFinished')}
    </InfoPlate>
  )
}

const Wrapper = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }

  .ant-collapse {
    margin: -20px 0 -20px -15px;

    .ant-collapse-expand-icon {
      margin-top: -2px;
    }
  }
`

const Footer = styled.div`
  display: flex;
  gap: 10px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: baseline;
  gap: 10px;
`

/* const AdvancedRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
  align-items: baseline;
  gap: 10px;
  margin-bottom: 10px;
` */

export const Date = styled.div<{ error?: boolean }>`
  position: relative;

  & > span {
    position: absolute;
    right: 5px;
    top: 8px;
    font-size: 18px;
    pointer-events: none;
    color: #0000008a;
  }

  .rmdp-container {
    width: 100%;
  }

  .styled-datepicker {
    width: 100%;
    border: 1px solid;
    border-color: ${props => (props.error ? '#f12832' : '#d9d9d9')};
    border-radius: 2px;
    height: 32px;
    padding: 4px 25px 4px 11px;
    cursor: pointer;
  }

  .rmdp-toolbar {
    & > div {
      visibility: hidden;

      &:nth-child(3) {
        visibility: visible;
      }
    }
  }

  & > .MuiFormControl-root {
    width: 100%;
  }
`

const Title = styled.p`
  color: ${props => props.theme.font.secondary};
`
/* 
const Description = styled.p`
  margin-top: 10px;
  color: #808080;
  font-size: 12px;
`
 */
