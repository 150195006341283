// store/slices/tourSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface TourState {
  completedTours: object // { moduleId: boolean }
  isFirstVisit: boolean
  activeTour: null | string // current active moduleId
  running: boolean
  currentStep: number
  nextTourScheduled: null | string
}

const initialState: TourState = {
  completedTours: {}, // { moduleId: boolean }
  isFirstVisit: false,
  activeTour: null, // current active moduleId
  running: false,
  currentStep: 0,
  nextTourScheduled: null,
}

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    initializeTourState: state => {
      // Load state from localStorage on app init
      const stored = localStorage.getItem('app_tour_state')
      if (stored) {
        const parsedState = JSON.parse(stored)
        state.completedTours = parsedState.completedTours
        state.isFirstVisit = parsedState.isFirstVisit
      }
    },
    markTourCompleted: (state, action) => {
      const { moduleId } = action.payload
      state.completedTours[moduleId] = true
      state.running = false
      state.activeTour = null
      state.nextTourScheduled = null
      state.currentStep = 0

      // Update localStorage
      localStorage.setItem(
        'app_tour_state',
        JSON.stringify({
          completedTours: state.completedTours,
          isFirstVisit: state.isFirstVisit,
        }),
      )
    },
    startTour: (state, action: PayloadAction<{ moduleId: string }>) => {
      const { moduleId } = action.payload
      state.activeTour = moduleId
      state.running = true
    },
    stopTour: state => {
      state.running = false
      state.activeTour = null
    },
    markFirstVisitComplete: state => {
      state.isFirstVisit = false
      localStorage.setItem(
        'app_tour_state',
        JSON.stringify({
          completedTours: state.completedTours,
          isFirstVisit: false,
        }),
      )
    },
    restartModuleTour: (state, action) => {
      const { moduleId } = action.payload
      // Remove the module from completed tours
      delete state.completedTours[moduleId]
      // Update localStorage
      localStorage.setItem(
        'app_tour_state',
        JSON.stringify({
          completedTours: state.completedTours,
          isFirstVisit: state.isFirstVisit,
        }),
      )
      state.activeTour = moduleId
      state.running = true
      state.currentStep = 0
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    scheduleNextTour: (state, action: PayloadAction<string>) => {
      state.nextTourScheduled = action.payload
    },
  },
})

export const {
  initializeTourState,
  markTourCompleted,
  startTour,
  stopTour,
  markFirstVisitComplete,
  restartModuleTour,
  setCurrentStep,
  scheduleNextTour,
} = tourSlice.actions

export default tourSlice.reducer
