import { FormBuilderProvider } from '../FormBuilderContext'
import { TemplatesTable } from '../tables/TemplatesTable/TemplatesTable'

export const FormBuilder = () => {
  return (
    <FormBuilderProvider>
      <TemplatesTable />
    </FormBuilderProvider>
  )
}
