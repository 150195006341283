import React, { useState, ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { Tooltip, Empty, Badge, Spin, Collapse } from 'antd'
import moment from 'moment-timezone'
import { IAppointment } from 'types/Appointment'
import { Home, Map } from '@material-ui/icons'
import { DispatchIcon } from 'layouts/MainLayout/Header/components/Navigation/NavigationIcons'
import Select from 'containers/MainContent/Orcatec/components/UI/Select'
import ModalTechRoutes from './components/ModalTechRoutes'
import {
  EyeIcon1,
  LocationOnMap,
  AssignedToIcon,
  infoIcon,
} from 'containers/MainContent/Orcatec/components/Icons/CommonIcons'
import usePreferredRoute from 'containers/MainContent/Orcatec/Map/components/hooks/usePreferredRoute'
import {
  resetCreated,
  selectCurrentMatrixLog,
  createDispatchEvent,
  selectMatrixLog,
  getMatrixLog,
} from 'features/Dispatch/dispatchSlice'
import {
  prepareDataForMatrix,
  prepareDataForAppt,
  timeToView,
  mileArr,
  dayArr,
  timeArrBySettingsTime,
} from './helpers'
import { useAppSelector } from 'store/Orcatec/hooks'
import { IWorker, IProps, ISettings } from './types'
import {
  Container,
  Wrapper,
  InputWrapper,
  Item,
} from './AppointmentPreferredTech.styles'
import { useColumns } from 'hooks/useColumns'
import { selectTimeoffList } from 'store/Orcatec/reducers/timeoff'
import { useTranslation } from 'react-i18next'

const { Panel } = Collapse

const message =
  'Please enable Route Optimization in your subscription to unlock full functionality.'

const getTimeValue = (
  settingsTime,
  selectorTime,
  isTimeS,
  matrixTimeStartOrEnd,
  // timeDiff,
) => {
  if (settingsTime) {
    const selectedTime = moment(settingsTime, 'HH:mm:ss').hours()

    if (isTimeS && selectedTime < selectorTime) {
      return selectorTime
    } else if (!isTimeS && selectedTime > selectorTime) {
      return selectorTime
    }

    return selectedTime < matrixTimeStartOrEnd ? selectorTime : selectedTime
  } else {
    return selectorTime
  }
}

const AppointmentPreferredTech = ({
  appointment,
  property,
  onChange,
  settings,
}: IProps) => {
  const dispatch = useDispatch()
  const { columns } = useColumns(
    appointment.date
      ? moment(appointment.date).format('MM/DD/YYYY')
      : moment().format('MM/DD/YYYY'),
  )
  const { t } = useTranslation('appointment')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showUserMap, setShowUserMap] = useState([])
  const [routesSettings, setRoutesSettings] = useState<ISettings>({
    ...settings,
    times_needed: 1,
  })
  const { time_start, time_end } = useAppSelector(selectCurrentMatrixLog)
  const { appointments_created } = useAppSelector(selectMatrixLog)
  const timeOff = useAppSelector(selectTimeoffList)
  const mapPerm = useAppSelector(
    state => state.orcatec.company.subscription.modules.map.status,
  )

  // const mapPerm = false
  const timeE = getTimeValue(
    routesSettings?.time_to,
    time_end,
    false,
    time_start,
  )
  const timeS = getTimeValue(
    routesSettings?.time_from,
    time_start,
    true,
    time_end,
  )

  // const timeE = routesSettings?.time_to
  //   ? moment(routesSettings?.time_to, 'HH:mm:ss').hours()
  //   : time_end
  // const timeS = routesSettings?.time_from
  //   ? moment(routesSettings?.time_from, 'HH:mm:ss').hours()
  //   : time_start
  const { employeesWithDistanceByRoutes, loading } = usePreferredRoute({
    date: appointment?.date,
    coordinates: [property?.lat, property?.lng],
    appointment,
    company_end_time: timeE,
    company_start_time: timeS,
    property,
    daysAhead: !mapPerm ? 5 : routesSettings?.days_ahead || 5,
    exclude_days: routesSettings?.exclude_days || [],
    radius: routesSettings?.radius || 10,
    timesNeeded: routesSettings?.times_needed || 1,
    days_behind: !mapPerm ? 0 : routesSettings?.days_behind || 0,
    timeOff: timeOff,
    // isFetching,
  })

  const showModal = (e: ChangeEvent<HTMLInputElement>, worker: IWorker) => {
    e.stopPropagation()
    setShowUserMap([worker])

    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
    setShowUserMap([])
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setShowUserMap([])
  }

  const onSetMatrixEvent = (
    event: ChangeEvent<HTMLInputElement>,
    appointment: IAppointment,
    worker: IWorker,
  ) => {
    if (worker.id !== appointment.appointed_technician_id)
      dispatch(resetCreated())
    if (
      appointments_created?.length &&
      worker.id === appointments_created[0].appointed_technician_id
    )
      return dispatch(resetCreated())
    const data = prepareDataForMatrix(appointment, worker, columns)
    const date = moment(data?.date || new Date()).format('MM/DD/YYYY')
    dispatch(getMatrixLog({ date }))
    return dispatch(
      createDispatchEvent({
        currentEvent: data,
      }),
    )
  }

  const onAssignTechToAppt = (appointment: IAppointment, worker: IWorker) => {
    const data = prepareDataForAppt(
      { ...appointment, times_needed: routesSettings?.times_needed || 1 },
      worker,
      columns,
    )
    onChange(prev => ({ ...prev, ...data }))
  }

  const handleChange = (value: number | boolean, name: string) => {
    dispatch(resetCreated())
    setRoutesSettings(prev => ({ ...prev, [name]: value }))
  }

  const displayTime = (created_at: string) => {
    const isToday = moment(created_at).isSame(moment(), 'day')
    const isTomorrow = moment()
      .add(1, 'day')
      .isSame(moment(created_at), 'day')

    if (isToday) return 'Today'
    if (isTomorrow) return 'Tomorrow'

    return (
      moment(created_at).format('dddd') + ' ' + moment(created_at).format('L')
    )
  }

  const findByPreferredTech = employeesWithDistanceByRoutes?.filter(
    a => a?.routes?.length,
  )?.length
  const totalCountRoutes = employeesWithDistanceByRoutes.reduce(
    (acc, { routes }) => (acc += routes?.length),
    0,
  )
  const randomNum = Math.floor(Math.random() * 5)

  const timeForTimePicker = timeArrBySettingsTime(timeS, timeE)

  return (
    property && (
      <>
        <Container>
          <Collapse
            className='container-preferred_worker'
            defaultActiveKey={[]}
          >
            <Panel
              header={
                <Badge
                  color='#4e5ccf'
                  count={
                    isNaN(totalCountRoutes)
                      ? 'User unassigned'
                      : totalCountRoutes
                  }
                >
                  <strong>{t('optimalRoutes.title')}</strong>{' '}
                  <Spin spinning={loading} />
                </Badge>
              }
              key='1'
              extra={<Map />}
            >
              <Wrapper className='wrapper'>
                {!mapPerm && <p style={{ color: 'red' }}>{message}</p>}
                {!loading && (
                  <InputWrapper>
                    <Select
                      value={routesSettings.times_needed}
                      label='Hours needed'
                      name='times_needed'
                      style={{ width: '100px' }}
                      options={timeForTimePicker}
                      onChange={e =>
                        handleChange(e.target.value, e.target.name)
                      }
                    />
                    <Select
                      value={routesSettings.radius || 10}
                      label='Radius'
                      name='radius'
                      style={{ width: '100px' }}
                      options={mileArr}
                      onChange={e =>
                        handleChange(e.target.value, e.target.name)
                      }
                    />

                    <Select
                      value={routesSettings.days_behind || 0}
                      label='From'
                      name='days_behind'
                      style={{ width: '100px' }}
                      options={dayArr}
                      onChange={e =>
                        handleChange(e.target.value, e.target.name)
                      }
                      disabled={!mapPerm}
                    />
                    <Select
                      value={routesSettings.days_ahead || 5}
                      label='To'
                      name='days_ahead'
                      style={{ width: '100px' }}
                      options={dayArr.slice(1)}
                      onChange={e =>
                        handleChange(e.target.value, e.target.name)
                      }
                      disabled={!mapPerm}
                    />
                    <span style={{ alignSelf: 'end' }}>ahead.</span>
                  </InputWrapper>
                )}

                <Spin size='large' spinning={loading}>
                  {!!findByPreferredTech &&
                  !!employeesWithDistanceByRoutes?.length ? (
                    employeesWithDistanceByRoutes?.map(
                      ({ date, routes }, idx) =>
                        routes?.length ? (
                          <div key={idx}>
                            <Collapse
                              className='container-preferred_worker'
                              defaultActiveKey={
                                !mapPerm ? [randomNum] : idx === 0 ? ['0'] : []
                              }
                            >
                              <Panel
                                collapsible={
                                  !mapPerm && idx !== randomNum
                                    ? 'disabled'
                                    : ''
                                }
                                header={
                                  <Tooltip title={message}>
                                    <Badge
                                      color='#4e5ccf'
                                      count={routes?.length}
                                    >
                                      <p>
                                        <strong>{displayTime(date)}</strong>
                                      </p>
                                    </Badge>
                                  </Tooltip>
                                }
                                key={idx}
                              >
                                {routes
                                  .sort(
                                    (a, b) =>
                                      a?.sortByTime?.[0]?.margin -
                                      b.sortByTime?.[0]?.margin,
                                  )
                                  .map(
                                    route =>
                                      route?.sortByTime?.length && (
                                        <Item key={route?.id}>
                                          <Tooltip
                                            title={`${
                                              route?.pointIndex
                                                ? 'From Appointment'
                                                : 'From Home Location'
                                            }`}
                                          >
                                            <span>
                                              {route?.pointIndex ? (
                                                <DispatchIcon
                                                  width={20}
                                                  height={20}
                                                />
                                              ) : (
                                                <Home />
                                              )}
                                            </span>
                                          </Tooltip>
                                          <span>
                                            approx. +{' '}
                                            {(
                                              (route?.sortByTime?.[0]?.margin *
                                                0.75) /
                                              1000
                                            ).toFixed(1)}{' '}
                                            ml.
                                          </span>
                                          <Tooltip title='To Appointment'>
                                            <span>
                                              <DispatchIcon
                                                width={20}
                                                height={20}
                                              />
                                            </span>
                                          </Tooltip>
                                          <Tooltip title={`${route.full_name}`}>
                                            <span>{route.full_name}</span>
                                          </Tooltip>

                                          <Tooltip title='Preferred time'>
                                            <span>
                                              {timeToView(
                                                route?.sortByTime?.[0]
                                                  ?.current_start_time,
                                                route?.sortByTime?.[0]
                                                  ?.current_end_time,
                                              )}
                                            </span>
                                          </Tooltip>

                                          {!window.location.pathname.includes(
                                            'job',
                                          ) && (
                                            <Tooltip title='Show Appointment On Schedule'>
                                              <span
                                                id='route-button'
                                                onClick={e =>
                                                  onSetMatrixEvent(
                                                    e,
                                                    route?.appointments?.[
                                                      route?.pointIndex
                                                    ],
                                                    route,
                                                  )
                                                }
                                              >
                                                <EyeIcon1 />
                                              </span>
                                            </Tooltip>
                                          )}
                                          <Tooltip title='Show Route On Map'>
                                            <span
                                              id='route-button'
                                              onClick={e => showModal(e, route)}
                                            >
                                              <LocationOnMap />
                                            </span>
                                          </Tooltip>
                                          <Tooltip
                                            title={`Assign to ${route.full_name}`}
                                          >
                                            <span
                                              id='route-button'
                                              onClick={() =>
                                                onAssignTechToAppt(
                                                  route?.appointments?.[
                                                    route?.pointIndex
                                                  ],
                                                  route,
                                                )
                                              }
                                            >
                                              <AssignedToIcon />
                                            </span>
                                          </Tooltip>
                                        </Item>
                                      ),
                                  )}
                              </Panel>
                            </Collapse>
                          </div>
                        ) : null,
                    )
                  ) : (
                    <Empty
                      description={
                        <span>
                          No data{' '}
                          {
                            <Tooltip
                              title={
                                'Please select a property to find the optimal route or change filter'
                              }
                            >
                              {infoIcon(15, 15)}
                            </Tooltip>
                          }
                        </span>
                      }
                    />
                  )}
                </Spin>
              </Wrapper>
            </Panel>
          </Collapse>
        </Container>

        <ModalTechRoutes
          users={showUserMap}
          handleOk={handleOk}
          handleCancel={handleCancel}
          isModalOpen={isModalOpen}
        />
      </>
    )
  )
}

export default AppointmentPreferredTech
