import { companiesWithDefaultRuLanguage } from 'constants/hardcodes'
import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'store/Orcatec/hooks'
import { selectCompany } from 'store/Orcatec/selectors/company'

interface ILanguageContext {
  currentLanguage: string
  changeLanguage: (language: string) => Promise<void>
  supportedLanguages: string[]
}

const LanguageContext = createContext<ILanguageContext>({})

export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation()

  const company = useAppSelector(selectCompany)

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

  useEffect(() => {
    const setAppropriateLanguage = async () => {
      let language = 'en'

      if (companiesWithDefaultRuLanguage.includes(company.id)) {
        language = 'ru'
      }

      const sessionLanguage = localStorage.getItem('userLanguage')
      if (sessionLanguage) {
        language = sessionLanguage
      }

      if (language !== i18n.language) {
        await changeLanguage(language)
      }
    }

    setAppropriateLanguage()
  }, [i18n, company.id])

  const changeLanguage = async (language: string) => {
    try {
      localStorage.setItem('userLanguage', language)

      await i18n.changeLanguage(language)
      setCurrentLanguage(language)
    } catch (error) {
      console.error('Failed to change language:', error)
    }
  }

  return (
    <LanguageContext.Provider
      value={{
        currentLanguage,
        changeLanguage,
        supportedLanguages: ['en', 'ru'],
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export const useLanguage = () => useContext(LanguageContext)
