import { useState } from 'react'
import styled from 'styled-components'
import { useRecurringEvents } from './hooks/useRecurringEvents'
import { DownCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { Button, InfoPlate } from 'components/UIKit'
import { Table } from 'antd'
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table'
import { DispatchEvent } from 'features/Dispatch/types'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import { useTranslation } from 'react-i18next'
// import { usePermissionsByModule } from 'features/Settings/UsersAndGroups/hooks'
// import { GroupEventsOperation } from 'features/Visits/types'
// import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
// import { useAppSelector } from 'store/Orcatec/hooks'
// import { selectJobDictionary } from 'store/Orcatec/selectors/company'

interface Props {
  eventType: 'appointment' | 'jobs'
  groupId: number
  currentEventId: number
  revalidate?: number
  isGenerating: boolean
  onEditRecurrence?: () => void
  onClick: (event: DispatchEvent) => void
  onSendGroupNotification?: () => void
}

export const RecurringEvents = ({
  // eventType,
  currentEventId,
  revalidate,
  groupId: parentId,
  isGenerating,
  onEditRecurrence,
  onClick,
}: // onSendGroupNotification,
Props) => {
  const { t } = useTranslation('recurrence')
  // const { READ, UPDATE } = usePermissionsByModule(GroupEventsOperation)
  // const dictionary = useAppSelector(selectJobDictionary)

  // const eventName = eventType === 'jobs' ? dictionary.singular : eventType

  const [open, setOpen] = useState(false)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
  })

  const { events, loading, pagination } = useRecurringEvents(
    parentId,
    {
      page: params.page,
      per_page: params.per_page,
    },
    revalidate,
  )

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setParams({
      page: pagination.current || 1,
      per_page: pagination.pageSize || 10,
    })
  }

  const columns: ColumnsType<DispatchEvent> = [
    {
      title: <ColumnTitle>{t('table.eventId')}</ColumnTitle>,
      dataIndex: 'visit_id',
      key: 'visit_id',
      align: 'left',
      render: (text, record) =>
        record.id !== currentEventId ? (
          <Link
            onClick={() => {
              onClick(record)
            }}
          >
            {text}
          </Link>
        ) : (
          text
        ),
    },
    {
      title: <ColumnTitle>{t('table.eventDate')}</ColumnTitle>,
      dataIndex: 'date',
      key: 'date',
      align: 'left',
      render: text => text || '-',
    },
    {
      title: t('table.promissedTime'),
      dataIndex: 'promised_arrival_time',
      key: 'promised_arrival_time',
      align: 'left',
      render: text => text || '-',
    },
    {
      title: t('table.assignedTo'),
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      align: 'left',
      render: text => text || '-',
    },
    {
      title: t('table.dispatchTime'),
      dataIndex: 'dispatch_time',
      key: 'dispatch_time',
      align: 'left',
      render: text => text || '-',
    },
    {
      title: t('table.status'),
      dataIndex: 'status',
      key: 'status',
      width: '7em',
      align: 'left',
      render: (status: number) => <AppointmentStatus statusId={status} />,
    },
  ]

  // if (!READ) return null

  return (
    <Wrapper>
      <Heading open={open}>
        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
          {open ? (
            <DownCircleOutlined
              style={{ fontSize: 18 }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <RightCircleOutlined
              style={{ fontSize: 18 }}
              onClick={() => setOpen(!open)}
            />
          )}

          <Title>
            {t('title')} ({events.length ? pagination?.total : 0})
          </Title>
        </div>

        {/* {!!onSendGroupNotification && (
          <Button onClick={onSendGroupNotification}>
            Send group notification
          </Button>
        )} */}
        {isGenerating && (
          <InfoPlate type='info' size='small' width='390'>
            {t('inProgress')}
          </InfoPlate>
        )}
        <Button
          onClick={() => {
            /*  if (!UPDATE) {
              return openNotificationWithIcon('warning', {
                message: 'You have no permissions to perform this action',
              })
            } */

            onEditRecurrence?.()
          }}
        >
          {t('settings')}
        </Button>
      </Heading>

      {open && (
        <Content>
          <Table
            size='small'
            columns={columns}
            dataSource={events}
            loading={loading}
            pagination={
              pagination.total > 10
                ? {
                    current: pagination.current_page,
                    total: pagination.total,
                    pageSize: pagination.per_page,
                    showSizeChanger: true,
                  }
                : false
            }
            onChange={handleTableChange}
            rowClassName={record =>
              record.id === currentEventId ? 'current' : ''
            }
          />
        </Content>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #d6d6d6;
  border-radius: 4px;

  .current {
    border: 1px solid;
    pointer-events: none;

    td {
      background-color: #eee;
    }
  }
`
const Heading = styled.div<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-bottom: ${props => (props.open ? '1px solid #d6d6d6' : 'none')};
  padding: 10px;
`
const Content = styled.div``
const Title = styled.p`
  font-weight: 500;
`

const Link = styled.p`
  color: #1890ff;
  cursor: pointer;
`

const ColumnTitle = styled.span`
  text-transform: capitalize;
`
