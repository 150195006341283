import { Button, Checkbox, DatePicker, Select, Upload } from 'antd'
import InputField from 'containers/MainContent/Orcatec/components/UI/InputField'
import styled from 'styled-components'
import { Component, PictureSize } from '../types'
import CanvasDraw from 'react-canvas-draw'

import { UploadOutlined } from '@ant-design/icons'

import SettingsRow from 'components/UI/SettingsRow'

export const getComponentByTypeSettings = (
  component: Component,
  mainFieldId?: string | null,
) => {
  switch (component?.type) {
    case 'text':
      return (
        <SettingsRow
          boldTitle={component.id === mainFieldId}
          required={component?.required}
          title={component?.label}
        >
          <InputField
            type={component.subtype}
            value={component.value}
            inputProps={{
              style: {
                textTransform: component.uppercase ? 'uppercase' : 'initial',
              },
            }}
          />
        </SettingsRow>
      )

    case 'note':
      return (
        <ComponentWrapper>
          {component.without_label ? (
            <ImageDescription
              align={component.descriptionAlign || 'left'}
              fontSize={component.descriptionSize || 14}
              fontWeight={component.descriptionWeight || 400}
            >
              {component.value}
            </ImageDescription>
          ) : (
            <SettingsRow
              boldTitle={component.id === mainFieldId}
              required={component?.required}
              title={component?.label}
            >
              <ImageDescription
                align={component.descriptionAlign || 'left'}
                fontSize={component.descriptionSize || 14}
                fontWeight={component.descriptionWeight || 400}
              >
                {component.value}
              </ImageDescription>
            </SettingsRow>
          )}
        </ComponentWrapper>
      )

    case 'date':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === mainFieldId}
            required={component?.required}
            title={component?.label}
          >
            <DatePicker format='MM/DD/YYYY' />
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'select':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === mainFieldId}
            required={component?.required}
            title={component?.label}
          >
            <Select
              options={component.options}
              style={{ width: 300 }}
              dropdownClassName='my-select-dropdown'
            />
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'checkbox_group':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === mainFieldId}
            required={component?.required}
            title={component?.label}
          >
            <Checkbox.Group
              value={component.value}
              options={component.options}
              style={{ display: 'flex', flexDirection: 'column' }}
            />
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'signature':
      return (
        <ComponentWrapper style={{ position: 'relative' }}>
          <SettingsRow
            boldTitle={component.id === mainFieldId}
            required={component?.required}
            title={component?.label}
          >
            <CanvasDraw
              brushRadius={1}
              brushColor='#000000'
              canvasWidth={400}
              canvasHeight={200}
              hideGrid={true}
              hideInterface={true}
              imgSrc={component.value}
              loadTimeOffset={0}
              className='signature-canvas-container'
              style={{
                cursor: 'pointer',
                border: `1px solid grey`,
                borderRadius: '8px',
              }}
            />
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'upload':
      return (
        <ComponentWrapper>
          <SettingsRow
            boldTitle={component.id === mainFieldId}
            required={component?.required}
            title={component?.label}
          >
            <UploadWrapper>
              <Upload
                multiple={component?.multiple}
                listType='picture'
                showUploadList={false}
                customRequest={() => null}
              >
                <Button icon={<UploadOutlined />}>
                  Upload file{component.multiple && 's'}
                </Button>
              </Upload>
            </UploadWrapper>
          </SettingsRow>
        </ComponentWrapper>
      )

    case 'picture':
      return (
        <ComponentWrapper>
          {component.without_label ? (
            <>
              <ImageWrapper align={component.pictureAlign || 'flex-start'}>
                <Image
                  size={component.size || PictureSize.S}
                  value={component.value?.preview_source || ''}
                />
              </ImageWrapper>
              {component.description && (
                <ImageDescription
                  align={component.descriptionAlign || 'left'}
                  fontSize={component.descriptionSize || 14}
                  fontWeight={component.descriptionWeight || 400}
                >
                  {component.description}
                </ImageDescription>
              )}
            </>
          ) : (
            <SettingsRow
              boldTitle={component.id === mainFieldId}
              required={component?.required}
              title={component?.label}
            >
              <ImageWrapper align={component.pictureAlign || 'flex-start'}>
                <Image
                  size={component.size || PictureSize.S}
                  value={
                    component.value?.preview_source ||
                    '/assets/images/placeholder-image.png'
                  }
                />
              </ImageWrapper>
              {component.description && (
                <ImageDescription
                  align={component.descriptionAlign || 'left'}
                  fontSize={component.descriptionSize || 14}
                  fontWeight={component.descriptionWeight || 400}
                >
                  {component.description}
                </ImageDescription>
              )}
            </SettingsRow>
          )}
        </ComponentWrapper>
      )

    default:
      return (
        <SettingsRow
          boldTitle={component.id === mainFieldId}
          required={component?.required}
          title={component?.label}
        >
          <InputField value={component.value} label={component?.label} />
        </SettingsRow>
      )
  }
}

const ComponentWrapper = styled.div`
  & .my-select-dropdown {
    z-index: 99999;
  }
`
const UploadWrapper = styled.div`
  .ant-upload-list-picture-container {
    max-width: 200px;
  }
`

const ImageWrapper = styled.div<{
  align: 'flex-start' | 'flex-end' | 'center'
}>`
  display: flex;
  justify-content: ${props => props.align};
`

const Image = styled.div<{
  size: PictureSize
  value: string
}>`
  background-image: url(${props => props.value});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props =>
    props.value === '/assets/images/placeholder-image.png'
      ? '1px solid #ebebeb'
      : 'none'};
  border-radius: 4px;
`

const ImageDescription = styled.div<{
  fontSize: number
  fontWeight: number
  align: string
}>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.align};
  white-space: pre-wrap;
  line-height: 1.33;
`
