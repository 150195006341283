import React, { useState, useEffect, useMemo, ChangeEvent } from 'react'

import { Tooltip } from 'antd'
import { Table } from 'containers/MainContent/Orcatec/components/Table/Table'
import {
  getCustomTaxesList,
  createTaxRate,
  deleteTaxRate,
  updateTaxRate,
} from 'api/settings/Proposal'
import useDebounce from 'hooks/useDebounce'
import { ColumnProps } from 'antd/lib/table'
import TableLayout, {
  IconWrapper,
  ActionsWrapper,
  IconsBlock,
} from 'containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons'
import { Settings } from '@material-ui/icons'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table/interface'
import { getValueFromLS } from 'hooks/useLocalStorage'
import { TaxRateModal } from '../TaxRateModal/TaxRateModal'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'

const linkStyle = {
  color: '#1890ff',
  cursor: 'pointer',
}

interface CustomColumnProps<T> extends ColumnProps<T> {
  name: string
  defaultWidth: number
  noFilter?: boolean
}

interface ICustomTaxes {
  created_at?: string
  updated_at?: string
  estimated_combined_rate: string
  zip_code: string
  key?: string
}

export interface ITaxRate {
  estimated_combined_rate: number | null
  zip_code: number | null
  id?: number
}
export const ellipsisStyle = {
  style: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
  },
}

const name = 'custom-taxes-list'

const initialTaxRate: ITaxRate = {
  estimated_combined_rate: 0,
  zip_code: null,
}
const initialTaxRateError: ITaxRate = {
  estimated_combined_rate: null,
  zip_code: null,
}

export const CustomTaxRate = () => {
  const settingsFromLC = getValueFromLS(`${name}-table_v1`)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [sortField, setSortField] = useState<string | null>(
    settingsFromLC?.sorter?.sort_field || null,
  )
  const [sortOrder, setSortOrder] = useState<string | null>(
    settingsFromLC?.sorter?.order || null,
  )
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 10,
  })
  const [tableFilters, setTableFilters] = useState({
    ...settingsFromLC?.filters,
  })
  const [search, setSearch] = useState(settingsFromLC?.search || '')
  const [clickedRowIndex, setClickedRowIndex] = useState(null)
  const [openTableSettings, setOpenTableSettings] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const debouncedSearchValue = useDebounce(search, 500)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [taxRate, setTaxRate] = useState<ITaxRate>(initialTaxRate)
  const [error, setError] = useState(initialTaxRateError)

  const fetchTaxes = async params => {
    setLoading(true)
    try {
      const { taxes, meta } = await getCustomTaxesList(params)

      setData(taxes)
      setPagination(meta)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ICustomTaxes, string[]>>,
    sorter: SorterResult<ICustomTaxes>,
  ) => {
    fetchTaxes({
      search: debouncedSearchValue || undefined,
      page: pagination.current,
      sort_field: sorter.order ? sorter.field : null,
      order:
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      per_page: pagination.pageSize,
      ...filters,
    })

    setSortOrder(
      sorter.order === 'ascend'
        ? 'asc'
        : sorter.order === 'descend'
        ? 'desc'
        : null,
    )
    setSortField(sorter.order ? sorter.field : null)
    setTableFilters({ ...filters })
  }

  const onRow = (record: ICustomTaxes, rowIndex: number) => ({
    onClick: () => {
      setTaxRate(record)
      setOpenModal(true)
      setClickedRowIndex(rowIndex)
    },
  })

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false)
    }
  }

  const handleCloseModal = async () => {
    setOpenModal(false)
    setError(initialTaxRateError)
    setTaxRate(initialTaxRate)
    await fetchTaxes({
      per_page: 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setError(prev => ({ ...prev, [name]: null }))
    setTaxRate(prev => ({ ...prev, [name]: value }))
  }

  const handleCreateTaxRate = async () => {
    try {
      await createTaxRate(taxRate)
      handleCloseModal()
      openNotificationWithIcon('success', {
        message: 'Tax Rate was created successfully!',
      })
    } catch (error) {
      console.error(error)
      const message = Object.values(error?.response?.data?.errors || {})
      setError(error?.response?.data?.errors)
      openNotificationWithIcon('error', {
        message: message || 'Something went wrong!',
      })
    }
  }

  const handleUpdateTaxRate = async () => {
    try {
      const { tax } = await updateTaxRate(taxRate.id, taxRate)
      setData(prev => prev.map(p => (p.id === taxRate.id ? tax : p)))
      setTaxRate(tax)
      openNotificationWithIcon('success', {
        message: 'Tax Rate was updated successfully!',
      })
    } catch (error) {
      console.error(error)
      const message = Object.values(error?.response?.data?.errors || {})
      setError(error?.response?.data?.errors)
      openNotificationWithIcon('error', {
        message: message || 'Something went wrong!',
      })
    }
  }

  const handleDeleteTaxRate = async () => {
    try {
      await deleteTaxRate(taxRate.id)
      handleCloseModal()
      setTaxRate(initialTaxRate)
      openNotificationWithIcon('success', {
        message: 'Tax Rate was deleted successfully!',
      })
    } catch (error) {
      console.error(error)
      const message = Object.values(error?.response?.data?.errors || {})
      openNotificationWithIcon('error', {
        message: message || 'Something went wrong!',
      })
    }
  }

  const handleCancelTaxRate = (key: keyof ITaxRate) => {
    const prevData = data?.find(item => item.id === taxRate.id)
    setTaxRate(prev => ({ ...prev, [key]: prevData[key] }))
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => document.removeEventListener('keydown', handleKeyPress)
  }, [])

  useEffect(() => {
    fetchTaxes({
      per_page: 25,
      search: debouncedSearchValue || undefined,
      sort_field: sortField,
      order: sortOrder,
      ...tableFilters,
    })
  }, [debouncedSearchValue])

  const columns: CustomColumnProps<ICustomTaxes>[] = useMemo(
    () => [
      {
        title: () => (
          <Tooltip placement='topLeft' title=' Combined Rate'>
            Combined Rate
          </Tooltip>
        ),
        name: ' Combined Rate',
        dataIndex: 'estimated_combined_rate',
        sorter: false,
        defaultWidth: 200,
        noFilter: true,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: ICustomTaxes['estimated_combined_rate']) => (
          <span>{`${value} %`}</span>
        ),
      },

      {
        title: () => (
          <Tooltip placement='topLeft' title='Zip Code'>
            Zip Code
          </Tooltip>
        ),
        name: 'Zip Code',
        dataIndex: 'zip_code',
        sorter: true,
        defaultWidth: 200,
        onCell: record =>
          record.key === clickedRowIndex ? undefined : ellipsisStyle,
        render: (value: ICustomTaxes['zip_code']) => (
          <span style={linkStyle}>{value}</span>
        ),
      },
    ],
    [],
  )

  return (
    <TableLayout
      isFullScreen={isFullScreen}
      visible={drawerVisible}
      onClose={() => setDrawerVisible(false)}
      actions={
        <ActionsWrapper>
          <IconsBlock>
            <IconWrapper>
              <MainButton onClick={() => setOpenModal(true)} title='Create' />
            </IconWrapper>
            <InputField
              label='Search'
              value={search}
              style={{ width: '100%', maxWidth: '200px' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
            />
            <IconWrapper
              onKeyDown={handleKeyPress}
              onClick={() => setIsFullScreen(prev => !prev)}
            >
              {isFullScreen ? (
                <FullscreenExitOutlined style={{ fontSize: '22px' }} />
              ) : (
                <FullscreenOutlined style={{ fontSize: '22px' }} />
              )}
            </IconWrapper>

            <IconWrapper onClick={() => setOpenTableSettings(true)}>
              <Tooltip placement='left' title='Columns settings'>
                <Settings />
              </Tooltip>
            </IconWrapper>
          </IconsBlock>
        </ActionsWrapper>
      }
    >
      <Table
        name={name}
        pagination={pagination}
        loading={loading}
        columns={columns}
        data={data}
        onChange={handleTableChange}
        onRow={onRow}
        search={debouncedSearchValue}
        openTableSettings={openTableSettings}
        onCloseTableSettings={() => setOpenTableSettings(false)}
        rowKey={obj => obj?.key?.toString()}
      />

      <TaxRateModal
        error={error}
        visible={openModal}
        onSave={handleCreateTaxRate}
        onClose={handleCloseModal}
        taxRate={taxRate}
        onChange={onChange}
        onDelete={handleDeleteTaxRate}
        onUpdate={handleUpdateTaxRate}
        onCancel={handleCancelTaxRate}
      />
    </TableLayout>
  )
}
