import styled from 'styled-components'

interface ElementProps {
  disabled?: boolean
}
export const Wrapper = styled.div`
  border-radius: 4px;
`

export const Element = styled.div<ElementProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px dashed;
  margin-bottom: 3px;
  border-radius: 4px;
  text-align: center;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  /* pointer-events: ${props => (props.disabled ? 'none' : 'auto')}; */
  padding: 5px;
  color: ${props => (!props.disabled ? 'inherit' : '#dadada')};

  &:hover {
    background-color: ${props => (props.disabled ? 'inherit' : '#dadada')};
  }
`
