import { Select } from 'antd'
import AppointmentStatus from 'containers/MainContent/Orcatec/components/AppointmentStatus/AppointmentStatus'
import { AppointmentStatusID } from 'types/Appointment'

const { Option } = Select

interface Props {
  placeholder?: string
  value: number[]
  onChange: (choosenEmployees: number[]) => void
}

export const EventStatus = ({
  placeholder = 'Show all',
  value,
  onChange,
}: Props) => {
  return (
    <Select
      mode='multiple'
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: '100%' }}
      allowClear
    >
      {options.map(worker => (
        <Option key={worker.value} value={worker.value} label={worker.label}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AppointmentStatus statusId={worker.value} />
            {worker.label}
          </div>
        </Option>
      ))}
    </Select>
  )
}

const options = [1, 5, 2, 3, 4].map(status => ({
  label: AppointmentStatusID[status],
  value: status,
}))
