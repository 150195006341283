import { FormBuilderContext } from 'features/Forms/FormBuilderContext'
import React, { useContext, useEffect } from 'react'
import { getComponentByTypeSettings } from 'features/Forms/utils/getComponentByTypeSettings'
import styled from 'styled-components'
import { FORM_WIDTH } from 'features/Forms/utils/constants'

export const FormViewer = () => {
  const { form, resetForm } = useContext(FormBuilderContext)
  const { body: components, componentIds } = form

  useEffect(() => {
    return () => resetForm()
  }, [])

  return (
    <Wrapper>
      <h6>Form Viewer</h6>

      <Content>
        {componentIds?.map(id => (
          <ComponentWrapper key={id} mainField={id === form.main_field_id}>
            {getComponentByTypeSettings(components[id])}
          </ComponentWrapper>
        ))}
      </Content>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  width: 100%;
  max-width: ${FORM_WIDTH}px;
  min-width: 500px;
  color: black;
`
const Content = styled.div`
  padding: 5px;

  & > * {
    margin-bottom: 10px;
  }
`
const ComponentWrapper = styled.div<{ mainField: boolean }>`
  & > div > p {
    font-weight: ${props => (props.mainField ? 700 : 400)};
  }
`
