import { ChangeEvent, FC } from 'react'
import { Modal, ModalProps, Popconfirm } from 'antd'
import styled from 'styled-components'

import { SettingsRow } from 'components/UI/SettingsRow/SettingsRow'
import { EditableBlock } from 'containers/MainContent/Orcatec/components/UI/EditableBlock/EditableBlock'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import NumberFormat from 'react-number-format'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import { ITaxRate } from '../CustomTaxRate/CustomTaxRate'

interface IProps extends ModalProps {
  visible: boolean
  error: ITaxRate
  taxRate: ITaxRate
  onClose: () => void
  onSave: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDelete: () => void
  onUpdate: () => void
  onCancel: (key: keyof ITaxRate) => void
}

export const TaxRateModal: FC<IProps> = ({
  error,
  visible,
  onClose,
  onSave,
  taxRate,
  onChange,
  onDelete,
  onUpdate,
  onCancel,
}) => {
  return (
    <Modal
      title='Rate'
      centered
      destroyOnClose
      onCancel={onClose}
      visible={visible}
      footer={
        <ControlsButton>
          {taxRate?.id && (
            <Popconfirm
              onConfirm={onDelete}
              title='Are you sure you want to delete this rate?'
            >
              <MainButton
                style={{ marginRight: 'auto' }}
                type='delete'
                title='Delete'
              />
            </Popconfirm>
          )}
          {!taxRate?.id && <MainButton onClick={onSave} title='Save' />}
        </ControlsButton>
      }
    >
      <Wrapper>
        <SettingsRow title='Zip' required width={150} withInfoIcon>
          <EditableBlock
            closeOnBlur={!!taxRate?.id}
            editMode={!taxRate?.id}
            value={taxRate.zip_code}
            onSave={onUpdate}
            onCancel={() => onCancel('zip_code')}
          >
            <InputField
              name='zip_code'
              value={taxRate.zip_code}
              inputProps={{ maxLength: 5 }}
              onChange={e => {
                const numericValue = e.target.value.replace(/[^0-9]/g, '')
                onChange({
                  target: {
                    name: 'zip_code',
                    value: numericValue,
                  },
                })
              }}
            />
          </EditableBlock>
        </SettingsRow>
        <SettingsRow
          description='Tax rate applicable to the project with the selected zip code'
          title='Rate'
          width={150}
          required
        >
          <EditableBlock
            closeOnBlur={!!taxRate?.id}
            editMode={!taxRate?.id || !!error?.estimated_combined_rate}
            value={`${taxRate?.estimated_combined_rate} %`}
            onSave={onUpdate}
            onCancel={() => onCancel('estimated_combined_rate')}
          >
            <NumberFormat
              allowNegative={false}
              decimalScale={3}
              suffix='%'
              value={taxRate?.estimated_combined_rate}
              onValueChange={({ floatValue }) =>
                onChange({
                  target: {
                    name: 'estimated_combined_rate',
                    value: floatValue,
                  },
                })
              }
              onBlur={() => {
                if (!taxRate?.estimated_combined_rate) {
                  onChange({
                    target: {
                      name: 'estimated_combined_rate',
                      value: 0,
                    },
                  })
                }
              }}
              customInput={InputField}
              isAllowed={({ floatValue }) => {
                return !floatValue || (floatValue <= 100 && floatValue >= 0)
              }}
              fullWidth={false}
              error={error}
              name='estimated_combined_rate'
              width={200}
            />
          </EditableBlock>
        </SettingsRow>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div`
  & .orcatec-input {
    width: 150px !important;
  }
  & .MuiFormHelperText-root.Mui-error {
    /* position: absolute;
    left: -10px;
    bottom: -32px; */
    font-size: 10px;
  }
  & .editable-content {
    align-items: baseline;
  }
`
const ControlsButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`
