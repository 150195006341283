import { upload as uploadAPI, publicUpload as publicAPI } from 'api/Media'
import { Media } from 'types/Media'
import imageCompression from "browser-image-compression";
import heic2any from "heic2any";

/* const convertHeicToJPG = async (file: File): Promise<File> => {
  const jpgBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  const newFile = new File([jpgBlob], `${file.name}`, {
    lastModified: Math.floor(new Date().getTime() / 1000),
    type: 'image/jpeg',
  })
  return newFile
} */

interface UploadProgress {
  percentCompleted: number
  loaded: number
  total: number
}

const onUploadProgress = (callback?: (progress: UploadProgress) => void) => (
  progressEvent: ProgressEvent,
) => {
  const percentCompleted = Math.floor(
    (progressEvent.loaded * 100) / progressEvent.total,
  )
  callback?.({
    percentCompleted,
    loaded: progressEvent.loaded,
    total: progressEvent.total,
  })
}

/* const upload = async (
  file: File,
  onProgress?: (progress: UploadProgress) => void,
): Promise<Media[]> => {
  const formData = new FormData()
  let fileToUpload = file

  if (
    file.type === 'image/heic' ||
    file?.name?.toLowerCase()?.includes('.heic')
  ) {
    fileToUpload = await convertHeicToJPG(file)
  }

  formData.append('media', fileToUpload)

  console.log('Orig', file)
  console.log('new', fileToUpload)

  return await uploadAPI(formData, onUploadProgress(onProgress))
} */



const upload = async (
  file: File,
  onProgress?: (progress: UploadProgress) => void,
  withCompression?: boolean
): Promise<Media[]> => {
  try {
    let processedFile = file;
    // console.log('Orig', file)

    const isImage = file.type.startsWith("image/") || file.name.endsWith(".heic");

    if (isImage && withCompression) {
      // Convert HEIC to JPEG
      if (file.type === "image/heic" || file.name.endsWith(".heic")) {
        try {
          const blob = await heic2any({ blob: file, toType: "image/jpeg", quality: 0.8 });
          processedFile = new File([blob as Blob], file.name.replace(/\.heic$/, ".jpg"), { type: "image/jpeg" });

        } catch (error) {
          console.error("HEIC conversion failed:", error);
          return Promise.reject(error);
        }
      }

      // console.log('after convertion', processedFile)

      // Compress image if it's larger than 1 MB (skip GIFs)
      if (processedFile.size > 1024 * 1024 && processedFile.type !== "image/gif") {
        const compressionOptions = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          onProgress: (percent) => {
            if (onProgress) onProgress({ percentCompleted: percent });
          },
        };
        const compressedBlob = await imageCompression(processedFile, compressionOptions);

        processedFile = new File([compressedBlob], processedFile.name, { type: processedFile.type, lastModified: Math.floor(new Date().getTime() / 1000) });
      }
      // console.log('after compressing', processedFile)
    }

    // Upload processed file
    const formData = new FormData();
    formData.append("media", processedFile);

    return await uploadAPI(formData, onUploadProgress(onProgress));
  } catch (error) {
    console.error("Error processing file:", error);
    return Promise.reject(error);
  }
}


const uploadPublic = async (
  file: File,
  eventHash: string,
  onUploadProgress: (event: ProgressEvent) => void,
): Promise<Media> => {
  const formData = new FormData()

  formData.append('media', file)

  return await publicAPI(formData, eventHash, onUploadProgress)
}

export default {
  upload,
  uploadPublic,
}

export type {
  UploadProgress
}