import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './locale/en'
import ru from './locale/ru'

use(initReactI18next).init({
  resources: {
    en: en,
    ru: ru,
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // React already safes from XSS
  },
  detection: {
    // Order of detection methods
    order: ['localStorage', 'cookie', 'sessionStorage', 'navigator'],
    lookupLocalStorage: 'userLanguage',
    lookupCookie: 'i18next',
    lookupSessionStorage: 'userLanguage',
    caches: ['localStorage', 'cookie'],
  },
})
