import { useState } from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import '../Form/style.scss'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { DeleteIcon } from '../../../../../components/Icons/CommonIcons'
// import SureModal from '../../../../../components/SureModal'
import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

// DeleteIcon

export const EmailRow = ({
  item,
  isOne,
  onChange,
  onCheck,
  removeRow,
  index,
  refProps,
  placeholder,
  validateField,
}) => {
  const { t } = useTranslation('contact')

  const [remove, changeRemove] = useState(false)

  const StyledCheckbox = withStyles({
    root: {
      color: '#191919',
      '&$checked': {
        color: '#191919',
      },
    },
    checked: {},
  })(props => <Checkbox {...props} />)

  const StyledRadio = withStyles({
    root: {
      color: '#191919',
      '&$checked': {
        color: '#191919',
      },
    },
    checked: {},
  })(props => <Radio {...props} />)

  return (
    <div className='emailWrapper'>
      <div className='email-actions'>
        <Popconfirm
          onCancel={() => {
            changeRemove(false)
          }}
          onConfirm={() => {
            changeRemove(false)
            removeRow()
          }}
          title={t('deleteEmail')}
          disabled={isOne && !item.value.email}
        >
          <button disabled={isOne && !item.value.email} className='btn delete'>
            <DeleteIcon />
          </button>
        </Popconfirm>
      </div>
      <TextField
        error={item.error ? true : false}
        helperText={item.error}
        size='small'
        label={t('email')}
        variant='outlined'
        type='email'
        name='emails'
        autoComplete='new-password'
        className='emailInput'
        value={item.value.email ? item.value.email : ''}
        ref={refProps}
        onChange={onChange}
        onBlur={validateField}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div className='email-preference-wrapper'>
        <FormControlLabel
          classes={{ root: 'email-preference' }}
          className='formControlItem'
          value='preference'
          control={
            <StyledRadio
              checked={item.value.preference}
              onClick={e => onCheck(e, 'preference', index)}
              disabled={!item.value.email}
              onDoubleClick={e => {
                onCheck({ target: { checked: false } }, 'preference', index)
              }}
            />
          }
          label={t('emailPreference')}
        />
      </div>

      <div className='formForEmailControls'>
        <FormControlLabel
          classes={{ root: 'promo' }}
          className='formControlItem'
          value='send_promo'
          control={
            <StyledCheckbox
              color='default'
              defaultChecked={item.value.send_promo}
              onChange={e => onCheck(e, 'send_promo', index)}
            />
          }
          label={t('sendPromo')}
        />
        <FormControlLabel
          classes={{ root: 'redmine' }}
          className='formControlItem'
          value='send_reminder'
          control={
            <StyledCheckbox
              color='primary'
              defaultChecked={item.value.send_reminder}
              onChange={e => onCheck(e, 'send_reminder', index)}
            />
          }
          label={t('sendReminders')}
        />
      </div>
    </div>
  )
}

export default EmailRow
