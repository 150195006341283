import { Table, Tooltip } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import { FilterDropdownProps } from 'antd/lib/table/interface'
import { TimeOff } from 'api/TimeOff'
import DateFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DateFilter/DateFilter'
import moment from 'moment-timezone'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
// import { getTimeOff } from 'store/Orcatec/actions/timeoff'
import { useAppSelector } from 'store/Orcatec/hooks'
import {
  fetchTimeOffList,
  selectSortedTimeOffListWithUser,
  timeOffSet,
} from 'store/Orcatec/reducers/timeoff'
import styled from 'styled-components'

const TextLink = styled.span`
  color: #1890ff;
  text-decoration: underline;
  cursor: pointer;
`

export const TimeOffTable = () => {
  const timeOffList = useAppSelector(selectSortedTimeOffListWithUser)
  const loading = useAppSelector(state => state.orcatec.timeoff.loading)
  const { t } = useTranslation('dispatch')

  const users = useMemo(
    () =>
      Array.from(
        new Set(timeOffList?.map(timeoff => timeoff?.user?.full_name)),
      ),
    [timeOffList],
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchTimeOffList())
  }, [])

  // const handleAddTimeOff = () => dispatch(toggleTimeOffModal())

  const handleEditTimeOff = (record: TimeOff) => dispatch(timeOffSet(record))

  const getColumnProps = () => ({
    filterDropdown: (props: FilterDropdownProps) => <DateFilter {...props} />,
  })

  const columns: ColumnProps<TimeOff>[] = useMemo(
    () => [
      {
        title: t('timeOffList.name'),
        dataIndex: ['user', 'full_name'],
        sorter: (a, b) => (a.user?.full_name > b.user?.full_name ? 1 : -1),
        render: (text, record) =>
          record?.user?.deleted_at ? (
            <Tooltip title='This user has been deleted'>
              <span>{text}</span>
            </Tooltip>
          ) : (
            <TextLink onClick={() => handleEditTimeOff(record)}>
              {text}
            </TextLink>
          ),
        filters: users
          ?.map(user => ({ text: user, value: user }))
          .sort((a, b) => (a.text > b.text ? 1 : -1)),
        onFilter: (value, record) => record.user.full_name.indexOf(value) === 0,
      },
      {
        title: t('timeOffList.type'),
        dataIndex: 'is_vacation',
        sorter: (a, b) => +a.is_vacation - +b.is_vacation,
        render: value =>
          value ? t('timeOffList.vacation') : t('timeOffList.timeOff'),
      },
      {
        title: t('timeOffList.startDate'),
        dataIndex: 'start_date',
        sorter: (a, b) =>
          +moment(a.start_date).format('MMDDYYYYhh') -
          +moment(b.start_date).format('MMDDYYYYhh'),
        render: date => moment(date).format('MM/DD/YYYY hh:mm a'),
        ...getColumnProps(),
        onFilter: (value, record) =>
          moment(record.start_date).isSameOrAfter(moment(value).startOf('day')),
      },
      {
        title: t('timeOffList.endDate'),
        dataIndex: 'end_date',
        sorter: (a, b) =>
          +moment(a.start_date).format('MMDDYYYYhh') -
          +moment(b.start_date).format('MMDDYYYYhh'),
        render: date => moment(date).format('MM/DD/YYYY hh:mm a'),
        ...getColumnProps(),
        onFilter: (value, record) =>
          moment(record.end_date).isSameOrBefore(moment(value).endOf('day')),
      },
      {
        title: t('timeOffList.reason'),
        dataIndex: 'reason',
        sorter: (a, b) => (a.reason > b.reason ? 1 : -1),
      },
      {
        title: t('timeOffList.notes'),
        dataIndex: 'note',
        sorter: (a, b) => (String(a.note) > String(b.note) ? 1 : -1),
      },
    ],
    [],
  )

  return (
    <Wrapper>
      <Table columns={columns} dataSource={timeOffList} loading={loading} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-table-content {
    height: ${window.innerHeight - 200}px;
    overflow: auto;
  }
`
