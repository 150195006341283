import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { ProjectItem, IProjectItemsGroup, ProjectStatusGroup } from '../types'
import ProjectItemsAPI from 'api/Project'
import { AsyncThunkAPI } from 'store/types'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { deleteErrorKeys } from 'features/Items/helpers'
import { Error } from 'types/Error'
import { transformArrayToObj } from 'features/Dispatch/helpers'
import { DropResult } from 'react-beautiful-dnd'
import { ItemUnit } from 'features/Items/types'
import { startAppListening } from 'store/listenerMiddleware'
// import { changePaymentStatus } from 'store/Orcatec/actions/proposal/proposalForm'
import { reorderItemsWithOptions } from '../Items/helpers'
import {
  addProjectDiscount,
  deleteProjectDiscount,
  updateProjectDiscount,
} from './projectDiscountsSlice'
import {
  createProjectFee,
  createProjectTax,
  deleteProjectFee,
  deleteProjectTax,
  updateProjectFee,
  updateProjectTax,
} from './projectTaxAndFeesSlice'
import { updateProjectSection } from './projectSectionsSlice'
import {
  selectBalancesPaidTotal,
  selectProject,
  selectProjectActiveTab,
  selectProjectTotal,
} from '../projectSelectors'
import ItemsAPI from 'api/Items'
import { updateProjectTabField } from './projectTabsSlice'

const initialState: {
  status: 'idle' | 'loading' | 'error'
  items: Record<number, ProjectItem>
  error: Error | null
  groups: Record<number, IProjectItemsGroup>
  units: Record<number, ItemUnit>
} = {
  status: 'idle',
  items: {},
  groups: {},
  units: {},
  error: null,
}

const projectItemsSlice = createSlice({
  name: 'projectItems',
  initialState,
  reducers: {
    resetProjectItemsErrors: (
      state,
      action: PayloadAction<string[] | undefined>,
    ) => {
      state.error = deleteErrorKeys(state.error, action.payload)
      state.status = 'idle'
    },
    itemsSet: (state, { payload }) => {
      state.items = {
        ...state.items,
        // ...populateItems(payload),
        ...transformArrayToObj(payload),
      }
    },

    //Groups
    resetGroupItems: (state, { payload }) => {
      state.items = transformArrayToObj(
        Object.values(state.items).filter(item => item.group_id !== payload),
      )
    },

    resetSectionItems: (state, { payload }) => {
      state.items = transformArrayToObj(
        Object.values(state.items).filter(item => item.section_id !== payload),
      )
    },

    //
    resetProjectItemsSlice: state => {
      state.items = {}
    },
    itemUpdated: (state, action: PayloadAction<ProjectItem>) => {
      if (!action.payload.id) return

      state.items[action.payload.id] = action.payload
    },
    itemsReordered: (state, { payload }: PayloadAction<ProjectItem[]>) => {
      state.items = {
        ...state.items,
        ...transformArrayToObj(payload),
      }
    },

    optionChecked: (state, { payload }) => {
      state.items[payload.id] = { ...payload, option_group_id: payload.id }

      const options = Object.values(state.items)
        .filter(
          option =>
            option.option_group_id === payload.option_group_id &&
            option.id !== payload.id,
        )
        .map(option => ({
          ...option,
          option_group_id: payload.id,
          checked_option: false,
          group_id: payload.group_id,
          position: payload.position,
        }))
      state.items = {
        ...state.items,
        ...transformArrayToObj(options),
      }
    },

    //////////

    optionGroupCreated: (state, { payload }) => {
      state.items[payload.option_group_id] = {
        ...state.items[payload.option_group_id],
        option_group_id: payload.option_group_id,
        required_to_pay: true,
        use_calculation: true,
        checked_option: true,
      }
    },

    optionGroupReset: (state, { payload }: PayloadAction<ProjectItem>) => {
      if (!payload.id) return

      state.items[payload.id] = {
        ...payload,
        option_group_id: null,
        checked_option: false,
      }
    },

    ////////
    optionGroupUpdated: (state, { payload }: PayloadAction<ProjectItem>) => {
      if (typeof payload.id !== 'number' || !payload.option_group_id) return

      const originalItem = { ...state.items[payload.option_group_id] }

      if (originalItem?.options) {
        //Update option group
        state.items[payload.option_group_id] = {
          ...originalItem,
          options: {
            ...originalItem.options,
            [payload.id]: {
              ...payload,
              order_option: Object.values(originalItem?.options).length,
              position: originalItem.position,
            },
          },
        }
        return
      }
    },
    optionGroupUpdate: (state, action: PayloadAction<ProjectItem>) => {
      if (!action.payload.id) return

      state.items[action.payload.id] = action.payload
    },

    //Units
    itemUnitAdded: (state, action: PayloadAction<ItemUnit>) => {
      const { id } = action.payload

      state.units = {
        ...state.units,
        [id]: action.payload,
      }
    },
    itemUnitDeleted: (state, action: PayloadAction<number>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload]: removedItem, ...rest } = state.units

      state.units = rest
    },
  },
  extraReducers: builder => {
    ///Items
    builder.addCase(addProjectItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(addProjectItem.fulfilled, (state, { payload }) => {
      state.status = 'idle'
      state.items[payload.id] = payload
    })
    builder.addCase(addProjectItem.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'error'
    })

    builder.addCase(updateProjectItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(updateProjectItem.fulfilled, state => {
      state.status = 'idle'
    })
    builder.addCase(updateProjectItem.rejected, (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    })

    builder.addCase(deleteProjectItem.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(deleteProjectItem.fulfilled, (state, action) => {
      state.status = 'idle'
      const item = action.meta.arg
      if (!item.id) return

      delete state.items[item.id]

      if (item.option_group_id) {
        if (
          Object.values(state.items).filter(
            option => option.option_group_id === item.option_group_id,
          ).length < 2
        ) {
          state.items[item.option_group_id] = {
            ...state.items[item.option_group_id],
            option_group_id: null,
            checked_option: false,
          }
        }
      }
    })
    builder.addCase(deleteProjectItem.rejected, state => {
      state.status = 'idle'
    })
    builder.addCase(getItemUnitsList.fulfilled, (state, action) => {
      state.units = transformArrayToObj(action.payload)
    })
  },
})

export default projectItemsSlice.reducer
export const {
  itemsSet,
  itemUpdated,
  itemsReordered,
  resetGroupItems,
  resetSectionItems,
  optionGroupUpdated,
  resetProjectItemsErrors,
  resetProjectItemsSlice,
  optionChecked,
  optionGroupCreated,
  optionGroupReset,
  itemUnitAdded,
  itemUnitDeleted,
} = projectItemsSlice.actions

//Thunks

export const addProjectItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('projectItems/addItem', async (newItem, { rejectWithValue, getState }) => {
  const items = Object.values(
    getState().orcatec.projectItemsSlice.items,
  ).filter(item => item.group_id === newItem.group_id)

  const itemsWithoutOptions = items.filter(item =>
    item.option_group_id ? item.checked_option : item,
  )

  const position = newItem.option_group_id
    ? itemsWithoutOptions.find(
        item => item.option_group_id === newItem.option_group_id,
      )?.position || 0
    : Math.max(
        itemsWithoutOptions.length,
        Math.max(...itemsWithoutOptions.map(item => item.position)) + 1,
      )

  const order_option =
    Object.values(items).filter(
      option =>
        option.option_group_id &&
        option.option_group_id === newItem.option_group_id,
    ).length || 0

  try {
    const res = await ProjectItemsAPI.addItem({
      ...newItem,
      order_option,
      position,
    })

    return res
  } catch (error) {
    return rejectWithValue(error.response.data)
  }
})

export const updateProjectItem = createAsyncThunk<
  ProjectItem,
  ProjectItem,
  AsyncThunkAPI
>('projectItems/updateItem', async (item, { rejectWithValue, dispatch }) => {
  try {
    if (item.option_group_id && item.checked_option) {
      dispatch(optionChecked(item))
    } else {
      dispatch(itemUpdated(item))
    }

    return await ProjectItemsAPI.updateItem(item)
  } catch (error) {
    return rejectWithValue(error.response?.data)
  }
})

export const deleteProjectItem = createAsyncThunk<
  void,
  ProjectItem,
  AsyncThunkAPI
>('projectItems/deleteItem', async (item, { rejectWithValue }) => {
  try {
    if (!item.id) return

    return await ProjectItemsAPI.deleteItem(item.id)
  } catch (error) {
    openNotificationWithIcon('error', {
      message: error.response?.data?.message || 'Something went wrong',
    })

    return rejectWithValue(error.response.data)
  }
})

export const reorderProjectItems = createAsyncThunk<
  ProjectItem[],
  { sectionId: number; result: DropResult },
  AsyncThunkAPI
>(
  'projectItems/reorder',
  async ({ sectionId, result }, { getState, dispatch }) => {
    const { destination, source } = result
    if (!destination) return

    const items = getState().orcatec.projectItemsSlice.items
    const groupId =
      destination.droppableId === 'null' ? null : +destination.droppableId

    const groupItems = Object.values(items).filter(
      item => item.section_id === sectionId && item.group_id === groupId,
    )

    // * If drop in the same group
    if (source?.droppableId === destination?.droppableId) {
      const allItems = reorderItemsWithOptions(groupItems, result)

      dispatch(itemsSet(allItems))

      return allItems
    }

    // * If dropped in another group
    const itemId = +result.draggableId
    const item = items[itemId]

    const dropedItems = item.option_group_id
      ? [
          ...Object.values(items).filter(
            option => option.option_group_id === item.option_group_id,
          ),
        ]
      : [item]

    const dropedItemsWihoutOptions = dropedItems.filter(item =>
      item.option_group_id ? item.checked_option : item,
    )

    const groupItemsCopy = [...groupItems]
    groupItemsCopy.splice(
      destination.index,
      0,
      ...dropedItemsWihoutOptions.map(item => ({
        ...item,
        group_id: groupId,
      })),
    )

    const reorderedItems = groupItemsCopy.map((item, index) => ({
      ...item,
      position: index,
    }))

    let otherOptions = dropedItems.filter(
      item => item.option_group_id && !item.checked_option,
    )

    if (otherOptions.length) {
      otherOptions = otherOptions.map(option => {
        const position = reorderedItems.find(
          item => item.option_group_id === option.option_group_id,
        )?.position

        return {
          ...option,
          position: position || option.position,
          group_id: groupId,
        }
      })
    }

    const allItems = [...reorderedItems, ...otherOptions]
    dispatch(itemsSet(allItems))

    return allItems
  },
)

export const getItemUnitsList = createAsyncThunk(
  'projectItems/getUnitsList',
  async (_, { rejectWithValue }) => {
    try {
      const res = await ItemsAPI.getItemUnits()
      return res
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

//listeners

startAppListening({
  matcher: isAnyOf(
    addProjectItem.fulfilled,
    deleteProjectItem.fulfilled,
    updateProjectItem.fulfilled,
    addProjectDiscount.fulfilled,
    deleteProjectDiscount.fulfilled,
    updateProjectDiscount.fulfilled,
    createProjectTax.fulfilled,
    updateProjectTax.fulfilled,
    deleteProjectTax.fulfilled,
    createProjectFee.fulfilled,
    updateProjectFee.fulfilled,
    deleteProjectFee.fulfilled,
    updateProjectSection.fulfilled,
  ),
  effect: (_, { getState, dispatch }) => {
    const project = selectProject(getState())
    const projectActiveTab = selectProjectActiveTab(getState())

    const projectTotal = selectProjectTotal(getState())
    const sumOfPaidBalances = selectBalancesPaidTotal(getState())

    const statusToSet = projectTotal === sumOfPaidBalances ? 4 : 2

    if (
      project.status === ProjectStatusGroup.Contract &&
      projectActiveTab.payment_status !== 1 &&
      projectActiveTab.payment_status !== statusToSet
    ) {
      // dispatch(changePaymentStatus(statusToSet))
      dispatch(
        updateProjectTabField({
          payment_status: statusToSet,
        }),
      )
    }
  },
})

/* startAppListening({
  matcher: isAnyOf(
    addProjectItem.fulfilled,
    deleteProjectItem.fulfilled,
    updateProjectItem.fulfilled,
  ),
  effect: (_, listenerAPI) => {
    const project = listenerAPI.getState().orcatec.proposalForm

    if (
      project.status === ProjectStatus.Contract &&
      project.proposal_tabs[project.activeTab || 0].payment_status === 4
    ) {
      listenerAPI.dispatch(changePaymentStatus(2))
    }
  },
})
 */
