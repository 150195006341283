import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'components/UIKit'
import { TooltipRenderProps } from 'react-joyride'
import styled from 'styled-components'
import { ExtendedStep } from '../types'

interface TooltipPropsWithExtendedStep
  extends Omit<TooltipRenderProps, 'step'> {
  step: ExtendedStep
}

export const TooltipComponent = (props: TooltipPropsWithExtendedStep) => {
  const {
    backProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    size,
    tooltipProps,
    isLastStep,
  } = props

  return (
    <Wrapper {...tooltipProps}>
      <Heading>
        {step.title && <Title>{step.title}</Title>}

        {!step.hideCloseButton && <CloseOutlined {...skipProps} />}
      </Heading>

      <Content>{step.content}</Content>

      <Footer>
        <Button size='small' type='text' {...skipProps}>
          {skipProps.title}
        </Button>

        <Spacer>
          {index > 0 && !step.hideBackButton && (
            <Button size='small' type='text' {...backProps}>
              {backProps.title}
            </Button>
          )}

          {continuous && !step.hideNextButton && (
            <Button size='small' type='primary' {...primaryProps}>
              {isLastStep ? 'Finish' : primaryProps.title}
              {step.showProgress && (
                <Progress>
                  ({index + 1}/{size})
                </Progress>
              )}
            </Button>
          )}
        </Spacer>
      </Footer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 360px;
`
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
const Content = styled.div``
const Spacer = styled.div`
  margin-left: auto;
  display: flex;

  gap: 12px;
`
const Title = styled.p`
  font-size: 18px;
  font-weight: 500;
`

const Progress = styled.span`
  margin-left: 5px;
`
