import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Select, Spin, Empty, Checkbox, Tooltip } from 'antd'
import { Table } from 'containers/MainContent/Orcatec/components/Table/Table'
import { TableExport } from 'containers/MainContent/Orcatec/components/Table/components/TableExport/TableExport'
import {
  getTemplatesList,
  getReportByTemplateId,
  exportSummayTable,
  getFormById,
  deleteFormById,
  verifyForm,
} from 'api/CustomForms'
import TableLayout, {
  ActionsWrapper,
  IconsBlock,
  IconWrapper,
} from 'containers/MainContent/Orcatec/Layouts/TableLayout/TableLayout'
import { DefaultOptionType } from 'antd/lib/select'

import { getValueFromLS } from 'hooks/useLocalStorage'
import { PaginationConfig } from 'antd/lib/pagination'
import { SorterResult } from 'antd/lib/table/interface'
import { ViewFile } from 'helpers/viewFile/viewFile'

import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { useAppSelector } from 'store/Orcatec/hooks'
import moment from 'moment-timezone'
import DateRangeFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DateRangeFilter/DateRangeFilter'
import AutocompliteFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/AutocompliteFilter/AutocompliteFilter'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import DefaultColumnFilter from 'containers/MainContent/Orcatec/components/Table/components/TableFilters/DefaultColumnFilter'
import { InputField } from 'containers/MainContent/Orcatec/components/Input/InputField'
import useDebounce from 'hooks/useDebounce'
import TableActionsNew from 'containers/MainContent/Orcatec/components/TableActionsNew'
import { EditFormModal } from 'features/Forms/components/FormsBlock/components/EditFormModal'
// import {
//   // canVerifyForm,
//   isUserJuanMilanFromSafetyNet,
// } from 'features/Settings/UsersAndGroups/userSlice'
import {
  Settings,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@material-ui/icons'
import { entityTypeIcons, entityTypeFilter } from '../FormsTable/FormsTable'
import { CustomForm, CustomFormEntityType } from 'features/Forms/types'
import { selectJobDictionary } from 'store/Orcatec/selectors/company'
import { selectAuthUser } from 'store/Orcatec/selectors/user'
import { NotificationModal } from 'features/Notification/modals/NotificationModal/NotificationModal'
import { NotificationTemplateType } from 'features/Notification/types'
import validator from 'validator'
import MainButton from 'containers/MainContent/Orcatec/components/buttons/MainButton'
import {
  canVerifyFormSelect,
  canSendFormSelect,
} from 'features/Settings/UsersAndGroups/permissionSlice'

interface IHeaders {
  title: string
  dataIndex: string
  key: string
}

export const verifiedStatusFilter = [
  {
    text: 'Verified',
    value: true,
  },
  {
    text: 'Not verified',
    value: false,
  },
]
export const completedStatusFilter = [
  {
    text: 'Submitted',
    value: true,
  },
  {
    text: 'Not submitted',
    value: false,
  },
]

const iconStyle = {
  fontSize: '18px',
  color: '#30be89',
}

const fileStyle = {
  width: '30px',
  height: '30px',
  borderRadius: '4px',
  cursor: 'zoom-in',
}
const LinkStyle = {
  color: '#1890ff',
  cursor: 'pointer',
}

const name = 'summary_form-table'

export const DetailsTable = () => {
  const timezone = useAppSelector(
    state => state.orcatec.company.timezone || moment.tz.guess(),
  )
  const technicians = useAppSelector(state =>
    state.orcatec?.company?.technicians?.map(t => ({
      id: t.id,
      name: t.full_name,
    })),
  )

  const dictionary = useAppSelector(selectJobDictionary)

  const isVisibleFor = useAppSelector(canVerifyFormSelect)
  const canFormVerify = useAppSelector(canVerifyFormSelect)
  const me = useAppSelector(selectAuthUser)

  const canSend = useAppSelector(canSendFormSelect)

  //const isVisibleFor = true
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<number | null>(null)
  const [templates, setTemplates] = useState<DefaultOptionType[]>([])
  const [columns, setColumns] = useState<IHeaders[]>([])
  const [summaryData, setSummaryData] = useState<[]>([])
  const [showTable, setShowTable] = useState<boolean>(false)
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)
  const [search, setSearch] = useState(null)
  const searchValue = useDebounce(search)
  const [openEditModal, setEditFormModal] = useState(false)
  const [form, setForm] = useState({})
  const [openTableSettings, setOpenTableSettings] = useState<boolean>(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [searchTemplate, setSearchTemplate] = useState('')

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [checkedRows, setCheckedRows] = useState([])

  const [showEmailModal, setShowEmailModal] = useState(false)

  //table
  const [tableName, setTableName] = useState(name)
  // const settingsFromLC = getValueFromLS(`${tableName}-table_v1`)
  const [sortField, setSortField] = useState<string | null>(null)

  const [sortOrder, setSortOrder] = useState<string | null>(null)

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 25,
    total: 10,
  })
  const [tableFilters, setTableFilters] = useState({})

  const handleFormVerify = async (record, e) => {
    const { checked } = e.target

    verifyForm(record.id, { is_verified: checked })

    setSummaryData(prev =>
      prev.map(item =>
        item.id === record.id
          ? {
              ...item,
              proposal_id: item.proposal_id,
              job_id: item.job_id,
              is_verified: checked,
              verified_name: checked ? me.full_name : null,
            }
          : item,
      ),
    )

    openNotificationWithIcon('success', {
      message: `The form has been successfully ${
        checked ? 'verified' : 'unverified'
      } `,
    })
  }

  const handleVerifyFromForm = async (form: CustomForm) => {
    setSummaryData(prev =>
      prev.map(item =>
        item.id === form.id
          ? {
              ...item,
              proposal_id: item.proposal_id,
              job_id: item.job_id,
              is_verified: form.is_verified,
              verified_name: form.is_verified ? me.full_name : null,
            }
          : item,
      ),
    )
  }

  const addAdditionaInfoToHead = column => {
    const newColumn = { ...column }
    if (column?.key === 'created_at') {
      newColumn.filterDropdown = props => (
        <DateRangeFilter
          nameLS='details-list-filter'
          name='created_at'
          {...props}
        />
      )
      newColumn.render = value =>
        moment
          .utc(value)
          .tz(timezone)
          .format('MM/DD/YYYY hh:mm a')
    }
    if (column?.key === 'updated_at') {
      newColumn.filterDropdown = props => (
        <DateRangeFilter
          nameLS='details-list-filter'
          name='updated_at'
          {...props}
        />
      )
      newColumn.render = value =>
        moment
          .utc(value)
          .tz(timezone)
          .format('MM/DD/YYYY hh:mm a')
    }
    if (column?.key === 'created_by') {
      newColumn.filterDropdown = props => (
        <AutocompliteFilter title='User Name' values={technicians} {...props} />
      )
    }
    if (column?.type === 'upload') {
      newColumn.render = value => {
        if (typeof value === 'string') {
          const toJsData = JSON.parse(value)
          return (
            <Wrapper>
              {toJsData?.map((file, idx) => (
                <ViewFile
                  key={idx}
                  file={file}
                  showName={true}
                  fileStyle={fileStyle}
                  isFullViewNeeded={true}
                />
              ))}
            </Wrapper>
          )
        } else return '--'
      }
    }

    if (column?.key === 'progress') {
      newColumn.filters = completedStatusFilter
      newColumn.render = (value, record) =>
        record?.completed ? (
          <i style={iconStyle} className='mdi mdi-check-circle' />
        ) : (
          <span> {value}</span>
        )
    }

    if (column?.key === 'type') {
      newColumn.filters = entityTypeFilter(dictionary.singular)
      newColumn.render = (type: CustomFormEntityType) => (
        <Tooltip title={entityTypeIcons(dictionary.singular)[type]?.label}>
          <i>{entityTypeIcons(dictionary.singular)[type]?.icon}</i>
        </Tooltip>
      )
      newColumn.filterMode = 'tree'
    }

    if (column?.key === 'proposal_code') {
      newColumn.filterDropdown = props => (
        <DefaultColumnFilter title='Project Code' {...props} />
      )
      newColumn.render = (value, record) =>
        value ? (
          <span
            style={LinkStyle}
            onClick={() => handleClick(`/projects/${record?.proposal_id}`)}
          >
            {value}
          </span>
        ) : (
          '--'
        )
    }
    if (column?.key === 'job_code') {
      newColumn.filterDropdown = props => (
        <DefaultColumnFilter title='Event ID' {...props} />
      )
      newColumn.render = (value, record) =>
        !isVisibleFor ? (
          <span>{value || '--'}</span>
        ) : value ? (
          <span
            style={LinkStyle}
            onClick={() =>
              handleClick(
                `/${record.type === 2 ? 'jobs' : 'appointment'}/${
                  record.job_id
                }`,
              )
            }
          >
            {value}
          </span>
        ) : (
          '--'
        )
    }
    if (column?.key === 'is_verified') {
      newColumn.align = 'center'
      newColumn.filters = verifiedStatusFilter
      newColumn.render = (value, record) => (
        <Tooltip
          title={
            !canFormVerify
              ? 'You have no permission to perform this action'
              : ''
          }
        >
          <Checkbox
            checked={value}
            onClick={e => {
              e.stopPropagation()
              handleFormVerify(record, e)
            }}
            disabled={!canFormVerify}
          />
        </Tooltip>
      )
    }
    if (column?.key === 'verified_name') {
      newColumn.render = (value, record) => (record.is_verified ? value : '-')
    }
    if (column?.key === 'client_name') {
      newColumn.filterDropdown = props => (
        <DefaultColumnFilter title={column.name} {...props} />
      )
    }
    if (column?.key === 'full_address') {
      newColumn.filterDropdown = props => (
        <DefaultColumnFilter title={column.name} {...props} />
      )
    }
    if (column?.key === 'is_sent') {
      newColumn.render = value =>
        value ? (
          <i style={iconStyle} className='mdi mdi-check-circle' />
        ) : (
          <span> {`--`}</span>
        )
    }

    return {
      ...newColumn,
      // width: 200,
      defaultWidth: 200,
      key: column.key,
      onCell: record => {
        return record.id === clickedRowIndex ? undefined : ellipsisStyle
      },
    }
  }

  const handleSelectTemplate = async (value: DefaultOptionType['value']) => {
    setLoading(true)
    setShowTable(false)
    // localStorage.setItem(`${tableName}-table_v1`, '')

    try {
      setValue(value)

      setTableName(`${value}-${name}`)
      const pageSize =
        getValueFromLS(`${value}-${name}-table_v1`)?.page_size || 25

      const { headers, forms, meta } = await getReportByTemplateId(value, {
        page: 1,
        per_page: pageSize,
        search: searchValue,
      })
      setColumns(headers.map(addAdditionaInfoToHead))
      setSummaryData(forms)

      setPagination(meta)
      setShowTable(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchTemplate = async (
    value: DefaultOptionType['value'],
    params = {},
  ) => {
    setLoading(true)
    try {
      setValue(value)

      const { headers, forms, meta } = await getReportByTemplateId(
        value,
        params,
      )
      setColumns(headers.map(addAdditionaInfoToHead))
      setSummaryData(forms)

      setPagination(meta)
      setShowTable(true)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  const handleTableChange = async (
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ISubmittedTable, string[]>>,
    sorter: SorterResult<ISubmittedTable>,
  ) => {
    filters.completed = filters.progress
    delete filters.progress

    try {
      await fetchTemplate(value, {
        page: pagination.current,
        sort_field: sorter.order ? sorter.field : null,
        order:
          sorter.order === 'ascend'
            ? 'asc'
            : sorter.order === 'descend'
            ? 'desc'
            : null,
        per_page: pagination.pageSize,
        search: searchValue,
        ...filters,
      })

      setSortOrder(
        sorter.order === 'ascend'
          ? 'asc'
          : sorter.order === 'descend'
          ? 'desc'
          : null,
      )
      setSortField(sorter.order ? sorter.field : null)
      setTableFilters({ ...filters })
    } catch (error) {
      openNotificationWithIcon('error', { message: error?.message })
      return Promise.reject(error)
    }
  }

  const handleClearTemplate = () => {
    setShowTable(false)
    setValue(null)
    setTemplates([])
    setColumns([])
    setSummaryData([])
    setSearch('')
    setSearchTemplate('')
    // localStorage.setItem(`${tableName}-table_v1`, '')
  }

  function onFilterOption(inputValue, option) {
    const matches = option?.options?.some(nestedOption =>
      nestedOption.label?.toLowerCase().includes(inputValue.toLowerCase()),
    )

    return matches
  }

  const handleExportTable = async () => {
    setLoading(true)
    exportSummayTable(value, {
      sort_field: sortField,
      order: sortOrder,
      search: searchValue,
      ...tableFilters,

      columns:
        getValueFromLS(`${tableName}-table_v1`)?.columns ||
        columns.map(column => column.dataIndex),
    })
      .catch(e => openNotificationWithIcon('error', { message: e?.message }))
      .finally(() => setLoading(false))
  }

  const onRow = record => ({
    onClick: () => {
      setClickedRowIndex(record.id)
    },
  })

  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      setIsFullScreen(false)
    }
  }

  const handleClick = (path: string) => {
    return window.open(path, '_blank')
  }
  const handleEditForm = async record => {
    if (!record.id) return

    try {
      const res = await getFormById(record.id)

      setForm(res)
      setEditFormModal(true)
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong!' })
    }
  }

  //   const handleCloseEditForm = async (editedForm, isRefetchNeeded) => {
  //     if (!editedForm) return setEditFormModal(false)
  //
  //     try {
  //       if (isRefetchNeeded) {
  //         const { forms } = await getReportByTemplateId(value, {
  //           form_id: editedForm.id,
  //         })
  //
  //         const existFormRow = forms?.find(item => item.id === editedForm.id)
  //
  //         setSummaryData(prev =>
  //           prev.map(item =>
  //             item.id === existFormRow.id ? { ...item, ...existFormRow } : item,
  //           ),
  //         )
  //       } else {
  //         setSummaryData(prev =>
  //           prev.map(item =>
  //             item.id === editedForm.id ? { ...item, ...editedForm } : item,
  //           ),
  //         )
  //       }
  //
  //       setEditFormModal(false)
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }

  const hadleFormUpdate = async (editedForm: CustomForm) => {
    if (!editedForm) return

    const { forms } = await getReportByTemplateId(value, {
      form_id: editedForm.id,
    })

    const existFormRow = forms?.find(item => item.id === editedForm.id)

    setSummaryData(prev =>
      prev.map(item =>
        item.id === existFormRow.id
          ? {
              ...item,
              ...existFormRow,
              proposal_id: item.proposal_id,
              job_id: item.job_id,
            }
          : item,
      ),
    )

    setEditFormModal(false)
  }

  const handleFormClose = (
    formId: number,
    fieldToUpdate: Record<keyof CustomForm, string>,
  ) => {
    setSummaryData(prev =>
      prev.map(form =>
        form.id === formId
          ? {
              ...form,
              ...fieldToUpdate,
            }
          : form,
      ),
    )

    setEditFormModal(false)
  }

  const handleDeleteForm = async (form: CustomForm) => {
    setLoading(true)
    try {
      await deleteFormById(form.id)

      setSummaryData(forms => forms.filter(el => el.id !== form.id))
      setEditFormModal(false)

      openNotificationWithIcon('success', {
        message: 'Form was deleted successfully!',
      })
    } catch (error) {
      openNotificationWithIcon('error', { message: 'Something went wrong' })
    } finally {
      setLoading(false)
    }
  }

  const handleCloseNNotificationModal = () => {
    const existsIds = checkedRows.map(item => item.id)

    setSummaryData(prev =>
      prev.map(item =>
        existsIds.includes(item.id) ? { ...item, is_sent: true } : item,
      ),
    )

    setCheckedRows([])
    setSelectedRowKeys([])
    setShowEmailModal(false)
  }

  const rowSelection = {
    selectedRowKeys,
    columnWidth: 30,
    onChange: (
      selectedRowKeys: React.SetStateAction<never[]>,
      selectedRows: {
        map: (arg0: (row: any) => any) => React.SetStateAction<never[]>
      },
    ) => {
      setCheckedRows(
        selectedRows.map(row => ({ ...row, display_info: row.name })),
      )
      setSelectedRowKeys(selectedRowKeys)
    },
  }

  const tableActionProps = template => ({
    todos: ['view'],
    callbacks: [() => handleEditForm(template)],
    preloaders: [false],
    tooltips: ['View form', false],
    popConfirms: [false],
  })

  useEffect(() => {
    setColumns(columns.map(addAdditionaInfoToHead))
  }, [clickedRowIndex])

  useEffect(() => {
    const fetchTemplatesList = async () => {
      setIsFetching(true)
      try {
        const templates = await getTemplatesList({ withTrashed: true })
        setTemplates(
          templates.data.map(item => ({
            value: item.id,
            label: item.title,
            deleted_at: item?.deleted_at,
          })),
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetching(false)
      }
    }
    if (open) fetchTemplatesList()
  }, [open])

  useEffect(() => {
    if (value) {
      fetchTemplate(value, {
        search: searchValue,
        page: 1,
        per_page: pagination.per_page,
      })
    }
  }, [searchValue])

  /*   useEffect(() => {
    return () => localStorage.setItem(`${tableName}-table_v1`, '')
  }, [])
 */
  const mappedTemplates = templates?.filter(item =>
    item?.label?.toLowerCase()?.includes(searchTemplate.toLowerCase()),
  )

  return (
    <TableWrapper>
      <TableLayout
        isFullScreen={isFullScreen}
        actions={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '97vw',
            }}
          >
            <Select
              placeholder='Select template'
              showSearch
              onSearch={value => setSearchTemplate(value)}
              loading={isFetching}
              onDropdownVisibleChange={open => setOpen(open)}
              //options={templates}
              options={[
                {
                  label: 'Active Templates',
                  options: mappedTemplates.filter(item => !item?.deleted_at),
                },
                {
                  label: 'Deleted Templates',
                  options: mappedTemplates.filter(item => !!item?.deleted_at),
                },
              ]}
              value={value}
              onSelect={handleSelectTemplate}
              allowClear
              onClear={handleClearTemplate}
              autoClearSearchValue
              filterOption={onFilterOption}
              style={{ margin: '5px 0', width: '400px' }}
            />
            <ActionsWrapper>
              {value && (
                <IconsBlock>
                  {canSend && (
                    <MainButton
                      onClick={() => setShowEmailModal(true)}
                      disabled={!checkedRows?.length}
                      title='Send'
                    />
                  )}

                  <InputField
                    label='Search'
                    value={search}
                    style={{ width: '100%', maxWidth: '200px' }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSearch(e.target.value)
                    }
                  />
                  <IconWrapper
                    onKeyDown={handleKeyPress}
                    onClick={() => setIsFullScreen(prev => !prev)}
                  >
                    {isFullScreen ? (
                      <FullscreenExitOutlined style={{ fontSize: '24px' }} />
                    ) : (
                      <FullscreenOutlined style={{ fontSize: '24px' }} />
                    )}
                  </IconWrapper>
                  <IconWrapper onClick={() => setOpenTableSettings(true)}>
                    <Tooltip placement='left' title='Columns settings'>
                      <Settings />
                    </Tooltip>
                  </IconWrapper>
                  <TableExport
                    handleExportTable={handleExportTable}
                    loading={loading}
                    currentCount={pagination?.total}
                  />
                </IconsBlock>
              )}
            </ActionsWrapper>
          </div>
        }
      >
        <Spin spinning={loading}>
          {showTable ? (
            <Table
              name={tableName}
              columns={[
                ...columns,
                {
                  title: 'Actions',
                  name: 'Actions',
                  dataIndex: 'action',
                  width: 100,
                  key: 'action',
                  noFilter: true,
                  render: (value, record) => (
                    <TableActionsNew {...tableActionProps(record)} />
                  ),
                },
              ]}
              dataSource={summaryData}
              size='small'
              onRow={onRow}
              pagination={pagination}
              loading={loading}
              onChange={handleTableChange}
              openTableSettings={openTableSettings}
              onCloseTableSettings={() => setOpenTableSettings(false)}
              rowSelection={rowSelection}
            />
          ) : (
            <Empty description='No form selected' />
          )}
        </Spin>

        {openEditModal && (
          <EditFormModal
            action='update'
            // handleCancel={() => setEditFormModal(false)}
            onVerify={handleVerifyFromForm}
            handleCancel={handleFormClose}
            onSave={hadleFormUpdate}
            onDeleteForm={handleDeleteForm}
            isModalOpen={openEditModal}
            form={form}
          />
        )}
        {showEmailModal && (
          <NotificationModal
            title='Send Forms'
            showModal
            setShowModal={handleCloseNNotificationModal}
            templateType={NotificationTemplateType.GENERAL}
            entityIds={checkedRows.map(item => item.id)}
            otherClientEmails={[
              ...new Set(
                checkedRows.flatMap(item =>
                  JSON.parse(item.primary_contact_emails),
                ),
              ),
            ].filter(item => item && validator.isEmail(item))}
          />
        )}
      </TableLayout>
    </TableWrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 4px;
`

const TableWrapper = styled.div`
  & .custom-page-headers {
    /* position: absolute;
    right: 20px;
    top: 34px;
    z-index: 2; */
  }
`
