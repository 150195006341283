import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import TableActions from '../../../../../components/TableActionsNew'
import './TableMy.scss'
import Rating from '../../../../../Clients/components/Rating'
import {
  getClientFeedback,
  updateClientFeedback,
  deleteClientFeedback,
  createClientFeedback,
} from '../../../../../../../../api/ClientFeedbacks'
import FeedbackModal from '../../../../../Clients/components/Feedback'
import * as actions from '../../../../../../../../store/Orcatec/actions'
import InputField from 'containers/MainContent/Orcatec/components/Input'
import { openNotificationWithIcon } from 'helpers/notifications/openNotificationWithIcon'
import { updatePropertyRelation } from 'api/Property'
import { updateAssociation, attachOrganizationToClient } from 'api/Client'
import { Link } from 'react-router-dom'
import Table from '../../../../../components/Table'
import { ellipsisStyle } from 'containers/MainContent/Orcatec/Properties'
import {
  ClientIcon,
  OrganizationIcon,
} from '../../../../../components/Icons/CommonIcons'
import { ClientType } from 'types/Client'
import { useAppSelector } from 'store/Orcatec/hooks'
import { Companies } from 'types/Company'
import { useTranslation } from 'react-i18next'

const style = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}

interface IClients {
  key: number
  contact_relation_id: number
  contact_role: boolean
  created_at: Date
  deleted_at: Date
  emails: []
  feedBackList: []
  id: number
  industry: string | null
  name: string
  note: null
  notes: []
  other_company_client_id: []
  owner_user_id: number
  phones: []
  pivot: { client_id: number; organization_id: number }
  properties: []
  relative_company_id: number
  tags: []
  type: number
  updated_at: Date
  user_id: number
  vote: number
  website: string | null
  relation_name?: string | null
  relation_id?: number | undefined
  relation?: string | null
  property_relation?: number
  rating?: { count_votes: number }
  relations?: string | null
}

interface IProps {
  clients: IClients[]
  onDellAttachedClient: () => void
  propertyId: number
  fetchProperty: () => void
  organizationId: number
  isOrganization: boolean
  isAssociation: boolean
  isClient: boolean
  getPopupContainer: object
  stepOfOpenedForm: number
  onUpdateRelation: () => void
  onDetachClientOrOrganization: () => void
}

export const TableMy = ({
  clients,
  onDellAttachedClient,
  propertyId,
  fetchProperty,
  organizationId,
  isOrganization,
  isAssociation,
  isClient,
  getPopupContainer = {},
  stepOfOpenedForm,
  onUpdateRelation,
  onDetachClientOrOrganization,
  assotiationsId,
}: IProps) => {
  const dispatch = useDispatch()

  const { t } = useTranslation('property')

  const [feedbackForm, toggleFeedbackForm] = useState(false)
  const [clientFeedbacks, setClientFeedbacks] = useState([])
  const [clientId, setClientId] = useState<number | null>(null)
  // const [deletIds, setDeletingIds] = useState<number[]>([])
  const [clickedRowIndex, setClickedRowIndex] = useState<number | null>(null)

  const company = useAppSelector(state => state.orcatec.company)
  const showDeleteButton = !!onDellAttachedClient
  const showToggleButton = stepOfOpenedForm < 3
  const showActionsCol =
    showToggleButton || showDeleteButton || onDetachClientOrOrganization
  const clientType = isOrganization ? 1 : 2
  const getClient =
    clientType === 1
      ? actions.organizations.getOrganizationById
      : actions.client.getClientById
  const showFeedbacksModal = (id: number) => {
    getClientFeedback(id, clientType).then(({ data: feedbacks }) => {
      setClientFeedbacks(feedbacks)
      setClientId(id)
      toggleFeedbackForm(!feedbackForm)
    })
  }

  const handleDeleteAttachedClient = (client: IClients) => () => {
    if (onDetachClientOrOrganization) {
      return onDetachClientOrOrganization(propertyId, client?.id, client.type)
    }
    if (onDellAttachedClient) {
      // setDeletingIds(prev => [...prev, client.id])
      onDellAttachedClient(client)
      //.finally(() => {
      // setDeletingIds(prev => prev.filter(id => id !== client.id))
      //})
    }
  }

  const onCreateFeedback = (id: number, data) => {
    const requestData = {
      entity_id: data.id,
      comment: data.comment,
      vote: data.vote,
      entity_type: clientType,
    }

    return createClientFeedback(id, requestData).then(() => {
      return dispatch(getClient(id)).then(res =>
        setClientFeedbacks(res.feedbacks),
      )
    })
  }

  const onDeleteFeedback = (id: number, userId: number) => {
    return deleteClientFeedback(id).then(() =>
      dispatch(getClient(userId)).then(res =>
        setClientFeedbacks(res.feedbacks),
      ),
    )
  }

  const onUpdateFeedback = (id: number, data, userId: number) => {
    return updateClientFeedback(id, data).then(() =>
      dispatch(getClient(userId)).then(res =>
        setClientFeedbacks(res.feedbacks),
      ),
    )
  }

  const handleUpdateRelation = async (
    e: React.ChangeEventHandler,
    record: IClients,
  ) => {
    const relationValue = e.target.value
    if (relationValue === record?.property_relation) return

    if (isAssociation) {
      await updateAssociation(assotiationsId, relationValue)
      return openNotificationWithIcon('success', {
        message: 'Relation has been updated',
      })
    }
    if (isOrganization || organizationId) {
      await attachOrganizationToClient(record.pivot.client_id, {
        organization_id: record.pivot.organization_id,
        contact_role: relationValue,
      })
      await onUpdateRelation?.()

      return openNotificationWithIcon('success', {
        message: 'Relation has been updated',
      })
    }

    await updatePropertyRelation({
      relation_id: record.relation_id,
      property_relation: relationValue,
    })

    await onUpdateRelation?.()

    openNotificationWithIcon('success', {
      message: 'Property relation has been updated',
    })
  }
  const renderTableName = (value: string, client: IClients) => {
    return (
      <>
        <Link
          to={`/clients/${client.id}?clientType=${
            isAssociation ? 2 : client.type
          }`}
          target='_blank'
        >
          {value}
        </Link>
        <Tooltip title={`Rating: ${client.vote}`} {...getPopupContainer}>
          <div
            className='client-rating'
            onClick={() => showFeedbacksModal(client.id)}
          >
            <Rating
              max={5}
              value={client.vote}
              readOnly
              precision={0.5}
              size='small'
            />
            {/* {!!client.feedbacksCount && <p className='rating-count'>{client.feedbacksCount}</p>} */}
          </div>
        </Tooltip>
      </>
    )
  }

  const renderRelation = (text: string, record: IClients) => {
    // if (organizationId) return <p>{text}</p>

    return (
      <InputField
        name='relation'
        defaultValue={text}
        onClick={e => e.stopPropagation()}
        onBlur={e => handleUpdateRelation(e, record)}
      />
    )
  }

  const renderArray = (array: IClients[], record: IClients, key: string) => {
    if (!array || !array.length) return '-'
    array = array?.map(el => (el[key] ? el[key] : el))
    if (record.key === clickedRowIndex) {
      return (
        <div>
          {array?.map(item => (
            <p key={item}>{item}</p>
          ))}
        </div>
      )
    }
    return (
      <div style={{ display: 'flex' }}>
        <Tooltip
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {array?.map(el => (
                <div key={el}>{el}.</div>
              ))}
            </div>
          }
        >
          <span style={style}>{!!array[0] && array[0]}</span>
        </Tooltip>
        {array?.length > 1 && (
          <span className='rest-items'>+{array.length - 1}</span>
        )}
      </div>
    )
  }

  const columns = [
    {
      title: t('tabs.contacts.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      noFilter: true,
      render: (value: Pick<IClients, 'name'>, client: IClients) =>
        renderTableName(value, client),
    },
    {
      title:
        company.id === Companies.SoCal
          ? 'Project Name'
          : t('tabs.contacts.relation'),
      dataIndex: 'relations',
      key: 'relations',
      sorter: false,
      noFilter: true,
      render: (text: Pick<IClients, 'relations'>, record: IClients) =>
        renderRelation(text, record),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: t('tabs.contacts.type'),
      dataIndex: 'type',
      key: 'type',
      sorter: false,
      noFilter: true,
      defaultWidth: 50,
      render: (type: IClients['type']) => (
        <span>
          {+type === ClientType.ORGANIZATION ? (
            <Tooltip title='Organization'>
              <i>
                <OrganizationIcon />
              </i>
            </Tooltip>
          ) : (
            <Tooltip title='Individual'>
              <i>
                <ClientIcon />
              </i>
            </Tooltip>
          )}
        </span>
      ),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: t('tabs.contacts.properties'),
      dataIndex: 'properties',
      key: 'properties',
      sorter: false,
      noFilter: true,
      render: (property: Pick<IClients, 'properties'>, record: IClients) =>
        renderArray(property, record, 'full_address'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: t('tabs.contacts.notes'),
      dataIndex: 'notes',
      key: 'notes',
      sorter: false,
      noFilter: true,
      render: (notes: Pick<IClients, 'notes'>, record: IClients) =>
        renderArray(notes, record, 'text'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: t('tabs.contacts.email'),
      dataIndex: 'emails',
      key: 'emails',
      sorter: false,
      noFilter: true,
      render: (emails: Pick<IClients, 'emails'>, record: IClients) =>
        renderArray(emails, record, 'email'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },
    {
      title: t('tabs.contacts.phone'),
      dataIndex: 'phones',
      key: 'phones',
      sorter: false,
      noFilter: true,
      render: (phones: Pick<IClients, 'phones'>, record: IClients) =>
        renderArray(phones, record, 'phone'),
      onCell: (record: IClients) =>
        record.key === clickedRowIndex ? undefined : ellipsisStyle,
    },

    {
      title: t('tabs.contacts.actions'),
      dataIndex: '',
      key: 'actions',
      sorter: false,
      noFilter: true,
      defaultWidth: 55,
      className: showActionsCol ? '' : 'hidden-column',
      render: (value: any, client: IClients) => {
        return (
          <div className='attached-client-delete-confirm'>
            <TableActions
              todos={['detach']}
              popConfirms={[
                `Are you sure you want to detach this ${
                  isClient
                    ? 'client'
                    : isAssociation
                    ? 'association'
                    : 'organization'
                }?`,
              ]}
              callbacks={[handleDeleteAttachedClient(client)]}
              tooltips={[
                `Detach this ${
                  isClient
                    ? 'client'
                    : isAssociation
                    ? 'association'
                    : 'organization'
                }?`,
              ]}
              popConfirmPositions={['left']}
            />
          </div>
        )
      },
    },
  ]
  const makeDataForTable = clients?.reduce(
    (data: IClients[], el: IClients, idx: number) => {
      const {
        name,
        phones = [],
        emails = [],
        notes = [],
        pivot = {},
        id,
        type,
        tags,
        relation_name,
        contact_role,
        property_relation,
        relation_id,
        contact_relation_id,
        feedBackList = [],
        properties = [],
        rating,
      } = el
      const relations = organizationId
        ? contact_role
          ? contact_role
          : ''
        : relation_name
        ? relation_name
        : property_relation
        ? property_relation
        : ''
      data.push({
        key: idx,
        relation_id,
        id,
        name,
        property_relation,
        relations,
        properties,
        type,
        vote: organizationId ? el.vote : rating?.value || 0,
        feedbacksCount: rating?.count_votes || 0,
        notes,
        phones,
        emails,
        tags,
        contact_relation_id,
        feedBackList,
        pivot,
      })
      return data
    },
    [],
  )

  const onRow = (record: IClients, rowIndex: number | null) => ({
    onClick: () => {
      if (rowIndex !== clickedRowIndex) {
        return setClickedRowIndex(rowIndex)
      }
      setClickedRowIndex(null)
    },
  })

  return (
    <>
      <Table
        columns={columns}
        dataSource={makeDataForTable}
        pagination={false}
        size='small'
        settings={false}
        className='myTable'
        name='property-contact'
        onRow={onRow}
        autoHeight
      />
      {clientFeedbacks && (
        <FeedbackModal
          feedback={clientFeedbacks}
          modal={feedbackForm}
          clientId={clientId}
          close={() => {
            fetchProperty(propertyId)
            setClientId(null)
            toggleFeedbackForm(false)
          }}
          onSaveFeedback={onUpdateFeedback}
          onCreateFeedback={onCreateFeedback}
          onDeleteFeedback={onDeleteFeedback}
        />
      )}
    </>
  )
}
